
  
  .archive-dialog-modal {
    height: auto;
    /* margin-right: 100px; */
    margin-top: 120px;
    width: auto;
    /* width: 600px;
    max-width: 1000px; */
  }
  .archive-dialog-modal .modal-content {
    width: unset;
    margin: auto;
    text-align: center;
    border: none;
  }


  .archivetitle {
       margin:1em;
       font-size: 1.5rem;
  }

  

  
  .archive-dialog-modal .archivebuttons{    
    display: flex;
    flex-direction: row;
    width: 50%;
    margin-left: 25%;
    height: 10%;
    /* height: 1000px; */
    /* justify-content: space-between;    */
    justify-content: space-around;
    align-content: center;
    margin-bottom: 1em;
    margin-top: 1em;
  }
  .archivebuttons .restore-button {
    width: 20%;
    height: 55px;
    color: white;
    background: rgb(141,198,63);
  }

  .archivebuttons .restore-spinner{
    height:auto;
    margin-top:45%;
    color: white;
  }
  
  .archivebuttons .decline-button {
    width: 20%;
    height: 55px;
    color: white;
    background: #e74c3c
  }

  .available-countdown{
    /* font-size: 1rem; */
    margin: 0.25em;
  }
  
  
  .preloader-archive {
    width: auto;
    height: 130px;
    text-align: center;
    font-size: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .preloader-archive > div {
      margin: 2px;
      background-color: white !important;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      -webkit-animation: stretchdelay 0.7s infinite ease-in-out;
      animation: stretchdelay 0.7s infinite ease-in-out; }
    .preloader-archive .circ2 {
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s;  }
    .preloader-archive .circ3 {
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s; }
    .preloader-archive .circ4 {
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s; }
    .preloader-archive .circ5 {
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s; }
    .preloader-archive .loader-md {
      height: 13px;
      width: 13px; }
    .preloader-archive .loader-lg {
      height: 18px;
      width: 18px; }
    
    
    
  