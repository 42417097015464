/*******************************************************************
Style the filter bar for the Call-ins tab:
********************************************************************/

.callin-filters {
    /* background: #e9e9e9; */
    
    position: relative;
    margin-left:auto; /*Add space to the left side: right aligned*/
    margin-top:-6em; /*Shift up*/
  
    /* grid-column: 3; */
    user-select: none;
  
    display: grid;
    grid-template-columns: auto fit-content(250px) auto;
    /* The date pick section needs a fixed width (at least 200px)`*/

    /*Auto scale down to the required size*/
    width:fit-content; 

    border-radius: 4px;
    padding: .7vh 0px .7vh .5vw;
    font-size: 13px;

    /* border: solid 1px black; */
  }

  /* .callin-filters > * {
    border: solid 1px black;
  } */

/*******************************************************************
  Apply section
********************************************************************/
.callin-filters .section-apply {
    /* Define the layout as grid so we can center easily in the grid layout */
    display: grid;
    
    align-items:center; /*Position verically in cell*/
    justify-items:end; /*Position horizontally in cell*/
    padding-right:1em;
    /* place-items: center;
    text-align: right; */
    /* width: fit-content; */
    
}

.callin-filters .section-apply .apply-filters{
    margin-right: unset;
    
}



/* Use the fixed date-picker size to prevent changes to experiences */
.callin-filters .date-pick {
    height: 27px !important;
    width: 100px !important;
    font-size: 13px;
    border: 1px solid #bdc3c7;
    border-radius: 2px;
}
/* .callin-filters .react-datepicker__close-icon {   right: 12px;  } */

/*******************************************************************
  Dropdown filters Section
********************************************************************/
.callin-filters .section-dropdowns {
    position: relative;    
     /* Define the layout as grid so we can center easily in the grid layout */
    display: grid;
    place-items: center;
    max-width: 60vw;
}

/* Remove/update the from the dropdown menus*/
.callin-filters :nth-child(2), .callin-filters :nth-child(3) {  padding: 0px;  }
.callin-filters .filter-drop-container .dropdown-wrap{   padding-right:unset !important; }  
.callin-filters .label-wrap {
    padding-right: 3px !important;
    padding-left:10px;
}
  
  

/* Update the formatting on the select - this is the visible top part of the dropdown menu */
  .callin-filters select {
    height: 27px !important;
    font-size: 13px;
    /* Remove the default filtering of the select dropdown */
    -webkit-appearance: none;
    -moz-appearance: none;
     appearance: none; 
    /* Add some styling */
    display: block;
    font-family: sans-serif;
    color: #444;
    line-height: 1.3;
    padding: .25em 1.4em .25em .8em;
    max-width: 100%; /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: .5em;
    background-color: #fff;
    /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
        for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
    */
    /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), */
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right .4em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 1.25em auto, 100%;
  }
/*Limit the number of characters in the dropdown selection (make it smaller on smaller screens?)*/
/* 4ch is the starting size of thte empty dropdown with an arrow, need +3 ch for the ellipsis */
@media screen and (max-width:1200px) { /*only apply if the screen width is less than*/
    .callin-filters .filter-drop-asset select.form-control.form-control-default{    max-width: 11ch; } /*XXYYYY format allowed without ellipsis*/
    .callin-filters .filter-drop-clip select.form-control.form-control-default{     max-width: 13ch;}
    .callin-filters .filter-drop-hardware select.form-control.form-control-default{ max-width: 14ch;}
    .callin-filters .filter-drop-site select.form-control.form-control-default{     max-width: 14ch;}
    .callin-filters .filter-drop-edge3 select.form-control.form-control-default{    max-width: 10ch; }        
}
/* Set a default length cutoff on a dropdown element:  */
.callin-filters select.form-control.form-control-default {
    max-width: 9vw;
    overflow: hidden;
    text-overflow: clip; /*Doesn't work on android?*/
    text-overflow: ellipsis;
    white-space: nowrap;
}
  
  