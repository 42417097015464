/* Configure the filter bar and filters that are displayed on the Reporting tab */
/* The Reporting tab is configured as 2x3 grid to layout the different sections of the page*/ 


/* Configure the Total Notecards display located to the left of the workflows */
.hr-review .search-results {
    /* Set background color and and round the edges of the box */
    background-color: #e9e9e9;
    border-radius: 4px;

    /* Define the size of the box to display*/
    height: 3vh;
    width: 9.5vw; 
    
    /* Define a padding left top bottom right to keep the text away from
        the edges of the box */
    padding: 0.75vh 0.4vw 0.75vh 0.4vw; 
    
    
    user-select: none; /* Disable the user-select, prevent drag clicking and highlighting */
    /* Set up the element in Flex so that it balances the label and the count*/
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    /* Set the font size for the label, weight of 600 is slighlty bold*/
    font-size:0.75vw;
    font-weight: 600;
    
    /* Configure the location in the Reporting page grid 
        second row aligns below filters event with the workflows
    */
    grid-row:2;
    grid-column: 1;
    
}


/* Set up the filters bar at the top of the page*/
.hr-review .review-filters {
    background-color: #e9e9e9; /* set the background color*/
    border-radius: 4px; /* configure the corner radius to round the edges*/
    padding: 0.752vh 0 0.752vh 0.3125vw; /* Add a default padding to move the content away from the edge*/
    font-size: 0.75vw; /* Set the default font size */
    height: 5vh; /* Configure the height of the filter bar */
    grid-column: 2; /* Set the location to the right portion of the page*/
    user-select: none; /* Disable user selection to prevent highlighting*/
    /* Set the display to flex type to allow the elementts to balance along the row*/
    display: flex;
    flex-direction: row;
    align-items: center;
    width:fit-content; /* Resize the filter bar based on the number of filters that are avaiable*/
    justify-self: end; /* Right justify the child elements, stack from right to left*/
}

/* Set the background color for all filter elements*/
.hr-review .review-filters > * {
    background-color: #e9e9e9;
}

/* Change the default padding around the dropdown menus*/
.hr-review .review-filters :nth-child(2), .review-filters :nth-child(3) {   padding:0;}

/* Override the default datePicker styles */
.hr-review .review-filters .date-pick {
    margin-right: .41vw; /* Add a space between the pickers*/ 
    border: 1px solid #bdc3c7; /*Make the border softer and thinner */
    border-radius: 2px; /*Round the edges */

    font-size: 0.75vw;   /* Set the font-size to match the page */
    /* Force the height and width*/
    height: 2.9vh !important; 
    width: 7vw !important; 
}
/* Override the padding given to the dropdown menu choices*/
.hr-review .review-filters .label-wrap {
    padding-right: 0.156vw !important;
}

/* Override the style of the dropdown menu buttons*/
.hr-review .review-filters select {
    height: 2.9vh !important;
    font-size: 0.75vw;
    /* Remove the default filtering of the select dropdown */
    -webkit-appearance: none;
    -moz-appearance: none;
     appearance: none; 

    /* Update the display style */
    display: block;
    font-family: sans-serif; /*Change the font type */
    color: #444; /* Set the default color */
    line-height: 1.3; /* Change the space around text to center better*/
    padding: .25em 1.4em .25em .8em;  /* Update teh padding to being the labels into the thinner buttons*/
    
    max-width: 11vw; /* Restrict the width of the dropdown, be default it will update to the longest string in the menu */
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa; /* Add a faint border around the button */ 
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04); /* Add a small shadow beneath and to the right of the button */
    border-radius: .5em;
    background-color: #fff;
    /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
        for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
    */
    /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), */
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right .4em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 1.25em auto, 100%;
    /* font-size:.6vw; */
    
}

.hr-review .review-filters .react-datepicker__close-icon {
    /* right: 12px; */
    right: 0.625vw;
    width: 0.8333vw;
    height: 1.719vh;
    
}
.hr-review .review-filters .react-datepicker__close-icon::after {
    width:100%;
    height:100%;
    line-height: 0.7em;
    right: 0.234vw;
    font-size: 1vw;
    padding: unset;
}
@media (-webkit-device-pixel-ratio: 2) {
    .hr-review .review-filters .react-datepicker__close-icon::after {
        line-height: 0.8em;
    }
    
}


.hr-review .apply-filters{
    /* border-radius: 15px; */
    /* width: 80px; */
    /* margin-right: 25px; */
    border-radius: 0.78vw;
    width: 4.166vw;
    margin-right: 1.30vw;

    background-color: #AFD47B;
}

/* Add the interaction with the apply button, set the transforms to scale the button*/
/* add the :enabled tag to eliminate the need for separate actions on the [disabled] tags*/
.hr-review .apply-filters:hover:enabled{
    transform:scale(1.05);
}
.hr-review .apply-filters:active:enabled{
    transform:scale(0.9); 
}


.hr-review .audioalert-filter{
    margin-right: 1.04vw;
    display: flex;
    width: 5.5vw;
    justify-content: space-around;
    align-items: center;   
}

.hr-review .review-card:hover{
    /* background-color: red; */
    transform:scale(103%,103%) 
}
.hr-review .review-card-placeholder:hover{
    /* background-color: red; */
    transform:scale(100%,100%) 
}

