/* Add styles to the Selector to remove the hardcoded padding and min-height. These require that the
*  min-height is 38 pixles. These changes allow it to be smaller than 38 pixels in height.
*/
.dropdownlist__control{ min-height: unset !important;  height:100%;}
.dropdownlist__value-container.dropdownlist__value-container{ padding:unset;}
/* Remove the padding around the value inside the dropdown list */
.dropdownlist__input-container{  padding:unset;}
/* Update the padding around the selector icon */
.dropdownlist__indicator.dropdownlist__dropdown-indicator{padding:2px;}
/* Update the padding around the clear icon */
.dropdownlist__indicator.dropdownlist__clear-indicator{padding:2px;}



