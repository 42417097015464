/* Style a popup that Displays a simple message and asks the user to confirm or deny the options */

.userconfirm-modal{
    margin-top: 20vh; 
    width: 30vw;
    height: 30vh;    
    max-width: 40vw; 
    user-select: none;
}


/* Set attribute for modal content that is child of the userconfirm-modal */
.userconfirm-modal .content-modal {
    /* Define a grid Title, body, footer */
    display: grid;
    grid-template-rows: 25% 50% 25%;
    grid-template-columns: 100%; 

    font-size: 1.25em;

    padding-left:5%;
    padding-right:5%;
    /* grid-template-rows: fit-content(10%) 25% auto 20px; */
}
.content-modal .footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.userconfirm-modal .message {
/* Vertically center */
    display: flex;
    justify-content: center;
    align-items: center;
}

