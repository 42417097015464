.callin-lister {
  font-size: 14px;
  margin-top: -4em;
}


/*=====================================================================
  Format Call-ins list table
  - call-in lister is a surrounding div
  - callin-table is the actual table
=====================================================================*/
.callin-lister table.callin-table {
    table-layout: auto !important; 
 }
.callin-lister .table-row{
  /* background-color: red ; */
  height: 15px !important;
}




 /* hide the icons that come with the library, they're not working correctly */
.callin-lister th.sortable span.order, .callin-lister th.sortable span.react-bootstrap-table-sort-order {
    display: none;
  }
  
  .callin-lister th.sortable {
    position: relative;
    padding-right: 24px;
  }
  
  .callin-lister th.sortable::after {
    content: '\f0dc';
    font-family: 'Font Awesome 5 Free';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    margin-left: 0.6em;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
  }
  
  .callin-lister th.sorted-asc::after {
    content: '\f0de';
  }
  
  .callin-lister th.sorted-desc::after {
    content: '\f0dd';
  }
  

  .callin-lister .nodata{
    font-size: 150%;
    font-weight: 400;
    margin-top:5vh;
  }
  
  
  .callin-lister th {
    user-select: none;
  }


/* Configure the cell in the Table: */
.callin-table tbody tr td {
    /* max-width: 15vw !important; */
    width: calc(10vw - 0.75rem);
    overflow-x: hidden; 
    text-overflow: ellipsis;    
    vertical-align: middle;
    /* Enable word wrapping in the cells */
    word-wrap: break-word;
    white-space: unset;
    
}
/* Configure the header in the Table: */
table.callin-table thead tr th{
  width: calc(10vw - 0.75rem);
  word-wrap: break-word;
  white-space: unset;
  vertical-align: middle;
}

/* Don't allow the up/down arrows to be split on different lines */
table.callin-table thead tr th > span{
  white-space: nowrap;
}

/* Individually change, start at 1 - with Margins should have around 95vw*/
table.callin-table th:nth-child(1){  width: calc(10vw + 1.5rem);} /*Review card column */
table.callin-table td:nth-child(1){  width: calc(10vw + 1.5rem);} 
table.callin-table th:nth-child(2){  width: 13vw;} /*Infraction type column */
table.callin-table td:nth-child(2){  width: 13vw;}
table.callin-table th:nth-child(5){  width: calc(11vw - 0.75rem);} /*Contact name column */
table.callin-table td:nth-child(5){  width: calc(11vw - 0.75rem);}
/* Set the last row to auto resize to the rest of the table */
table.callin-table th:nth-child(9){  width: auto;} /*Notes column */
table.callin-table td:nth-child(9){  width: auto;}


.callin-table .review-card{
    width: 9vw;
    height: 6.75vh;
    margin-left:auto;
    margin-right:auto;
    transition-duration:50ms;     
}

.callin-table .review-card .date{
  transform: translateY(-.15em);
}

.callin-table .review-card:hover{
  transform:scale(105%,105%) 
}

.notes-short{
    max-width: 15vw !important;
    overflow-x: hidden; 
    text-overflow: ellipsis;    
}
.notes-long{
    /* max-width: 15vw !important;
    overflow-x: hidden; 
    text-overflow: ellipsis;     */
    /* position: relative; */
    background-color: white;
    margin-right:100px;
    text-align: center;
    min-height:2em;
    /* width:35%; */
    /* padding-left: 2%;
    padding-right: 2%; */
     border: 1px solid;
     border-radius: 5px;
    /* margin-top:400px; */
}
.notes-long .comments-section{
    width: 100%;
    border-bottom:1px solid black;
    padding-bottom: 20px;
    /* border-radius: 5px; */
}
.notes-long th, td, tr{
    text-align: center;
}
.notes-long th, td{
    border-bottom:1px solid black;
    padding: 5px;
}
.notes-long th:nth-child(2){
    border-left:1px solid black;
    border-right:1px solid black;
}
.notes-long td:nth-child(2){
    border-left:1px solid black;
    border-right:1px solid black;
}

.notes-short .callcount{
  /* padding: 5px; */
  /* font-size: 12px; */
  border-radius: 50%;
  padding-left:2%;
  padding-right:2%;
  outline:1px solid black;
  margin-left:1em; 
  margin-top: auto;
  margin-bottom: auto;
}
.notes-short .badge{    
  padding: 5px;
  font-size: 12px;
  margin-left:0.5em;
  margin-top: auto;
  margin-bottom: auto;
}

/* table, th, td {
    border:1px solid black;
  } */


/* Add a vertical scroll bar to the table */
.callin-table tbody {
    display:block;
    height:60vh;
    overflow-y:scroll;
}
.callin-table thead{
    display:table;
    width:100%;
    table-layout:fixed;
}
.callin-table tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
.callin-table .status-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.modal-content .progress{
  margin-bottom:1vh;
}
/* Display the total number of elements at the bottom of hte table */
.callin-lister .table_total{
  display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  column-gap: .5vw;
  align-items: center;  
  width: 15%;
}

