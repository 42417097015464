.storyline-content {
  width: 100%;
  height:100%;
  
  text-align: center;  
  display: grid;
  grid-template-rows: 12% 80% 8%;
  
  /* margin-top: 3%; */
}

/* .storytile-content > * {
  background-color: #ccc;
} */

.storyline-content .titleSection{
  width: 100%;
  /* background-color: red; */
  display: grid;  
  grid-template-columns: 23% 15% 30% 30%; 
  grid-template-rows: auto;
  text-align: left;
  /* margin-left:5%; */
  margin-top:auto;
  margin-bottom:auto;
  font-size: clamp(10px,1vw,16px);
  /* font-size: 1vw;  */
  /* font-size: 110%; */
  margin-left:2vh;  

}

.storyline-content .body{
  width: 100%;
  display: grid;
  grid-template-columns: 77% 22%;
}

.storyline-content .body .left{
  /* width: 100%; */
  display: grid;
  grid-template-rows: 60% 40%;
}

.storyline-content .stemplot{
  /* height: 10vh; */
  /* position: absolute; */
  height: 100%;
  width:100%;
  max-width: 100;
  /* overflow-x: scroll; */
  overflow-x: hidden;
}
/* Prevent the notecards from running out off the edge of the card */
.storyline-content .body .right{
  max-height: 100%;
  overflow: hidden;
}

.storyline-content .review-card-list{
  width: 100%;
  height: 100%;
}
/* .storyline-content .review-card-list .ReactVirtualized__Grid, .storyline-content .ReactVirtualized__List {
  width:100% ;
  height: 100% ; 
} */
/* .storyline-content .ReactVirtualized__Grid__innerScrollContainer {
  height: 100%;
  max-height: 100%;
  overflow-y: auto !important; 
}  */


.storyline-content .spinner-wrapper{
  /* position: absolute; */
  width:100%;
  height: 100%;
}

.storyline-content .summary{
  /* background-color: red; */
  /* margin-left:10%; */
  text-align: left;  
  margin-left:2vh;  
}

.storyline-content .title{
  /* margin-left:-5%; */
  text-align: left;
  font-size: 125%;
  font-weight: bold;
  margin-bottom:2%;
  /* border-top: 1px solid black; */
}

/* .storyline-content .summary >div >span{
  margin-left: 5%;  
} */


.storyline-content .bottom{
  display:grid;
  grid-template-columns: 80% 20%;
}

.storyline-content .downloadicon{
  height: 40%;
  width: auto;
}

/* .storyline-content .report-btn{
  height: 30%;
  width: auto;
  margin: auto;
  border-radius: 20%;
} */

.storyline-content .footer{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.storyline-content .footer .btn{
  font-size: 1em;
  margin-bottom: 2px;  
}
.storyline-content .btn:hover:enabled{
  transform:scale(1.1,1.1);
  font-size: 1em;
}
.storyline-content .btn:disabled{
  opacity: .3;
}
.storyline-content .btn[disabled]:hover{
  transform:scale(1,1);
  background-color:#28a745;
  border-color:#28a745;
}


