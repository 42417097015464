/* Style a popup that Displays a simple message and asks the user to confirm or deny the options */

.videocutreview-modal{
    margin-top: 10vh; 
    width: 75vw;
    height: 70vh;    
    max-width: 70vw; 
    user-select: none;
}


/* Set attribute for modal content that is child of the userconfirm-modal */
.videocutreview-modal .content-modal {
    /* Define a grid Title, body, footer */
    display: grid;
    grid-template-rows: fit-content(25%) 50% 25%;
    grid-template-columns: 100%; 

    font-size: 1.25em;

    padding-left:5%;
    padding-right:5%;
}

.videocutreview-modal .message {
/* Vertically center */
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* Override the default behavior to move to the right side since there is only one button */
/* .videocutreview-modal .footer{
    justify-content: flex-end;
} */

/*Force a height so that the pagination is set in a constant spot*/
.videocutreview-modal .react-bootstrap-table{
    height: 51vh; 
}
.videocutreview-modal .cutsTable{
    width: 90%;
    margin-left: 5%;
    height: auto;
}

/* Format the size of the thumbnail in the table */
.videocutreview-modal .tableimg{
    /* Format the height of the thumbnail since we are defining the table in terms of height*/
    height: 6vh; 
    width: auto;
    aspect-ratio: 4.25/5;
    object-fit: fill;
}

/* Adjust the table contents for smaller screens */
@media screen and (max-width:1300px) { /*only apply if the screen width is less than*/
    /* decrease the padding for the smaller screen */
    .videocutreview-modal .cutsTable .table td{ padding: .2rem;}
    /* With decreased padding allow for more thumbnail for visiblity */
    .videocutreview-modal .tableimg{ height: 7vh; }
}


.videocutreview-modal tbody tr td {
    font-size: 90%;
    vertical-align: middle;
    width: calc(10vw - 0.75rem);
    overflow-x: hidden;
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
    white-space: unset;
}

.videocutreview-modal .nodata{
    display: flex;
    font-style: italic;
    align-items: center;
    justify-content: center;

}