
.phonecall-modal {
    margin-top: 100px;
    height: auto ;
    width: 60vw;
    height: 55vh;
    max-width: 80vw; 
    user-select: none;
}

.phonecall-modal .phonecall-modal-content {
    transform-origin: top right;
    width: 100%;
    height:100%;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

}

@media screen and (max-width:1300px) { /*only apply if the screen width is less than*/
  .phonecall-modal .phonecall-modal-content {  height: 70vh;  }
  .phonecall-modal {   width: 75vw;  }
}

.phonecall-modal .pc-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10%;
}

.phonecall-modal .pc-footer .btn {
    width:10%;
}

.phonecall-modal-content .phone-top{
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;      */
    display: grid;
    grid-template-columns: 50% 50% ;
    grid-template-rows: auto;

    height: 70%;
    width: 98%;
    margin-left: auto;
    margin-right:auto;
}

.phonecall-modal-content .viewTabs{
    width:50%;
    height:10%;
    margin-left: auto;
    margin-right:auto;
    /* border: 1px solid; */
}
.phonecall-modal-content .viewSelection{
    height:100%;
    width:100%;
}
.phonecall-modal-content .nav-tabs{
    border-bottom: unset;
}
.phonecall-modal-content .nav{
    height:100%;
    justify-content: center;
}


.phone-title{
    display: grid;
    grid-template-columns: 30% 40% 30%;
    grid-template-rows: 35px;
    width: 98%;
    margin-left: auto;
    margin-right:auto;
    margin-bottom: 2%;
    margin-top: 1%;
}

.phone-title .titletext{
    font-size: 26px;
    text-align: center;
}

.phone-title .logo-e3 {
    max-height: 30px;    
    margin-left:auto;
    margin-top:auto;
    margin-bottom: auto;
}
.phone-title .logo-client {
    max-height: 70px;    
    margin-right:auto;
    margin-top:auto;
    margin-bottom: auto;
}

.phone-top .phone-section{
    /* background-color: bisque; */
    width: 100%;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */

  
      display: grid;
      /* grid-template-columns: 10% 20% 20% 20% 20% 10%;  */
      grid-template-columns: auto;  
      grid-template-rows: 46% 54%;

}
.phone-top .phone-section .content{
    height: 75%;
    /* width: 100%;     */
    margin-top:6%;
    display: grid;
    grid-template-columns: 30% 70%; 
    grid-template-rows: 20% 50% 25%;
    /* margin-left:10%;     */
}
.phone-top .phone-section .content .label-text{
    font-weight: bold;
    font-size:16px;
    width: unset;
    padding-right:10%;
}
.phone-top .phone-section .content .label-value{
    margin-right:auto;
    font-size:16px;
    word-break:break-all;
    text-align: left;
}

.phone-top .phone-section .content .callstate{
    grid-column:2;
    height:25%;
    width: 100%;
    display: flex;
    flex-direction:row;
     justify-content: space-evenly; 
    margin-top:auto;
    margin-bottom:auto;
     /* margin-left:10%;  */
     /* margin-left:auto;
     margin-right:auto; */
    /* border: 1px solid; */
    margin-left:-20%;
    
}
.phone-top .phone-section .content .stage{
    margin-top:2%;
    margin-left:-20%; 
}


.callstate .statelabel{
    margin-top:auto;
    margin-bottom:auto;
    /* background-color: red; */
}
.callstate .spinner-wrapper{
    max-height:100%;   
     /* margin-top:auto;
    margin-bottom:auto;  */
    margin-top:5%;
    margin-left:5%;
}


.phone-top .phone-section .controls{
    /* background-color: cadetblue; */
    height: 100%;

    display: grid;
    /* grid-template-columns: 10% 20% 20% 20% 20% 10%;  */
    grid-template-columns: 33% 33% 33% ;  
    grid-template-rows: 50% 50%;
    
    width: 50%;  
    margin-left:3%;  
}

  .startcallbutton{
    background-image: linear-gradient(115deg, rgba(0,153,226,0.7), rgba(0,153,226,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .startcallbutton-disabled{
    background-color: rgba(175,175,175,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .startcallbutton:hover{
      background-image:unset;
      background-color:rgba(0,120,212,1);
  }

  .startcallicon{
    color:white;
    margin-top: 20%;
    width: 30px; height: 30px;
    transform: rotate(20deg);
  }
  .mutebutton{
    /* background-color:rgba(0,153,226,1); */
    background-image: linear-gradient(115deg, rgba(0,153,226,0.7), rgba(0,153,226,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .mutebutton-disabled{
    background-color: rgba(175,175,175,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .mutebutton:hover{
    background-image:unset;
    background-color:rgba(0,120,212,1);
  }
  
  .muteicon{
    color:white;
    margin-top: 20%;
    width: 30px; height: 30px;
  }
  .unmutebutton{
    background-color:rgba(232,231,236,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .unmuteicon{
    /* color:black; */
    margin-top: 20%;
    width: 30px; height: 30px;
  }
  .endcallbutton{
    /* background-color:rgba(244,67,54,1); */
    background-image: linear-gradient(115deg, rgba(244,67,54,0.7), rgba(244,67,54,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .endcallbutton-disabled{
    background-color: rgba(175,175,175,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .endcallbutton:hover{
    background-image:unset;
    background-color:rgba(220,28,12,1);
  }
  .endcallicon{
    color:white;
    margin-top: 25%;
    width: 30px;
    height: 30px;
    transform: rotate(135deg);
  }
  .noanswerbutton{
    /* background-color:rgba(244,67,54,1); */
    background-image: linear-gradient(115deg, rgba(244,67,54,0.7), rgba(244,67,54,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
    position: relative;
  }
  .noanswerbutton-disabled{
    background-color: rgba(175,175,175,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
    position: relative;
  }
  .noanswerbutton:hover{
    background-image:unset;
    background-color:rgba(220,28,12,1);
  }
  .noanswericon{
    position: absolute;
    color:white;
    margin-top: 25%;
    margin-left:-28%;
    width: 30px;
    height: 30px;
    transform: rotate(135deg);
  }
  .noanswerx{
      position:absolute;
      width:50px;
      height:50px; 
      font-size:40px;
      color:white;
      font-weight: 100;
      margin-top:-10%;
      transform: rotate(-20deg);
  }
  .noanswerx-text{
     position: absolute;
  }

  .hungupbutton{
    /* background-color:rgba(244,67,54,1); */
    background-image: linear-gradient(115deg, rgba(244,67,54,0.7), rgba(244,67,54,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
    position: relative;
  }
  .hungupbutton-disabled{
    background-color: rgba(175,175,175,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
    position: relative;
  }
  .hungupbutton:hover{
    background-image:unset;
    background-color:rgba(220,28,12,1);
  }
  .hungupicon{
    position: absolute;
    color:white;
    margin-top: 25%;
    margin-left:-28%;
    width: 30px;
    height: 30px;
    transform: rotate(135deg);
  }
  .hungupx{
      position:absolute;
      width:50px;
      height:50px; 
      font-size:40px;
      color:white;
      font-weight: 100;
      margin-top:-10%;
      /* transform: rotate(-20deg); */
  }
  .hungupx-text{
     position: absolute;
  }
  
  .callissuebutton{
    /* background-color:rgba(244,67,54,1); */
    background-image: linear-gradient(115deg, rgba(244,67,54,0.7), rgba(244,67,54,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
    position: relative;
  }
  .callissuebutton-disabled{
    background-color: rgba(175,175,175,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
    position: relative;
  }
  .callissuebutton:hover{
    background-image:unset;
    background-color:rgba(220,28,12,1);
  }
  .callissueicon{
    position: absolute;
    color:white;
    margin-top: 18%;
    margin-left:-29%;
    width: 30px;
    height: 30px;
    /* transform: rotate(135deg); */
  }  

.phone-top .phone-section .controls .startcall{
    /* grid-column: 2; */
    height: 98%;
    margin-top: 2%;
     /* background-color: cadetblue;  */
}
.phone-top .phone-section .controls .under-label{
    font-weight: bold;
    text-align: center;
}
.phone-top .phone-section .controls .startcall > img{
    height: 55%;
}
.phone-top .phone-section .controls .mute > img{
    height: 55%;
}
.phone-top .phone-section .controls .hangup > img{
    height: 55%;
}

.phone-top .phone-section .controls .mute{
    /* grid-column: 3; */
    height: 98%;
    margin-top: 2%;
}

.phone-top .phone-section .controls .hangup{
    /* grid-column: 4; */
    height: 98%;
    margin-top: 2%;
}


.phone-top .details{
    width: 100%;
    /* border: 1px solid; */
}
.details-main{
    height: 100%;
}
.details-offline{
    height: 100%;
    
}

/* Add a vertical scroll bar to the table */
.offline-assets tbody {
    display:block;
    height:250px;
    overflow-y:scroll;
}
.offline-assets thead{
    display:table;
    width:100%;
    table-layout:fixed;
}
.offline-assets tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
.offline-assets tbody tr td:nth-child(1) {
    /* background-color: red; */
    width:28%;
}
.offline-assets .status-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
/* .offline-assets tbody :nth-child(2) {
 
    width:100%;
} */
/* END - Add a vertical scroll bar to the table */



.phone-top .detail{
    display: flex;
    flex-direction: row;
    height: 15%;
    /* margin-bottom: 1%; */
}
.phone-top .label-text{
    width: 20%;
    text-align: right;
    padding-right: 2%;
    font-weight: bold;
    /* border: 1px solid; */
}
.phone-top .dropwrapper{
    width: 70%;
    /* border: 1px solid; */
}
.phone-top .dropwrapper-sxs{
    width: 70%;
    display: grid;
    grid-template-columns: 49.5% 49.5%; 
    grid-template-rows: 32px;
    column-gap: 1%;
}

/* .phone-top .dropwrapper .rw-multiselect-taglist{
    max-height: 125px;    
} */


.phone-top .detail-wide{
    display: flex;
    flex-direction: row;
    /* height: 30%; */
    height:35%;
    margin-bottom: 1%;
    /* border: 1px solid; */
}
/* .phone-top .multiselect-wide{
    height:5.5em;
} */



.phone-top .contactgroup{
    display: flex;
    flex-direction: row;
    background-color: azure;
}
.phone-top .details .infraction-dropdown-container{
    width:100%;
    margin-top: 5%;
}
.phone-top .details .form-control {
    width: 15vw; 
}



.phone-top .scrollable-list{
    width: 80%; 
    display: grid;
    /* grid-template-columns: repeat(2,1fr); */
    grid-template-columns: 49.5% 49.5%; 
    grid-template-rows: 32px;
    column-gap: 1%;
    /* border: 1px solid; */
}

.subject-display {

    display:inline-flex;
    flex-direction: row; 

    padding: 5px;
    height: 30px;
    margin-top: 2px; 

    border-radius: 5px;
    border: solid;
    border-width: thin;
    background-color: #fff;
    border-color: #3498DB;
}

.subject-display .infractiontext{
    margin-left:auto;
}

.subject-display div{
    position: relative;
    /* font-size: 80%; */
    color:#3498DB;
    font-weight: bold;
    text-align: center;
}

.subject-display .remove{
    position: relative;
    color: rgb(221, 48, 57);
    background: transparent;
    border:none;
    margin-left:auto; margin-right:0;
    font-weight: bold;
}

.offline-assets{
    height:50%;
}



/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 .dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: black;
    color: black;
    animation: dotFlashing 1.5s infinite linear alternate;
    animation-delay: 1s;
  }
  
  .dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  
  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: black;
    color: black;
    animation: dotFlashing 1.5s infinite alternate;
    animation-delay: .5s;
  }
  
  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: black;
    color: black;
    animation: dotFlashing 1.5s infinite alternate;
    animation-delay: 1.5s;
  }
  
  @keyframes dotFlashing {
    0% {
      background-color: white;
    }
    50%,
    100% {
      background-color: rgba(0,153,226,1);
    }
  }