/*******************************************************************
Style the small review card:
********************************************************************/


.review-card {
    position: relative;
    box-sizing: border-box;

    margin: 0;
    padding:  clamp(2px,4.5%,8px) clamp(2px,3.5%,6px);

    border-radius: 4px;
    background: #fff;
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.08);
    
    overflow: hidden;
    cursor: default;
    user-select: none;

    /* height: 80px; */
    height: 8vh;

    
    width:auto;

    display: grid;
    grid-template-rows: 15% 72%;    
    row-gap: 8%;    
    font-size: .6vw;
}
.review-card .name, .review-card .notes, .review-card .type, .review-card .workflow {   text-align: left;}
.review-card .name, .review-card .notes, .review-card .type, .review-card .date, .review-card .workflow {
    height: 13px;
    line-height: 11px;
}

.callin-lister .review-card{
    height: unset;
    font-size: clamp(6px,0.6vw,9px);
}

/* .review-card-list .review-card{
    width:auto;
} */

.card-list-card-wrap {
    height: unset;
    /* background-color: red; */
}



/*******************************************************************
Title section: Severity , Alerts, Date, and Options Hamburger
********************************************************************/
/* Define the section to hold the elements */
.review-card .ncard-title{    
    /* position: relative;     */
    position: absolute;
    top:0px;
    left:2%;

    box-sizing: border-box;

    width: 96%;
    height: auto;

    margin-bottom: 1.5%;
    grid-template-columns: 25% 14% 13% 13% 35%;    
    /* background-color: red; */
    grid-row:1;
}

/* Update the styling of the severity bar for the review-card */
.review-card .card-severity {
    width: 100%;
    height: clamp(3px, 70%,7px);
    border-radius: 3px;    
    margin-bottom: 4px;
    
}


.review-card .audioalert{ 
    position: absolute; /*Allow this to overflow the height of the title bar, but stay relative in position and size*/
    aspect-ratio: 17/19;
    height: auto;
    display: inherit;
    width: clamp(10px,15%,30px); 
}
.review-card .audioalert > img{ 
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: translateY(-25%); 
}

/* This is for the small card*/
.review-card .vibrationalert{ 
    position: absolute;
    aspect-ratio: 100/97;
    height: auto;    
    width: clamp(10px,25%,30px); 
}
.review-card .vibrationalert > img{ 
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: translateY(-25%); 
}





/* Date hambuger pair */
.review-card .date {
    position: relative;
    width: 80%;   
    white-space: nowrap;
    padding-right: 10%;
    font-size:1em;
    text-align: right;
}

.review-card .hamburger{
    position: relative;
    
    width: clamp(3px,1em,10px);     
    height: clamp(3px,1em,10px);     
    /* height: 1em; */
    aspect-ratio: 1 / 1;    
    /* background-color: red; */
}
.review-card .hamburger > img{
    position: absolute;
    width: 100%; 
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: contain;
}


/*******************************************************************
Upper->Basics Section: Information block: DriverID, Infraction type, Workflow, Notes
********************************************************************/

.review-card .card-upper{ 
    position: absolute;
    top:0px; left:2%;
    width:96%;
    height:96%;
    grid-row:2;
}


/* Enable a larger information section on the review card */
.review-card .card-basics {
    margin-top:2%;
    width: 70%;
    /* border: 1px solid black; */
}


/* Apply to all children of the card-basics*/
 .review-card .card-basics > *{
    background-color: transparent;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-y: hidden;

    
    height:unset;
    line-height: 100%;

}


/* .review-card .notes {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 35px;
} */
/*******************************************************************
Upper->Card-Date-Avatar Section: Thumbnail of the video clip
********************************************************************/
.review-card .card-date-avatar { 
    /* background-color: red; */
    width: clamp(20px,25%,35px);
    margin-top: auto;
    margin-bottom: 2%;
    
    /* padding: clamp(2px,5%,6px); */
}

.review-card .avatar {
    padding-top:5%; 
    aspect-ratio: auto 1/1;
    border-radius: 5px;
    object-fit: fill;
    width:100%;
    height:auto;
}





