
.train-drivers .viewSelection{    
    margin-top: 0.5vh;
    /* margin-top: -4em; */
}

/* Format the loaded image, force it to fill the space */
.train-drivers-layout > p > img{
    height: 100%;
}

.train-drivers-layout .selectedcontent{
    /* The tabs at the top fo the section consume 40px? */
    height: calc(100% - 40px);
    
}

.eval-driver{
    height: 100%;
}

/* Layout the grid to show the top 3 matches for the photo */
.eval-driver .driverDisplay {
    display: grid;
    grid-template-columns: repeat(3, 32%);
    grid-template-rows: 100%;
    grid-column-gap: 0.5%;
    height: 70%;
}

.selectedcontent .v-center{  margin-top: 10%;}