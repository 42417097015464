
/*=====================================================================
  Format list table
=====================================================================*/
.riskworst {
  height:100%;
  font-size: 14px;
}

.riskworst .risktable{
  width: 100%;
  height: 96%;
  /* max-height: 50%; */
  overflow-y: scroll;
  overflow-x: hidden;
}

.riskworst .risktableheader{
  /* width:calc(100% - 16.8px); remove the scrollbar */
  width:100%;
  display: grid;
  grid-template-columns: 3vw 20% 15% 20% 20% auto;  
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  
  font-weight: bold;
  font-size: 16px;

  background-color: rgba(0, 175, 237, 0.04);
  
}
.riskworst .risktableheader >div{
  border-right: 1px solid black;
  vertical-align: bottom;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.risktableheader div:nth-child(1) {  border-left: 1px solid black;} 





/* Comments */
.radialcard-modal-content .commenter {
  width: 90%;
  margin-left: auto;
  margin-right:auto;
}
.radialcard-modal-content .commenter .adding-buttons{
  height:4vh;
}
.radialcard-modal-content .commenter .adding-buttons .btn{
  height: 100%;
  font-size: 0.9em;
}


.radialcard-modal-content .comment {
  /* background: #f4f5f7; */
  /* border-radius: 4px; */
  /* margin: 8px 0;
  padding-left: 8px;
  padding-top: 8px; */
  width: 96%; 
  text-align: left;
}

.radialcard-modal-content .comment pre {
  margin-bottom: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  font-family: 'Open Sans';
}
.radialcard-modal-content .comment-timestamp pre {
  color: #555;
}



.radialcard-modal-content .comments {
  overflow-y: auto;
  max-height: 64%;
  margin-bottom: 5px;    
  margin-left:5%;
}
.radialcard-modal-content .comments-title {
  margin-top: 8px;
  text-align: left;
  margin-left:5%;
}


.radialcard-modal-content .comment-timestamp {
  font-size: 0.8em;
  margin-top: -0.5em;
  left : 2%;
  display:inline-block;
}

.radialcard-modal-content .comment-username {
  position: relative;
  font-size: 0.8em;
  margin-top: -0.5em;
  display:inline-block;
}

.riskworst .nodata{
  font-size: 24px;
  font-style: italic; 
}