.staffperformance{
  display: grid;                  /* Layout of the parent view is a grid */
  grid-template-rows: 10% 90%;    /* 10% of screen to toprow, 90% to remaining content */
  grid-template-columns: 100%;    /* Only one column */
  
  user-select: none;              /* Not selectable */
  margin-top:-4vh; 
  height:67vh;                    
}

.staffperformance .toprow{
  display: grid;  
  grid-template-columns: 25% 75%; /* Split top row 25/75 between tabs and filters */
  grid-template-rows: 100%;       /* Only one row */
  /* height: 2.5vh; */
}

.staffperformance .topRight{
  display: flex;  
  align-items: center;
  /* grid-template-columns: 20% 80%; */
  /* margin-top:-2.5vh;  */
  margin-left:auto;
}

.staffperformance .performancecontent{
  /* display: grid;
  grid-template-columns: 100%;     */
  /* Only one column */
  height:100%;
  overflow: auto;
  position:relative;
}

.staffperformance .callin-filters{
  grid-column: unset;
  margin-top:-1em;
}

.staffperformance .react-datepicker-wrapper{
  z-index: 5;
}

.staffperformance .date-checkboxes{
  font-size: 14px;
  display: flex;
  gap: .75vw;
  padding: .7vh 0px .7vh 0px;
}

.staffperformance .comparison-checkboxes{
  /* padding-right: .5vw; */
  font-size: 14px;
  display: flex;
  gap: .75vw;
  padding: .7vh .5vw .7vh 0px;
}

.staffperformance .accuracytab{
  height:45vh;
}

.staffperformance .responstab{
  height:149vh;
}

.staffperformance .statisticstab {
  /* height: 100%;  */
  font-family: sans-serif;
  text-align: center;
  font-size: 16px;
  align-items: center;
  width: 50%;
  margin: 0 auto;
}

.staffperformance .cbox {
  margin-bottom: .75rem;
}

.staffperformance .cbox > input {
  margin-right: 5px;
}

.staffperformance .error-table {
  margin: 0 0 0 0;
  font-size: 17px;
  /* height: 9vh !important; */
}

.staffperformance .error-table > thead > tr > th {
  text-align: center!important;
  font-size: 16px;
}

.staffperformance .title {
  font-weight: bold;
  font-size: 18px;
}

.staffperformance .spacer{
  height:7vh;
  width: 40px;
}

.staffperformance .accuracytab .echarts-for-react{
  /* height: 46.39% !important; */
  height: 100% !important;
}

.staffperformance .responstab .echarts-for-react{
  height: 30.20134% !important;
  /*height: 14.754% !important;*/
}

.staffperformance .callin-filters{
  max-width: 45vw;
}
.staffperformance .hidden{
  display:none;
  height:100%;
}


/*only apply if the screen width is less than */
 @media  (max-width:1300px)  { 
  /* Update the font on the checkboxes and dropdown labels */
  .staffperformance .filter-drop-container {    font-size: 12px; } 
  .staffperformance .comparison-checkboxes { font-size: 12px; }
  .staffperformance .date-checkboxes { font-size: 12px; grid-gap: .5vw}
  .staffperformance .date-pick { width:90px !important; font-size: 12px;}

  /* Update the spacing of the top row,  */
  .staffperformance .toprow{
    grid-template-columns: 28% 72%; /* Split top row 25/75 between tabs and filters */
  }

  .staffperformance .filter-drop-staff select.form-control.form-control-default{    max-width: 10ch; } /*XXYYYY format allowed without ellipsis*/
  .staffperformance .filter-drop-bin select.form-control.form-control-default{    max-width: 9ch; } 
  
  /* .staffperformance .date-checkboxes .cbox{max-width: 50px;} */
  .staffperformance .date-checkboxes .cbox:nth-child(1){ font-size: 0px;} 
  .staffperformance .date-checkboxes .cbox:nth-child(1)::after{ content: "Prev 3 Months"; font-size:12px} 
  /*Update the font size of the tab labels */
  .staffperformance .viewSelection > a {font-size: 12px;} 
}

