
.AdminView{
    user-select: none;
}

.AdminView .viewSelection{    
    margin-top: -4em;
    display:flex;
    justify-content: space-around;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}

.AdminView .message1{    
    margin-top: 2em;
    font-style: italic;
}

.AdminView .viewSelection .viewSelection-buttons-selected{
    background-color:#00AFED;
    width: 25%;
    outline: none;
    border: none;

}
.AdminView .viewSelection .viewSelection-buttons-selected:hover{
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
}
.AdminView .viewSelection .viewSelection-buttons{
    width: 25%;
    outline: none;
    border: none;
}
.AdminView .viewSelection .viewSelection-buttons:hover{
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
}


.resetUser {
    background:none;    
}
.resetUser:hover {
    transform:scale(1.5,1.5);
    -webkit-transform:scale(1.5,1.5);
    -moz-transform:scale(1.5,1.5);
}
.resetUser:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}

.disabledAccount{
  /* color:red; */
}

.AdminView .topRow{
  margin-top: 2em;
  margin-bottom:0.5em;
  display: grid;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  grid-template-rows: 2.5em;
}
.totalassets {
    font-size: 14px;
    border-radius:5px;
    grid-column: 1/span 2;
    margin-right: 1em;
    margin-top:8px;
    text-align: left;
}

.show-disabled-users{
  font-size: 14px;
  grid-column: 5/span 2;
}
.disabledCheck{
  vertical-align: middle;  
}
.disabledText{
  vertical-align: middle;  
  margin-left:5px;
  margin-top:8px;
}

.search-title{
  grid-column: 8;
  text-align: right;
  margin-right: 1em;
  margin-top:8px;
}

.usernameSearch{
  /* background-color: grey; */
  /* width:20%; */
  grid-column: 9/span 10;
  /* margin-left:auto; */
}


/*=====================================================================
  Format asset management  table
=====================================================================*/
.driver-lister table.video-table {
    table-layout: auto !important; 
 }
 .driver-lister table td.dm-driverid {
   overflow: hidden;
   text-overflow: ellipsis;
 }
 .driver-lister table td.dm-status {
  overflow: hidden;
  text-overflow: ellipsis;
}
.driver-lister table td.dm-date {
  overflow: hidden;
  text-overflow: ellipsis;
}
.driver-lister table td.dm-sites {
  overflow: hidden;
  text-overflow: ellipsis;
}


.dm-driveridFormat{
  width: 95%; 
  height:1.5em;
  display:grid;
  grid-template-columns: 32% 30% 32%;
}
 
.confirmButton{
  background-color: rgb(141,198,63);
  grid-column: 1;
  height: 75%;
  text-align: center;
}
.driverid-name{
  grid-column: 2;
  white-space:unset;
}
 
 

 /*=====================================================================
  Style the table used for displaying lists of videos
=====================================================================*/

/* hide the icons that come with the library, they're not working correctly */
.driver-lister th.sortable span.order, .video-lister th.sortable span.react-bootstrap-table-sort-order {
    display: none;
  }
  
  .driver-lister th.sortable {
    position: relative;
    padding-right: 24px;
  }
  
  .driver-lister th.sortable::after {
    content: '\f0dc';
    font-family: 'Font Awesome 5 Free';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    margin-left: 0.6em;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
  }
  
  .driver-lister th.sorted-asc::after {
    content: '\f0de';
  }
  
  .driver-lister th.sorted-desc::after {
    content: '\f0dd';
  }
  
  .driver-lister {
    font-size: 14px;
    /* margin-top: 3em; */
  }
  
  .driver-lister th {
    user-select: none;
  }

  .disableDriver{
    border-radius: 1em;
    background-color: rgba(237,28,36,0.6);
    border:none;
    outline: none;
  }
  .disableDriver:hover {
    transform:scale(1.5,1.5);
    -webkit-transform:scale(1.5,1.5);
    -moz-transform:scale(1.5,1.5);
  }
  .disableDriver:active {
      transform:scale(.9,.9);
      -webkit-transform:scale(.9,.9);
      -moz-transform:scale(.9,.9);
  }

  .enableDriver{
    border-radius: 1em;
    background-color: rgba(141,198,63,0.6);
    border:none;
    outline: none;
  }
  .enableDriver:hover {
    transform:scale(1.5,1.5);
    -webkit-transform:scale(1.5,1.5);
    -moz-transform:scale(1.5,1.5);
  }
  .enableDriver:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
  }

