.roi-selector-modal .roibuttons{    
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 10%;
  /* height: 1000px; */
  /* justify-content: space-between;    */
  justify-content: space-around;
  align-content: center;
  margin: 1em;
}
.roibuttons .update-button {
  width: 60px;
  height: 55px;
  border: none;
}

.roibuttons .process-button {
  width: 60px;
  height: 55px;
  border: none;
}

.roi-selector-modal {
  height: auto;
  margin-right: 100px;
  margin-top: 120px;
  width: 600px;
  max-width: 1000px;
  
}
.roi-selector-modal .modal-content {
  width: unset;
  margin: auto;
}


  



  
  
  
