
/* hide/reveal an entire component */
@-webkit-keyframes flash {
  50%,from,to { opacity: 1 }
  25%,75% { opacity: 0}
}
@keyframes flash {
  50%,from,to {opacity: 1}    
  25%,75% { opacity: 0 }
}



/* Apply an opacity only to the outline */
@-webkit-keyframes flash-outline {
  50%,from,to { outline-color: rgba(0,0,255,1); }  
  25%,75% { outline-color: rgba(0,0,255,0); } 
}
@keyframes flash-outline {
  50%,from,to { outline-color: rgba(0,0,255,1); }  
  25%,75% { outline-color: rgba(0,0,255,0); } 
}
@-webkit-keyframes flash-background {
  50%,from,to { background-color: rgba(0,0,255,1); }  
  25%,75% { background-color: rgba(0,0,255,0); } 
}
@keyframes flash-background {
  50%,from,to { background-color: rgba(0,0,255,1); }  
  25%,75% { background-color: rgba(0,0,255,0); } 
}