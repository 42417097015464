.label-input-pair {
    display: grid;
    grid-template-columns: 40% 60%;
}

.label-input-pair .text-label{
    text-align: right;
    margin-right: 2%;
    /* Center in the row, this will center it against the input field half of the pair*/
    display: flex ;
    flex-direction: column;
    justify-content: center;
}

.label-input-pair .settime{
    text-align: center; 
}



