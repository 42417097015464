.siteoverview-modal {
    margin-top: 100px;
    height: auto ;
    width: 60vw;
    height: 80vh;
    max-width: 80vw; 
    user-select: none;
}

.siteoverview-modal .siteoverview-modal-content {
    transform-origin: top right;
    width: 100%;
    height:100%;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

    
    display: grid;
    grid-template-rows: fit-content(10%) fit-content(15%) auto 3em;

}
.siteoverview-modal .sc-title{
    margin-bottom: 1%;
}

.sc-title-warning{
    position: absolute;
    margin-top:0%;
    font-size: 18px;
    font-style: italic;
    left:0;
    right:0;
    color: red;
}

.siteoverview-modal .add-action {
    width: 100%;
}



.siteoverview-modal .sc-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.siteoverview-modal .sc-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    /* margin-right: 5px; */
    /* height: 25px; */
} 

.siteoverview-modal .sc-footer .submitPlaceholder {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    background-color: gray;
    color:white;
    font-family: 'Open Sans';
    /* margin-right: 5px; */
    /* height: 25px; */
} 



.siteoverview-modal .sc-footer .blank-spacer {
    width: 30%;
    border: none;
  }

 .siteoverview-modal .sc-footer .delete-region {
    margin-top: 8px;
    width:15%;
}
.siteoverview-modal .sc-footer .add-comment {
    margin-top: 8px;
    width:fit-content;
}
.siteoverview-modal .sc-footer .close-region {
    margin-top: 8px;
    width:15%;
}
.siteoverview-modal .sc-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.siteoverview-modal .settings-grid {
    display: grid;
    /* grid-template-columns: 50px 100px 100px 50px 100px 50px 50px 150px; assume 1000pixels as reference*/
    grid-template-columns: 5% 12% 10% 5% 10% 5% 5% 15%; 
    gap: 5px 0px;
    
    margin-top:0%;
    margin-left:5%;
  }


.siteoverview-modal .settings-grid .entry-field{
    height: 2em;
    width:95%;
    margin-top: auto;
    margin-bottom: auto;
}
.entry-field-wide{
    height:2em;    
    grid-column: span 3;
    margin-top: auto;
    margin-bottom: auto;
}



.siteoverview-modal .settings-grid .title-type{
    font-size: clamp(10px,.9vw,14px);
    grid-column: 1/3;
    grid-row:auto;
    margin-top: auto;
    margin-bottom: auto;
    text-align: right;
    margin-right:2%;
}
.siteoverview-modal .settings-grid .title-units{
    font-size: clamp(10px,.9vw,14px);
    grid-row:auto;
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;
}
.siteoverview-modal .settings-grid > input{font-size: clamp(10px,.9vw,14px);}

.siteoverview-modal .settings-grid .title{
    font-size: clamp(10px,.9vw,14px);
    grid-row:auto;
}

.siteoverview-modal .settings-grid .speed-button{
    display:flex;
    font-size: clamp(10px,.9vw,14px);
    grid-column: 8;
    grid-row:auto;
    height: 30px;
    line-height: 30px ;
    border-radius: 1em;
    background-color: rgba(141,198,63,0.6);
    border:none;
    outline: none;
    margin-top: auto;
    margin-bottom: auto;
}
.siteoverview-modal .settings-grid .speed-button span{
    display: block;
    line-height: 100%;    
    margin:auto;
}

.siteoverview-modal .settings-grid .speed-button:hover {
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
}
.siteoverview-modal .settings-grid .speed-button:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}
  
.siteoverview-modal-content .rw-widget-picker{
    /* height: 2em; */
    font-size: clamp(10px,.9vw,14px);
}

.siteoverview-modal-content .soc-spec{
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    grid-template-rows: 30px;
    /* grid-template-rows: auto auto auto auto; */
    gap: 5px 0px;
    margin-left:5%;
    margin-bottom:1%;
    font-size: clamp(10px,.9vw,14px);
}
.siteoverview-modal-content .soc-spec .title-wide{
    font-size: clamp(10px,.9vw,14px);
    grid-column: 1/3;
    grid-row:auto;
    text-align: right;
    margin-right:2%;
    margin-top: auto;
    margin-bottom: auto;
}
.siteoverview-modal-content .soc-spec .entry-field{
    height:2em;
    
    width:95%;
    margin-top: auto;
    margin-bottom: auto;
}
.siteoverview-modal-content .soc-spec .entry-field-wide{
    height:2em;    
    grid-column: span 3;
    margin-top: auto;
    margin-bottom: auto;
}



.siteoverview-modal .empty-bottom{
    height:7em;
}


.siteoverview-modal .soc-spec-tall{
    display: grid;
    grid-template-columns: 50px 100px 125px 100px 100px 125px 100px;
    align-items: baseline;
    margin-top:5px;
}
.siteoverview-modal .soc-spec-tall .title-wide{
    font-size: clamp(10px,.9vw,14px);
    grid-column: span 2;
    grid-row:auto;
}
.siteoverview-modal .soc-spec-tall .title{
    font-size: clamp(10px,.9vw,14px);
    grid-column: span 1;
    grid-row:auto;
}
.siteoverview-modal-content .soc-spec-tall .entry-field{
    height:5.5em;
    width:95%
}
.siteoverview-modal-content .soc-spec-tall .entry-field-wide{
    height:5.5em;
    grid-column: span 2;
}


.siteoverview-modal-content .Table{
    height: 400px;       /* Just for the demo          */
    overflow-y: auto;    /* Trigger vertical scroll    */
    overflow-x: hidden;  /* Hide the horizontal scroll */
 }

/* Adjust the table's font to match the scaling of the top section on the card */
.siteoverview-modal tbody{
    font-size: clamp(10px,.9vw,14px);
}


/*=====================================================================
  Format the bottom section (tabs and map/tables)
=====================================================================*/
.siteoverview-modal-content .siteBottom{

    display: grid;
    grid-template-rows: fit-content(15%) auto;
    /* row-gap: 2%; */
    position: relative;
}
.siteoverview-modal-content .viewTabs a:focus {   outline: 0;}

.siteoverview-modal-content .viewTabs{
    width:50%;
    margin-left: 15%;
}

.siteoverview-modal-content .site-olMap {
    width: 95%;
    height: auto;
    margin-top:unset;
    margin-left: auto;
    margin-right: auto;
    background-color: #cccccc;
}

.siteoverview-modal-content .fenceTable{
    /* background-color: grey; */
    width:90%;
    margin-left:5%
}

.fenceTable .react-bootstrap-table{
    height: 35vh; /*Force a height so that the pagination is set in a constant spot*/
    /* background-color: green; */
}

.soc-lister-row table.video-table {
    table-layout: auto !important; 
 }
 .soc-lister tr.soc-name {
   overflow: hidden;
   text-overflow: ellipsis;
   text-align:left;
 }
 .soc-lister tr.soc-group {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
 .soc-lister tr.soc-remove {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}

