.radialcard-modal-content {
  transform-origin: top right;
  width: 100%;
  height:100%;
  margin: auto;
  text-align: center;
  position: relative;
  background-color: #fff;
  
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
          box-shadow: 0 3px 9px rgba(0, 0, 0, .5);


  display: grid;
  grid-template-rows: 60% 40%; 
}

.radialTop .card-half {
  width: 40vw;
  height: 32vh;
  margin-top:2vh;  
  margin-right:auto;
  margin-left:auto;
}
.radialTop .radial-left{
  display: grid;
  grid-template-rows: 10.5% 90%;
  margin-left: 5%;
  max-height: 100% !important;
}

.radial-left .radial-name{
  /* margin-top: 5%; */
  margin-top:auto;
  /* margin-bottom:auto; */
  font-size: 16px;
  font-weight: 700;
  
}
.radial-left .radial-photo{
  margin-top:auto;
  margin-bottom:auto;
  margin-left:auto;
  margin-right:auto;
  height: auto;    
  width: 68%;
}
.radialTop{
  display: grid;
  grid-template-columns: 25% 75%; 
}

.radialBottom{
  /* background-color: red; */
  display: flex;
  flex-direction: column;
}
.radialBottom .add-comment{
  width: 20%;
  margin-left: auto;
  margin-right:auto;
  
}


.radialcard-modal-content .btn:hover {
  transform:scale(105%,105%);
} 

/* Comments */
.radialcard-modal-content .commenter {
  width: 90%;
  margin-left: auto;
  margin-right:auto;
}
.radialcard-modal-content .commenter .adding-buttons{
  height:4vh;
}
.radialcard-modal-content .commenter .adding-buttons .btn{
  height: 100%;
  font-size: 0.9em;
}


.radialcard-modal-content .comment {
  background: #f4f5f7;
  border-radius: 4px;
  margin: 8px 0;
  padding-left: 8px;
  padding-top: 8px;
  width: 96%; 
  text-align: left;
}

.radialcard-modal-content .comment pre {
  margin-bottom: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  font-family: 'Open Sans';
}
.radialcard-modal-content .comment-timestamp pre {
  color: #555;
}



.radialcard-modal-content .comments {
  overflow-y: auto;
  max-height: 64%;
  margin-bottom: 5px;    
  margin-left:5%;
}
.radialcard-modal-content .comments-title {
  margin-top: 8px;
  text-align: left;
  margin-left:5%;
}

.radialcard-modal-content .comment-timestamp {
  font-size: 0.8em;
  margin-top: -0.5em;
  left : 2%;
  display:inline-block;
}

.radialcard-modal-content .comment-username {
  position: relative;
  font-size: 0.8em;
  margin-top: -0.5em;
  display:inline-block;
}