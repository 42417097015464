.siteconfig-card-modal {
    height: auto;
    margin-right: 100px;
    margin-top: 120px;
    width: 750px;
    max-width: 1000px;
}

.siteconfig-card-modal .siteconfig-modal {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    min-height: 250px;
    text-align: center;
    /* border: none; */

    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    /* border: 1px solid #999; */
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
}
.sc-title{
    margin-left:auto;
    margin-right:auto;
    font-size: 26px;
    text-align: center;
}

.siteconfig-modal .add-action {
    width: 100%;
}


.siteconfig-modal .sc-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.siteconfig-modal .sc-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    /* margin-right: 5px; */
    /* height: 25px; */
} 
.siteconfig-modal .sc-footer .blank-spacer {
    width: 30%;
    border: none;
  }

.sc-footer .delete-region {
    margin-top: 8px;
    width:15%;
}
.sc-footer .add-comment {
    margin-top: 8px;
    width:fit-content;
}
.sc-footer .close-region {
    margin-top: 8px;
    width:15%;
}
.sc-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.geofence-modal .monitored-action{
    width:100%;
    position: relative;
}
.geofence-modal .monitored-action .action-element{
    width:100%;
}



.settings-grid {
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    grid-template-rows: auto auto auto auto;
    gap: 5px 0px;
    margin-top:5%;
    margin-left:5%;
  }


.settings-grid .entry-field{
    height: 2em;
    width:95%;
}
.settings-grid .title-type{
    font-size: 14px;
    grid-column: 1/3;
    grid-row:auto;
    margin-top: auto;
}
.settings-grid .title-units{
    font-size: 14px;
    grid-row:auto;
    margin-top: auto;
    text-align: left;
}

.settings-grid .title{
    font-size: 14px;
    grid-row:auto;
}


.empty-bottom{
    height:7em;
}

input:invalid {
    background-color: #ffdddd;
  }
