

.AssetAlert {
    /* font-weight: bold; */
    background:#ed1c2450;
    border-radius: 5px;
    padding: 0px 5% 1px;
}
.AssetOffline {
    /* font-weight: bold; */
    color:rgba(50,50,50,.6);
    background:#88888880;
    border-radius: 5px;
    padding: 0px 5% 1px;
    text-align: center;
}
.AssetOnline {
    /* font-weight: bold; */
    background:rgba(141,198,63,.5);
    border-radius: 5px;
    padding: 0px 5% 1px;
}

.connectivity-Asset{
    /* width: 75%; */
    display:grid;
    grid-template-columns: 5% 90% 5%;
    position: relative;
}

.alertMark{
    margin: auto;
    height:2.2vh;
    width: auto;
    grid-column: 1;
}
.asset-name{
    grid-column: 2;
}