

.combinedcard-modal {
  margin-top: 5vh;
  height: auto ;
  width: 60vw;
  height: 70vh;
  max-width: 80vw; 
  user-select: none;
  transition-duration:250ms; 
}

.combinedcard-modal .combinedcard-modal-content {
  transform-origin: top right;
  width: 100%;
  height:100%;
  margin: auto;
  text-align: center;
  position: relative;
  background-color: #fff;
  
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
          box-shadow: 0 3px 9px rgba(0, 0, 0, .5);


  display: grid;
  grid-template-rows: 6% 94%; 
}

.combinedcard-modal-content .toprow{
  /* background-color: red; */
}
.combinedcard-modal-content .tabcontent{
  /* background-color: blue;   */
}

