
@import "opad.css"; 

.camera-control {
    /* margin-top: 100px; */
    height: auto ;
    /* width: 11.5vw;
    height: 22.5vh; */
    width: 14vw;
    height: 7.5vw;
    /* height: 15vh; */

    max-width: 15vw; 
    user-select: none;
    z-index: 10004; /*very high z-index is set on the rgb edge3 bars*/

    --mask_radius: 4.25vw
}
/* Set the style on the parent when disabled */
.camera-control[disabled] {
  opacity: .3;
  /* Disable the hover/click events on all the children  */
  > *{
    pointer-events: none;
  }
  
}
/* Style the buttons when the parent is disabled */
.camera-control[disabled] .statefulbutton{
  opacity: .3;
}

.camera-control .body {
    transform-origin: top right;
    width: 100%;
    height:100%;
    margin: auto;
    text-align: center;
    position: relative;

    /* background-color: #dfdede; */
    background-color: transparent;
   
}


/* Position the camera control next to the button */
/* Fine tune the margin-top so that it is vertically centered */
.camera-control{
  position: absolute;
  margin-left:10.5vw;
  margin-top: -4.75vh;
}

.camera-control .statefulbutton{
  background-color: rgb(0, 174, 239);
  width: 100%;
  font-size: 110%;
}

.camera-control .body{
  /* border:none; */
  /* background-color: red; */
  /* background-color: transparent; */
  width:14vw;
  display:flex;
  flex-direction: row;
}

.camera-control .control-buttons{
  /* background-color: blue; */
  position: absolute;
  left:3.75vw;
  /* background-color: blue; */
  height:100%;
  width:8vw;
  z-index: 1; 


  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

.control-buttons {
  /* background-image:  */
  mask-image:
    radial-gradient(circle at left, transparent 0, transparent var(--mask_radius), black 0);
}

.control-buttons .statefulbutton{ 
  --c: #767777f6;
  --dpad-fg: #545657af;
  --dpad-fg-hover: #1e8fff6e;
  --r: .75vw; /* the radius of the button's rounded corners*/

  /* background-color: var(--dpad-fg); */
  background-color: var(--c);
  width:70%;
  margin-left: 30%;
  height: 5vh;
  border-radius: var(--r);
  padding-left: 30%;
  color:white;

  &:hover{
    background: linear-gradient(to right, var(--dpad-fg) 15%, var(--dpad-fg-hover) 100%);
    background-color: var(--dpad-fg); 
  }
  &:active{
    /* transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9); */
    transform: translate(10%,0%);
  }
  &:active:after { transition: transform 1s ease-in-out;}
}
.control-buttons .control{ 
  --c: #767777f6;
  --dpad-fg: #545657af;
  --dpad-fg-hover: #1e8fff6e;
  --arrowcolor: #ffffff;
  --c-t-a: #1e90ff; /*active arrow color*/
  --r: .75vw; /* the radius of the button's rounded corners*/
  --button_offset: 2.5vw;
  --button_width:8vw;

  background-color: var(--c);
  width: calc(var(--button_width) - var(--button_offset));
  margin-left: var(--button_offset); 
  height: 5vh;
  border-radius: var(--r);
  &:hover{
    background: linear-gradient(to right, var(--dpad-fg) 15%, var(--dpad-fg-hover) 100%);
    background-color: var(--dpad-fg); 
  }

  a {
    display:flex;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    width: 100%;
    height: 100%;
    padding-left: calc(var(--mask_radius) - var(--button_offset));
    color: var(--arrowcolor);
    font-weight: 500;
    transition: all 2s ease-in-out;
    line-height: 0px;
    
    /* color: var(--c-t);  */

    &:before {
      background: rgba(255,255,255,0.1);
    }

    &:active{ 
      color: var(--c-t-a);
      transform: translate(20%);  
      transition: transform .25s linear;
    }
  }
  a[disabled]{
    pointer-events: none;
    opacity: 0.3;      
  }
 
}

@media screen and (max-width:1300px) { /*only apply if the screen width is less than*/
  .camera-control-modal .camera-control-modal-content {  height: 70vh;  }
  .camera-control-modal {   width: 75vw;  }
}
