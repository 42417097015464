/*******************************************************************
Style Card and CardList
********************************************************************/



.comment-block {
    background: #f4f5f7;
    padding-bottom: 20px;
}

.comment{
    border-radius: 4px;
    padding-left: 8px;
    padding-top: 8px;
    margin: 8px 0;

    display: grid;
    grid-template-columns: 96% auto;
}




.expanded-card .comment pre {
    margin-bottom: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
}

.expanded-card .commenter {
    width: 100%;
}

.expanded-card .comment {
    
    margin: 8px 0;
    /* padding: 8px; */
    
    /* width: 96%;  */
    width:100%;
}





.expanded-card .comment .comment-block {
    background: #f4f5f7;
    padding-bottom: 20px;
}

.expanded-card .comments {
    overflow-y: auto;
    max-height: 250px;
    margin-bottom: 5px;    
}

.comment > pre {
    font-family: 'Open Sans';
}

.comment .edit-comment-button {  
    background-color: transparent;  
    border:none;
}

.commenter .adding-comment textarea {
    width: 100%;
    resize: none;
}

.expanded-card .comments-title {
    margin-top: 8px;
}

.comment-top{
    display: grid;
    grid-template-columns: 75% 25% ;
    grid-template-rows: auto;
    gap: 5px 0px;
}
.comment-nametime{
    grid-column: 1;
    grid-row:auto;
}
.comment-timestamp {
    font-size: 0.8em;
    margin-top: -0.5em;
    left : 2%;
    display:inline-block;
}

.comment-delete{
    grid-column: 2;
    grid-row:auto;
    margin-left:auto;
    margin-top:-8px;
}
.deleteComment{
    color:red;
    font-size: 0.8em;
}

.comment-username {
    position: relative;
    font-size: 0.8em;
    margin-top: -0.5em;
    /* left: 10%; */
    display:inline-block;
}

.comment-timestamp pre {
    color: #555;
}


.editing-comment textarea {
    width: 100%;
    resize: none;
}

.comment .editing-cancel {  
    position: relative;
    left: 90%;    
    width: 5%;
    height: 20px; 
    background-color: rgb(167, 53, 53);
}
.comment .editing-confirm {  
    position: relative;
    left: 95%;    
    width: 5%;
    height: 20px; 
    background-color: #AFD47B;
    
}

.comment .buttonText {  
    font-size:10px;
    transform: translate(0%, -10%); 
}

