/*******************************************************************
Style Card and CardList
********************************************************************/


.infraction-dropdown-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    
  }
   .infraction-dropdown-container .label-text {
    /* height: 13px;
    line-height: 11px; */
    font-size: 14px;
    padding-top: 2px;
    padding-right: 18px;
  } 
  
  .infraction-dropdown-container ul {
    
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-items: right !important;
    
}

/* Style the table filters in the AnalyticsView */
.infraction-display {
    /* margin: 5px;  */
    display: flex;
    flex-direction: row;
    padding: 5px;
    /* font-size: 15px; */
    border-radius: 5px;
    margin-top: 2px; 
    border: solid;
    border-width: thin;
    background-color: #fff;
    border-color: #3498DB;
    width: 190px;
    height: fit-content;
    
    /* color: #3498DB; */
}

.infraction-display div{
    /* margin: 5px;  */
    position: relative;
    /* font-size: 15px; */
    color:#3498DB;
    font-weight: bold;
    /* height:20px; */
    /* margin-top: 5px; */
}
.infraction-display .infractiontext{
    /* height:10px; */
    line-height: 1em;
    /* vertical-align: center; */
    margin-top: auto;
    margin-bottom: auto;
}

.infraction-display .remove{
    /* margin: 5px;  */
    position: relative;
    color: rgb(221, 48, 57);
    /* border-radius: 25px; */
    background: transparent;
    /* border-color: #fff; */
    border:none;
     /* float: right;  */
     /* margin-right: auto; */
    margin-left:auto; margin-right:0;
    /* background-color: rgb(221, 48, 57); */
    /* padding: 5px; */
    font-weight: bold;
}

.phone-button{
    background-image: linear-gradient(115deg, rgba(0,153,226,0.7), rgba(0,153,226,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
}
.phone-button-disabled{
  background-color: rgba(175,175,175,1);
  width:50px; height:50px;
  border-radius: 50%;
  margin:auto;
}
.phone-button:hover{
    background-image:unset;
    background-color:rgba(0,120,212,1);
}

.phoneicon{
  color:white;
  width: 30px; height: 30px;
  margin-top: 15%;
  margin-left:20%;
  /* margin:auto; */
  transform: rotate(20deg);
  
}

.notecard-back.card-upper{
  margin-right: 25%;
}
/* Make the loading animation smaller to fit in the DriverID location */
.name-text .spinner-wrapper.preloader3.loader-block{
  height:50%;
  transform: scale(75%) translateY(50%);
}

 
