@import '../Util/animations.css';

.live-float{
  position: fixed;
  height: auto;
  width: 50%;
  right: 10px;
  top: calc( 5.5em + 35px + 20px);
}


.live-float .live-reviewer-players{
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: 75vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  user-select:none;
}

/* Add a border around the clip, transparent by default */
.live-float .fixed-size{
  /* border: 0.5vw solid transparent; */
  border-radius: 0.25vw; 
  /* outline-color: blue;
  outline-width: 10px; */
}

.live-float .fixed-size-background {
  /* width:103%;
  height:103%; */
  background-color: none;
}

/* Override the transparent border with a solid color when a remote clip is received */
.live-float .fixed-size{
  /* border: 0.5vw solid transparent;
  border-radius: 0.25vw; */
  /* border: 0.5vw solid blue;
   */
}
.live-float fieldset.fixed-size{
  /* border-width: 0.6vw;
  border-color:green;
  border-style: dashed; */
}

.live-float fieldset.fixed-size.remote-clip{
  outline-width: 0.83vw;
  outline-style: solid;
  /* outline-color: #0000ff77;   */
  animation: flash-outline 2.5s infinite ease-in-out;  
}
.live-float fieldset.fixed-size.remote-clip > div{
  animation: flash 2.5s infinite ease-in-out;  
}

.live-float .cliptitle{
  --fontSize: 0.83vw;
  color:white;
  font-weight: 600;
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  top: calc( var(--fontSize) * -1);
  & div{
    font-size:var(--fontSize);
    line-height: var(--fontSize);
    /* border-radius: 1vw; */
    padding-left: 10px;
    padding-right: 10px;
    /* background-color: blue;   */
    /* animation: flash 2.5s infinite ease-in-out; */
  }
  
}

