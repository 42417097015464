/* @import '../Util/animations.css'; */

.train-drivers{
  
  margin-top: -4em; /* Offset the main box app  */
  margin-left: 2%;
  margin-right: 2%;
}
.train-drivers-layout{
  display: grid;
  grid-template-columns: 25% 70%;
  gap: 5%; 
  height:50vh;
}

.train-drivers .upload{
  /* background: red; */
  
}
.train-drivers .content{
  /* background: blue; */
  border: solid 1px;
}

.dropzone{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2;
  border-radius: 2;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  /* height: 100px; */
  
}