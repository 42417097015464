/*=============================
  Layout of the password prompt
================================*/
.password-verify-modal {
    margin-top: 120px;
    height: auto ;
    width: 50vw;
    max-width: 50vw; 
    user-select: none;
}

.password-verify-modal .password-verify-content {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

    height: 12em;

}

.password-verify-content .title{
    font-size: 26px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 1em;
    /* grid-column: 2; */
}
.password-verify-content .loginStatus{
    margin-top: 1em;
    font-style: italic;
    margin-left:auto;
    margin-right:auto;
    color:red;
}
.password-verify-content .loginStatus1{
    margin-top: 1em;
    font-style: italic;
    margin-left:auto;
    margin-right:auto;
    color:green;
}



.password-verify-content .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.password-verify-content .footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%
}
