
.riskprofile{
  
  display: grid;
  grid-template-rows: 10% 90%;
  grid-template-columns: 100%;
  
  user-select: none;
  margin-top:-8vh; 
  height:70vh; /*Higher than this causes the scrollbar? */
  /* position: relative; */
}
.riskprofile .toprow{
  display: grid;
  grid-template-columns: 35% 65%;
  grid-template-rows: auto;
}
.riskprofile .riskcontent{
  height:100%;
}

.riskprofile .callin-filters{
  grid-column: unset;
  margin-top:-1em;
}

.riskprofile .react-datepicker-popper{
  z-index: 5;
}

.riskprofile .form-control{
  min-width: 5.815em;
}
