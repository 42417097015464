
/* Driver photo section add [blank="false"] if we need specific styles on the blank avatar sectionts */
.driver-details{
    width: 100%;
    height: 100%;
    max-height: 100%;
    /* background-color: red; */
    position: relative;
    display: grid;
    grid-template-rows: 1.25em auto 1.25em;
    place-items: center;
}

.driver-details div.driverphoto{
    width: 100%;
    height: 100%;
    /* Add background image controls, set to be single contained centered image */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
