.geofence-group-settings-modal {
    margin-top: 120px;
    height: auto ;
    width: 50vw;
    max-width: 50vw; 
    
    user-select: none;
}

.geofence-group-settings-modal .geofence-group-settings-content {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    min-height: 20vh;
    height: calc(20vh + var(--policyCount,1) * 5vh);
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    
}

@media screen and (max-width:1300px) { /*only apply if the screen width is less than*/
  .geofence-group-settings-modal .geofence-group-settings-content {  height: calc(28vh + var(--policyCount,1) * 6vh);  }
  .geofence-group-settings-modal {   width: 65vw; max-width: 65vw; }
}
.group-header{
  width:100%;
  display: grid;
  grid-template-columns: 10% 80% 10% ;
}

.geoTitle{
    margin-left:auto;
    margin-right:auto;
    font-size: 26px;
}

.group-header .geoTitle2{
    font-size: 2.85vh;
    margin-left:auto;
    margin-right:auto;
    grid-column: 2;
}
.group-header .stackedTitle{
  font-size: 26px;
  margin-left:auto;
  margin-right:auto;
  grid-column: 2;
}
.groupEditTitle{
  font-size: 26px;
  font-weight: normal !important;
}


.group-header .helpIcon{
  grid-column: 1;
}

/*=============================
  Configure the buttons at the base of the card
================================*/
.gfs-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.gfs-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%
}

 /*=============================
  Title text for the speed zones
================================*/
.speedpolicyTitle{
  display: grid;
  grid-template-columns: 10% 15% 20% 20% 20% 10% ;
  margin-top:10px;
}
.speedpolicyTitle .title {
  grid-column: span 1;
  font-size: 16px;
}
/*=============================
  Layout of the rows of the speed zones
================================*/
.speedpolicy-display{
  display: grid;
  grid-template-columns: 10% 15% 20% 20% 20% 10% ;
  height: 4vh;
}
@media screen and (max-width:1300px) { /*only apply if the screen width is less than*/
  .speedpolicy-display{ height: 6vh; }
}
.speedpolicy-display .entry-field{
  border: 2px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  margin-left: 5px;
  /* margin-right: 5px; */
  margin-bottom: 5px;
  text-align: center;
  height: 90%;
}
.speedpolicy-display .dropDown{
  border: 2px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  margin-left: 5px;
  /* margin-right: 5px !important; */
  margin-bottom: 5px;
  text-align: center;
  width: 97%;
}
.speedpolicy-display .deleteRow{
  background:none;
  outline: none !important;
  border: none;
  color:red;
  font-size: 18px;
  text-align: left;
}

/*=============================
  Layout of the footer of the speed zones
================================*/
.speedpolicyFooter{
  display: grid;
  grid-template-columns: 10% 15% 20% 20% 20% 10% ;
}
.speedpolicyFooter .addZone{
  margin-top: 10px;
  grid-column: 2;
}


/*=============================
  Layout of the help image popup
================================*/
.gfs-help-popup-modal {
  margin-top: 120px;
  height: auto ;
  width: 50vw;
  max-width: 50vw; 
  user-select: none;
  
}

.gfs-help-popup-modal .gfs-help-popup-content {
  /* border-radius: 4px; */
  transform-origin: top right;
  width: unset;
  margin: auto;
  text-align: center;
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
          box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  
}
.gfs-help-popup-content .helpimg {
  width: 100%;
  object-fit:fill;
}

.categorydrop .rw-placeholder{
  color: red;
}

.speeddelta ::placeholder{
   color: red;
  font-size:unset; 
}

.conflict .entry-field{
  color:red;
}

.conflict .rw-input{
  color:red;
}
