
.dateselect{
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
}

.dateselect .content{
  width:fit-content;
  height:5vh;
  grid-column: 4;
  /* background: #e9e9e9; */
  border-radius: 4px;
  padding: 7px 0 7px 10px;
}

.react-datepicker{
  margin-right:1vw;
  /* padding-right: 10px; */
}

.vertically-center{
  display: flex ;

  flex-direction: row;
  /* How to space across the div */
  justify-content: flex-end;
  /* Center in the div */
  align-items: center; 
}

.dateselect .label-text{
  padding-right: 2px !important;
  /* color:black; */

}

/* Style the date selection boxes*/
.dateselect .date-pick {
  margin-right: 8px;
  /* Set the border  and corner rounding */
  border: 1px solid #bdc3c7;
  border-radius: 2px;

  /* Update the size of the date selection regions */
  font-size: 11px;
  height: 27px !important;
  width: 90px !important;
  
}

.dateselect .react-datepicker__close-icon {
  right: 11px;
}

