/* Style a popup that Displays a simple message and asks the user to confirm or deny the options */

.cutvideonotecard-modal {
    /* height: auto ; */
    margin-top: 10vh; 
    width: 50vw;
    height: 45vh;
    max-width: 80vw; 
    user-select: none;

    font-size: 1.1vw;
}
.cutvideonotecard-modal p{
    font-size: inherit;
}

.cutvideonotecard-modal .content-modal {
    display: grid;
    grid-template-rows: fit-content(10%) 14% 30% 20%;
}

/* Configure the base line sizes on the footer */
.cutvideonotecard-modal .btn {min-width: 15%;}
.cutvideonotecard-modal .blank-spacer {  width: 15%;}

.section{
    /* background-color: bisque; */
    display:flex;
    flex-direction: column;
    /* display: grid; */
    /* grid-template-rows: fit-content(10%) auto; */
}

.section-title{
    text-align: left;    
    font-size: 125%;
    margin-bottom: 1%;
}

.section-content{    
    margin-left:7%;
    margin-right: 7%;
}


/* Override previous passed in with the Style from ExpandedCard render() if hwTags were to be shown */
.cutwindow .settime{
    background-color:rgba(0, 174, 239,.05);
}
.cutwindow .settime[clamped="true"]{
    background-color: rgba(237, 28, 36, .4);
    
}

.cutwindow {
    width: 80%;
    display: grid;
    grid-template-columns: 50% 50%;
    gap:1%;
}

.cutwindow .label-input-pair {
    grid-template-columns: 60% 40%;
}

.cutfeedback{
    margin-top: 3%;
}

.cutvideonotecard-modal .preloader3 {   
    height:4vh;
    /* Shift it down so that it is center aligned */
    transform: translateY(50%);     
}


.cutvideonotecard-modal .dvr-status{
    /* Push to the far right? */    
    position: absolute;
    right: 0px;
    width: 40%;
    /* Set the children to align side by side */
    display: grid;
    grid-template-columns: 60% 40%;
}
/* Add the label  to right justify against the break*/
.cutvideonotecard-modal .dvr-status .text-label{
    text-align: right;
}

.dvr-status > div{ border-radius: 6px}
/* Set up the animation to stick to the height of the parent container */
.dvr-status .FETCHING .preloader3 {   
    height:100%;
    /* Shift it down so that it is center aligned */
    /* transform: translateY(50%);      */
}
.dvr-status .PENDING {      background-color: #d3e011;}
.dvr-status .ARCHIVED {     background-color: rgb(0, 174, 239);}
.dvr-status .AVAILABLE {    background-color: rgb(141, 198, 63);}
.dvr-status .UNAVAILABLE {  background-color: #eb0b0b;}

.errormsg{
    color:red;
}
