

.apply-filters{
    border-radius: 15px;
    /* width: 80px; */
    width: min(80px,5vw);
    margin-right: 25px;
    background-color: #AFD47B;
}


.apply-filters:hover:enabled{
    transform:scale(1.05);
    
}
.apply-filters:active:enabled{
    transform:scale(0.9); 
}


/*******************************************************************
    General styling applied to the navigation tabs
********************************************************************/
.nav{
    /* justify-content: space-between;      */
    flex-wrap: unset;
}
.nav-link{
    min-width: 33%;
    padding: .5rem;
    overflow-wrap: break-word;
}


/*Retina style displays: */
@media (min-resolution: 2dppx) and (max-width:1800px) { }