
/* Set general attributes of modal-content is defined in bootstrap */
.content-modal{
    transform-origin: top right;
    width: 100%;
    height: 100%;
    /* height: 40vh !important;  */
    margin: auto;  text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);  border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

    /* Set padding on the left and right of the card, so content doesn't flow to the edge */
    padding-left:2.5%;
    padding-right:2.5%;
}
/* General positioning of footer elements on the notecard */
.content-modal .footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.content-modal .message{
    /* background-color: red; */
    overflow-wrap: break-word;
    /* hyphens: auto; */
}

.content-modal .title{
    /* Unset the grid-column/row when used in a content-modal context */
    grid-column: unset;
    grid-row: unset;

    /* background-color: red; */
    margin-bottom: 1%;
}

.content-modal .title-warning{
    position: absolute;
    margin-top:0%;
    font-size: 18px;
    font-style: italic;
    left:0;
    right:0;
    color: red;
}
