/*=====================================================================
  Style the Video Reviewer
=====================================================================*/

.highlight-view .video-reviewer {
  margin-top: -4.5em;
}

.video-reviewer-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: start; */
  /* margin-top: 4em; */
  margin-top: -0.5em;
}

.video-reviewer-bottom > * {
  flex-grow: 2;
  max-width: 88%;
  margin: 1em;
  margin-left: 3%;
  /* margin-left: 7%; */
  
}


.video-reviewer-top {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
  align-items: start;

  display: grid;
  grid-template-columns: 24vw 55vw 14vw 1vw ;
  
  /* margin-top: 2em; */
  max-width: 100%;
  /* margin-left: 1%; */
}

.video-reviewer-top > * {
  flex-grow: 2;
  /* max-width: 50%; */
  margin: 1em;
}

.video-chooser-group {
  display: flex;
  flex-direction: row;  
  justify-content: flex-start;   
}

.video-chooser-tabs {
  width: 10px;
  font-size: 30px;
}

.video-chooser {
  display: flex;
  flex-direction: column;
  /* min-width: 23%;
  max-width: 23%; */
}

.video-chooser button {
   width: 100%; 
  margin-bottom: 3px;
  border-radius: 6px;
}

.video-chooser .tab-content>.tab-pane {
  /* overflow: auto;  
  height: 50vh; */
} 

/* ::-webkit-scrollbar-track {
  background: #f10000;
} */

/* @keyframes is-loading-animate {
  0% { transform: scaleX(1.02) }
  100% { transform: scaleX(.98) }
} */
@keyframes is-loading-animate {
  0% { background-color: whitesmoke }
  100% { background-color: #AFD47B }
}




.video-chooser .is-loading{
  /* background-color: indianred; */
  /* animation-name: is-loading-animate;
  animation-duration: 1s ease infinite alternate; */
  animation: is-loading-animate 0.5s ease infinite alternate;
}

.video-reviewer-players {
   /* max-width: 53%;      */
  /* width: fit-content;  */
  margin-left:auto;
  margin-right:auto;
  width:100%;
}



.video-card.video-card-placeholder {
  background: white !important;
  border: 1px dashed #ccc !important;
  box-shadow: none !important;
}

.video-card.video-card-placeholder > * {
  display: none;
}

.video-card.video-card-placeholder .video-card-tab-wrap {
  display: block;
  background: white !important;
  border: 1px dashed #ccc !important;
  color: white;
  box-shadow: none;
}

.video-card.video-card-placeholder .video-card-tab {
  background: white !important;
  border-left: 10px solid white !important;
}

.video-card-deck .ReactVirtualized__Grid__innerScrollContainer {
  height: 100% !important;
  max-height: 100% !important;
}

/* Remove blue outline/border around the list on Chrome when it is active */
.video-card-deck .ReactVirtualized__Grid, .ReactVirtualized__List {
  border: none !important;
  outline: none !important;
}

/*
Configure the notecard list on the side:
*/
.video-reviewer-top .review-card-list .ReactVirtualized__List {
  border: none !important;
  outline: none !important;
  /*unset the min width of the cards so that they fit in the space on the page*/
  width:unset !important; 
}

.video-reviewer-top .review-card-list{
  width:unset !important;
}


.video-reviewby{
  margin-top: -1.5em;
  margin-bottom: 1.5em; 
  text-align: left;
}

.archived-filename{
  margin-bottom: 4.0em;
  margin-top: -4.0em; 
  text-align: center;
}