.storycall-modal {
  min-width: 88vw;
  width: 88vw;
  height: 40vh;
  user-select: none;
  margin-left:auto;
  margin-right:auto;
  margin-top: 10vh;
}

.storycall-modal .storycall-modal-content {
   width: 100%; 
  height:100%;
  margin: auto;
  text-align: center;
  position: relative;
  background-color: #fff;
  
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
          box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

}

.storycall-modal .callin-table{
  font-size: .85vw !important;
  width: 100%;
}

/* Add a vertical scroll bar to the table */
.storycall-modal .callin-table >tbody {
  display:block;
  height:34vh;
  overflow-y:scroll;
}
.storycall-modal .callin-table >thead{
  display:table;
  width:100%;
  table-layout:fixed;
}
.storycall-modal .callin-table >tbody >tr {
  display:table;
  width:100%;
  table-layout:fixed;
}


/* Set a max column width? */
.storycall-modal .callin-table >tbody >tr >td {
 width:unset; 
overflow-x: hidden; 

text-overflow: ellipsis;    
vertical-align: middle
}

/* Set custom column widths? */
.storycall-modal .callin-table >thead >tr >th:nth-child(1) {  width:11.4%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(2) {  width:11.4%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(3) {  width:6.9%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(4) {  width:11.4%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(5) {  width:11.4%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(6) {  width:11.4%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(7) {  width:11.4%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(8) {  width:11.4%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(9) {  width:13.3%!important;}  

.storycall-modal .callin-table >tbody >tr >td:nth-child(1) {  width:11.5%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(2) {  width:11.5%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(3) {  width:7%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(4) {  width:11.5%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(5) {  width:11.5%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(6) {  width:11.5%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(7) {  width:11.5%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(8) {  width:11.5%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(9) {  width:12.5%!important;}  
