.riskstats{
  height:103%; 
  /* height:130vh; */
  display: grid;
  grid-template-columns: auto;
  /* grid-template-rows: 50vh 80vh; */
  /* grid-template-rows: 42vh 45vh 10vh;  */
  grid-template-rows: auto auto auto;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;  
  /* background-color: red; */
  z-index:1;
}
.riskstats .drivertable{
  height: 42vh;
  position: relative;
  display: grid;
  grid-template-columns: repeat(10, 9vw);
  grid-template-rows: 19vh,19vh;
  column-gap: 0.25vw; 
  /* row-gap: .25vh; */
  margin-left:0.125vw;
  margin-top:1.2vh;
  width:100%;  
  z-index: 2;
}

.riskstats .piecharts{
  grid-row:2;
  height: 45vh;
  width:100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  column-gap: 1vw;
  margin-top:-3vh;
  z-index: 1;
}
.piecharts .card{
  height:45vh;
  border: none;
}

.piecharts .stats-piechart-title{
  position: relative;
  z-index: 3;
  transform:translate(-5.8vw,3.5vh);  
  
  font-size: 3vh ;
  font-weight: 700 ;
  width:100%;  
  text-align: center;
}

.riskstats .stats-foot{
  height: 10vh;
  margin-top:-2vh;
  width:100%;  
  z-index: 2;
  transform:translateX(-5.5vw);  
}

.riskstats .nodata{
  font-size: 24px;
  font-style: italic; 
}


.google-visualization-tooltip {  
  background-color: red;
  /* margin-left: 150px !important;  */
  /* z-index:+1; */
}
