.storytile-content {
  /* width: 90%; */
  width: 100%;
  height:12vh;
  text-align: center;  
  border-bottom: 1px solid black;  
  border-right: 1px solid black;
  margin-left:auto;
  margin-right:auto;
  z-index: 1;

  transition-duration:250ms; 
  /* border: 1px solid black; */
  background-color: white;
}

.storytile-content .tiletable{
  height:100%;
  display: grid;
  grid-template-columns: 12% 89%;
  text-align: center;
}

.storytile-content .date{
  height: 12vh;
  text-align: center;
  /* border-right: 1px solid black;   */
  padding-top:60%;
}

.storytile-content .chartWrap{
  height: 12vh;
  text-align: center;
  position: relative;
}



.storytile-content:hover {
  transform:scale(1.01,105%) translate(0.5%,2.5%); 
  /* background-color: #00AFED15; */
  background-color: rgb(204,239,251);
  border-top: 1px solid black;
  z-index: 2;
}
.storytile-content:active {
  transform:scale(.98);  
}


