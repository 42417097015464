.notecarddriverid-card-modal {
    /* height: auto; */
    margin-right: 100px;
    margin-top: 120px;
    user-select: none;

    /* Duplicate in the modal below: */
    width: 52vw;
    height: 60vh;
    max-width: 1000px;      
    
}

.notecarddriverid-card-modal .notecarddriverid-modal {
    /* border-radius: 4px; */
    transform-origin: top right;
    margin: auto;
    text-align: center;
    /* border: none; */

    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;

    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

    /*Repeat the dimesions?*/
    width: 52vw;
    height: 50vh;
    display:grid;
    grid-template-rows: 10% auto 3em;
    grid-template-columns: auto;

}

.notecarddriverid-modal .title{
    grid-row : 1;
    grid-column: unset;
}


.notecarddriverid-modal .topDisplay{
    display: grid;
    grid-template-columns: 50% 49%;
    height: auto;
    position: relative;
}

.notecarddriverid-modal .driverDisplay{
    display: grid;
    grid-template-columns: repeat(3,32%);
    grid-template-rows: 54% 40%;
    grid-column-gap: 0.5%;
    grid-row-gap: 6%;
    height:100%;    
    
    
}

.driver-select:hover{
    transform:scale(1.05,1.05);
    -webkit-transform:scale(1.05,1.05);
    -moz-transform:scale(1.05,1.05);
} 
.driver-select:active{
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}
.driver-select[selected-driver='true'] {
    border: 2px solid rgba(0, 255, 0, .75);
}

.notecarddriverid-modal .driver-select{
    height: 100%;
    /* background-color: blue; */
}
/* .notecarddriverid-modal .driver-details{
 width: 100%;
 height: 100%;
 max-height: 100%;
 position: relative;
 display: grid;
 grid-template-rows: 1.25em auto 1.25em;
 place-items: center;
} */

.notecarddriverid-modal .cell-label{
    width: 100%;
    height: 100%;
}

.notecarddriverid-modal .driverblank{
    height: 100%;
}
/* Driver photo section add [blank="false"] if we need specific styles on the blank avatar sectionts */
/* .notecarddriverid-modal div.driverphoto{
    width: 100%;
    height: 100%;*/
    /* Add background image controls, set to be single contained centered image */
    /* background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
} */ 

.notecarddriverid-modal .notecard-video{
    width: 100%;
    height:auto;    
    object-fit: fill;
}


.notecarddriverid-modal .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.notecarddriverid-modal .footer .btn {
    font-size: clamp(.5em,1vw,.9em);
    padding: clamp(.25em,0.5vw,.5em);
    margin-bottom: 2px;
} 


.empty-bottom{   height:7em;}

input:invalid {  background-color: #ffdddd;  }

/*=====================================================================
  Show the infractionid on small screens that don't have console access:
=====================================================================*/
.notecarddriverid-modal .notecardRef>span{    user-select: all; } 
/* By default set it to hiddden */
/* .notecarddriverid-modal .notecardRef{    display: none; }  */
/*only apply if the screen width is less than*/
/* @media  (max-width:1300px)  {  */
    /* Enable the view and allow a single click to select the full text for copy */
    /* .notecarddriverid-modal .notecardRef{    display: initial; }      */
    .notecarddriverid-modal .notecardRef>span{    user-select: all; } 
/* } */
