.usermanagement-card-modal {
    /* height: auto; */
    margin-right: 100px;
    margin-top: 120px;
    width: 750px;
    max-width: 750px;
    height: 305px;
    user-select: none;
    height:455px;
}

.usermanagement-card-modal .usermanagement-modal {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    
    text-align: center;
    /* border: none; */

    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    /* border: 1px solid #999; */
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

    height:100%
}
.um-title{
    margin-left:auto;
    margin-right:auto;
    font-size: 26px;
}
.um-title .title2{
    font-size: 26px;
    text-align: center;
}
.um-add{
    width:5%;
}

.usermanagement-modal .add-action {
    width: 100%;
}

.usermanagement-modal .comment {
    background: #f4f5f7;
    border-radius: 4px;
    margin: 8px 0;
    padding-left: 8px;
    padding-top: 8px;
    width: 96%; 
}

.geofence-modal .comments {
    overflow-y: auto;
    max-height: 250px;
    margin-bottom: 5px;    
}
.rw-multiselect-taglist {
    max-height: 75px;
    overflow-y: auto;
}

.usermanagement-modal .gf-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.usermanagement-modal .um-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    /* margin-right: 5px; */
    /* height: 25px; */
} 
.usermanagement-modal .um-footer .blank-spacer {
    width: 30%;
    border: none;
  }

.um-footer .delete-region {
    margin-top: 8px;
    width:15%;
}
.um-footer .add-comment {
    margin-top: 8px;
    width:fit-content;
}
.um-footer .close-region {
    margin-top: 8px;
    width:15%;
}
.um-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.geofence-modal .monitored-action{
    width:100%;
    position: relative;
}
.geofence-modal .monitored-action .action-element{
    width:100%;
}


.um-spec-tall{
    display: grid;
    grid-template-columns: 40px 10px 100px 125px 100px 98px 125px 100px;
    align-items: baseline;
    margin-top:10px;
}
.um-spec-tall .title-wide{
    font-size: 14px;
    grid-column: 2/4;
    grid-row:auto;
}
.um-spec-tall .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.um-spec-tall .entry-field{
    height:5.5em;
    width:95%
}
.um-spec-tall .entry-field-wide{
    height:5.5em;
    grid-column: span 3;
}






.rw-popup-container{
    top:unset !important
}



.um-spec{
    display: grid;
    grid-template-columns: 40px 10px 100px 125px 100px 98px 125px 100px;
    align-items: center;
    margin-top:5px;
}
.um-spec .icon{
    display:block;
    margin-left: auto;     
}
.um-spec .title-wide{
    font-size: 14px;
    grid-column: 2/4;
    grid-row:auto;
}
.um-spec .title-wide-error{
    font-size: 14px;
    grid-column: span 2;
    grid-row:auto;
    color: red;
    font-style: italic;
}
.um-spec .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.um-spec .entry-field{
    height:2em;
    width:95%
}
.um-spec .entry-field-wide{
    height:2em;    
    grid-column: span 3;
}
.um-spec .entry-field-wide-conflict{
    height:2em;    
    grid-column: span 3;
    background-color:red;
}

.empty-bottom{
    height:7em;
}

input:invalid {
    background-color: #ffdddd;
  }



.usermanagement-popup-modal {
    margin-top: 120px;
    height: auto ;
    width: 95vw;
    max-width: 95vw; 
    user-select: none;
    
}

.usermanagement-popup-modal .help-popup {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    
}
.usermanagement-popup-modal .help-popup .roleimg {
    width: 100%;
    object-fit:fill;
}


/* 
* Set up the notification box section at the bottom fo the User notecard
*/
.notificationSection{
    width:98%;
    height:43%;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 1em;
    margin-top:-1em;
    /* border: 1px solid rgba(0, 0, 0, .2); */
}
.notificationSection .title{
    font-size: 20px;
    text-align: center;
}
.notificationSection .toggles{
    display: grid;
    grid-template-columns: 4% 23% 23% 23% 23% ;
    /* justify-content: space-evenly; */
    margin-top:.5em;
    margin-bottom:.5em;
}
.notificationSection .enableAll{
    border-radius: .5em;
    background-color: rgba(141,198,63,0.6);
    border:none;
    outline: none;
    grid-column: 3;
    width: 75%;
    margin-left: auto;
    margin-right:auto;    
}
.enableAll:hover {
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
}
.enableAll:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}
.notificationSection .disableAll{
    border-radius: .5em;
    background-color: rgba(237,28,36,0.6);
    border:none;
    outline: none;
    width: 75%;
    margin-left: auto;
    margin-right:auto;
}
.disableAll:hover {
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
    }
.disableAll:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}

/* Create the section to hold the list of checkboxes and infraction types */
.notificationSection .notificationlist{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    
}
/* Each element in the list contians two items - checkbox and label 
* adjust the width to determine the number of items per row
*/
.notificationSection .notificationElem{
    width:24%;
    /* border: 1px solid rgba(0, 0, 0, .2); */
    display: grid;
    grid-template-columns: 15% 75% ;
}
/* Format the location of the checkbox  - vertically center */
.notificationSection .notificationElem .notifyCheck{
    margin-top:auto;
    margin-bottom: auto;
    margin-left: 10%;
}
/* Format the label  - vertically center */
.notificationSection .notificationElem .notifyText{
    margin-top:auto;
    margin-bottom: auto;
    text-align: left;
}

/*=====================================================================
  Format notification list
=====================================================================*/
.usermanagement-modal .notifTabs{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:50%;
    margin-left: auto;
    margin-right: auto;   
}

input:disabled+label{color:#ccc;} /* Gray out a label following the disabled checkbox*/


