
  

  .live-reviewer-players{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
  }
  .live-reviewer-players .live-reviewer-player{
    margin-left: auto;
    margin-right: auto;
  }
  .live-reviewer-player .fixed-size{
    object-fit: fill;
  }

  .live-reviewer-players .notifyButtonsRight{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    /* margin-top: 1em; */
    /* margin-top: -1em; */
  }
  .live-reviewer-players .notifyButtonsLeft{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    /* margin-top: 1em; */
  }

  .live-reviewer-players .notifyButtonsLeft .email-button{
    border-radius: 20px;
    width: 125px;
    height: 85px;
  }
  .live-reviewer-players .notifyButtonsRight .alert-button{
    border-radius: 20px;
    width: 200px;
    height: 85px;
    outline:none;
    margin-bottom: 1em;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }
  .alert-button .alert-button-img{
    width: 35px;
    height: auto;
    margin-right: 5%;
  }
  .alert-button > img{
    max-height: 75%;
  }


  .hidden-video {
    display: none !important;
  }
  
  .live-reviewer-player {
    position: relative;
  }

  .live-reviewer-player .overlay-button {
    /* position: absolute; */
    position: absolute; 
    top: 10px;
    right: 5%;
    width: 76px;
    height: 76px;
    border-radius: 38px;
    border: none;
    opacity: 0.5;
  }
  .live-reviewer-player  .overlay-button[data-card] {
    background: rgb(141, 198, 63);
  }

  



  .live-reviewer .live-reviewer-players .notifyButtonsRight .top-row{
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin-bottom: .25em;
    
  } 
  
  .live-reviewer .live-reviewer-players .notifyButtonsRight .download-button {
    margin-top: 8px;
    color: white;
    width: 120px;
    transform: translate(0, -10%);
    background: rgb(159, 212, 123);
    border-radius: 20px;
  }
  
  .live-reviewer .live-reviewer-players .notifyButtonsRight .downloaded-button {
    margin-top: 8px;
    color: white;
    width: 120px;
    height: 40px;
    transform: translate(0, -10%);
    background: rgb(159, 159, 159);
    border-radius: 20px;
  }

  
.live-reviewer-players .playbuttons{    
  display: grid;
  grid-template-columns: 14.25% 14.25% 19% 19% 14.25% 14.25%;
  column-gap: 1%;
  user-select:none;
}

  .live-reviewer .live-reviewer-players .playbuttons .skipbutton {
    /* width: 20%; */
    width:100%;
    height: 75px;
    border: none;
  }
  
  .live-reviewer .live-reviewer-players .playbuttons .blank-spacer {
    /* width: 15%; */
    width: 100%;
    height: 75px;
    border: none;
  }

  .live-reviewer .live-reviewer-players .playbuttons .reorderbutton {
    width:100%;
    height: 75px;
    border: none; 
    background-color: rgb(51,207,253);
    border-radius: 15%;
  }
  

  
  .playbuttons > *{
    /*Allow interactive animation to transition instead of instant snap*/
    /* setting all will apply to any transform on the element instead of just scale/position... */
    transition: ease-in-out all 0.1s; 
  }

  .playbuttons > *:disabled{
    opacity:0.5;
  }
  .playbuttons > *:hover:enabled{
    transform:scale(1.05,1.05);
    -webkit-transform:scale(1.05,1.05);
    -moz-transform:scale(1.05,1.05);
  } 
  .playbuttons > *:active:enabled{
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
  }  
