
.drivercard{
    user-select: none;
    width: 100%;
    height:100%;
}
.drivercard .drivertab-content {

    transform-origin: top right;
    width: 100%;
    height:100%;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

}
.drivertab-content .dc-title{
    margin-left:auto;
    margin-right:auto;
    font-size: 26px;
    text-align: center;
}

.drivertab-content .dc-top{
    margin-top: 1em;
    margin-bottom: 1em;
    height:60%;
    display: flex;
}
.drivertab-content .dc-top .dc-tl{
    width: 65%;
}
.drivertab-content .dc-top .dc-tr{
    width: 35%;
}

.drivertab-content .driverphoto{
    width:50%;
    height:auto;
}

.drivertab-content .hamburger{
    width: 2%;
    height: auto;
    position: absolute;
    top: 6px;
    right: 8px;
}

.drivercard .add-action {
    width: 100%;
}


.drivercard .dc-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
}
.drivercard .dc-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    pointer-events: all;
    /* margin-right: 5px; */
    /* height: 25px; */
} 

.drivercard .dc-footer .btn:hover {
    transform:scale(105%,105%);
}


.drivercard .dc-footer .blank-spacer {
    width: 30%;
    border: none;
  }

 .drivercard .dc-footer .delete-region {
    margin-top: 8px;
    width:15%;
}
.drivercard  .add-comment {
    margin-top: 2em;
    width:fit-content;
}
.drivercard .dc-footer .close-region {
    margin-top: 8px;
    width:15%;
}

.drivercard .settings-grid {
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    grid-template-rows: auto auto auto auto;
    gap: 5px 0px;
    margin-top:5%;
    margin-left:5%;
  }


.drivercard .settings-grid .entry-field{
    height: 2em;
    width:95%;
}
.drivercard .settings-grid .title-type{
    font-size: 14px;
    grid-column: 1/3;
    grid-row:auto;
    margin-top: auto;
}
.drivercard .settings-grid .title-units{
    font-size: 14px;
    grid-row:auto;
    margin-top: auto;
    text-align: left;
}

.drivercard .settings-grid .title{
    font-size: 14px;
    grid-row:auto;
}


.drivercard .empty-bottom{
    height:7em;
}


.drivercard .soc-spec-tall{
    display: grid;
    grid-template-columns: 50px 100px 125px 100px 100px 125px 100px;
    align-items: baseline;
    margin-top:5px;
}
.drivercard .soc-spec-tall .title-wide{
    font-size: 14px;
    grid-column: span 2;
    grid-row:auto;
}
.drivercard .soc-spec-tall .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.drivercard--content .soc-spec-tall .entry-field{
    height:5.5em;
    width:95%
}
.drivertab-content .soc-spec-tall .entry-field-wide{
    height:5.5em;
    grid-column: span 2;
}

.drivertab-content .soc-spec{
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    gap: 5px 0px;
    margin-left:5%;
    margin-bottom:1%;
}
.drivertab-content .soc-spec .title-wide{
    font-size: 14px;
    grid-column: 1/3;
    grid-row:auto;
    text-align: left;
}
.drivertab-content .soc-spec .entry-field{
    height:2em;
    width:95%
}
.drivertab-content .soc-spec .entry-field-wide{
    height:2em;    
    grid-column: span 3;
}

.drivertab-content .soc-lister-row table.video-table {
    table-layout: auto !important; 
 }
.drivertab-content .soc-lister tr.soc-name {
   overflow: hidden;
   text-overflow: ellipsis;
   text-align:left;
 }
.drivertab-content .soc-lister tr.soc-group {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
.drivertab-content .soc-lister tr.soc-remove {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}


.drivertab-content .btn:hover {
    transform:scale(105%,105%);
}

/* Comments */
.drivertab-content .commenter {
    width: 82.5%;
    margin-left: auto;
    margin-right:auto;
}
.drivertab-content .commenter .adding-buttons{
    height:4vh;
}
.drivertab-content .commenter .adding-buttons .btn{
    height: 100%;
    font-size: 0.9em;
}


.drivertab-content .comment {
    width: 96%; 
    text-align: left;
}

.drivertab-content .comment pre {
    margin-bottom: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
    font-family: 'Open Sans';
}
.drivertab-content .comment-timestamp pre {
    color: #555;
}



.drivertab-content .comments {
    overflow-y: auto;
    max-height: 64%;
    margin-bottom: 5px;    
    margin-left:5%;
}
.drivertab-content .comments-title {
    margin-top: 8px;
    text-align: left;
    margin-left:5%;
}


.drivertab-content .comment-timestamp {
    font-size: 0.8em;
    margin-top: -0.5em;
    left : 2%;
    display:inline-block;
}

.drivertab-content .comment-username {
    position: relative;
    font-size: 0.8em;
    margin-top: -0.5em;
    display:inline-block;
}

