

.video-reviewer .review-card-list {
    flex-grow: unset;
}

.video-reviewer .video-chooser, .video-reviewer .review-card-list {
    margin-top: 1.5em;
}

.notecard-view-toggle{
  margin-top: 25vh; 
  background-color: #AFD47B;
  border-radius: 10px;
  border:none;
  outline: none;
}
.notecard-view-toggle:hover {
  transform:scale(1.05,1.05);
  -webkit-transform:scale(1.05,1.05);
  -moz-transform:scale(1.05,1.05);
}
.notecard-view-toggle:active {
  transform:scale(.9,.9);
  -webkit-transform:scale(.9,.9);
  -moz-transform:scale(.9,.9);
}


/*===== Style the control buttons on the bottom of the page=======*/
  .controlbuttons{   
     
    display: flex; /*dynamically set the location of the elemnts in the group*/
    flex-direction: row; /*position them along a row instead of a column*/
    flex-wrap: wrap; /*Wrap to two rows if there is not enough space*/    
    font-size: 0.78125vw; /*Default font size*/
    width: 100%; /*use the full width of the parent div */
    justify-content: space-between; /*Equally space the elements apart along the row*/ 
    align-items: center;/*Center the items vertically in the control button container*/ 
  }

  /* General styles to apply to all buttons in the group */
  .video-reviewer-bottom .controlbuttons > button{    
    border-radius: 0.78125vw; /* Round the corners of the box */
    width: 7.8125vw; /* Set the default width to 150 pixels */
    height: 5.37vh;  /* Set the default width to height pixels */
    outline:none;     
    transition: ease-in-out all 0.1s; /*Allow interactive animation to transition instead of instant snap*/
  }

  /* Set the unique values for each button */
  .video-reviewer-bottom .controlbuttons .reprocess-button{
    margin: -0.8em;
    width: 4.16vw; /* Set the width to ~80 pixels */
    height: 8.59vh; /* Set the height to ~80 pixels */
    background-color: transparent; /* Remove the background color of the button, leave just the image */
    border: none; /* Remove the border to leave just the image */
  }  
  .video-reviewer-bottom .controlbuttons .complete-button { background-color: #61BD7D;  }
  .video-reviewer-bottom .controlbuttons .publish-button  { background-color: #AFD47B;  }
  .video-reviewer-bottom .controlbuttons .review-button   { background-color: #00AFED;  }
  .video-reviewer-bottom .controlbuttons .unusable-button { background-color: #E6002490;}

  /* Add the interactions with the control buttons, will apply to all buttons in this group */
  /* Only apply these interactions if the button is not disabled */  

  
  

  /* Interaction when hovering over the button */
  .video-reviewer-bottom .controlbuttons > button:hover:enabled { 
    transform:scale(1.05,1.05); /* Change the X,Y scale of the object, relative to the no interative size */
    -webkit-transform:scale(1.05,1.05); /*compatibility with other browsers*/
    -moz-transform:scale(1.05,1.05); /*compatibility with other browsers*/
  }
  /* Interaction when clicking on the button */
  .video-reviewer-bottom .controlbuttons > button:active:enabled {
    transform:scale(.9,.9); /* Change the X,Y scale of the object, relative to the no interative size */
    -webkit-transform:scale(.9,.9); /*compatibility with other browsers*/
    -moz-transform:scale(.9,.9); /*compatibility with other browsers*/
  }

  /* Modify the control buttons if the tagged attribute has been set to processing
     This catches a special case for the processing button, we dont' want the hover and click interation
     to be available when the button is in the processing state.
  */
  .video-reviewer-bottom .controlbuttons > button[tagged="processing"]:hover { 
    transform: unset; /*Disable the hover change when the tag is present */
  }
  .video-reviewer-bottom .controlbuttons > button[tagged="processing"]:active { 
    transform: unset; /*Disable the hover change when the tag is present */
  }

  /* =========END ControlButton styling =========== */

  .video-reviewer-top .video-chooser .reprocess-button{
    position: absolute;
    bottom: 10px;
    width: 80px;
    height: auto;
    border: none;
  }
  

  .nav-link {
    /* color: blue !important; */
    color: black;
    background-color: #00AFED15 !important;
    /* margin: 1em; */
  }
  .nav-link.active {
    color: black;
    background-color: #00AFED77 !important;
}

.roibuttons{    
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10%;
  /* height: 1000px; */
  justify-content: space-between;   
  /* justify-content: space-around; */
  align-content: center;
  /* margin: 1em; */
  
}
  



  
  
  
