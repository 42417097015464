
/* Top level wrapper on the map - define the region offset */
.mapView{
    /* margin-top: -3.5em;
    height:75vh;
    width:103.5%; */
    /* margin-top: -10vh;
    height: 60vh;
    width: 100vw; */
    margin-left: -1.5%;
}


.olMap {
    width: 100%;
    height: 100%;
    margin-top:5px;
}

.ol-popup .ol-popup-content {
    overflow: hidden;
    cursor: default;
    padding: 0.25em 0.5em;
    text-align: initial;
}

.inline-circle{
    height: 65px;
    width: 65px;
    border-radius: 50%;
    border:1px solid red;
    line-height: 65px;
    text-align: center;
}
.ol-popup img {
    margin-left: 10%;
    max-width: 25px;
    max-height: 25px;
    /* float: right;
    margin: -1.25em 0.5em 0 0;
    max-width: 100px;
    max-height: 100px; */
}
.toggle-infractions{
    top:0.5em;
    right:1%;
}


/* Active buttons */
.ol-toggle.ol-active > button {
    background: rgba(62, 136, 0, 0.7)
}
.ol-toggle.ol-active button:hover {
    background: rgba(60, 136, 0, 0.7)
}
.ol-toggle button:disabled {
    background: rgba(0,60,136,.3);
}

.mapView .dateselect{
    /* Set the background color of the datefilter */
    background: #e9e9e9;
}
.toggle-fence-button{
    /* background: rgba(62, 136, 0, 0.7); */
    width: 100px;
    font-size: 14px;
    height: auto;
}

.map-filters2 > * {
    background: #e9e9e9;
}

.map-filters2 :nth-child(2), .map-filters2 :nth-child(3) {
    padding: 0;
}
.map-filters2 .date-pick {
    margin-right: 8px;
    border: 1px solid #bdc3c7;
    border-radius: 2px;
}

.map-filters2 .label-wrap {
    padding-right: 2px !important;
}

.map-filters2 .nav-item {
    max-width: 180px;
}


.map-filters2 .date-pick {
    font-size: 11px;
    height: 27px !important;
    width: 90px !important;
}


.map-filters2 select {
    /* Styling for the specific select box */
    height: 27px !important;
    font-size: 11px;
    /* max-width :8em !important; */
    /* Remove the default filtering of the select dropdown */
    -webkit-appearance: none;
    -moz-appearance: none;
     appearance: none; 
    /* Add some styling */
    display: block;
    font-family: sans-serif;
    color: #444;
    line-height: 1.3;
    padding: .25em 1.4em .25em .8em;
    max-width: 100%; /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: .5em;
    background-color: #fff;
    /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
        for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
    */
    /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), */
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right .4em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 1.25em auto, 100%;
}

.map-filters2 .react-datepicker__close-icon {
    right: 11px;
}

.filter-controls{
    position:relative;
    width: 100%;

    display:grid;
    grid-template-columns: 21.5% fit-content(12vw) auto;
    grid-template-rows: auto;
    margin-top:1vh;
}

.map-controls > * {
    background: #e9e9e9;
}


.filter-dropdown-container ul {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-items: center !important;
}

.filter-controls .site-settings{
    height: 20px;
    border: 1px solid #aaa;
     box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
     border-radius: .5em;
     margin-right: 0.25vw;
}
.filter-controls .dateselect {
    grid-column: 2;
}
.filter-controls .map-controls {
    background: #e9e9e9;
    border-radius: 4px;
    padding: 7px 0 7px 10px;
    font-size: 12px;
    grid-column: 3;
    /* height: 5vh; */
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right:1px;
    margin-left: -0.5vw;
    width:fit-content;
}
.filter-controls .map-filters2 {
    background: #e9e9e9;
    border-radius: 4px;
    padding: 7px 0 7px 10px;
    font-size: 11px;
    /* height: 5vh; */
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -0.5vw;
    grid-column: 3;
    width:fit-content;
}

.filter-controls .nav-link.disabled {
    /* color: blue !important; */
    color: #0000006c;
    background-color: #8c8e8f18 !important;
    font-size: 14px;
    /* margin: 1em; */
  }

.filter-controls .map-controls :nth-child(2), .map-controls :nth-child(3) {
    padding: 0;
}


.filter-controls .map-controls .date-pick {
    margin-right: 8px;
    border: 1px solid #bdc3c7;
    border-radius: 2px;
}

.filter-controls .map-controls .label-wrap {
    padding-right: 3px !important;
}

.filter-controls .map-controls .date-pick {
    font-size: 11px;
    height: 27px !important;
}



.filter-controls .map-controls select {
     /* Styling for the specific select box */
     height: 27px !important;
     font-size: 11px;
     /* font-size: 0.9vw; */
     /* max-width :8em !important; */
     /* Remove the default filtering of the select dropdown */
     -webkit-appearance: none;
     -moz-appearance: none;
      appearance: none; 
     /* Add some styling */
     display: block;
     font-family: sans-serif;
     color: #444;
     line-height: 1.3;
     padding: .25em 1.4em .25em .8em;
     max-width: 110px; /* useful when width is set to anything other than 100% */
     /* max-width: 8.5vw; */
     box-sizing: border-box;
     margin: 0;
     border: 1px solid #aaa;
     box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
     border-radius: .5em;
     background-color: #fff;
     /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
         for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
     */
     /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), */
     background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E'),
         linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
     background-repeat: no-repeat, repeat;
     /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
     background-position: right .4em top 50%, 0 0;
     /* icon size, then gradient */
     background-size: 1.25em auto, 100%;
}

.filter-controls .map-controls .react-datepicker__close-icon {
    right: 12px;
}


/* ===============================================================
Add formatting to include a help icon in the title text for the dropdown menu
===============================================================*/
.filter-controls .map-controls .richDropTitle {
    display:flex;
    width:100%;
}
.filter-controls .map-controls .titleText {
    margin-top:auto;
    margin-bottom:auto;
}
.filter-controls .map-controls .richDropTitle .helpIcon {
    width:30%;
    margin-right:5px;
    margin-top:1px;
    
}

.FilterSelection{
    user-select: none;
    grid-column-start: 1;
    grid-column-end:2;
}

.FilterSelection .viewSelection{    
    display:grid;
    grid-template-columns: 33% 33% 33%;
    justify-content: space-around;
    width: 21vw;
    margin-right: auto;
    height:100%;    
}

.mapView .apply-filters{
    border-radius: 15px;
    width: 80px;
    margin-right: 25px;
    background-color: #AFD47B;
}

.ol-control.ol-layerswitcher-image{
    right: 3em !important;
    top: 0.5em !important;
}



