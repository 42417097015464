.journeynotecard-modal {
    
    /* height: auto ; */
    margin-top: 10vh; 
    width: 60vw;
    height: 80vh;
    max-width: 80vw; 
    user-select: none;
}

.journeynotecard-modal .journeynotecard-modal-content {
    transform-origin: top right;
    width: 100%;
    height:100%;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);


    display: grid;
    grid-template-rows: fit-content(10%) 25% auto 20px;

}

.journeynotecard-modal .sc-title{
    margin-bottom: unset;
}


.journeynotecard-modal .add-action {
    width: 100%;
}


.journeynotecard-modal .sc-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.journeynotecard-modal .sc-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    /* margin-right: 5px; */
    /* height: 25px; */
} 
.journeynotecard-modal .sc-footer .blank-spacer {
    width: 30%;
    border: none;
  }

 .journeynotecard-modal .sc-footer .delete-region {
    margin-top: 8px;
    width:15%;
}
.journeynotecard-modal .sc-footer .add-comment {
    margin-top: 8px;
    width:fit-content;
}
.journeynotecard-modal .sc-footer .close-region {
    margin-top: 8px;
    width:15%;
}
.journeynotecard-modal .sc-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.journeynotecard-modal .settings-grid {
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    grid-template-rows: auto auto auto auto;
    gap: 5px 0px;
    margin-top:5%;
    margin-left:5%;
  }


.journeynotecard-modal .settings-grid .entry-field{
    height: 2em;
    width:95%;
}
.journeynotecard-modal .settings-grid .title-type{
    font-size: 14px;
    grid-column: 1/3;
    grid-row:auto;
    margin-top: auto;
}
.journeynotecard-modal .settings-grid .title-units{
    font-size: 14px;
    grid-row:auto;
    margin-top: auto;
    text-align: left;
}

.journeynotecard-modal .settings-grid .title{
    font-size: 14px;
    grid-row:auto;
}


.journeynotecard-modal .empty-bottom{
    height:7em;
}


.journeynotecard-modal .soc-spec-tall{
    display: grid;
    grid-template-columns: 50px 100px 125px 100px 100px 125px 100px;
    align-items: baseline;
    margin-top:5px;
}
.journeynotecard-modal .soc-spec-tall .title-wide{
    font-size: 14px;
    grid-column: span 2;
    grid-row:auto;
}
.journeynotecard-modal .soc-spec-tall .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.journeynotecard-modal-content .soc-spec-tall .entry-field{
    height:5.5em;
    width:95%
}
.journeynotecard-modal-content .soc-spec-tall .entry-field-wide{
    height:5.5em;
    grid-column: span 2;
}



.soc-spec{
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    /* grid-template-rows: auto auto auto auto; */
    gap: 5px 0px;
    margin-left:5%;
    margin-bottom:1%;
    /* grid-template-columns: 40px 10px 100px 125px 100px 98px 125px 100px;
    align-items: center;
    margin-top:5px; */
}
.soc-spec .title-wide{
    font-size: 14px;
    grid-column: 1/3;
    grid-row:auto;
}
.soc-spec .entry-field{
    height:2em;
    width:95%
}
.soc-spec .entry-field-wide{
    height:2em;    
    grid-column: span 3;
}




.journeynotecard-modal-content .Table{
    height: 400px;       /* Just for the demo          */
    overflow-y: auto;    /* Trigger vertical scroll    */
    overflow-x: hidden;  /* Hide the horizontal scroll */
 }




/*=====================================================================
  Format the bottom section (tabs and map/tables)
=====================================================================*/
.journeynotecard-modal-content .journeyBottom{

    display: grid;
    grid-template-rows: fit-content(15%) auto;
    /* row-gap: 2%; */
    position: relative;
}

/* Remove the focus outline from the tabs so it doesn't interrupt the layout */
.journeynotecard-modal-content .viewTabs a:focus {   outline: 0; }

.journeynotecard-modal .journeynotecard-modal-content .viewTabs{
    width:50%;
    margin-left: auto;
    margin-right: auto;
    
}
/* Overwrite the general settings of olMap to make it fit the card */
.journeynotecard-modal-content .site-olMap {
    margin-top:unset;
    margin-left: auto;
    margin-right: auto;
    background-color: #cccccc;
    width: 95%;
    height: auto;
}

/* Style the table to auto add the scroll bar if there are too many elements 
   to fit on the allowed vertical space */
.journeynotecard-modal-content .journeyTable{
    width:90%;
    margin-left:5%;
    height: auto;
    max-height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
}
/* Adjust the table's font to match the scaling of the top section on the card */
.journeynotecard-modal tbody{
    font-size: clamp(10px,.9vw,14px);
}

.journeyTable .react-bootstrap-table{
    height: 35vh; /*Force a height so that the pagination is set in a constant spot*/
    /* background-color: green; */
}

.soc-lister-row table.video-table {
    table-layout: auto !important; 
 }
 .soc-lister tr.soc-name {
   overflow: hidden;
   text-overflow: ellipsis;
   text-align:left;
 }
 .soc-lister tr.soc-group {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
 .soc-lister tr.soc-remove {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}


.journeynotecard-modal .report-grid {
    display: grid;
    /* grid-template-columns: 50px 100px 75px 100px 75px 100px 75px 100px 75px 100px; */
    grid-template-columns: 4.3% 8.6% 6.5% 8.6% 6.5% 8.6% 6.5% 8.6% 6.5% 8.6% 6.5% 8.6% 10%; 
    grid-template-rows: auto auto auto auto;
    gap: 5px 0px;
    /* margin-top:5%; */
    margin-left:5%;
  }

  
  .journeynotecard-modal .report-grid .title-type{
    /* font-size: 14px; */
    font-size: .9vw;
    grid-column: 1/3;
    grid-row:auto;
    margin-top: auto;
}
.journeynotecard-modal .report-grid .value{
    /* font-size: 14px; */
    text-align: left;
    font-size: .9vw;
    margin-top: auto;
    width:95%;
}
.journeynotecard-modal .report-grid .value-wide{
    /* font-size: 14px; */
    grid-column: 3 /span 3;
    text-align: left;
    font-size: .9vw;
    margin-top: auto;
    width:95%;
}


.journeynotecard-modal .report-grid .title-type-r{
    /* font-size: 14px; */
    font-size: .9vw;
    grid-column-start: 11;
    grid-column-end: 12;
    grid-row:auto;
    margin-top: auto;
}
.journeynotecard-modal .report-grid .value-r{
    /* font-size: 14px; */
    font-size: .9vw;
    margin-top: auto;    
    grid-column-start: 12;
    grid-column-end: 14;
}
.journeynotecard-modal .report-grid .units{
    /* font-size: 14px; */
    font-size: .9vw;
    grid-row:auto;
    margin-top: auto;
    /* text-align: center; */
    text-align: left;
    padding-left: .3vw;
}

.driverphoto-oc{
    width: 3.25vw;
    height: auto;
    aspect-ratio: 4.25/5;
    object-fit: fill;
}