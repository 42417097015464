#root.reporting .box.mainApp {
    margin-left: 0;
    padding-left: 0;
    margin-right: 0;
    padding-right: 0;
}

#root.reporting .amplify-container {
    padding-left: 6px !important;
}

#root.reporting .pcoded .pcoded-inner-content {
    padding-right: 0;
  }


.hr-review {
    /* height: 40%; */
    display: grid;
    /* grid-template-columns: 10% 20% 20% 20% 20% 10%;  */
    grid-template-columns: 10vw 87.5vw 2.5vw;  
    grid-template-rows: 6vh 80%;
    width: 100%;  
    margin-top: -80px;
    transition-duration:250ms; 
}

.card-lists {
    display: flex;
    flex-direction: row;
    /* justify-content: flex-start; */
    justify-content: space-between; 
    align-items: flex-start;
    position: relative;
    grid-row:2;
    grid-column: 2;
    width:100%;
    
}

.hr-review .review-card:hover{
    /* background-color: red; */
    transform:scale(103%,103%) 
}
.hr-review .review-card-placeholder:hover{
    /* background-color: red; */
    transform:scale(100%,100%) 
}

@media screen and (max-width:1300px) { /*only apply if the screen width is less than*/
    .hr-review .filter-drop-site select.form-control.form-control-default{  max-width: 24ch; } 
}