@import "..//Notifications/Notifications.css";

.alert-icon {
    margin-top: 45%;
    width: 50px;
    height: 50px;
    margin-right: 25px;
    user-select: none;
  }
  





