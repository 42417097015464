/*******************************************************************
Style Card and CardList
********************************************************************/
@import "VideoCard-reviewcard.css";

#root.reporting {
    background: url('assets/backgrounds/LightBluePaper.jpg') no-repeat center center fixed;
    background-size: cover;
}

#root.reporting .amplify-container, #root.reporting .pcoded-main-container, #root.reporting #pcoded, #root.reporting .pcoded-container {
    background: transparent;
}

.review-card-list {
    background: #e9e9e9;
    padding: 0 4px 28px 7px;
    border-radius: 4px;
    margin-right: 10px;
    user-select: none;
    width:16%;
}

.review-card-list.can-drop {
    box-shadow: 0 0 2px red;
}

.review-card-list.is-over {
    box-shadow: 0 0 2px blue;
}

.review-card-list:last-child {
    margin-right: 0;
}

.review-card-list .review-card-list-title {
    /* font-size: 13px; */
    font-size: 0.75vw;
    font-weight: 600;
    text-align: left;
    padding-left: 7px;
    padding-bottom: 3px;
    padding-top: 3px;
}

.review-card-list *::-webkit-scrollbar {
    width: 10px;
    background: #f0f0f0;
    border-radius: 6px;
}

.review-card-list *::-webkit-scrollbar-thumb {
    width: 10px;
    background: #cdcdcd;
    border-radius: 5px;
}

.review-card-list *::-webkit-scrollbar-button {
    display: none;
}


/* Remove blue outline/border around the list on Chrome when it is active */
.review-card-list .ReactVirtualized__Grid, .ReactVirtualized__List {
    width:100% !important;
}


/* .review-card .hamburger, .expanded-card .hamburger {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 6px;
    right: 8px;
} */


/* This is for the small card*/
/* .review-card .audioalert{ 
    height: 2vh;
    position: absolute;
    left: 55%;
    margin-left:-7px;
    top: 0px;
} */





.audioalert{
    height: 30px;
    margin-left:auto;
    margin-right:auto;
    grid-column: 3;
}
.vibrationalert{
    height: 30px;
    margin-left:auto;
    margin-right:auto;
    grid-column: 4;
}

.audioalert > img {
    max-width: 100%;
    max-height: 100%;
}
.vibrationalert > img {
    max-width: 100%;
    max-height: 100%;
}



.review-card-placeholder * {
    display: none;
}

.review-card-placeholder {
    border: 1px dashed #aaa;
    background: transparent;
    position: relative;
    box-shadow: none;
}

.review-card-placeholder::after {
    content: 'Drag card here';
    position: absolute;
    top: 50%;
    left: 50%;
    color: #aaa;
    display: block !important;
    font-size: 14px;
    transform: translate(-50%, -50%);
}

.review-flow-restricted * {
    display: none;
}

.review-flow-restricted {
    border: 1px dashed #aaa;
    background: transparent;
    position: relative;
    box-shadow: none;
}
.review-flow-restricted::after {
    content: 'Access Denied';
    position: absolute;
    top: 50%;
    left: 50%;
    color: #aaa;
    display: block !important;
    font-size: 14px;
    transform: translate(-50%, -50%);
}


.highlight-view .review-card-placeholder::after {
    content: '';
}

.pulse {
    animation: pulse-animation 2s infinite;
}
@keyframes pulse-animation {
	0% {
		transform: scale(0.95);
		/* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); */
	}
	70% {
		transform: scale(1.2);
		/* box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); */
	}
	100% {
		transform: scale(0.95);
		/* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
	}
}

   /* padding: 0 4px 7px 0; */
   /* padding: 0 .52vw .5vh 0; */
.card-list-card-wrap {
    padding: 0 .52vw .5vh 0; 
    overflow: hidden;    
}

.ncard-title{    
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 5% 25% 5% 5% 60% ;
    align-content: center;
    width: 100%;
    height: 10px;
    min-height: 10px;
}

.date-ham-pair{    
    position: relative;
    display: flex;
    flex-direction: row;
    /* height: 1000px; */
    justify-content: space-between;   
    /* justify-content: space-around; */
    align-content: center;
    margin-left:auto;
    grid-column: 5;
    /* margin: 1em; */
}

.ncard-title .videoName{
    /* height: 13px;
    line-height: 11px; */
    line-height: 1em;
    font-size: 13px;
}


.card-severity {
    text-align: left;
    width: 40px;
    height: clamp(3px, 12%,7px);;
    border-radius: 3px;
    background-color: #d3d3d3; /* default background color if a severity is not set
                                    (should never happen)
                                  */
    margin-bottom: 4px;
    grid-column: 1;
}

.card-severity-HIGH {
    background-color: #fe7353;
}

.card-severity-MEDIUM {
    background-color: #ffbf35;
}

.card-severity-LOW {
    background-color: #fdde00;
}

.card-severity-IRRELEVANT {
    background-color: #d3d3d3;
}

.card-severity-GOOD {
    background-color: #61BD7D;
}

.review-card-list.no-scroll .ReactVirtualized__Grid__innerScrollContainer {
    overflow-y: scroll !important;
    max-width: 250px !important;
}
.review-card-list .ReactVirtualized__Grid__innerScrollContainer {
    max-width: 250px !important;
}



/*******************************************************************
Style the Video Card
********************************************************************/

.infraction-number:before {
    content: 'Infraction ';
}


.card-date-avatar .notecard-video{
    width: 400px;
    height: 285px;
    object-fit: fill;
} 

.phoneCall{
    /* max-height: 10vh; */
    height: 35%;
    /* width: 100%; */
    /* margin-top:5%; */
     margin-top:-12%; 
     margin-left:85%; 
    
}
.phoneCall .phone-controls{
    display: flex;
    flex-direction: row;
    justify-content: space-around;     
}
/* .phoneCall .phone-button{
    height: 3rem;
} */

.back-bottom{
    max-height: 51vh;
    overflow-y: auto;
}

.heartbeat-plot{
    height:55%;
    max-height: 7vh;
}
.heartbeat-card{
    font-size: 16px;
    /* width:50%; */
    margin-top:2%;
    margin-bottom: 3vh;
    height:40%;
    width: 60%;
    max-height: 10vh;
    
}

/* Format the top portion of the notecard - set up to hold two side by side regions */
.card-upper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.card-basics {    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


.dropdown-set{
    width:100%;
    /* border: 1px solid; */
    display: grid;
    grid-template-columns: 25% 75%; 
    grid-template-rows: auto;
}
.dropdown-set .infraction-dropdown-container{
    max-width:100%;
    /* display: grid;
    grid-template-columns: 25% 25% 50%; 
    grid-template-rows: auto; */
    /* display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center; */
}

/* Add the buttons for the footer: */
.notecard-footer .delete-button {
    margin-top: 8px;
}

.notecard-footer .irrelevant-button {
    margin-top: 8px;
    color: white;
    background: #B0B14D
}

.notecard-footer .cut-button {
    margin-top: 8px;
    color: white;
    /* background: #B0B14D */
    background-color: blueviolet;
}

.notecard-footer .gotohighlight-button {
    margin-top: 8px;
    color: white;
    background: #00AFED
}
.notecard-footer .gotohighlight-disable-button {
    margin-top: 8px;
    color: white;
    background: rgb(121, 122, 122)
}



.notecard-footer .download-button {
    margin-top: 8px;
    color: white;
    background: rgb(159, 212, 123)
}

.notecard-footer .downloaded-button {
    margin-top: 8px;
    color: white;
}

.notecard-footer .add-comment {
    margin-top: 8px;
}


.infraction-dropdown-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    
  }
   .infraction-dropdown-container .label-text {
    /* height: 13px;
    line-height: 11px; */
    font-size: 14px;
    padding-top: 2px;
    padding-right: 18px;
  } 
  
  .infraction-dropdown-container ul {
    
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-items: right !important;
    
}

/* Style the table filters in the AnalyticsView */
.infraction-display {
    /* margin: 5px;  */
    display: flex;
    flex-direction: row;
    padding: 5px;
    /* font-size: 15px; */
    border-radius: 5px;
    margin-top: 2px; 
    border: solid;
    border-width: thin;
    background-color: #fff;
    border-color: #3498DB;
    width: 190px;
    height: fit-content;
    
    /* color: #3498DB; */
}

.infraction-display div{
    /* margin: 5px;  */
    position: relative;
    /* font-size: 15px; */
    color:#3498DB;
    font-weight: bold;
    /* height:20px; */
    /* margin-top: 5px; */
}
.infraction-display .infractiontext{
    /* height:10px; */
    line-height: 1em;
    /* vertical-align: center; */
    margin-top: auto;
    margin-bottom: auto;
}

.infraction-display .remove{
    /* margin: 5px;  */
    position: relative;
    color: rgb(221, 48, 57);
    /* border-radius: 25px; */
    background: transparent;
    /* border-color: #fff; */
    border:none;
     /* float: right;  */
     /* margin-right: auto; */
    margin-left:auto; margin-right:0;
    /* background-color: rgb(221, 48, 57); */
    /* padding: 5px; */
    font-weight: bold;
}

.phone-button{
    background-image: linear-gradient(115deg, rgba(0,153,226,0.7), rgba(0,153,226,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .phone-button-disabled{
    background-color: rgba(175,175,175,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .phone-button:hover{
      background-image:unset;
      background-color:rgba(0,120,212,1);
  }

  .phoneicon{
    color:white;
    width: 30px; height: 30px;
    margin-top: 15%;
    margin-left:20%;
    /* margin:auto; */
    transform: rotate(20deg);
    
  }  
  

