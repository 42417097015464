
.control-set {
    overflow: hidden;
    width:7.5vw;
    text-align: left;
    .o-pad {
      display: inline-block;
    }
    z-index: 2;
  }
  .control-set.setbg { background: #222; }
  .control-set.setbg2 { background: #5f9837; }
  
  :root {
    --blue: #1e90ff;
    --white: #ffffff;
    --dpad-fg: #545657af;
    --dpad-fg-hover: #1e8fff6e;
    --arrowcolor: #aaa;
    --tri-lrg-a: .68vw;
    --tri-lrg-b: 1vw;
    --dpad-arrow-shift: 5px;
    --dpad-arrow-move: 35%;
  }
  
  
  .o-pad {
    transition: all .25s;

    position: relative;
    background: var(--dpad-fg);
    width: 7.5vw;
    height: 7.5vw;
    /* width: 10.45vw;
    height: 22vh; */
    border-radius:50%;
    overflow:hidden;
    /* Shrink the initial circle down to a center section  */
    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      width: 20%;
      height: 20%;
      top: 50%;
      left: 50%;
      background: #ddd;
      border-radius: 50%;   
      transform: translate(-50%, -50%);
      display: none;
      transition: all .25s;
      cursor: pointer;
    }
    /* Enlarge the o-pad if hovering over */
    &:hover:after {
      width: 30%;
      height: 30%;
    }
    a {
      display:block;
      position: absolute;
      -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
      width: 50%;
      height: 50%;
      text-align: center;  
      transform: rotate(45deg);
      border: 1px solid rgba(0,0,0,.2);
      &:before {
        content: '';
        position: absolute;
        width: 60%;
        height: 60%;
        top: 50%;
        left: 50%;
        background: rgba(255,255,255,0.1);
        border-radius: 50%;   
        transform: translate(-50%, -50%);
        transition: all .25s;
        cursor: pointer;
        display: none;
      }
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-radius: 5px;
        border-style: solid;    
        transform: translate(-50%, -50%) rotate(-45deg);
        transition: all .25s;
        
      }
    }
    a.up {
      bottom: 50%;
      left: 50%;
      transform: translate(-50%, -20%) rotate(45deg);
      border-top-left-radius: 50%;
      z-index:1;
      
      &:hover {
        /* background: linear-gradient(315deg, rgba(255,255,255,0) 15%, rgba(255,255,255,.4) 100%); */
        background: linear-gradient(315deg, var(--dpad-fg) 15%, var(--dpad-fg-hover) 100%);
      }
      &:before {
        left: 57%;
        top: 57%;
      }
      &:after {
        left: 53%;
        top: 53%;
        border-width: 0 var(--tri-lrg-a)  var(--tri-lrg-b)  var(--tri-lrg-a) ;
        /* border-color: transparent transparent var(--arrowcolor) transparent; */
        border-color: transparent transparent transparent transparent;

      }
      &:active:after {
        border-bottom-color: #333;
      }
    }
    
    a.down {
      top: 50%;
      left: 50%;    
      transform: translate(-50%, 20%) rotate(45deg);
      border-bottom-right-radius: 50%;
      z-index:1;
      &:hover {
        /* background: linear-gradient(135deg, rgba(255,255,255,0) 15%, rgba(255,255,255,.4) 100%); */
        background: linear-gradient(135deg, var(--dpad-fg) 15%, var(--dpad-fg-hover) 100%);
      }
      &:before {
        left: 43%;
        top: 43%;
      }
      &:after {
        left: 47%;
        top: 47%;
        border-width: var(--tri-lrg-b)  var(--tri-lrg-a)  0px var(--tri-lrg-a) ;
        border-color: var(--arrowcolor) transparent transparent transparent;
      }
      &:active:after {
        border-top-color: #333;
      }
    } 
    
    a.left {
      top: 50%;
      right: 50%;    
      transform: translate(-20%, -50%) rotate(45deg);
      border-bottom-left-radius: 50%;
      border: none;
      &:hover {
        /* background: linear-gradient(225deg, rgba(255,255,255,0) 15%, rgba(255,255,255,.4) 100%); */
        background: linear-gradient(225deg, var(--dpad-fg) 15%, var(--dpad-fg-hover) 100%);
      }
      &:before {
        left: 57%;
        top: 43%;
      }
      &:after {
        left: 53%;
        top: 47%;
        border-width: var(--tri-lrg-a)  var(--tri-lrg-b)  var(--tri-lrg-a)  0;
        border-color: transparent var(--arrowcolor) transparent transparent;
      }
      &:active:after {
        border-right-color: #333;
      }
    }  
    
    a.right {
      top: 50%;
      left: 50%;    
      transform: translate(20%, -50%) rotate(45deg);  
      border-top-right-radius: 50%;
      border: none;
      &:hover {
        /* background: linear-gradient(45deg, rgba(255,255,255,0) 15%, rgba(255,255,255,.4) 100%); */
        background: linear-gradient(45deg, var(--dpad-fg) 15%, var(--dpad-fg-hover) 100%);
      }
      &:before {
        left: 43%;
        top: 57%;
      }
      &:after {
        left: 47%;
        top: 53%;
        border-width: var(--tri-lrg-a)  0 var(--tri-lrg-a)  var(--tri-lrg-b) ;
        border-color: transparent transparent transparent var(--arrowcolor);
      }    
      &:active:after {
        border-left-color: #333;
      }
    } 
    /* a:hover:after { left: 50%; top: 50%; }   */
    a:active:after { 
        left: 50%; top: 50%;
        /* transition: transform 2s ease-in-out; */
     }
     a:active { 
        transition: transform 2s ease-in-out;
     }  
     /* if the button is disabled, then don't pass the hover/click to the link */
     a[disabled]{
      pointer-events: none;
      opacity: 0.3;      
     }
  }
  
  
  .control-set {
    --c: #767777f6;
    --c-h: #143cb9;
    --c-t: #ffffff; /*arrow color*/
    --c-t-a: #1e90ff; /*active arrow color*/
    .o-pad {
      background: var(--c); 
      a {   
            /* border-color: rgba(255,255,255,.5);  */
            border-color: transparent;
        }
      &:before, a { background: var(--c);  }
      &:after { display: block; background: #ccc; }     
      a.up:after { border-bottom-color: var(--c-t); transition: all 0.35s ease-in-out;}
      a.right:after { border-left-color: var(--c-t); transition: all 0.35s ease-in-out;}    
      a.down:after { border-top-color: var(--c-t); transition: all 0.35s ease-in-out;}
      a.left:after { border-right-color: var(--c-t); transition: all 0.35s ease-in-out;}
      a.up:active:after { border-bottom-color: var(--c-t-a); transition: transform 1s ease-in-out;}
      a.right:active:after { border-left-color: var(--c-t-a); transition: transform 1s ease-in-out;}    
      a.down:active:after { border-top-color: var(--c-t-a); transition: transform 1s ease-in-out;}
      a.left:active:after { border-right-color: var(--c-t-a);transition: transform 1s ease-in-out;}
      
      /* Add styling to clicking on the center o-pad button */
      /* Check if any of the other children of the o-pad are active*/
      /* If active then reset the color to prevent false animation */
      &:has(a:active):after {
        background: #ccc;
        transition: all 0.35s ease-in-out;
      }
      /* if there are no active children, and the button has been clicked */
      /* Then cahnge the color and size of the center button */
      &:not(:has(a:active)):active:after{
        background-color: var(--c-t-a); 
        width: 20%; height: 20%;
        transition: transform 1s ease-in-out;
      }
    }
  }
  





  