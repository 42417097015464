.drivercard-modal {
    margin-top: 120px;
    height: auto ;
    width: 60vw;
    height: 60vh;
    max-width: 80vw; 
    user-select: none;
}

.drivercard-modal .drivercard-modal-content {
    transform-origin: top right;
    width: 100%;
    height:100%;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

}
.drivercard-modal-content .dc-title{
    margin-left:auto;
    margin-right:auto;
    font-size: 26px;
    text-align: center;
}

.drivercard-modal-content .dc-top{
    margin-top: 1em;
    margin-bottom: 1em;
    height:60%;
    display: flex;
}
.drivercard-modal-content .dc-top .dc-tl{
    width: 65%;
}
.drivercard-modal-content .dc-top .dc-tr{
    width: 35%;
}

.drivercard-modal-content .driverphoto{
    /* width:auto; */
    /* height:80%; */
    height:auto; 
    width:45%;

}

.drivercard-modal-content .hamburger{
    width: 2%;
    height: auto;
    position: absolute;
    top: 6px;
    right: 8px;
}

.drivercard-modal .add-action {
    width: 100%;
}


.drivercard-modal .dc-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
}
.drivercard-modal .dc-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    pointer-events: all;
    /* margin-right: 5px; */
    /* height: 25px; */
} 
.drivercard-modal .dc-footer .blank-spacer {
    width: 30%;
    border: none;
  }

 .drivercard-modal .dc-footer .delete-region {
    margin-top: 8px;
    width:15%;
}
.drivercard-modal  .add-comment {
    /* margin-top: 2em; */
    width:fit-content;
}
.drivercard-modal .dc-footer .close-region {
    margin-top: 8px;
    width:15%;
}
/* .drivercard-modal .dc-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */


.drivercard-modal .settings-grid {
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    grid-template-rows: auto auto auto auto;
    gap: 5px 0px;
    margin-top:5%;
    margin-left:5%;
  }


.drivercard-modal .settings-grid .entry-field{
    height: 2em;
    width:95%;
}
.drivercard-modal .settings-grid .title-type{
    font-size: 14px;
    grid-column: 1/3;
    grid-row:auto;
    margin-top: auto;
}
.drivercard-modal .settings-grid .title-units{
    font-size: 14px;
    grid-row:auto;
    margin-top: auto;
    text-align: left;
}

.drivercard-modal .settings-grid .title{
    font-size: 14px;
    grid-row:auto;
}


.drivercard-modal .empty-bottom{
    height:7em;
}


.drivercard-modal .soc-spec-tall{
    display: grid;
    grid-template-columns: 50px 100px 125px 100px 100px 125px 100px;
    align-items: baseline;
    margin-top:5px;
}
.drivercard-modal .soc-spec-tall .title-wide{
    font-size: 14px;
    grid-column: span 2;
    grid-row:auto;
}
.drivercard-modal .soc-spec-tall .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.drivercard-modal-content .soc-spec-tall .entry-field{
    height:5.5em;
    width:95%
}
.drivercard-modal-content .soc-spec-tall .entry-field-wide{
    height:5.5em;
    grid-column: span 2;
}

.drivercard-modal-content .soc-spec{
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    gap: 5px 0px;
    margin-left:5%;
    margin-bottom:1%;
}
.drivercard-modal-content .soc-spec .title-wide{
    font-size: 14px;
    grid-column: 1/3;
    grid-row:auto;
    text-align: left;
}
.drivercard-modal-content .soc-spec .entry-field{
    height:2em;
    width:95%
}
.drivercard-modal-content .soc-spec .entry-field-wide{
    height:2em;    
    grid-column: span 3;
}

.drivercard-modal-content .soc-lister-row table.video-table {
    table-layout: auto !important; 
 }
.drivercard-modal-content .soc-lister tr.soc-name {
   overflow: hidden;
   text-overflow: ellipsis;
   text-align:left;
 }
.drivercard-modal-content .soc-lister tr.soc-group {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
.drivercard-modal-content .soc-lister tr.soc-remove {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}

/* Comments */
.drivercard-modal-content .commenter {
    width: 81%;
    margin-left: auto;
    margin-right:auto;
    margin-top:-1em;
}
.drivercard-modal-content .commenter .adding-buttons{
    height:4vh;
}
.drivercard-modal-content .commenter .adding-buttons .btn{
    height: 100%;
    font-size: 0.9em;
}


.drivercard-modal-content .comment {
    background: #f4f5f7;
    border-radius: 4px;
    margin: 8px 0;
    padding-left: 8px;
    padding-top: 8px;
    width: 96%; 
    text-align: left;
}

.drivercard-modal-content .comment pre {
    margin-bottom: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
    font-family: 'Open Sans';
}
.drivercard-modal-content .comment-timestamp pre {
    color: #555;
}



.drivercard-modal-content .comments {
    overflow-y: auto;
    max-height: 50%;
    margin-bottom: 5px;    
    margin-left:5%;
}
.drivercard-modal-content .comments-title {
    margin-top: 8px;
    text-align: left;
    margin-left:5%;
}





.drivercard-modal-content .comment-timestamp {
    font-size: 0.8em;
    margin-top: -0.5em;
    left : 2%;
    display:inline-block;
}

.drivercard-modal-content .comment-username {
    position: relative;
    font-size: 0.8em;
    margin-top: -0.5em;
    display:inline-block;
}

