.geofence-card-modal {
    height: auto;
    margin-right: 100px;
    margin-top: 120px;
    width: 750px;
    max-width: 1000px;
}

.geofence-card-modal .geofence-modal {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    min-height: 500px;
    text-align: center;
    /* border: none; */

    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    /* border: 1px solid #999; */
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
}
.gf-title{
    margin-left:auto;
    margin-right:auto;
}
.gf-title .title2{
    font-size: 26px;
    text-align: center;
}
.gf-add{
    width:5%;
}

.geofence-modal .add-action {
    width: 100%;
}

.geofence-modal .comment {
    background: #f4f5f7;
    border-radius: 4px;
    margin: 8px 0;
    padding-left: 8px;
    padding-top: 8px;
    width: 96%; 
}

.geofence-modal .comments {
    overflow-y: auto;
    max-height: 250px;
    margin-bottom: 5px;    
}
.rw-multiselect-taglist {
    max-height: 75px;
    overflow-y: auto;
}

.geofence-modal .gf-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.geofence-modal .gf-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    /* margin-right: 5px; */
    /* height: 25px; */
} 
.geofence-modal .gf-footer .blank-spacer {
    width: 30%;
    border: none;
  }

.gf-footer .delete-region {
    margin-top: 8px;
    width:15%;
}
.gf-footer .add-comment {
    margin-top: 8px;
    width:fit-content;
}
.gf-footer .close-region {
    margin-top: 8px;
    width:15%;
}
.gf-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.geofence-modal .monitored-action{
    width:100%;
    position: relative;
}
.geofence-modal .monitored-action .action-element{
    width:100%;
}
/* .monitored-action .action-element .delete-region{
    position:absolute;
} */
.action-list{
    width:100%;   
    max-height: 400px;
    overflow-y: auto;
}

.monitored-action .delete-action{
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 25px;
    font-size:10px;
    margin: auto;    
    padding:0;
}
.monitored-action .edit-action{
    position: absolute;
    top: 28px;
    right: 0;
    width: 50px;
    height: 25px;
    font-size:10px;
    margin: auto;    
    padding:0;
}




.action-line{
    display: flex;
    flex-direction: row;
    height: fit-content;
}
.action-line >div{
    padding: 0px 5px;
}
.action-line .contact-list{
    word-wrap: break-word;
    height: auto;
    width: 100%;
    text-align: left;
}

.type-group .radio{
    display:flex;
    flex-direction: row;
    grid-column: 2;
    height: 2em;
}
.type-group .title2{
    display:flex;
    font-size: 14px;
    margin-left:2%;
    grid-column: 1;
}



.type-group {
    display: grid;
    grid-template-columns: 50px 100px 100px 100px 50px 300px;
    grid-template-rows: auto auto auto auto;
    gap: 5px 0px;
  }

.type-group .dwell{
    grid-column: 2 / 3;
 
    display: grid;
    grid-template-columns: 100px 100px 100px 50px 300px;
    align-items: center;
}
.type-group .dwell .radio{
    display:flex;
    flex-direction: row;
    grid-column: 1;
}

.dwell .entry-field{
    height: 2em;
    width:95%;
}
.dwell .title-units{
    font-size: 14px;
    grid-column: 4; 
    grid-row:auto;
}
.dwell .title-comments{
    font-size: 14px;
    grid-column: 5; 
    grid-row:auto;
    color: #D8000C;
}

.type-group .dwelldisable{
    grid-column: 2 / 3;
    color:gray;
    display: grid;
    grid-template-columns: 100px 100px 100px;
    align-items: center;
}
.type-group .dwelldisable .radio{
    display:flex;
    flex-direction: row;
    grid-column: 1;
    color:gray;
}

.dwelldisable .entry-field{
    height: 2em;
    width:95%;
    color:gray;
}


.spec-tall{
    display: grid;
    grid-template-columns: 50px 100px 125px 100px 100px 125px 100px;
    align-items: baseline;
    margin-top:5px;
}
.spec-tall .title-wide{
    font-size: 14px;
    grid-column: span 2;
    grid-row:auto;
}
.spec-tall .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.spec-tall .entry-field{
    height:5.5em;
    width:95%
}
.spec-tall .entry-field-wide{
    height:5.5em;
    grid-column: span 2;
}

.spec-2r{
    display: grid;
    grid-template-columns: 50px 100px 125px 100px 100px 125px 100px;
    grid-template-rows: auto auto auto auto auto auto auto;
    align-items: baseline;
    margin-top:5px;
}
.spec-2r .title-wide{
    font-size: 14px;
    grid-column: span 2;
    grid-row:auto;
}
.spec-2r .title{
    font-size: 14px;
    grid-column: 2;
    grid-row:auto;
}
.spec-2r .entry-field{
    height:2em;
    width:95%
}
.spec-2r .entry-field-wide{
    height:2em;
    grid-column: span 2;
}
.spec-2r .entry-field-wide2{
    height:2em;
    grid-row:2;
    grid-column: 3/6;
}

.spec{
    display: grid;
    grid-template-columns: 50px 100px 125px 100px 100px 125px 100px;
    align-items: center;
    margin-top:5px;
}
.spec .title-wide{
    font-size: 14px;
    grid-column: span 2;
    grid-row:auto;
}
.spec .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.spec .entry-field{
    height:2em;
    width:95%
}
.spec .entry-field-wide{
    height:2em;
    
    grid-column: span 2;
}

.empty-bottom{
    height:7em;
}

input:invalid {
    background-color: #ffdddd;
  }

.asset-spec{
    display: flex;
    flex-direction: row;
    margin-left:2%;
}
.asset-spec .title{
    font-size: 14px;
}
.asset-spec .entry-field{
    height:2em;
    margin-left:10px;
}

.action-spec{
    display: flex;
    flex-direction: row;
    margin-left:2%;
}
.action-spec .radio{
    margin-left:10px;
}
.action-spec .title{
    font-size: 14px;
}

.contact-spec{
    display: flex;
    flex-direction: row;
    margin-left:2%;
}
.contact-spec .title{
    font-size: 14px;
}
.contact-spec .entry-field{
    height:2em;
    margin-left:10px;
}

.commenter .adding-comment textarea {
    width: 100%;
    resize: none;
}


.dwell-config{
    height: 2em;
    width:28%;
    
    display: flex;
    flex-direction: row;    
    justify-content: space-evenly;
}
.dwell-config .title{
    width:110px;
    font-size: 14px;
}
.dwell-config .entry-field{
    width:75px;
}