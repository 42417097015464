
.drivertile{
  position: relative;
  width: 9vw;    
  height: 19vh;  
  display: grid;
  grid-template-rows: 14% 60% 13% 13%;
  border: 1px solid rgb(0,174,239);
  /*Make the hover smoother*/ 
  transition-duration:250ms; 
  background-color: white;

  font-size: clamp(12px,2.25vh,21px); 

}

.drivertile-loading{
  width: 9vw;    
  height: 19vh;  
  display: grid;
  grid-template-rows: 14% 60% 13% 13%;
  border: 1px solid rgb(0,174,239);
  /*Make the hover smoother*/ 
  transition-duration:250ms; 
  background-color: rgba(0,174,239,.1);
}


.drivertile:hover {
  transform:scale(1.15); 
}
.drivertile:active {
  transform:scale(.98);  
}


/* Set the border bottom on each element of the tile to give it a table appearance */
.drivertile .dtrow{ 
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 1;  
}
.drivertile-loading .dtrow{ 
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 1;  
}

.drivertile .dtphoto{ border-bottom: 1px solid white;}
/* Set the background color on each of the tile rows */
.drivertile .dttitle{ background-color: rgb(0,174,239); color:white;}
.drivertile-loading .dttitle{ background-color: rgb(0,174,239); color:white;}
.drivertile .dtphoto{ background-color: rgba(0,174,239,.2);}
.drivertile .dtcount{ background-color: rgba(0,174,239,.1);}
.drivertile .dtpercent{ background-color: rgba(0,174,239,.2);}

/* .drivertile .dtphoto{ border-bottom: 1px solid rgb(0,174,239);} */

/* Auto size based on the size of the tile */
.drivertile .driverphoto{
  width: auto;    
  height:95%;
  margin:auto;
  max-width: 98%;  
}

