/*******************************************************************
Style Card and CardList
********************************************************************/
@import "VideoCard-reviewcard.css";
@import 'VideoCard.css';


/*******************************************************************
Style the Video Card
********************************************************************/


.expanded-card-modal {
    height: auto;
    /* margin-right: 100px; */
    margin-right: 7%; 
    margin-top: 120px;
    
    min-width: 750px;
    max-width: 1000px; 
}

.expanded-card-modal .modal-content {
    border-radius: 4px;
    transform-origin: top right;
    /* width:850px; */
    /* height: 57vh; */
}

/* Override previous passed in with the Style from ExpandedCard render() if hwTags were to be shown */
.expanded-card-modal .modal-content[with_hw="display"]{
    width: 850px;
}

.expanded-card .gpsmap-empty {
    width : 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;    
}

.expanded-card .gpsmap {
    width : 100%;
    height: 50vh;
}

.expanded-card .gpsmap-spacer {
    margin-top: 10%;
}
.expanded-card .gpsmap-text {
    text-align: center;
    font-weight: 400;
    font-size: 1.5em;
}
.expanded-card .gpsmap-text2 {
    text-align: center;
    font-weight: 300;
    font-size: 1.1em;
}
.expanded-card .gpsmap-text3 {
    text-align: center;
    font-weight: 400;
    font-size: 2em;
}

.expanded-card .gpsmap .gpsrefresh {
     position: absolute;
    top: 50%;
    left: 40%; 
    width: 20%;
    height: 10%;
    font-size: 1.5em;
    border-radius: 15px;
    background-color: #AFD47B;
}


.expanded-card select {
    padding: 0;
    height: 27px !important;
}


.expanded-card .card-upper {
    margin-top: -.5em;
}
/* Balance the information section against the video clip on the expanded card*/
.expanded-card .card-basics {
    max-width: 50%;
}
.expanded-card .card-basics .card-basic-top{
    /* background-color: green; */
    height:40%;
}
.expanded-card .card-basics .card-basic-bottom {
    /* order: 1; */
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start; */
    width:100%;
    display: grid;
    grid-template-columns: 200px 200px; 
    grid-template-rows: auto;
    column-gap: 1%;
    /* border: 1px solid; */
}
.expanded-card .card-basics .card-basic-bottom .form-control {
    /* max-width: 75%; */
    width:140px;
    /* margin-right:auto; */
}
.dropdown-set{
    width:100%;
    /* border: 1px solid; */
    display: grid;
    grid-template-columns: 25% 75%; 
    grid-template-rows: auto;
}


.expanded-card .card-reviewer {
    /* order: 1; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 250px;
}

.expanded-card .card-date-avatar {
    position: relative;
    /* order: 2; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    /* max-width: 50%; */
}

.expanded-card .avatar {
    border-radius: 3px;
}


.expanded-card .expanded-card-player > div {
    width: auto !important;
    height: auto !important ;
    margin: 1em;
  }
.expanded-card-player ::-webkit-media-controls-overlay-play-button {
    opacity: 0.4;
}

.expanded-card-player ::-webkit-media-controls-panel {
    background: none;
} 


.expanded-card .hamburger-back {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 15px;
    margin-right: 15px;
    height: 11px;
    width: 11px;
}

.expanded-card .notecard-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-right: 5px;
}

.expanded-card .notecard-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.expanded-card .ncard-title .date-ham-pair .date {
    padding-right: 5px;
    padding-top: 0;
    line-height: 1em;
    height: 1em;
    margin-top: -1px; 
    font-size: 13px;
}

.expanded-card .ncard-title .date-ham-pair .hamburger {
    width: 11px;
    height: 11px;
}


/* ===========ENABLE HOVER EFFECTS================*/
.expanded-card .hamburger:hover{
    transform:scale(1.25,1.25); 
}
.expanded-card .hamburger-back:hover{
    transform:scale(1.25,1.25); 
}

.expanded-card  .btn:hover:enabled{
    transform:scale(1.05,1.05); 
}
.expanded-card .btn:disabled{
    opacity: .3;
}

.expanded-card .gotohighlight-button:disabled {
    margin-top: 8px;
    color: white;
    background: rgb(121, 122, 122)
}
.expanded-card .btn-danger[disabled]:hover{
    background-color: #dc3545;
    border-color: #dc3545;
    opacity: .3;
}
.expanded-card .btn[disabled]:hover{
    transform:scale(1,1);
}
/* 
===========END HOVER EFFECTS================
*/

/* Name styling */
.expanded-name{
    display: flex;
    flex-direction: row;
}
.expanded-name .name-text{
    display:flex;
    flex-direction: row;
    
    align-items: center;
}
.expanded-name .name-text .name-button{
    border-radius: 10%;
    border: 1px solid black;
    outline:none;
    height:1.5em;
    margin-left: 5%;
    line-height: 0px;
    background-color: #ed7669;
}

.expanded-name .name-text .name-button:hover{
    transform:scale(1.05,1.05);
    -webkit-transform:scale(1.05,1.05);
    -moz-transform:scale(1.05,1.05);
} 
.expanded-name .name-text .name-button:active{
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}