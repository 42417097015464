/*=====================================================================
  Style the VideoClipPlayer
=====================================================================*/

  
  .video-reviewer-player .react-player{
    /* width: auto; */
    width: auto !important;
    height: auto !important ;
  }
  .video-reviewer-player .react-playerLR{
    /* width: auto; */
    width: 765px !important;
    height: 510px !important ;  
    /* height: 640px !important ;   */
    /* max-height: 510px !important; */
    margin-left: auto;
    margin-right: auto;
  }

  .video-reviewer-player .fixed-size{
    object-fit: fill;
    width: 49.6vw;
    height: 28vw;
    margin-left:auto;
    margin-right:auto;
  }

  /*Previously loaded in VideoShaka, removed so that the unused UI elements aren't added */
  .video-reviewer-player .VideoShaka{
    /* object-fit: fill; */
    width:49.6vw;
    height:28vw;
    margin-left:auto;
    margin-right:auto;
  }

/* Handle the attributes for the video player loaded in ShakaPlayer, this affects the Original videos*/
.video-reviewer-player .VideoShaka .shakavideo{ 
  max-width: 49.6vw; /*Set a max width to constrain the initial width of the loading video*/
  max-height: 28vw; /*Set a max height to constrain the initial height of the loading video*/
  /* scale the video using the scales passed from the javascript*/
  /* Scale(x,y) is used to scale the video's width and height*/
  /*The var() loads the custom variable, if the variable isn't found it will fallback to the default scale of 1*/
  transform: scale(var(--vidScaleX,1),var(--vidScaleY,1)); 
}


  .video-reviewer-player .offsetLR .overlay-button{
    position: absolute; 
    top: 10px;
    right: 15%;
    width: 76px;
    height: 76px;
    border-radius: 38px;
    border: none;
    opacity: 0.5;
    user-select:none;    
    z-index:5;
  }
  
  .hidden-video {
    display: none;
  }
  
  
  
  .video-reviewer-player {
    position: relative;
  }
  
  .video-reviewer-player .overlay-button {
    /* position: absolute; */
    position: absolute; 
    top: 10px;
    right: 10%;
    width: 76px;
    height: 76px;
    border-radius: 38px;
    border: none;
    opacity: 0.5;
    user-select:none;
    z-index:5;
  }
  

  .video-reviewer-player .vcp-reviewtext {
    /* position: absolute;  */
    top: 10px;
    right: 10%;    
    user-select:none;
    color:red;
  }
  .video-reviewer-player .overlaybutton-text {
    user-select:none;
    line-height: 1.2;
  }
  .video-reviewer-player .overlay-reviewer {
    color:white;
    font-size: 0.68vw;
  }
  
  
  .video-reviewer-player .overlay-button:focus {
    outline: none;
  }
  
  .video-reviewer-player .overlay-button:hover {
    opacity: 0.6;
  }
  
  .video-reviewer-player .overlay-button:active {
    background: #33cffd;
  }
  .video-reviewer-player .overlay-button[data-card] {
    background: rgb(141, 198, 63);
  }
  .video-reviewer-player .overlay-button[reviewed-clip] {
    opacity: 0.6;
    background: #E60024;
  }
  .video-reviewer-player .overlay-button:disabled {
    opacity: 0.6;
    background: #E60024;
  }
  
  
   .video-reviewer-players .video-filename {
    /* margin-bottom: 0.5em; */
     /* margin-top: -1.0em;  */
  }

  .video-reviewer-players .dvrdeleted{
    margin-top: 5em;
    padding-left: 10%;
    padding-right: 10%;
  }
 
  
  
  .video-filename {
    margin-bottom: 0.5em;
    margin-top: -1.0em; 
     /* margin-top: -5.0em;  */
  }
  

  
  .playbuttonsVR{    
    display: flex;
    flex-direction: row;
    width: 100%; 
    height: 10%;
    /* height: 1000px; */
    /* justify-content: space-between;    */
    /* justify-content: space-evenly; */
    justify-content: space-evenly;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    /* margin: 1em; */
    user-select:none;
    
  }


  
  /** Defaut interaction for a child element of the playbuttonsVR container */
  .video-reviewer-players .playbuttonsVR > *:disabled{
    opacity:0.5;
  }
  .video-reviewer-players .playbuttonsVR > *:hover:enabled{
    transform:scale(1.05,1.05);
    -webkit-transform:scale(1.05,1.05);
    -moz-transform:scale(1.05,1.05);
  }
  .video-reviewer-players .playbuttonsVR > *:active:enabled{
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
  }

  /** Default styling of the elements in the playbuttonsVR container*/
  .video-reviewer-players .playbuttonsVR > *{
    border: none;
    height: 75px;
    width: 75px;
  }
  .video-reviewer-players .playbuttonsVR .skipPrev-button {
    width: 125px;   
  }
  .video-reviewer-players .playbuttonsVR .skipNext-button {
    width: 125px;
  }
  .video-reviewer-players .playbuttonsVR .roi-button {
    width: 100px;
    height: 85px;
  }
  .video-reviewer-players .playbuttonsVR .blank-spacer {
    width: 150px;
  }
  

  .playerblink-canvas{
    background-color: white;
    position: absolute;
    z-index:4;
  }
  .onVideoCanvas{
    background-color: white;
    position: absolute;
    z-index:4;
    margin-left: auto;
    margin-right: auto;
  }
  