

.shiftsummary{
  position:relative;
  height:100%;
  width: 100%;
}

.shiftplots{
  position:relative;
  display: grid;                  /* Layout of the parent view is a grid */
  grid-template-rows: 95% 5%;    /* 10% of screen to toprow, 90% to remaining content */
  grid-template-columns: 100%;    /* Only one column */
  user-select: none;              /* Not selectable */

  height:90%;                    
}

.shiftplots .plots{
  height:100%;
  overflow: auto;  
}

.shiftplots .plots{
  border: 1px black solid;
}


.shiftplots .controls{
  display:flex;
  flex-direction: rows;
  align-items: center;
  /* justify-content: center; */
  justify-content: space-between;
}
.shiftplots .controls > div{
  min-width: 30vw;
  /* background-color: red; */
}
.shiftplots .controls > div:nth-child(1){
  text-align: left;
}


.shiftplots .controls .btn:hover{
    transform:scale(1.1,1.1); 
}
.shiftplots .controls .btn[disabled]:hover{
  transform:scale(1,1);
}
.shiftplots .controls .btn:active{
  transform:scale(.9,.9);
  -webkit-transform:scale(.9,.9);
  -moz-transform:scale(.9,.9);
}

.shiftplots .pageCount{
  padding-left: 2vw;
  padding-right: 2vw;
}

