
.storylinestab{
  width: 98%;
  height:100%;
  margin-left:1%;
}

.storylines-content {
  width: 100%;
  height:100%;
  
  text-align: left;
  position: relative;

  display: grid;
  grid-template-rows: 10% 85%; 
  z-index: 1;
}


.storylines-content .storyTop{
  display: grid;
  grid-template-rows: repeat(4);
}

.storylines-content .storyBottom{
  width:100%;
  
}

.storyBottom .title{
  text-align: left;
}

.storyBottom .header{
  /* width:90%;   */
  width:100%;
  margin-left:auto;
  margin-right:auto;

  display: grid;
  grid-template-columns: 9.8% auto;
  border: 1px solid black;
  text-align: center;
  
  background-color: #00AFED77;
  
  visibility: hidden;
  
}
.storyBottom .header >div{
  border-right: 1px solid black;
}
.storyBottom .bottomcontent{
  width: 58.8vw;
  height: 90%;
  border: 1px solid black;
  z-index: 1;
}

.storyBottom .nodata{
  height: 100%; 
  text-align: center;
  padding-top: 15%;
  font-style: italic;
  font-size: 125%;
  
}
.storyBottom .storytable{
  height: 100%; 
  max-height: 100%; 
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
