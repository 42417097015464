
.reset-all{
    position: absolute;
    max-height: 50px;
    float:left;
    background-color: #81d4fa; 
    border-color: #81d4fa;
    border-radius: 10px;
    top: 18%;
    left: 5%;
}

.monitored-sets {   
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    height: 63vh;
}
.monitored-set {    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* align-items: center; */
    /* justify-content: center;        */
    justify-content: space-between;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    /* height: 200px; */
    
    /* margin-top:-4em; */
}
/* .monitored-set>* {
    flex: 0 0 13%;
}     */


.monitored-asset {    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;      
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    /* margin: 1%; */
    /* max-width: 20%; */
    width:125px;
    height: 200px;
    align-items: center;
    
}

.status-circle-green {
    background-color: white; 
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: 5px solid green;
  }
  .status-circle-yellow {
    background-color: white; 
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: 5px solid yellow;
  }
  .status-circle-red {
    background-color: white; 
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: 5px solid red;
  }

  /* monitored-radio */
.monitored-asset .monitored-radio{
    width: 30px;
    height: 30px;
}


.monitored-asset .countdown{
    /* height: 30px; */
    margin-top:1em;
    display: block;
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    display: table-cell;
    align-items: center; 
    text-align: center;
    outline:none;
    background-color: #81d4fa;  
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-color: #81d4fa;
    font-size:14px;
}
.monitored-asset .countdown.flash-background{
    animation: flash-background 2s infinite ease-in-out;
    animation-delay: 0.75s;
}

@-webkit-keyframes flash-background {
    40%{background-color: #81d4fa;}
    0%,60%,100%{background-color: #ffffff;}
  }
  @keyframes flash-background {
    40%{background-color: #81d4fa;}
    0%,60%,100%{background-color: #ffffff;}
  }


.monitored-asset .asset-name{
    margin-bottom: 1px;
}



.reset-all:focus {
    outline: none;
}

.monitored-asset .stoplight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;   
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 20%;
    align-items: center;
}

.monitored-asset .stoplight-background{
    width: 35px;
    height: 90px;
    border: 1px solid #000;
    position: absolute;
    background-color: #77777780;
    border-radius: 5%;
    z-index: 0;
}

.monitored-asset .stoplight > button{
    width: 20px;
    height: 20px;
    border-radius: 50%;    
    outline:none;
    margin: 4px 2px;
    background-color:#77777780;     
    z-index:1;
    text-align: center;
    font-size:16px;
}
  