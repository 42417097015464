/*=============================
  Layout of the help image popup for the groups content
================================*/
.groups-help-popup-modal {
    margin-top: 120px;
    height: auto ;
    width: 50vw;
    max-width: 50vw; 
    user-select: none;
}

.groups-help-popup-modal .groups-help-popup-content {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

}
.groups-help-popup-content .titleTop {
    font-size: 26px;
    margin-left:auto;
    margin-right:auto;
}

.groups-help-popup-content .section {
    width: 100%;    
    display: grid;
    grid-template-columns: 5% 80% 5% ;
    margin-left: 2%;
    line-height: 1.45em;
}
.groups-help-popup-content .section .sectionTitle {
    text-align: left;
    grid-column:span 2;
    font-weight: bold;
}
.groups-help-popup-content .section .sectionContent {
    text-align: left;
    grid-column:2;
}


.journey-count {
    padding: 7px 10px 7px 10px;
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-style: italic;
    width: 9%;
}