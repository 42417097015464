.tagconfirm-card-modal {
    /* height: auto; */
    margin-right: auto;
    margin-top: 15vh;
    /* width: 850px;
    height:455px;
    max-width: 850px;      */
    user-select: none;

    width: 28vw;
    height:21vh;
    max-width: 1000px;      
}
.tagconfirm-card-modal-second {
    margin-right: auto;
    margin-top: 15vh;
    user-select: none;

    width: 50vw;
    height: 37vh;
    max-width: 1000px;      
}

.tagconfirm-modal {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    /* border: 1px solid #999; */
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

    height:100%
}

.tagconfirm-modal .title{
  text-align: center;
  display: block;
  max-height: 100%;
  max-width: 100%;
  font-size:175%;
  height: 16%;
}

.tagconfirm-modal .topDisplay{
    display: grid;
    grid-template-rows: 70% 30%;
    height:60%;
    font-size: 1.5em;
}

.tagconfirm-modal .topDisplay .iconset{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    min-height: 10px;
    gap:3%
}

.tagconfirm-modal .topDisplay .icon > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.tagconfirm-modal .topDisplay .icon{
    position: relative;
    width: 15%;
    height: 90%;
    margin-top: auto;
    margin-bottom: auto;
    
}
/* RE-use the already defined blinkanim (LiveTab), add a tag to set the time loop to 1 second */
.blink2s{
    animation: blinkanim normal 2s infinite ease-in-out;
}

.tagconfirm-modal .topDisplay .tagtype{
    font-size: clamp(.5em,3.5vh,1.5em);
    margin-left:auto;
    margin-right:auto;
    
}
/* Adapt the font to the larger card */
.tagconfirm-card-modal-second .tagconfirm-modal .topDisplay .tagtype{
    font-size: clamp(1em,6vh,2.2em);
}


.tagconfirm-modal .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height:24%;
}

.tagconfirm-modal .footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    width: 18%;
} 
/* Adapt the font to the larger card */
.tagconfirm-card-modal-second .tagconfirm-modal .footer .btn{
    font-size: clamp(0.9em,3.5vh,1.8em);
}



.tagconfirm-modal .footer .blank-spacer {
    width: 30%;
    border: none;
  }

/* .add-comment:hover{
    transform:scale(105%,105%);
} */


.tagconfirm-popup-modal {
    margin-top: 120px;
    height: auto ;
    width: 95vw;
    max-width: 95vw; 
    user-select: none;
    
}



