

.dashcard {
    text-align: center !important
}
/* Update the bootstrap default min resolution, as long as the resolution is greter than this
   Allow the xol-x1-4 to not take over the full width of the row  */
@media (min-width: 1100px){
    .col-xl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}
