.geofencemanagement-modal {
    margin-top: 120px;
    height: auto ;
    width: 95vw;
    max-width: 95vw; 
    user-select: none;
}

.geofencemanagement-modal .geofencemanagement-content {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    
}

.geoTitle{
    margin-left:auto;
    margin-right:auto;
    font-size: 26px;
}

.geoTitle2{
    margin-left:auto;
    margin-right:auto;
    font-size: 26px;
}


.geofencemanagement-content .topRow{
    margin-top: 3em;
    margin-bottom:0.5em;
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
}

.AssignGroup {
    /* height:3em; */
    background:#83c63f;
    outline: none;
    border: none;
    margin-left:auto;
    margin-right:auto;    
    font-size: 18px;
    border-radius:5px;
    width:8vw;
    grid-column: 1/2;
}
.AssignGroup:hover {
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
}
.AssignGroup:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}

.search-title{
    grid-column: 8;
    text-align: right;
    margin-right: 1em;
    margin-top:8px;
}
  
.groupSearch{
    grid-column: 9/span 10;
}



/*=====================================================================
  Format video list table
=====================================================================*/
.geofence-lister-row table.video-table {
    table-layout: auto !important; 
 }
 .geofence-lister td.gf-region {
   overflow: hidden;
   text-overflow: ellipsis;
   text-align:left;
 }
 .geofence-lister td.gf-bEnter {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
 .geofence-lister td.gf-bExit {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
.geofence-lister td.gf-bSpeeding {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
.geofence-lister td.gf-bDwell {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
.geofence-lister td.gf-bAssetCount {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
.geofence-lister td.gf-email {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
.geofence-lister td.gf-groupname {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
 
 

 /*=====================================================================
  Style the table used for displaying lists of videos
=====================================================================*/

/* hide the icons that come with the library, they're not working correctly */
.geofence-lister th.sortable span.order, .geofence-lister th.sortable span.react-bootstrap-table-sort-order {
    display: none;
  }
  
  .geofence-lister th.sortable {
    position: relative;
    padding-right: 24px;
    text-align: center;
  }
  
  
  .geofence-lister th.sortable::after {
    content: '\f0dc';
    font-family: 'Font Awesome 5 Free';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    margin-left: 0.6em;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
  }
  
  .geofence-lister th.sorted-asc::after {
    content: '\f0de';
  }
  
  .geofence-lister th.sorted-desc::after {
    content: '\f0dd';
  }
  
  .geofence-lister {
    font-size: 14px;
    /* margin-top: 3em; */
  }
  
  .geofence-lister th {
    user-select: none;
  }
 

.assigngroup-modal {
    margin-top: 15vh;
    min-height: 15vh;
    width: 35vw;
    max-width: 35vw; 
    user-select: none;
}

.assigngroup-modal .assigngroup-content {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    min-height: 15vh;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    
}
.assigngroup-content .warningmessage{
    margin-top: 5px;
    color: red;
    font-style: italic;
}
.assigngroup-modal .ag-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.ag-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%
}