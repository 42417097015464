/*=====================================================================
  Handle the logout menu logic
=====================================================================*/
.user-profile .show-notification {
  visibility: hidden !important;
  opacity: 0 !important;
  transform: none !important;
}

.user-profile .show-notification.shown-menu {
  visibility: visible !important;
  opacity: 1 !important;
}
  





