/*=====================================================================
  Style the notifications icon
=====================================================================*/
@import '../Util/animations.css';

.notifications .show-notification {
    visibility: hidden !important;
    opacity: 0 !important;
    transform: none !important;
}
  
.notifications .show-notification.shown-menu {
    visibility: visible !important;
    opacity: 1 !important;
}

.notifications .ti-bell{
    font-size: 1.25em;
}
.notifications .ti-bell.showpending{
    color: red;
}
/* Override the defaults so that there is less spacing around the bell icon */
.notifications > a{
    padding: unset !important;
}
.shake-icon {
    animation: jump-shaking 1.0s infinite;
}

.fade-icon {
    animation: flash 1.1s infinite;
}

@keyframes tilt-shaking {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
}
@keyframes jump-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateY(-5px) }
    35% { transform: translateY(-5px) rotate(17deg) }
    55% { transform: translateY(-5px) rotate(-17deg) }
    65% { transform: translateY(-5px) rotate(17deg) }
    75% { transform: translateY(-5px) rotate(-17deg) }
    100% { transform: translateY(3px) rotate(0) }
  }



/* .flash {
    -webkit-animation-name: flash;
    animation-name: flash
} */