.analytics-toprow{
    display: flex;
    flex-direction: row;
}

.analytics-filters > * {
    background: #e9e9e9;
}

.analytics-filters {
    background: #e9e9e9;
    border-radius: 4px;
    padding: 7px 0 7px 10px;
    font-size: 14px;
    position: relative;
    /* right: 10px; */
    /* top: -10px; */
    bottom: 10px;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.analytics-filters :nth-child(2), .analytics-filters :nth-child(3) {
    padding: 0;
}

.analytics-filters .date-pick {
    margin-right: 8px;
    border: 1px solid #bdc3c7;
    border-radius: 2px;
}