.storyreport-modal {
  width: 45vw;  
  height: 85vh;
  user-select: none;
  margin-left:auto;
  margin-right:auto; 
  margin-top: 5vh;
  box-sizing: border-box;
  max-width: unset;
}
.storyreport-modal .scrolling-report{
  width: 100%;
  max-height: 100%;
  overflow-y: auto;

  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
}



.storyreport-modal .storyreport-modal-content {
  width:100%;
  height: 100%;

  margin-top: -2vh; 
  text-align: center;  
  background-color: #fff;
  
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
          box-shadow: 0 3px 9px rgba(0, 0, 0, .5);


  display: flex;
  flex-direction: column;

  padding-left:1%;
  padding-right:1%;
  text-align: left;
  font-size: 13px;

  
  z-index: 2;
}

.storyreport-modal-content .top{
  min-height: 29vh;
  height: auto;
  width: 100%;
  display: grid;
  grid-template-rows: 18vh 1.5em 1.5em 1.5em 1.5em;
  margin-bottom: 2%; 
}
.storyreport-modal-content .e3-logo{
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  padding-top: 1.5vh;
  padding-right: 1%;
  z-index: 2;
}


.storyreport-modal-content .photo{
  margin-top:auto;
  margin-bottom:auto;
  
  height: 15vh;    
  width: auto;
}


.storyreport-modal-content .events{
  width: 100%;
}

.storyreport-modal-content .title{
  grid-column:unset;
  grid-row:unset;
  font-size: 150%;
  font-weight: 400;
}

.storyreport-modal-content .events .title{
  text-align: left;
  padding-left:unset;
}
.storyreport-modal-content .events >div{
  padding-left:2%;
}

.storyreport-modal-content .notecardlist{
  margin-left: 8vw;
  list-style-type: unset;
}
.storyreport-modal-content .notecardsfoot{
  padding-top:1%;
  padding-bottom:1%;
  margin-left: 8vw;
}
.storyreport-modal-content .listelem{
  margin-left:5%;
}

.storyreport-modal-content .calls{
  max-width: 90%;
  overflow-x: auto;  
  margin-left:auto;
  margin-right:auto;
  margin-top:1%;
}

.reportcalls-table ,.reportcalls-table th,.reportcalls-table td{
  border:1px solid black;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}
 

.storyreport-modal-content .conclusion .title{
  text-align: left;
  padding-left:unset;
}
.storyreport-modal-content .conclusion >div{
  padding-left:2%;
}

.storyreport-modal-content .top .header{
  max-height: 100%;
  height: 100%;
  width: 100%;
  position: relative;

  display: grid;
  grid-template-columns: 80% 20%;
}
.storyreport-modal-content .bottom{
  margin-top:5vh;
  bottom:5px;
  width: 99%;
  padding-left:1%;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.storyreport-modal .scrolling-report .downloadicon{
  height:7vh;
  width:auto;
  position: relative;

  z-index: 5;

  margin-top: -7vh; 
  transform: translateX(37vw) translateY(15.5vh); 
  
}
.storyreport-modal .scrolling-report .downloadicon:hover{
  transform:translateX(37vw) translateY(15.5vh) scale(1.1,1.1);
}


.genreport-modal-dialog {
  margin-top: 15vh;
  height: auto ;
  width: 30vw;
  height: 15vh;
  max-width: 80vw; 
  user-select: none;
  transition-duration:250ms; 
}
.genreport-modal-dialog .genreport-content { 
  width: 100%;
  height:100%;
  margin: auto;
  text-align: center;
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  background-clip: padding-box;
}

