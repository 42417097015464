
.page-title{
  font-size: 30px;
  font-weight: bold; 
  text-align: center;
  margin-top:-4em;
  width: 100%;
}

.asset-chooser .nav{
  flex-wrap: unset;
}
.asset-chooser .nav-tabs{
  width:350px !important;
}
.asset-chooser .nav-link{
  min-width: 33%;
  padding: .5rem;
}
.asset-chooser .nav-link {
  color: black;
  background-color: #00AFED15 !important;
  font-size: 14px;
}
.asset-chooser .nav-link.active {
  color: black;
  background-color: #00AFED77 !important;
  font-size: 14px;  
  font-weight: bold
}



.live-review {
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  /* max-width: 90%; */
  margin-left: auto;
  margin-right: auto;
  transition-duration:250ms; 
}

  .live-reviewer-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -0.5em;
    width: 100%;
  }
  .live-reviewer-bottom .controlbuttons{    
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap; 
    font-size: 15px;
    width: 100%;
    justify-content: space-between;   
     margin-top: 0em; 
    margin-left:auto;
  }
  
  .live-reviewer-bottom .controlbuttons .email-button{
    border-radius: 26px;
    width: 150px;
    /* background-color:#E60024; */
  }
  


  
.live-reviewer {
  display: flex;
  flex-direction: row;
  
  justify-content: space-between;
  align-items: flex-start;
  width:100%;
}

.assetid-block{
  /* display: flex;
  flex-direction: row; */
  width: 42%;
  /* max-width: 30%; */

  display:grid;
  grid-template-columns: 25% 25% 25% 25%; 
  grid-template-rows: 100%; 
  /* height: 20em; */
  /* height:660px; */

  user-select:none;
  margin-left:-2%;
  /* margin-bottom:auto; */
}
.assetid-block .asset-col{
  display: flex;
  flex-direction: column;
  /* width: 50%; */
  /* max-width: 50%; */
  height:100%
  /* margin-top:auto; */
  /* margin-bottom:auto; */
}

/* .assetid-block .asset-col button { */
.asset-elem button {
  /* width: 97%;  
  height: 7%;
  margin-bottom: 3%; */

  border-radius: 6px;
  border:none;
  outline: none;
  user-select:none;
}


.asset-elem{
  
  /* width:150px; */
  width: 97%;  
  height: 8%;
  /* margin-bottom: 3%; */

  display: grid;
  grid-template-columns: 35% 65%; 
  grid-template-rows: 95%;
  column-gap: 2%; 
  /* flex-wrap:wrap; */
  /* justify-content: space-between;    */
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  font-size:16px;
  /* height:25px; */
  max-width:100%;
}

.warningblock{
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 15%;
}

.offline-mark{
  margin-top:auto;
  margin-bottom:auto;
  /* height:22px; */
  height:2.3vh;
}

.alert-mark{
  /* height:20px; */
  margin-top:auto;
  margin-bottom:auto;
  height:2.3vh;
}

.tabtitle{    
  display: flex;
  flex-direction: row;
  /* flex-wrap:wrap; */
  justify-content: space-between;   
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  font-size:0.83vw;
  /* font-size:16px; */
  height:auto;
  max-width:100%;
}

.tabtitle .badge{    
  padding: 5px;
  font-size: 12px;
  margin-top: auto;
  margin-bottom: auto;
}
.tabtitle .empty-badge{    
  /* background-color: red; */
  padding: 5px;
  width: 20px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius:10px;
}


@media screen and (max-width:1300px) { /*only apply if the screen width is less than*/
  .tabtitle {  font-size: 14px; } 
  .tabtitle .badge{  font-size: 10px; padding:4px;  }
}

/* .assetid-block button :active {
  
  background-color: blue;
} */

.alert-icon {
  position: absolute; 
  top: 15%;
  right: 5%;
  width: 100px;
  height: 75px;
}

.asset-chooser-group {
  display: flex;
  flex-direction: row;  
  flex-wrap:wrap;
  justify-content: flex-start;   
}

.asset-chooser-tabs {
  width: 10px;
  font-size: 20px;
}

.asset-chooser {
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  /* width: 18% !important; */
  /* width: 18% !important; */
  min-width: 23%;
  max-width: 23%;
  /* min-width: 16em; */
  /* flex-grow: 0; */
  /* height:150px; */
}


  .live-reviewer .live-reviewer-players .live-reviewer-player{
    margin-left: auto;
    margin-right: auto;
  }

  .live-reviewer .live-reviewer-players .notifyButtonsRight{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 80%;
    margin-left:auto;
    width: 20%;
    user-select: none;
    /* margin-top: 1em; */
    /* margin-top: -1em; */
  }




  .live-reviewer .live-reviewer-players .notifyButtonsLeft{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    /* margin-top: 1em; */
  }

  .live-reviewer .live-reviewer-players .notifyButtonsRight .email-button{
    border-radius: 12px;
    width: 70px;
    height: 50px;
    
    margin-bottom: .25em;
  }
  .live-reviewer .live-reviewer-players .notifyButtonsRight .alert-button{
    border-radius: 20px;
    width: 200px;
    height: 50px;
    outline:none;
    margin-bottom: .25em;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    line-height:1;
  }
  
  .alert-button .alert-button-img{
    width: auto;
    height: 30px;
    margin-right: 10%; 
    transform: translate(50%, 0);
  }
  .alert-button > img{
    max-height: 75%;
  }

  .live-reviewer .live-reviewer-players .notifyButtonsRight .infraction-button{
    border-radius: 20px;
    width: 200px;
    height: 50px;
    outline:none;
    margin-bottom: .25em;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    line-height:1;
  }
  .infraction-button .infraction-button-img{
    width: auto;
    height: 30px;
    margin-right: 10%; 
    transform: translate(50%, 0);
  }
  .infraction-button > img{
    max-height: 75%;
  }



  .hidden-video {
    display: none;
  }
  
  
  
  .live-reviewer-player {
    position: relative;
    user-select: none;
  }

.roibuttons{    
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10%;
  /* height: 1000px; */
  justify-content: space-between;   
  /* justify-content: space-around; */
  align-content: center;
  /* margin: 1em; */
  
}

.live-review .asset-button:hover{
  transform:scale(1.05); 
}
.live-review .asset-button:active{
  transform:scale(0.9); 
}


.asset-elem img:hover{
  transform:scale(1.15);   
} 
.asset-elem img:active{
  transform:scale(0.9); 
}

.blink{
  animation: blinkanim normal 1s infinite ease-in-out;
}
@keyframes blinkanim {
  0% {
      opacity:1;
  }
  50% {
      opacity:.25;
  }
  100% {
      opacity:1;
  }
}

  



  
  
  
