
body {
  background: none !important;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

html, body, #root {
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  padding-bottom: 56px;
}

/*====================================================================
  Change the defualt color of a progress bar. this needs to be handled
  for separately for mozilla and webkit browser (safari / chrome)
=====================================================================*/
::-moz-progress-bar {
  background-color:rgb(0,174,239);
}
::-webkit-progress-bar {
  background:#fff;
}
::-webkit-progress-value {
  background:#00aeef;
}

/*====================================================================
 Set up a grid layout for the default amplify container, 
 3 columns, with 4 rows:
 [Client logo ][       title        ][ edge3 logo ]
 [            ][   toggle button    ][  sign out  ]
 [            ][      Upload form   ][            ]
 [            ][                    ][            ]
  classNames:
 [    .b      ][       .top         ][    .c      ]
 [                amplify-nav-bar                 ]
 [            ][      Upload form   ][            ]
 [            ][                    ][            ]

=====================================================================*/
.amplify-container {
  display: grid;
  grid-gap: 10px; 
  /*Define the grid, the names are the lines at the left of each tile */  
  grid-template-columns: 1px [first-col] 25% [col1] calc(50% - 1px) [col2] 25% [end-col];
  /* grid-template-rows: 1px[row-logo]110px [first-row] minmax(10%,20px) [row1] minmax(10%,20px) [row2] 40% [end-row] ; */
  grid-template-rows: 1px[row-logo] 110px [first-row] 20px [row1] 20px [row2] 40% [end-row] ;
  background-color: #fff;
  color:black;
  padding: 35px;
  overflow: hidden;
}

/* Define what a grid cell looks like */
.box {
  /*Set the background color to view the rendered grid pattern*/
   /* background-color: rgb(184, 183, 183);  */
  color:black; /*Font color in the grid cell*/
  border-radius: 5px;
  padding: 20px;
  font-size: 150%;
}

.amplify-form-container{
  grid-column: first-col / end-col;
  grid-row: row1 ;
  text-align: center;
  padding:95px;
}
/* Set the content region for the authenticator signout form*/
.amplify-nav-bar{
  grid-column: col2 / end-col;
  grid-row: row1 ;
  text-align: center;
}
/* Set the content region for the analytics toggle button*/
.buttonAnalytics{
  grid-column: col1 / col2;
  grid-row: row1 ;
  text-align: center;
  font-size:150%;
}

/* Set the content region for the title */
.title {
  grid-column: col1;
  grid-row: row-logo;
  text-align: center;
  display: block;
  max-height: 100%;
  max-width: 100%;
  font-size:175%;
}

/* Set the content region for the upload form*/
.mainApp {
  grid-column: first-col /end-col;
  grid-row: row1 /end-row;
  /* grid-row: row2 / row3 ; */
  text-align: center;
}

/*===================================================================== 
  Add the floating colored edge bars with the EDGE3 color schemes
=====================================================================*/
.bottombar_edge3{
  border: 10px solid rgb(0,174,239);
  height: 0px;
  width:  38%;
  position: fixed;
  bottom: 1%;
  right: 0%;
  z-index: 100;
}
.sidebar_edge3{
  border: 10px solid rgb(141,198,63);
  height: 50%;
  width:  0%;
  position: fixed;
  top: 30%;
  left: 0%;
  z-index: 10000;
}
.topbar_edge3{
  border: 10px solid rgb(237,28,36);
  height: 0px;
  width:  38%;
  position: fixed;
  top: 111px;
  left: 0;
  z-index: 10000;
}


/*===================================================================== 
  Add formatting as a embedded grid for the upload form
=====================================================================*/
.uploadForm{
  display: grid;
  grid-template-columns: 1px [first-col] 25% [col1] auto [col2] 25% [end-col] ;
  grid-template-rows: 1fr 1fr ;
}
.uploadBrowse{
  grid-column: col1;
  grid-row: 1;
  display:inline-block;
  cursor: pointer;
  /* border: 2px solid #ccc; */
}
.uploadButton{
  grid-column: col1;
  grid-row: 2;
  text-align: center;  
  padding:5px;
}
input[type="file"] {
  font-size: 100%;  
  border: 2px solid #ccc;
  /* display:none; */
}
input[type="button"] {
  font-size: 100%;  
}

.jFiler-input {
  width: 100% !important;
}

.jFiler-input-button {
  background-image: none !important;
  color: white !important;
}

.uploadButton {
  font-size: 0.7em;
}

.jFiler-item .icon-jfi-trash::after {
  content: '\f2ed';
  font-family: 'Font Awesome 5 Free';
  padding-right: 2px;
}

.upload-form-wrapper {
  margin-top: 2em;
}

.upload-form-wrapper h5 {
  margin-bottom: 3em;
}

/*=====================================================================
  Format video list table
=====================================================================*/
.video-lister table.video-table {
   table-layout: auto !important; 
}
.video-lister table td.vid-filename {
  max-width: 15em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.video-lister table td.vid-driverId {
  max-width: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-status-pending {
}

.row-status-available .vid-status {
  font-weight: 600;
  /* color:  #00AFEDff; */
}
.row-status-reviewed .vid-status {
  font-weight: 700;
  color:  #00AFEDff;
}
.row-status-unusable .vid-status {
  font-weight: 700;
  color:  #ED6B53ff;
}

.row-status-review {
}

.row-status-rejected {
}

.row-status-simulated .vid-status {
  font-style: italic;
}

.row-status-simulated {
  color: rgba(0, 0, 0, 0.6);
}

.table-striped .row-status-pending:nth-child(2n) {
}

.table-striped .row-status-pending:nth-child(2n+1) {
}

/*
.table-striped .row-status-available:nth-child(2n+1) {
  background-color: rgba(141, 198, 63, .09);
}

.table-striped .row-status-available:nth-child(2n) {
  background-color: rgba(255, 255, 63, .015);
}
*/

table.infraction-table thead th, table.video-table thead th {
  text-align: center;
}

.highlight-top > div {
  margin: 1em;
}

.infraction-vid div {
  margin-left: auto;
  margin-right: auto;
  max-width: 360px;
  max-height: 360px;
}

.highlight-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.no-highlights-message {
  margin: 1em;
}

.infraction-table {
  table-layout: auto !important;
}

.infraction-table td {
  vertical-align: middle;
  font-size: 110%;
}

/*=====================================================================
  Style nav bar and theme fixups
=====================================================================*/

img.client-logo, img.main-logo {
  max-height: 35px;
}


.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.noicon > a::after {
  content: "" !important;
}

nav.pcoded-navbar.is-hover {
  border-top: 1px solid #d5dae6;
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li > a {
  padding: .53705vh 1.302vw !important; /* Padding shorthand: padding top/bottom , left/right*/
  /* background-color: red; */
}
.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li  {
  /* padding: 5px 25px !important; */
  margin-left: -.32vw !important;
}

div.navbar-logo {
  width: 240px !important;
}

ul.nav-right {
  display: block !important;
}

.pcoded-main-container {
  background: white;
}

.pcoded-navbar li.pcoded-trigger > a {
  background: none !important;
  border-color: none !important;
}

body {
  min-width: 720px;
}

.card {
  text-align: left;
  box-shadow:unset;
  
}
/* .card > h5{
   
} */

/*
* Styling specifics for the logo bar
*
* The !important rules for this section and the menu bar could need to be reworked for
* mobile/tablet targetting if the menu behavior must change. For now though this is the
* easiest way to make sure that we have the nav bar correctly positioned always.
*/
.header-navbar {
  position: fixed !important;
  width: 100%;
  z-index: 9999 !important;
  top: 0;
  user-select: none;
}

/*
* Styling specifics for the menu bar
*/
.main-menu-bar {
  position: fixed !important;
  top: 56px;
  width: 100%;
  z-index: 9997;
  height: 55px !important;
  user-select: none;
}

/*=====================================================================
  Style dashboard, especially widgets and charts
=====================================================================*/

.main-dashboard {
  margin-top: -40px;
}

.main-dashboard .dashboard-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 30px;
}
.main-dashboard .dashboard-row-overlap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top:-20vh; 
}
/* Allow for more spacing between the rows on the dashboard on the smaller screens */
@media (max-width: 1300px){
  .main-dashboard .dashboard-row-overlap {
    margin-top: -10vh;
  }
}

.dash-black-half{
  width: 50vw;
}

.main-dashboard .dashboard-row-limity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  height: 50vh;
}
.main-dashboard .dashboard-row-limity .card-full {
  width: 100%;
  height: 90%;
}

.card-full .titletext{
  /* font: 14px; */
  text-align: left; 
  /* color: #757575;
  font-size: 16px;
  font-weight: 600; */
}
.main-dashboard .dashboard-row-limity .card-half {
  width: 50vw;
  height: 50vh;
  margin-top:2vh;
  /* margin-right:0.4vw; */
  margin-right:-4.5vw;
}

.card-half .titletext{
  font-size: 16px !important;
  /* text-align: left;  */
  margin-bottom: 3vh;
  font-weight: 700 !important;
}

.main-dashboard .dashboard-row-limity .card-half-extended {
  width: 50vw;
  height: 50vh;
  margin-top:2vh;
  /* margin-right:0.4vw; */
  margin-left:auto;
  margin-right:auto;
}

.card-half-extended .titletext{
  font-size: 16px !important;
  /* text-align: left;  */
  margin-bottom: 3vh;
  font-weight: 700 !important;
}




.col-x1-4 {
  max-width: 45%;
}
.infraction-proportions-title{
  /* margin-top: -10%; */
  /* z-index: 500; */
  position: relative;
  z-index: 999;
  margin-left:25%;  
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-bottom: 1.5vh !important;
}

.card .titletext{
  font-size: 16px;
  font-weight: 700; 
}

.card h5{
  color: black;  
}




.infraction-proportions{
  margin-top: -8%;
}
.infraction-proportions-hide{
  display:none;
}

.main-dashboard .dashboard-row > div:nth-child(3n+0) > div {
  border-top-color: rgb(141,198,63);
}

.main-dashboard .dashboard-row > div:nth-child(3n+1) > div {
  border-top-color: rgb(237,28,36);
}

.main-dashboard .dashboard-row > div:nth-child(3n+2) > div {
  border-top-color: rgb(0,174,239);
}

.main-dashboard .dashboard-row-limity > div:nth-child(3n+0) > div {
  border-top-color: rgb(141,198,63);
}

.main-dashboard .dashboard-row-limity > div:nth-child(3n+1) > div {
  border-top-color: rgb(237,28,36);
}

.main-dashboard .dashboard-row-limity > div:nth-child(3n+2) > div {
  border-top-color: rgb(0,174,239);
}

.main-dashboard .dashboard-row-overlap > div:nth-child(3n+0) > div {
  border-top-color: rgb(141,198,63);
}

.main-dashboard .dashboard-row-overlap > div:nth-child(3n+1) > div {
  border-top-color: rgb(237,28,36);
}

.main-dashboard .dashboard-row-overlap > div:nth-child(3n+2) > div {
  border-top-color: rgb(0,174,239);
}


.dashboard-row .radial-bar {
  width: 180px;
  height: 180px;
  line-height: 180px !important;
  z-index: 2;
}

.dashboard-row .radial-bar::after, .dashboard-row .radial-bar > img {
  width: 140px;
  height: 140px;
  margin-left: 20px;
  margin-top: 20px;
  line-height: 140px;
  z-index: 1;
}

.radial-image-chart.radial-bar::after {
  display: none;
}

.main-dashboard .dashboard-row:nth-child(2) .card-block-big {
  padding-bottom: 27px !important;
}

.dashboard-row:nth-child(2) .card-block-big {
  height: 300px !important;
}

.dashboard-row .card-block-big h5 {
  margin-bottom: 20px;
  text-transform: none;
  font-weight: bold;
  font-family: sans-serif;
}



.card-block-big > .radial-image-wrapper {
  margin-left: auto;
  margin-right: auto;
}

@keyframes graph-appear {
  0% {opacity: 0;}
  99% {opacity: 0;}
  100% {opacity: 1;}
}
/* make the first row smaller? */
.main-dashboard .dashboard-row:nth-child(1) {
  /* padding-left: 30px;
  padding-right: 30px; */
}

.main-dashboard .dashboard-row .echarts-for-react {
  width: 100%;
}

.main-dashboard .download-timeline{
  background-color: #AFD47B;
  border-radius: 10px;
  /* height:1vh; */
  height: 4vh;
}
.main-dashboard .download-timeline:hover {
  transform:scale(1.05,1.05);
  -webkit-transform:scale(1.05,1.05);
  -moz-transform:scale(1.05,1.05);
}
.main-dashboard .download-timeline:active {
  transform:scale(.9,.9);
  -webkit-transform:scale(.9,.9);
  -moz-transform:scale(.9,.9);
}
.main-dashboard .download-timeline-clicked{
  background-color:grey;
}



.filter-drop-container {
  height: 100%;
}

.filter-drop-container > li {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px !important;
}

.filter-drop-container select {
  color: black !important;
}

/*=====================================================================
  Style the TopDriverWidget and its subcomponents
=====================================================================*/

.top-driver-widget {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width:80%;  
}
/* Allow for more left margin on the smaller screens */
@media (max-width: 1500px) {
  .top-driver-widget {
    margin-left: 10%;
  }
}

.top-driver-widget > div {
  margin: 0 5px;
  margin-top : 2vh;
}

.top-driver-widget h5 {
  font-size: 0.7em;
}

.top-driver-widget .radial-image-wrapper {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top-driver-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dashboard-row .card-block-big h5 {
  text-align: center;
  width: 100%;
}

.top-driver-wrapper, .radial-chart-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.top-driver-wrapper {
  margin-top: -32px;
}

.infraction-prop-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: -32px;
}
/* not needed on 3rd row? */
.top-driver-widget {
  margin-top: 0px;
}

/* fix height of wrapper around top driver widget */
.main-dashboard .dashboard-row:nth-child(2) > :nth-child(2) > div {
  height: 304px !important;
}

.radial-chart-inner {
  position: relative;
  z-index: 0;
}

.radial-chart-inner > * {
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 1;
  font-weight: bold;
}

.radial-chart-inner > * > img {
  vertical-align: top !important;
}

.top-driver-widget {
  word-wrap: normal;
}

.radial-chart-inner .text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  text-shadow: 1px 1px 2px black;
  text-align: center;
  width: 100%;
  font-weight: normal;
}

.top-driver-widget h5 {
  width: 120% !important;
  margin: 0 0 5px -8% !important;
}

/*=====================================================================
  Style the table used for displaying lists of videos
=====================================================================*/

/* hide the icons that come with the library, they're not working correctly */
.video-lister th.sortable span.order, .video-lister th.sortable span.react-bootstrap-table-sort-order {
  display: none;
}

.video-lister th.sortable {
  position: relative;
  padding-right: 24px;
}

.video-lister th.sortable::after {
  content: '\f0dc';
  font-family: 'Font Awesome 5 Free';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  margin-left: 0.6em;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}

.video-lister th.sorted-asc::after {
  content: '\f0de';
}

.video-lister th.sorted-desc::after {
  content: '\f0dd';
}

.video-lister {
  font-size: 14px;
  margin-top: -4em;
}

.video-lister th {
  user-select: none;
}

.analytics-view button {
  margin-top: 2px;
}

/*=====================================================================
  Style the modal used to display the 'Network Delay' message from ApiCaller
=====================================================================*/
.ReactModal__Content--after-open {
  width: 400px;
  height: 300px;
  margin: 250px auto;
}

/* Make sure that the 'Network Delay' modal isn't overlapped */
.ReactModalPortal {
  z-index: 30000;
  position: relative;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-footer .spinner-wrapper {
  height: 40px;
  padding-top: 20px;
}

.modal-header {
  flex-direction: column;
}

.api-caller-message {
  text-align: center;
}

/* Workaround to prevent Google Charts toolip flickering
* https://github.com/google/google-visualization-issues/issues/2162
*/
svg > g > g:last-child { pointer-events: none }

.top-driver-widget .hovering {
  box-shadow: 0 2px rgb(0,174,239)
}

/* Change cursor to indicate that a radial image chart is clickable */
.radial-image-wrapper.clickable {
  cursor: pointer;
}

/* Style the tooltip for the top driver widget */
.top-driver-widget .mytooltip .tooltip-content {
  width: 180px;
  margin: 0 0 2px -90px;
  text-align: center;
}

/* Style the table filters in the AnalyticsView */
.table-filters {
  display: flex;
  float: right;
  width: 50%;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 7px;
  margin-right: -5px;
}

.table-filter {
  margin: 5px;
}

/******
* Styling for fitting in the CloudDETECT logo
*/

/* move the client menu down to the menu bar */
.client-menu {
  position: absolute;
  top: 55px;
  right: 0;
}

/* make space for the client menu in the menu bar */
.main-menu-bar {
  padding-right: 140px;
}

.main-dashboard .filter-dropdown-container {
  position: absolute;
  top: 167px;
  /*move to the left to align with the logout down arrow*/
  /* right: 1.5rem;  */
  /* font-size: 0.7em; */
}

.filter-dropdown-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.filter-dropdown-container ul {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
  align-items: center !important;

}

/*=====================================================================
  Style logo bar CloudDETECT logo
=====================================================================*/
.navbar-logo {
  /* pull the main EDGE3 logo left a bit to match the CloudDETECT logo*/
  user-select: none;
  margin-left:-1.4vw; 
}
.navbar-cloud-logo {
  float: right !important;
  margin: 15px 20px;
  user-select: none;
  width:10%;
  height:auto;
}

.top-driver-wrapper > h5 {
  margin-top: 6px;
}

.no-map-message {
  position: relative;
  font-size: 34px;
  font-style: italic;
}
.versiontext{
  position:absolute;
  margin-top: 50%;
  margin-right:5%;
  height: 0px;
  width:  38%;
  position: fixed;
  bottom: 5%;
  z-index: 100;
  left: 0;
}
/*=====================================================================
  Style the version popup notification
=====================================================================*/

.version-notify-content{
  display: grid;
  grid-template-columns: 80% 20%;
  position: absolute;
  top: 50%; 
  width:100%;
  transform: translate(0%,-50%);
}
.version-notify-content2{
  position: absolute;
  top: 50%; 
  width:100%;
  transform: translate(0%,-50%);  
  font-size: 2.5vh;
}
.version-notify-text{
  grid-column: 1;
  margin-left:auto;
  margin-right:auto;
  text-align: center;
}
.version-notify-button{
  grid-column: 2;  
  margin-left:auto;
  margin-right:auto;
  margin-top:auto;
  margin-bottom:auto;
}

/* =========================
Style the dashboard filter bar:
===========================
 */
 /* .dashboard-filters > * {
  background: #e9e9e9;
} */

.dashboard-filters{
  
  margin-left:auto;
  /* margin-right: 3.75vw; */
  margin-right: 3.15vw;

  border-radius: 4px;
  padding: 7px 0 7px 10px;
  font-size: 11px;
   
  height: 5vh;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: 0.5vw; */
  grid-column: 3;
  width:fit-content;
}

.dashboard-filters :nth-child(2), .dashboard-filters :nth-child(3) {
  padding: 0;
}


.dashboard-filters .filter-dropdown-container{
  position: unset;
}


.dashboard-filters .date-pick {
  margin-right: 8px;
  border: 1px solid #bdc3c7;
  border-radius: 2px;
}

.dashboard-filters .label-wrap {
  padding-right: 2px !important;
}

.dashboard-filters .nav-item {
  max-width: 180px;
}


.dashboard-filters .date-pick {
  font-size: 11px;
  height: 27px !important;
  width: 90px !important;
}



.dashboard-filters select {
  /* Styling for the specific select box */
  height: 27px !important;
  font-size: 11px;
  /* max-width :8em !important; */
  /* Remove the default filtering of the select dropdown */
  -webkit-appearance: none;
  -moz-appearance: none;
   appearance: none; 
  /* Add some styling */
  display: block;
  font-family: sans-serif;
  color: #444;
  line-height: 1.3;
  padding: .25em 1.4em .25em .8em;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
      for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
  */
  /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right .4em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 1.25em auto, 100%;
}

.dashboard-filters .react-datepicker__close-icon {
  right: 11px;
}

.dashboard-filters-download{
  
  margin-left:auto;
  margin-right: 3.65vw;

  border-radius: 4px;
  padding: 7px 0 7px 10px;
  font-size: 11px;
   
  height: 5vh;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: 0.5vw; */
  grid-column: 3;
  width:fit-content;
}

.dashboard-filters-download :nth-child(2), .dashboard-filters-download :nth-child(3) {
  padding: 0;
}


.dashboard-filters-download .filter-dropdown-container{
  position: unset;
}


.dashboard-filters-download .date-pick {
  margin-right: 8px;
  border: 1px solid #bdc3c7;
  border-radius: 2px;
}

.dashboard-filters-download .label-wrap {
  padding-right: 2px !important;
}

.dashboard-filters-download .nav-item {
  max-width: 180px;
}


.dashboard-filters-download .date-pick {
  font-size: 11px;
  height: 27px !important;
  width: 90px !important;
}



.dashboard-filters-download select {
  /* Styling for the specific select box */
  height: 27px !important;
  font-size: 11px;
  /* max-width :8em !important; */
  /* Remove the default filtering of the select dropdown */
  -webkit-appearance: none;
  -moz-appearance: none;
   appearance: none; 
  /* Add some styling */
  display: block;
  font-family: sans-serif;
  color: #444;
  line-height: 1.3;
  padding: .25em 1.4em .25em .8em;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
      for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
  */
  /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right .4em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 1.25em auto, 100%;
}

.dashboard-filters-download .react-datepicker__close-icon {
  right: 11px;
}

.dashboard-filters-2ndrow{
  
  margin-left: auto;
  margin-right: 3.15vw;
  
  border-radius: 4px;
  padding: 7px 0 7px 10px;
  font-size: 11px;
   
  height: 5vh;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: 0.5vw; */
  grid-column: 3;
  width:fit-content;
}

.dashboard-filters-2ndrow :nth-child(2), .dashboard-filters-2ndrow :nth-child(3) {
  padding: 0;
}


.dashboard-filters-2ndrow .filter-dropdown-container{
  position: unset;
}


.dashboard-filters-2ndrow .date-pick {
  margin-right: 8px;
  border: 1px solid #bdc3c7;
  border-radius: 2px;
}

.dashboard-filters-2ndrow .label-wrap {
  padding-right: 2px !important;
}

.dashboard-filters-2ndrow .nav-item {
  max-width: 180px;
}


.dashboard-filters-2ndrow .date-pick {
  font-size: 11px;
  height: 27px !important;
  width: 90px !important;
}



.dashboard-filters-2ndrow select {
  /* Styling for the specific select box */
  height: 27px !important;
  font-size: 11px;
  /* max-width :8em !important; */
  /* Remove the default filtering of the select dropdown */
  -webkit-appearance: none;
  -moz-appearance: none;
   appearance: none; 
  /* Add some styling */
  display: block;
  font-family: sans-serif;
  color: #444;
  line-height: 1.3;
  padding: .25em 1.8em .25em .8em;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
      for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
  */
  /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right .4em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 1.25em auto, 100%;
}

.dashboard-filters-2ndrow .react-datepicker__close-icon {
  right: 11px;
}

/* Override the bootstrap styling and force all buttons to have the same font as the container */
.video-chooser .btn{
    font-family: inherit; 
}