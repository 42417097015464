
/*=====================================================================
  Format list table
=====================================================================*/
.worsttile-content{
  /* height:19.25vh; */
  width: 100%;
  /* border: 1px solid black;   */
  border-bottom: 1px solid black;  
  /* background-color: white; */
  background-color: rgba(0, 175, 237, 0.02);
  transition-duration:50ms; 
  z-index:2;
}

.worsttile-content .tiletable{
  width:100%;
  display: grid;
  grid-template-columns: 3vw 20% 15% 20.05% 20% auto;
}



.worsttile-content .tiletable> div{
  text-align: center;    
  /* margin-top:auto;
  margin-bottom:auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border-right: 1px solid black; */
}

.worsttile-content .radialdiv{
  height:19.1vh;
}

.worsttile-content .card-half {
  height:100%;
  margin-right:auto;
  margin-left:auto;
}


.worsttile-content .spinner-wrapper{
  flex-direction:row !important;
}


.worsttile-content .tiletable .tile-severity{
  margin-top:unset;
  margin-bottom:unset;
}


.worsttile-content .driverphoto{
  width: 100%;    
  height: 100%;
}
.worsttile-content .driverphoto .photo{
  width: auto;    
  height: 15vh;    
  margin-top:1%;
  object-fit: contain;
}



.worsttile-content:hover {
  transform:scale(1.01,105%) translate(0.05%,2.5%); 
  /* background-color: #00AFED15; */
  background-color: rgb(235,249,254);
  border-top: 1px solid black;
  z-index: 3;
}
.worsttile-content:active {
  transform:scale(.98);  
}




/* Comments */
.radialcard-modal-content .commenter {
  width: 90%;
  margin-left: auto;
  margin-right:auto;
}
.radialcard-modal-content .commenter .adding-buttons{
  height:4vh;
}
.radialcard-modal-content .commenter .adding-buttons .btn{
  height: 100%;
  font-size: 0.9em;
}


.radialcard-modal-content .comment {
  /* background: #f4f5f7;
  border-radius: 4px;
  margin: 8px 0;
  padding-left: 8px;
  padding-top: 8px; */
  width: 96%; 
  text-align: left;
}

.radialcard-modal-content .comment pre {
  margin-bottom: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  font-family: 'Open Sans';
}
.radialcard-modal-content .comment-timestamp pre {
  color: #555;
}



.radialcard-modal-content .comments {
  overflow-y: auto;
  max-height: 64%;
  margin-bottom: 5px;    
  margin-left:5%;
}
.radialcard-modal-content .comments-title {
  margin-top: 8px;
  text-align: left;
  margin-left:5%;
}


.radialcard-modal-content .comment-timestamp {
  font-size: 0.8em;
  margin-top: -0.5em;
  left : 2%;
  display:inline-block;
}

.radialcard-modal-content .comment-username {
  position: relative;
  font-size: 0.8em;
  margin-top: -0.5em;
  display:inline-block;
}

.riskworst .nodata{
  font-size: 24px;
  font-style: italic; 
}


.riskNotes{
  /* max-height: 10vh !important;  */
}
.riskNotes .notet{
  table-layout: fixed;
  white-space: normal !important;
}
.riskNotes tbody{
  height: 100%;
}
.riskNotes .notetd{
  word-wrap: break-word;
  white-space: pre-wrap;
}
