body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


body {
  background: none !important;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

html, body, #root {
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  padding-bottom: 56px;
}

/*====================================================================
  Change the defualt color of a progress bar. this needs to be handled
  for separately for mozilla and webkit browser (safari / chrome)
=====================================================================*/
::-moz-progress-bar {
  background-color:rgb(0,174,239);
}
::-webkit-progress-bar {
  background:#fff;
}
::-webkit-progress-value {
  background:#00aeef;
}

/*====================================================================
 Set up a grid layout for the default amplify container, 
 3 columns, with 4 rows:
 [Client logo ][       title        ][ edge3 logo ]
 [            ][   toggle button    ][  sign out  ]
 [            ][      Upload form   ][            ]
 [            ][                    ][            ]
  classNames:
 [    .b      ][       .top         ][    .c      ]
 [                amplify-nav-bar                 ]
 [            ][      Upload form   ][            ]
 [            ][                    ][            ]

=====================================================================*/
.amplify-container {
  display: grid;
  grid-gap: 10px; 
  /*Define the grid, the names are the lines at the left of each tile */  
  grid-template-columns: 1px [first-col] 25% [col1] calc(50% - 1px) [col2] 25% [end-col];
  /* grid-template-rows: 1px[row-logo]110px [first-row] minmax(10%,20px) [row1] minmax(10%,20px) [row2] 40% [end-row] ; */
  grid-template-rows: 1px[row-logo] 110px [first-row] 20px [row1] 20px [row2] 40% [end-row] ;
  background-color: #fff;
  color:black;
  padding: 35px;
  overflow: hidden;
}

/* Define what a grid cell looks like */
.box {
  /*Set the background color to view the rendered grid pattern*/
   /* background-color: rgb(184, 183, 183);  */
  color:black; /*Font color in the grid cell*/
  border-radius: 5px;
  padding: 20px;
  font-size: 150%;
}

.amplify-form-container{
  grid-column: first-col / end-col;
  grid-row: row1 ;
  text-align: center;
  padding:95px;
}
/* Set the content region for the authenticator signout form*/
.amplify-nav-bar{
  grid-column: col2 / end-col;
  grid-row: row1 ;
  text-align: center;
}
/* Set the content region for the analytics toggle button*/
.buttonAnalytics{
  grid-column: col1 / col2;
  grid-row: row1 ;
  text-align: center;
  font-size:150%;
}

/* Set the content region for the title */
.title {
  grid-column: col1;
  grid-row: row-logo;
  text-align: center;
  display: block;
  max-height: 100%;
  max-width: 100%;
  font-size:175%;
}

/* Set the content region for the upload form*/
.mainApp {
  grid-column: first-col /end-col;
  grid-row: row1 /end-row;
  /* grid-row: row2 / row3 ; */
  text-align: center;
}

/*===================================================================== 
  Add the floating colored edge bars with the EDGE3 color schemes
=====================================================================*/
.bottombar_edge3{
  border: 10px solid rgb(0,174,239);
  height: 0px;
  width:  38%;
  position: fixed;
  bottom: 1%;
  right: 0%;
  z-index: 100;
}
.sidebar_edge3{
  border: 10px solid rgb(141,198,63);
  height: 50%;
  width:  0%;
  position: fixed;
  top: 30%;
  left: 0%;
  z-index: 10000;
}
.topbar_edge3{
  border: 10px solid rgb(237,28,36);
  height: 0px;
  width:  38%;
  position: fixed;
  top: 111px;
  left: 0;
  z-index: 10000;
}


/*===================================================================== 
  Add formatting as a embedded grid for the upload form
=====================================================================*/
.uploadForm{
  display: grid;
  grid-template-columns: 1px [first-col] 25% [col1] auto [col2] 25% [end-col] ;
  grid-template-rows: 1fr 1fr ;
}
.uploadBrowse{
  grid-column: col1;
  grid-row: 1;
  display:inline-block;
  cursor: pointer;
  /* border: 2px solid #ccc; */
}
.uploadButton{
  grid-column: col1;
  grid-row: 2;
  text-align: center;  
  padding:5px;
}
input[type="file"] {
  font-size: 100%;  
  border: 2px solid #ccc;
  /* display:none; */
}
input[type="button"] {
  font-size: 100%;  
}

.jFiler-input {
  width: 100% !important;
}

.jFiler-input-button {
  background-image: none !important;
  color: white !important;
}

.uploadButton {
  font-size: 0.7em;
}

.jFiler-item .icon-jfi-trash::after {
  content: '\F2ED';
  font-family: 'Font Awesome 5 Free';
  padding-right: 2px;
}

.upload-form-wrapper {
  margin-top: 2em;
}

.upload-form-wrapper h5 {
  margin-bottom: 3em;
}

/*=====================================================================
  Format video list table
=====================================================================*/
.video-lister table.video-table {
   table-layout: auto !important; 
}
.video-lister table td.vid-filename {
  max-width: 15em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.video-lister table td.vid-driverId {
  max-width: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-status-pending {
}

.row-status-available .vid-status {
  font-weight: 600;
  /* color:  #00AFEDff; */
}
.row-status-reviewed .vid-status {
  font-weight: 700;
  color:  #00AFEDff;
}
.row-status-unusable .vid-status {
  font-weight: 700;
  color:  #ED6B53ff;
}

.row-status-review {
}

.row-status-rejected {
}

.row-status-simulated .vid-status {
  font-style: italic;
}

.row-status-simulated {
  color: rgba(0, 0, 0, 0.6);
}

.table-striped .row-status-pending:nth-child(2n) {
}

.table-striped .row-status-pending:nth-child(2n+1) {
}

/*
.table-striped .row-status-available:nth-child(2n+1) {
  background-color: rgba(141, 198, 63, .09);
}

.table-striped .row-status-available:nth-child(2n) {
  background-color: rgba(255, 255, 63, .015);
}
*/

table.infraction-table thead th, table.video-table thead th {
  text-align: center;
}

.highlight-top > div {
  margin: 1em;
}

.infraction-vid div {
  margin-left: auto;
  margin-right: auto;
  max-width: 360px;
  max-height: 360px;
}

.highlight-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.no-highlights-message {
  margin: 1em;
}

.infraction-table {
  table-layout: auto !important;
}

.infraction-table td {
  vertical-align: middle;
  font-size: 110%;
}

/*=====================================================================
  Style nav bar and theme fixups
=====================================================================*/

img.client-logo, img.main-logo {
  max-height: 35px;
}


.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.noicon > a::after {
  content: "" !important;
}

nav.pcoded-navbar.is-hover {
  border-top: 1px solid #d5dae6;
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li > a {
  padding: .53705vh 1.302vw !important; /* Padding shorthand: padding top/bottom , left/right*/
  /* background-color: red; */
}
.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li  {
  /* padding: 5px 25px !important; */
  margin-left: -.32vw !important;
}

div.navbar-logo {
  width: 240px !important;
}

ul.nav-right {
  display: block !important;
}

.pcoded-main-container {
  background: white;
}

.pcoded-navbar li.pcoded-trigger > a {
  background: none !important;
  border-color: none !important;
}

body {
  min-width: 720px;
}

.card {
  text-align: left;
  box-shadow:unset;
  
}
/* .card > h5{
   
} */

/*
* Styling specifics for the logo bar
*
* The !important rules for this section and the menu bar could need to be reworked for
* mobile/tablet targetting if the menu behavior must change. For now though this is the
* easiest way to make sure that we have the nav bar correctly positioned always.
*/
.header-navbar {
  position: fixed !important;
  width: 100%;
  z-index: 9999 !important;
  top: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/*
* Styling specifics for the menu bar
*/
.main-menu-bar {
  position: fixed !important;
  top: 56px;
  width: 100%;
  z-index: 9997;
  height: 55px !important;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/*=====================================================================
  Style dashboard, especially widgets and charts
=====================================================================*/

.main-dashboard {
  margin-top: -40px;
}

.main-dashboard .dashboard-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 30px;
}
.main-dashboard .dashboard-row-overlap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top:-20vh; 
}
/* Allow for more spacing between the rows on the dashboard on the smaller screens */
@media (max-width: 1300px){
  .main-dashboard .dashboard-row-overlap {
    margin-top: -10vh;
  }
}

.dash-black-half{
  width: 50vw;
}

.main-dashboard .dashboard-row-limity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  height: 50vh;
}
.main-dashboard .dashboard-row-limity .card-full {
  width: 100%;
  height: 90%;
}

.card-full .titletext{
  /* font: 14px; */
  text-align: left; 
  /* color: #757575;
  font-size: 16px;
  font-weight: 600; */
}
.main-dashboard .dashboard-row-limity .card-half {
  width: 50vw;
  height: 50vh;
  margin-top:2vh;
  /* margin-right:0.4vw; */
  margin-right:-4.5vw;
}

.card-half .titletext{
  font-size: 16px !important;
  /* text-align: left;  */
  margin-bottom: 3vh;
  font-weight: 700 !important;
}

.main-dashboard .dashboard-row-limity .card-half-extended {
  width: 50vw;
  height: 50vh;
  margin-top:2vh;
  /* margin-right:0.4vw; */
  margin-left:auto;
  margin-right:auto;
}

.card-half-extended .titletext{
  font-size: 16px !important;
  /* text-align: left;  */
  margin-bottom: 3vh;
  font-weight: 700 !important;
}




.col-x1-4 {
  max-width: 45%;
}
.infraction-proportions-title{
  /* margin-top: -10%; */
  /* z-index: 500; */
  position: relative;
  z-index: 999;
  margin-left:25%;  
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-bottom: 1.5vh !important;
}

.card .titletext{
  font-size: 16px;
  font-weight: 700; 
}

.card h5{
  color: black;  
}




.infraction-proportions{
  margin-top: -8%;
}
.infraction-proportions-hide{
  display:none;
}

.main-dashboard .dashboard-row > div:nth-child(3n+0) > div {
  border-top-color: rgb(141,198,63);
}

.main-dashboard .dashboard-row > div:nth-child(3n+1) > div {
  border-top-color: rgb(237,28,36);
}

.main-dashboard .dashboard-row > div:nth-child(3n+2) > div {
  border-top-color: rgb(0,174,239);
}

.main-dashboard .dashboard-row-limity > div:nth-child(3n+0) > div {
  border-top-color: rgb(141,198,63);
}

.main-dashboard .dashboard-row-limity > div:nth-child(3n+1) > div {
  border-top-color: rgb(237,28,36);
}

.main-dashboard .dashboard-row-limity > div:nth-child(3n+2) > div {
  border-top-color: rgb(0,174,239);
}

.main-dashboard .dashboard-row-overlap > div:nth-child(3n+0) > div {
  border-top-color: rgb(141,198,63);
}

.main-dashboard .dashboard-row-overlap > div:nth-child(3n+1) > div {
  border-top-color: rgb(237,28,36);
}

.main-dashboard .dashboard-row-overlap > div:nth-child(3n+2) > div {
  border-top-color: rgb(0,174,239);
}


.dashboard-row .radial-bar {
  width: 180px;
  height: 180px;
  line-height: 180px !important;
  z-index: 2;
}

.dashboard-row .radial-bar::after, .dashboard-row .radial-bar > img {
  width: 140px;
  height: 140px;
  margin-left: 20px;
  margin-top: 20px;
  line-height: 140px;
  z-index: 1;
}

.radial-image-chart.radial-bar::after {
  display: none;
}

.main-dashboard .dashboard-row:nth-child(2) .card-block-big {
  padding-bottom: 27px !important;
}

.dashboard-row:nth-child(2) .card-block-big {
  height: 300px !important;
}

.dashboard-row .card-block-big h5 {
  margin-bottom: 20px;
  text-transform: none;
  font-weight: bold;
  font-family: sans-serif;
}



.card-block-big > .radial-image-wrapper {
  margin-left: auto;
  margin-right: auto;
}

@-webkit-keyframes graph-appear {
  0% {opacity: 0;}
  99% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes graph-appear {
  0% {opacity: 0;}
  99% {opacity: 0;}
  100% {opacity: 1;}
}
/* make the first row smaller? */
.main-dashboard .dashboard-row:nth-child(1) {
  /* padding-left: 30px;
  padding-right: 30px; */
}

.main-dashboard .dashboard-row .echarts-for-react {
  width: 100%;
}

.main-dashboard .download-timeline{
  background-color: #AFD47B;
  border-radius: 10px;
  /* height:1vh; */
  height: 4vh;
}
.main-dashboard .download-timeline:hover {
  transform:scale(1.05,1.05);
  -webkit-transform:scale(1.05,1.05);
  -moz-transform:scale(1.05,1.05);
}
.main-dashboard .download-timeline:active {
  transform:scale(.9,.9);
  -webkit-transform:scale(.9,.9);
  -moz-transform:scale(.9,.9);
}
.main-dashboard .download-timeline-clicked{
  background-color:grey;
}



.filter-drop-container {
  height: 100%;
}

.filter-drop-container > li {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px !important;
}

.filter-drop-container select {
  color: black !important;
}

/*=====================================================================
  Style the TopDriverWidget and its subcomponents
=====================================================================*/

.top-driver-widget {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width:80%;  
}
/* Allow for more left margin on the smaller screens */
@media (max-width: 1500px) {
  .top-driver-widget {
    margin-left: 10%;
  }
}

.top-driver-widget > div {
  margin: 0 5px;
  margin-top : 2vh;
}

.top-driver-widget h5 {
  font-size: 0.7em;
}

.top-driver-widget .radial-image-wrapper {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top-driver-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dashboard-row .card-block-big h5 {
  text-align: center;
  width: 100%;
}

.top-driver-wrapper, .radial-chart-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.top-driver-wrapper {
  margin-top: -32px;
}

.infraction-prop-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: -32px;
}
/* not needed on 3rd row? */
.top-driver-widget {
  margin-top: 0px;
}

/* fix height of wrapper around top driver widget */
.main-dashboard .dashboard-row:nth-child(2) > :nth-child(2) > div {
  height: 304px !important;
}

.radial-chart-inner {
  position: relative;
  z-index: 0;
}

.radial-chart-inner > * {
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 1;
  font-weight: bold;
}

.radial-chart-inner > * > img {
  vertical-align: top !important;
}

.top-driver-widget {
  word-wrap: normal;
}

.radial-chart-inner .text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  text-shadow: 1px 1px 2px black;
  text-align: center;
  width: 100%;
  font-weight: normal;
}

.top-driver-widget h5 {
  width: 120% !important;
  margin: 0 0 5px -8% !important;
}

/*=====================================================================
  Style the table used for displaying lists of videos
=====================================================================*/

/* hide the icons that come with the library, they're not working correctly */
.video-lister th.sortable span.order, .video-lister th.sortable span.react-bootstrap-table-sort-order {
  display: none;
}

.video-lister th.sortable {
  position: relative;
  padding-right: 24px;
}

.video-lister th.sortable::after {
  content: '\F0DC';
  font-family: 'Font Awesome 5 Free';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  margin-left: 0.6em;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}

.video-lister th.sorted-asc::after {
  content: '\F0DE';
}

.video-lister th.sorted-desc::after {
  content: '\F0DD';
}

.video-lister {
  font-size: 14px;
  margin-top: -4em;
}

.video-lister th {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.analytics-view button {
  margin-top: 2px;
}

/*=====================================================================
  Style the modal used to display the 'Network Delay' message from ApiCaller
=====================================================================*/
.ReactModal__Content--after-open {
  width: 400px;
  height: 300px;
  margin: 250px auto;
}

/* Make sure that the 'Network Delay' modal isn't overlapped */
.ReactModalPortal {
  z-index: 30000;
  position: relative;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-footer .spinner-wrapper {
  height: 40px;
  padding-top: 20px;
}

.modal-header {
  flex-direction: column;
}

.api-caller-message {
  text-align: center;
}

/* Workaround to prevent Google Charts toolip flickering
* https://github.com/google/google-visualization-issues/issues/2162
*/
svg > g > g:last-child { pointer-events: none }

.top-driver-widget .hovering {
  box-shadow: 0 2px rgb(0,174,239)
}

/* Change cursor to indicate that a radial image chart is clickable */
.radial-image-wrapper.clickable {
  cursor: pointer;
}

/* Style the tooltip for the top driver widget */
.top-driver-widget .mytooltip .tooltip-content {
  width: 180px;
  margin: 0 0 2px -90px;
  text-align: center;
}

/* Style the table filters in the AnalyticsView */
.table-filters {
  display: flex;
  float: right;
  width: 50%;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 7px;
  margin-right: -5px;
}

.table-filter {
  margin: 5px;
}

/******
* Styling for fitting in the CloudDETECT logo
*/

/* move the client menu down to the menu bar */
.client-menu {
  position: absolute;
  top: 55px;
  right: 0;
}

/* make space for the client menu in the menu bar */
.main-menu-bar {
  padding-right: 140px;
}

.main-dashboard .filter-dropdown-container {
  position: absolute;
  top: 167px;
  /*move to the left to align with the logout down arrow*/
  /* right: 1.5rem;  */
  /* font-size: 0.7em; */
}

.filter-dropdown-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.filter-dropdown-container ul {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
  align-items: center !important;

}

/*=====================================================================
  Style logo bar CloudDETECT logo
=====================================================================*/
.navbar-logo {
  /* pull the main EDGE3 logo left a bit to match the CloudDETECT logo*/
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-left:-1.4vw; 
}
.navbar-cloud-logo {
  float: right !important;
  margin: 15px 20px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width:10%;
  height:auto;
}

.top-driver-wrapper > h5 {
  margin-top: 6px;
}

.no-map-message {
  position: relative;
  font-size: 34px;
  font-style: italic;
}
.versiontext{
  position:absolute;
  margin-top: 50%;
  margin-right:5%;
  height: 0px;
  width:  38%;
  position: fixed;
  bottom: 5%;
  z-index: 100;
  left: 0;
}
/*=====================================================================
  Style the version popup notification
=====================================================================*/

.version-notify-content{
  display: grid;
  grid-template-columns: 80% 20%;
  position: absolute;
  top: 50%; 
  width:100%;
  transform: translate(0%,-50%);
}
.version-notify-content2{
  position: absolute;
  top: 50%; 
  width:100%;
  transform: translate(0%,-50%);  
  font-size: 2.5vh;
}
.version-notify-text{
  grid-column: 1;
  margin-left:auto;
  margin-right:auto;
  text-align: center;
}
.version-notify-button{
  grid-column: 2;  
  margin-left:auto;
  margin-right:auto;
  margin-top:auto;
  margin-bottom:auto;
}

/* =========================
Style the dashboard filter bar:
===========================
 */
 /* .dashboard-filters > * {
  background: #e9e9e9;
} */

.dashboard-filters{
  
  margin-left:auto;
  /* margin-right: 3.75vw; */
  margin-right: 3.15vw;

  border-radius: 4px;
  padding: 7px 0 7px 10px;
  font-size: 11px;
   
  height: 5vh;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: 0.5vw; */
  grid-column: 3;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
}

.dashboard-filters :nth-child(2), .dashboard-filters :nth-child(3) {
  padding: 0;
}


.dashboard-filters .filter-dropdown-container{
  position: unset;
}


.dashboard-filters .date-pick {
  margin-right: 8px;
  border: 1px solid #bdc3c7;
  border-radius: 2px;
}

.dashboard-filters .label-wrap {
  padding-right: 2px !important;
}

.dashboard-filters .nav-item {
  max-width: 180px;
}


.dashboard-filters .date-pick {
  font-size: 11px;
  height: 27px !important;
  width: 90px !important;
}



.dashboard-filters select {
  /* Styling for the specific select box */
  height: 27px !important;
  font-size: 11px;
  /* max-width :8em !important; */
  /* Remove the default filtering of the select dropdown */
  -webkit-appearance: none;
  -moz-appearance: none;
   appearance: none; 
  /* Add some styling */
  display: block;
  font-family: sans-serif;
  color: #444;
  line-height: 1.3;
  padding: .25em 1.4em .25em .8em;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
      for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
  */
  /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right .4em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 1.25em auto, 100%;
}

.dashboard-filters .react-datepicker__close-icon {
  right: 11px;
}

.dashboard-filters-download{
  
  margin-left:auto;
  margin-right: 3.65vw;

  border-radius: 4px;
  padding: 7px 0 7px 10px;
  font-size: 11px;
   
  height: 5vh;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: 0.5vw; */
  grid-column: 3;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
}

.dashboard-filters-download :nth-child(2), .dashboard-filters-download :nth-child(3) {
  padding: 0;
}


.dashboard-filters-download .filter-dropdown-container{
  position: unset;
}


.dashboard-filters-download .date-pick {
  margin-right: 8px;
  border: 1px solid #bdc3c7;
  border-radius: 2px;
}

.dashboard-filters-download .label-wrap {
  padding-right: 2px !important;
}

.dashboard-filters-download .nav-item {
  max-width: 180px;
}


.dashboard-filters-download .date-pick {
  font-size: 11px;
  height: 27px !important;
  width: 90px !important;
}



.dashboard-filters-download select {
  /* Styling for the specific select box */
  height: 27px !important;
  font-size: 11px;
  /* max-width :8em !important; */
  /* Remove the default filtering of the select dropdown */
  -webkit-appearance: none;
  -moz-appearance: none;
   appearance: none; 
  /* Add some styling */
  display: block;
  font-family: sans-serif;
  color: #444;
  line-height: 1.3;
  padding: .25em 1.4em .25em .8em;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
      for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
  */
  /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right .4em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 1.25em auto, 100%;
}

.dashboard-filters-download .react-datepicker__close-icon {
  right: 11px;
}

.dashboard-filters-2ndrow{
  
  margin-left: auto;
  margin-right: 3.15vw;
  
  border-radius: 4px;
  padding: 7px 0 7px 10px;
  font-size: 11px;
   
  height: 5vh;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: 0.5vw; */
  grid-column: 3;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
}

.dashboard-filters-2ndrow :nth-child(2), .dashboard-filters-2ndrow :nth-child(3) {
  padding: 0;
}


.dashboard-filters-2ndrow .filter-dropdown-container{
  position: unset;
}


.dashboard-filters-2ndrow .date-pick {
  margin-right: 8px;
  border: 1px solid #bdc3c7;
  border-radius: 2px;
}

.dashboard-filters-2ndrow .label-wrap {
  padding-right: 2px !important;
}

.dashboard-filters-2ndrow .nav-item {
  max-width: 180px;
}


.dashboard-filters-2ndrow .date-pick {
  font-size: 11px;
  height: 27px !important;
  width: 90px !important;
}



.dashboard-filters-2ndrow select {
  /* Styling for the specific select box */
  height: 27px !important;
  font-size: 11px;
  /* max-width :8em !important; */
  /* Remove the default filtering of the select dropdown */
  -webkit-appearance: none;
  -moz-appearance: none;
   appearance: none; 
  /* Add some styling */
  display: block;
  font-family: sans-serif;
  color: #444;
  line-height: 1.3;
  padding: .25em 1.8em .25em .8em;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
      for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
  */
  /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right .4em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 1.25em auto, 100%;
}

.dashboard-filters-2ndrow .react-datepicker__close-icon {
  right: 11px;
}

/* Override the bootstrap styling and force all buttons to have the same font as the container */
.video-chooser .btn{
    font-family: inherit; 
}


.apply-filters{
    border-radius: 15px;
    /* width: 80px; */
    width: min(80px,5vw);
    margin-right: 25px;
    background-color: #AFD47B;
}


.apply-filters:hover:enabled{
    transform:scale(1.05);
    
}
.apply-filters:active:enabled{
    transform:scale(0.9); 
}


/*******************************************************************
    General styling applied to the navigation tabs
********************************************************************/
.nav{
    /* justify-content: space-between;      */
    flex-wrap: unset;
}
.nav-link{
    min-width: 33%;
    padding: .5rem;
    overflow-wrap: break-word;
}


/*Retina style displays: */
@media (-webkit-min-device-pixel-ratio: 2) and (max-width:1800px), (min-resolution: 2dppx) and (max-width:1800px) { }


.dashcard {
    text-align: center !important
}
/* Update the bootstrap default min resolution, as long as the resolution is greter than this
   Allow the xol-x1-4 to not take over the full width of the row  */
@media (min-width: 1100px){
    .col-xl-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

.analytics-toprow{
    display: flex;
    flex-direction: row;
}

.analytics-filters > * {
    background: #e9e9e9;
}

.analytics-filters {
    background: #e9e9e9;
    border-radius: 4px;
    padding: 7px 0 7px 10px;
    font-size: 14px;
    position: relative;
    /* right: 10px; */
    /* top: -10px; */
    bottom: 10px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.analytics-filters :nth-child(2), .analytics-filters :nth-child(3) {
    padding: 0;
}

.analytics-filters .date-pick {
    margin-right: 8px;
    border: 1px solid #bdc3c7;
    border-radius: 2px;
}
/*=====================================================================
  Style the Video Reviewer
=====================================================================*/

.highlight-view .video-reviewer {
  margin-top: -4.5em;
}

.video-reviewer-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: start; */
  /* margin-top: 4em; */
  margin-top: -0.5em;
}

.video-reviewer-bottom > * {
  flex-grow: 2;
  max-width: 88%;
  margin: 1em;
  margin-left: 3%;
  /* margin-left: 7%; */
  
}


.video-reviewer-top {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
  align-items: start;

  display: grid;
  grid-template-columns: 24vw 55vw 14vw 1vw ;
  
  /* margin-top: 2em; */
  max-width: 100%;
  /* margin-left: 1%; */
}

.video-reviewer-top > * {
  flex-grow: 2;
  /* max-width: 50%; */
  margin: 1em;
}

.video-chooser-group {
  display: flex;
  flex-direction: row;  
  justify-content: flex-start;   
}

.video-chooser-tabs {
  width: 10px;
  font-size: 30px;
}

.video-chooser {
  display: flex;
  flex-direction: column;
  /* min-width: 23%;
  max-width: 23%; */
}

.video-chooser button {
   width: 100%; 
  margin-bottom: 3px;
  border-radius: 6px;
}

.video-chooser .tab-content>.tab-pane {
  /* overflow: auto;  
  height: 50vh; */
} 

/* ::-webkit-scrollbar-track {
  background: #f10000;
} */

/* @keyframes is-loading-animate {
  0% { transform: scaleX(1.02) }
  100% { transform: scaleX(.98) }
} */
@-webkit-keyframes is-loading-animate {
  0% { background-color: whitesmoke }
  100% { background-color: #AFD47B }
}
@keyframes is-loading-animate {
  0% { background-color: whitesmoke }
  100% { background-color: #AFD47B }
}




.video-chooser .is-loading{
  /* background-color: indianred; */
  /* animation-name: is-loading-animate;
  animation-duration: 1s ease infinite alternate; */
  -webkit-animation: is-loading-animate 0.5s ease infinite alternate;
          animation: is-loading-animate 0.5s ease infinite alternate;
}

.video-reviewer-players {
   /* max-width: 53%;      */
  /* width: fit-content;  */
  margin-left:auto;
  margin-right:auto;
  width:100%;
}



.video-card.video-card-placeholder {
  background: white !important;
  border: 1px dashed #ccc !important;
  box-shadow: none !important;
}

.video-card.video-card-placeholder > * {
  display: none;
}

.video-card.video-card-placeholder .video-card-tab-wrap {
  display: block;
  background: white !important;
  border: 1px dashed #ccc !important;
  color: white;
  box-shadow: none;
}

.video-card.video-card-placeholder .video-card-tab {
  background: white !important;
  border-left: 10px solid white !important;
}

.video-card-deck .ReactVirtualized__Grid__innerScrollContainer {
  height: 100% !important;
  max-height: 100% !important;
}

/* Remove blue outline/border around the list on Chrome when it is active */
.video-card-deck .ReactVirtualized__Grid, .ReactVirtualized__List {
  border: none !important;
  outline: none !important;
}

/*
Configure the notecard list on the side:
*/
.video-reviewer-top .review-card-list .ReactVirtualized__List {
  border: none !important;
  outline: none !important;
  /*unset the min width of the cards so that they fit in the space on the page*/
  width:unset !important; 
}

.video-reviewer-top .review-card-list{
  width:unset !important;
}


.video-reviewby{
  margin-top: -1.5em;
  margin-bottom: 1.5em; 
  text-align: left;
}

.archived-filename{
  margin-bottom: 4.0em;
  margin-top: -4.0em; 
  text-align: center;
}
/*******************************************************************
Style the small review card:
********************************************************************/


.review-card {
    position: relative;
    box-sizing: border-box;

    margin: 0;
    padding:  clamp(2px,4.5%,8px) clamp(2px,3.5%,6px);

    border-radius: 4px;
    background: #fff;
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.08);
    
    overflow: hidden;
    cursor: default;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

    /* height: 80px; */
    height: 8vh;

    
    width:auto;

    display: grid;
    grid-template-rows: 15% 72%;    
    grid-row-gap: 8%;    
    row-gap: 8%;    
    font-size: .6vw;
}
.review-card .name, .review-card .notes, .review-card .type, .review-card .workflow {   text-align: left;}
.review-card .name, .review-card .notes, .review-card .type, .review-card .date, .review-card .workflow {
    height: 13px;
    line-height: 11px;
}

.callin-lister .review-card{
    height: unset;
    font-size: clamp(6px,0.6vw,9px);
}

/* .review-card-list .review-card{
    width:auto;
} */

.card-list-card-wrap {
    height: unset;
    /* background-color: red; */
}



/*******************************************************************
Title section: Severity , Alerts, Date, and Options Hamburger
********************************************************************/
/* Define the section to hold the elements */
.review-card .ncard-title{    
    /* position: relative;     */
    position: absolute;
    top:0px;
    left:2%;

    box-sizing: border-box;

    width: 96%;
    height: auto;

    margin-bottom: 1.5%;
    grid-template-columns: 25% 14% 13% 13% 35%;    
    /* background-color: red; */
    grid-row:1;
}

/* Update the styling of the severity bar for the review-card */
.review-card .card-severity {
    width: 100%;
    height: clamp(3px, 70%,7px);
    border-radius: 3px;    
    margin-bottom: 4px;
    
}


.review-card .audioalert{ 
    position: absolute; /*Allow this to overflow the height of the title bar, but stay relative in position and size*/
    aspect-ratio: 17/19;
    height: auto;
    display: inherit;
    width: clamp(10px,15%,30px); 
}
.review-card .audioalert > img{ 
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: translateY(-25%); 
}

/* This is for the small card*/
.review-card .vibrationalert{ 
    position: absolute;
    aspect-ratio: 100/97;
    height: auto;    
    width: clamp(10px,25%,30px); 
}
.review-card .vibrationalert > img{ 
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: translateY(-25%); 
}





/* Date hambuger pair */
.review-card .date {
    position: relative;
    width: 80%;   
    white-space: nowrap;
    padding-right: 10%;
    font-size:1em;
    text-align: right;
}

.review-card .hamburger{
    position: relative;
    
    width: clamp(3px,1em,10px);     
    height: clamp(3px,1em,10px);     
    /* height: 1em; */
    aspect-ratio: 1 / 1;    
    /* background-color: red; */
}
.review-card .hamburger > img{
    position: absolute;
    width: 100%; 
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: contain;
}


/*******************************************************************
Upper->Basics Section: Information block: DriverID, Infraction type, Workflow, Notes
********************************************************************/

.review-card .card-upper{ 
    position: absolute;
    top:0px; left:2%;
    width:96%;
    height:96%;
    grid-row:2;
}


/* Enable a larger information section on the review card */
.review-card .card-basics {
    margin-top:2%;
    width: 70%;
    /* border: 1px solid black; */
}


/* Apply to all children of the card-basics*/
 .review-card .card-basics > *{
    background-color: transparent;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-y: hidden;

    
    height:unset;
    line-height: 100%;

}


/* .review-card .notes {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 35px;
} */
/*******************************************************************
Upper->Card-Date-Avatar Section: Thumbnail of the video clip
********************************************************************/
.review-card .card-date-avatar { 
    /* background-color: red; */
    width: clamp(20px,25%,35px);
    margin-top: auto;
    margin-bottom: 2%;
    
    /* padding: clamp(2px,5%,6px); */
}

.review-card .avatar {
    padding-top:5%; 
    aspect-ratio: auto 1/1;
    border-radius: 5px;
    object-fit: fill;
    width:100%;
    height:auto;
}






/*******************************************************************
Style Card and CardList
********************************************************************/

#root.reporting {
    background: url(/static/media/LightBluePaper.304f502f.jpg) no-repeat center center fixed;
    background-size: cover;
}

#root.reporting .amplify-container, #root.reporting .pcoded-main-container, #root.reporting #pcoded, #root.reporting .pcoded-container {
    background: transparent;
}

.review-card-list {
    background: #e9e9e9;
    padding: 0 4px 28px 7px;
    border-radius: 4px;
    margin-right: 10px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width:16%;
}

.review-card-list.can-drop {
    box-shadow: 0 0 2px red;
}

.review-card-list.is-over {
    box-shadow: 0 0 2px blue;
}

.review-card-list:last-child {
    margin-right: 0;
}

.review-card-list .review-card-list-title {
    /* font-size: 13px; */
    font-size: 0.75vw;
    font-weight: 600;
    text-align: left;
    padding-left: 7px;
    padding-bottom: 3px;
    padding-top: 3px;
}

.review-card-list *::-webkit-scrollbar {
    width: 10px;
    background: #f0f0f0;
    border-radius: 6px;
}

.review-card-list *::-webkit-scrollbar-thumb {
    width: 10px;
    background: #cdcdcd;
    border-radius: 5px;
}

.review-card-list *::-webkit-scrollbar-button {
    display: none;
}


/* Remove blue outline/border around the list on Chrome when it is active */
.review-card-list .ReactVirtualized__Grid, .ReactVirtualized__List {
    width:100% !important;
}


/* .review-card .hamburger, .expanded-card .hamburger {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 6px;
    right: 8px;
} */


/* This is for the small card*/
/* .review-card .audioalert{ 
    height: 2vh;
    position: absolute;
    left: 55%;
    margin-left:-7px;
    top: 0px;
} */





.audioalert{
    height: 30px;
    margin-left:auto;
    margin-right:auto;
    grid-column: 3;
}
.vibrationalert{
    height: 30px;
    margin-left:auto;
    margin-right:auto;
    grid-column: 4;
}

.audioalert > img {
    max-width: 100%;
    max-height: 100%;
}
.vibrationalert > img {
    max-width: 100%;
    max-height: 100%;
}



.review-card-placeholder * {
    display: none;
}

.review-card-placeholder {
    border: 1px dashed #aaa;
    background: transparent;
    position: relative;
    box-shadow: none;
}

.review-card-placeholder::after {
    content: 'Drag card here';
    position: absolute;
    top: 50%;
    left: 50%;
    color: #aaa;
    display: block !important;
    font-size: 14px;
    transform: translate(-50%, -50%);
}

.review-flow-restricted * {
    display: none;
}

.review-flow-restricted {
    border: 1px dashed #aaa;
    background: transparent;
    position: relative;
    box-shadow: none;
}
.review-flow-restricted::after {
    content: 'Access Denied';
    position: absolute;
    top: 50%;
    left: 50%;
    color: #aaa;
    display: block !important;
    font-size: 14px;
    transform: translate(-50%, -50%);
}


.highlight-view .review-card-placeholder::after {
    content: '';
}

.pulse {
    -webkit-animation: pulse-animation 2s infinite;
            animation: pulse-animation 2s infinite;
}
@-webkit-keyframes pulse-animation {
	0% {
		transform: scale(0.95);
		/* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); */
	}
	70% {
		transform: scale(1.2);
		/* box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); */
	}
	100% {
		transform: scale(0.95);
		/* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
	}
}
@keyframes pulse-animation {
	0% {
		transform: scale(0.95);
		/* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); */
	}
	70% {
		transform: scale(1.2);
		/* box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); */
	}
	100% {
		transform: scale(0.95);
		/* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
	}
}

   /* padding: 0 4px 7px 0; */
   /* padding: 0 .52vw .5vh 0; */
.card-list-card-wrap {
    padding: 0 .52vw .5vh 0; 
    overflow: hidden;    
}

.ncard-title{    
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 5% 25% 5% 5% 60% ;
    align-content: center;
    width: 100%;
    height: 10px;
    min-height: 10px;
}

.date-ham-pair{    
    position: relative;
    display: flex;
    flex-direction: row;
    /* height: 1000px; */
    justify-content: space-between;   
    /* justify-content: space-around; */
    align-content: center;
    margin-left:auto;
    grid-column: 5;
    /* margin: 1em; */
}

.ncard-title .videoName{
    /* height: 13px;
    line-height: 11px; */
    line-height: 1em;
    font-size: 13px;
}


.card-severity {
    text-align: left;
    width: 40px;
    height: clamp(3px, 12%,7px);;
    border-radius: 3px;
    background-color: #d3d3d3; /* default background color if a severity is not set
                                    (should never happen)
                                  */
    margin-bottom: 4px;
    grid-column: 1;
}

.card-severity-HIGH {
    background-color: #fe7353;
}

.card-severity-MEDIUM {
    background-color: #ffbf35;
}

.card-severity-LOW {
    background-color: #fdde00;
}

.card-severity-IRRELEVANT {
    background-color: #d3d3d3;
}

.card-severity-GOOD {
    background-color: #61BD7D;
}

.review-card-list.no-scroll .ReactVirtualized__Grid__innerScrollContainer {
    overflow-y: scroll !important;
    max-width: 250px !important;
}
.review-card-list .ReactVirtualized__Grid__innerScrollContainer {
    max-width: 250px !important;
}



/*******************************************************************
Style the Video Card
********************************************************************/

.infraction-number:before {
    content: 'Infraction ';
}


.card-date-avatar .notecard-video{
    width: 400px;
    height: 285px;
    object-fit: fill;
} 

.phoneCall{
    /* max-height: 10vh; */
    height: 35%;
    /* width: 100%; */
    /* margin-top:5%; */
     margin-top:-12%; 
     margin-left:85%; 
    
}
.phoneCall .phone-controls{
    display: flex;
    flex-direction: row;
    justify-content: space-around;     
}
/* .phoneCall .phone-button{
    height: 3rem;
} */

.back-bottom{
    max-height: 51vh;
    overflow-y: auto;
}

.heartbeat-plot{
    height:55%;
    max-height: 7vh;
}
.heartbeat-card{
    font-size: 16px;
    /* width:50%; */
    margin-top:2%;
    margin-bottom: 3vh;
    height:40%;
    width: 60%;
    max-height: 10vh;
    
}

/* Format the top portion of the notecard - set up to hold two side by side regions */
.card-upper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.card-basics {    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


.dropdown-set{
    width:100%;
    /* border: 1px solid; */
    display: grid;
    grid-template-columns: 25% 75%; 
    grid-template-rows: auto;
}
.dropdown-set .infraction-dropdown-container{
    max-width:100%;
    /* display: grid;
    grid-template-columns: 25% 25% 50%; 
    grid-template-rows: auto; */
    /* display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center; */
}

/* Add the buttons for the footer: */
.notecard-footer .delete-button {
    margin-top: 8px;
}

.notecard-footer .irrelevant-button {
    margin-top: 8px;
    color: white;
    background: #B0B14D
}

.notecard-footer .cut-button {
    margin-top: 8px;
    color: white;
    /* background: #B0B14D */
    background-color: blueviolet;
}

.notecard-footer .gotohighlight-button {
    margin-top: 8px;
    color: white;
    background: #00AFED
}
.notecard-footer .gotohighlight-disable-button {
    margin-top: 8px;
    color: white;
    background: rgb(121, 122, 122)
}



.notecard-footer .download-button {
    margin-top: 8px;
    color: white;
    background: rgb(159, 212, 123)
}

.notecard-footer .downloaded-button {
    margin-top: 8px;
    color: white;
}

.notecard-footer .add-comment {
    margin-top: 8px;
}


.infraction-dropdown-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    
  }
   .infraction-dropdown-container .label-text {
    /* height: 13px;
    line-height: 11px; */
    font-size: 14px;
    padding-top: 2px;
    padding-right: 18px;
  } 
  
  .infraction-dropdown-container ul {
    
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-items: right !important;
    
}

/* Style the table filters in the AnalyticsView */
.infraction-display {
    /* margin: 5px;  */
    display: flex;
    flex-direction: row;
    padding: 5px;
    /* font-size: 15px; */
    border-radius: 5px;
    margin-top: 2px; 
    border: solid;
    border-width: thin;
    background-color: #fff;
    border-color: #3498DB;
    width: 190px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    
    /* color: #3498DB; */
}

.infraction-display div{
    /* margin: 5px;  */
    position: relative;
    /* font-size: 15px; */
    color:#3498DB;
    font-weight: bold;
    /* height:20px; */
    /* margin-top: 5px; */
}
.infraction-display .infractiontext{
    /* height:10px; */
    line-height: 1em;
    /* vertical-align: center; */
    margin-top: auto;
    margin-bottom: auto;
}

.infraction-display .remove{
    /* margin: 5px;  */
    position: relative;
    color: rgb(221, 48, 57);
    /* border-radius: 25px; */
    background: transparent;
    /* border-color: #fff; */
    border:none;
     /* float: right;  */
     /* margin-right: auto; */
    margin-left:auto; margin-right:0;
    /* background-color: rgb(221, 48, 57); */
    /* padding: 5px; */
    font-weight: bold;
}

.phone-button{
    background-image: linear-gradient(115deg, rgba(0,153,226,0.7), rgba(0,153,226,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .phone-button-disabled{
    background-color: rgba(175,175,175,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .phone-button:hover{
      background-image:unset;
      background-color:rgba(0,120,212,1);
  }

  .phoneicon{
    color:white;
    width: 30px; height: 30px;
    margin-top: 15%;
    margin-left:20%;
    /* margin:auto; */
    transform: rotate(20deg);
    
  }  
  


/*******************************************************************
Style Card and CardList
********************************************************************/


/*******************************************************************
Style the Video Card
********************************************************************/


.expanded-card-modal {
    height: auto;
    /* margin-right: 100px; */
    margin-right: 7%; 
    margin-top: 120px;
    
    min-width: 750px;
    max-width: 1000px; 
}

.expanded-card-modal .modal-content {
    border-radius: 4px;
    transform-origin: top right;
    /* width:850px; */
    /* height: 57vh; */
}

/* Override previous passed in with the Style from ExpandedCard render() if hwTags were to be shown */
.expanded-card-modal .modal-content[with_hw="display"]{
    width: 850px;
}

.expanded-card .gpsmap-empty {
    width : 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;    
}

.expanded-card .gpsmap {
    width : 100%;
    height: 50vh;
}

.expanded-card .gpsmap-spacer {
    margin-top: 10%;
}
.expanded-card .gpsmap-text {
    text-align: center;
    font-weight: 400;
    font-size: 1.5em;
}
.expanded-card .gpsmap-text2 {
    text-align: center;
    font-weight: 300;
    font-size: 1.1em;
}
.expanded-card .gpsmap-text3 {
    text-align: center;
    font-weight: 400;
    font-size: 2em;
}

.expanded-card .gpsmap .gpsrefresh {
     position: absolute;
    top: 50%;
    left: 40%; 
    width: 20%;
    height: 10%;
    font-size: 1.5em;
    border-radius: 15px;
    background-color: #AFD47B;
}


.expanded-card select {
    padding: 0;
    height: 27px !important;
}


.expanded-card .card-upper {
    margin-top: -.5em;
}
/* Balance the information section against the video clip on the expanded card*/
.expanded-card .card-basics {
    max-width: 50%;
}
.expanded-card .card-basics .card-basic-top{
    /* background-color: green; */
    height:40%;
}
.expanded-card .card-basics .card-basic-bottom {
    /* order: 1; */
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start; */
    width:100%;
    display: grid;
    grid-template-columns: 200px 200px; 
    grid-template-rows: auto;
    grid-column-gap: 1%;
    -webkit-column-gap: 1%;
            column-gap: 1%;
    /* border: 1px solid; */
}
.expanded-card .card-basics .card-basic-bottom .form-control {
    /* max-width: 75%; */
    width:140px;
    /* margin-right:auto; */
}
.dropdown-set{
    width:100%;
    /* border: 1px solid; */
    display: grid;
    grid-template-columns: 25% 75%; 
    grid-template-rows: auto;
}


.expanded-card .card-reviewer {
    /* order: 1; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 250px;
}

.expanded-card .card-date-avatar {
    position: relative;
    /* order: 2; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    /* max-width: 50%; */
}

.expanded-card .avatar {
    border-radius: 3px;
}


.expanded-card .expanded-card-player > div {
    width: auto !important;
    height: auto !important ;
    margin: 1em;
  }
.expanded-card-player ::-webkit-media-controls-overlay-play-button {
    opacity: 0.4;
}

.expanded-card-player ::-webkit-media-controls-panel {
    background: none;
} 


.expanded-card .hamburger-back {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 15px;
    margin-right: 15px;
    height: 11px;
    width: 11px;
}

.expanded-card .notecard-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-right: 5px;
}

.expanded-card .notecard-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.expanded-card .ncard-title .date-ham-pair .date {
    padding-right: 5px;
    padding-top: 0;
    line-height: 1em;
    height: 1em;
    margin-top: -1px; 
    font-size: 13px;
}

.expanded-card .ncard-title .date-ham-pair .hamburger {
    width: 11px;
    height: 11px;
}


/* ===========ENABLE HOVER EFFECTS================*/
.expanded-card .hamburger:hover{
    transform:scale(1.25,1.25); 
}
.expanded-card .hamburger-back:hover{
    transform:scale(1.25,1.25); 
}

.expanded-card  .btn:hover:enabled{
    transform:scale(1.05,1.05); 
}
.expanded-card .btn:disabled{
    opacity: .3;
}

.expanded-card .gotohighlight-button:disabled {
    margin-top: 8px;
    color: white;
    background: rgb(121, 122, 122)
}
.expanded-card .btn-danger[disabled]:hover{
    background-color: #dc3545;
    border-color: #dc3545;
    opacity: .3;
}
.expanded-card .btn[disabled]:hover{
    transform:scale(1,1);
}
/* 
===========END HOVER EFFECTS================
*/

/* Name styling */
.expanded-name{
    display: flex;
    flex-direction: row;
}
.expanded-name .name-text{
    display:flex;
    flex-direction: row;
    
    align-items: center;
}
.expanded-name .name-text .name-button{
    border-radius: 10%;
    border: 1px solid black;
    outline:none;
    height:1.5em;
    margin-left: 5%;
    line-height: 0px;
    background-color: #ed7669;
}

.expanded-name .name-text .name-button:hover{
    transform:scale(1.05,1.05);
    -webkit-transform:scale(1.05,1.05);
    -moz-transform:scale(1.05,1.05);
} 
.expanded-name .name-text .name-button:active{
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}
/*******************************************************************
Style Card and CardList
********************************************************************/



.comment-block {
    background: #f4f5f7;
    padding-bottom: 20px;
}

.comment{
    border-radius: 4px;
    padding-left: 8px;
    padding-top: 8px;
    margin: 8px 0;

    display: grid;
    grid-template-columns: 96% auto;
}




.expanded-card .comment pre {
    margin-bottom: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
}

.expanded-card .commenter {
    width: 100%;
}

.expanded-card .comment {
    
    margin: 8px 0;
    /* padding: 8px; */
    
    /* width: 96%;  */
    width:100%;
}





.expanded-card .comment .comment-block {
    background: #f4f5f7;
    padding-bottom: 20px;
}

.expanded-card .comments {
    overflow-y: auto;
    max-height: 250px;
    margin-bottom: 5px;    
}

.comment > pre {
    font-family: 'Open Sans';
}

.comment .edit-comment-button {  
    background-color: transparent;  
    border:none;
}

.commenter .adding-comment textarea {
    width: 100%;
    resize: none;
}

.expanded-card .comments-title {
    margin-top: 8px;
}

.comment-top{
    display: grid;
    grid-template-columns: 75% 25% ;
    grid-template-rows: auto;
    grid-gap: 5px 0px;
    gap: 5px 0px;
}
.comment-nametime{
    grid-column: 1;
    grid-row:auto;
}
.comment-timestamp {
    font-size: 0.8em;
    margin-top: -0.5em;
    left : 2%;
    display:inline-block;
}

.comment-delete{
    grid-column: 2;
    grid-row:auto;
    margin-left:auto;
    margin-top:-8px;
}
.deleteComment{
    color:red;
    font-size: 0.8em;
}

.comment-username {
    position: relative;
    font-size: 0.8em;
    margin-top: -0.5em;
    /* left: 10%; */
    display:inline-block;
}

.comment-timestamp pre {
    color: #555;
}


.editing-comment textarea {
    width: 100%;
    resize: none;
}

.comment .editing-cancel {  
    position: relative;
    left: 90%;    
    width: 5%;
    height: 20px; 
    background-color: rgb(167, 53, 53);
}
.comment .editing-confirm {  
    position: relative;
    left: 95%;    
    width: 5%;
    height: 20px; 
    background-color: #AFD47B;
    
}

.comment .buttonText {  
    font-size:10px;
    transform: translate(0%, -10%); 
}


/*******************************************************************
Style Card and CardList
********************************************************************/


.infraction-dropdown-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    
  }
   .infraction-dropdown-container .label-text {
    /* height: 13px;
    line-height: 11px; */
    font-size: 14px;
    padding-top: 2px;
    padding-right: 18px;
  } 
  
  .infraction-dropdown-container ul {
    
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-items: right !important;
    
}

/* Style the table filters in the AnalyticsView */
.infraction-display {
    /* margin: 5px;  */
    display: flex;
    flex-direction: row;
    padding: 5px;
    /* font-size: 15px; */
    border-radius: 5px;
    margin-top: 2px; 
    border: solid;
    border-width: thin;
    background-color: #fff;
    border-color: #3498DB;
    width: 190px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    
    /* color: #3498DB; */
}

.infraction-display div{
    /* margin: 5px;  */
    position: relative;
    /* font-size: 15px; */
    color:#3498DB;
    font-weight: bold;
    /* height:20px; */
    /* margin-top: 5px; */
}
.infraction-display .infractiontext{
    /* height:10px; */
    line-height: 1em;
    /* vertical-align: center; */
    margin-top: auto;
    margin-bottom: auto;
}

.infraction-display .remove{
    /* margin: 5px;  */
    position: relative;
    color: rgb(221, 48, 57);
    /* border-radius: 25px; */
    background: transparent;
    /* border-color: #fff; */
    border:none;
     /* float: right;  */
     /* margin-right: auto; */
    margin-left:auto; margin-right:0;
    /* background-color: rgb(221, 48, 57); */
    /* padding: 5px; */
    font-weight: bold;
}

.phone-button{
    background-image: linear-gradient(115deg, rgba(0,153,226,0.7), rgba(0,153,226,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
}
.phone-button-disabled{
  background-color: rgba(175,175,175,1);
  width:50px; height:50px;
  border-radius: 50%;
  margin:auto;
}
.phone-button:hover{
    background-image:unset;
    background-color:rgba(0,120,212,1);
}

.phoneicon{
  color:white;
  width: 30px; height: 30px;
  margin-top: 15%;
  margin-left:20%;
  /* margin:auto; */
  transform: rotate(20deg);
  
}

.notecard-back.card-upper{
  margin-right: 25%;
}
/* Make the loading animation smaller to fit in the DriverID location */
.name-text .spinner-wrapper.preloader3.loader-block{
  height:50%;
  transform: scale(75%) translateY(50%);
}

 

/* Handle adding an image inside of the button default configuration, can be overridden buy class css*/
button > img{    
    max-width: 100%; /* Force the image to assume the size of the container (button) */
    max-height: 100%; /* Force the image to assume the size of the container (button) */
}

button.statefulbutton:disabled{    
    color: white;
    opacity: .3;
}

.phonecall-modal {
    margin-top: 100px;
    height: auto ;
    width: 60vw;
    height: 55vh;
    max-width: 80vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
}

.phonecall-modal .phonecall-modal-content {
    transform-origin: top right;
    width: 100%;
    height:100%;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

}

@media screen and (max-width:1300px) { /*only apply if the screen width is less than*/
  .phonecall-modal .phonecall-modal-content {  height: 70vh;  }
  .phonecall-modal {   width: 75vw;  }
}

.phonecall-modal .pc-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10%;
}

.phonecall-modal .pc-footer .btn {
    width:10%;
}

.phonecall-modal-content .phone-top{
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;      */
    display: grid;
    grid-template-columns: 50% 50% ;
    grid-template-rows: auto;

    height: 70%;
    width: 98%;
    margin-left: auto;
    margin-right:auto;
}

.phonecall-modal-content .viewTabs{
    width:50%;
    height:10%;
    margin-left: auto;
    margin-right:auto;
    /* border: 1px solid; */
}
.phonecall-modal-content .viewSelection{
    height:100%;
    width:100%;
}
.phonecall-modal-content .nav-tabs{
    border-bottom: unset;
}
.phonecall-modal-content .nav{
    height:100%;
    justify-content: center;
}


.phone-title{
    display: grid;
    grid-template-columns: 30% 40% 30%;
    grid-template-rows: 35px;
    width: 98%;
    margin-left: auto;
    margin-right:auto;
    margin-bottom: 2%;
    margin-top: 1%;
}

.phone-title .titletext{
    font-size: 26px;
    text-align: center;
}

.phone-title .logo-e3 {
    max-height: 30px;    
    margin-left:auto;
    margin-top:auto;
    margin-bottom: auto;
}
.phone-title .logo-client {
    max-height: 70px;    
    margin-right:auto;
    margin-top:auto;
    margin-bottom: auto;
}

.phone-top .phone-section{
    /* background-color: bisque; */
    width: 100%;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */

  
      display: grid;
      /* grid-template-columns: 10% 20% 20% 20% 20% 10%;  */
      grid-template-columns: auto;  
      grid-template-rows: 46% 54%;

}
.phone-top .phone-section .content{
    height: 75%;
    /* width: 100%;     */
    margin-top:6%;
    display: grid;
    grid-template-columns: 30% 70%; 
    grid-template-rows: 20% 50% 25%;
    /* margin-left:10%;     */
}
.phone-top .phone-section .content .label-text{
    font-weight: bold;
    font-size:16px;
    width: unset;
    padding-right:10%;
}
.phone-top .phone-section .content .label-value{
    margin-right:auto;
    font-size:16px;
    word-break:break-all;
    text-align: left;
}

.phone-top .phone-section .content .callstate{
    grid-column:2;
    height:25%;
    width: 100%;
    display: flex;
    flex-direction:row;
     justify-content: space-evenly; 
    margin-top:auto;
    margin-bottom:auto;
     /* margin-left:10%;  */
     /* margin-left:auto;
     margin-right:auto; */
    /* border: 1px solid; */
    margin-left:-20%;
    
}
.phone-top .phone-section .content .stage{
    margin-top:2%;
    margin-left:-20%; 
}


.callstate .statelabel{
    margin-top:auto;
    margin-bottom:auto;
    /* background-color: red; */
}
.callstate .spinner-wrapper{
    max-height:100%;   
     /* margin-top:auto;
    margin-bottom:auto;  */
    margin-top:5%;
    margin-left:5%;
}


.phone-top .phone-section .controls{
    /* background-color: cadetblue; */
    height: 100%;

    display: grid;
    /* grid-template-columns: 10% 20% 20% 20% 20% 10%;  */
    grid-template-columns: 33% 33% 33% ;  
    grid-template-rows: 50% 50%;
    
    width: 50%;  
    margin-left:3%;  
}

  .startcallbutton{
    background-image: linear-gradient(115deg, rgba(0,153,226,0.7), rgba(0,153,226,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .startcallbutton-disabled{
    background-color: rgba(175,175,175,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .startcallbutton:hover{
      background-image:unset;
      background-color:rgba(0,120,212,1);
  }

  .startcallicon{
    color:white;
    margin-top: 20%;
    width: 30px; height: 30px;
    transform: rotate(20deg);
  }
  .mutebutton{
    /* background-color:rgba(0,153,226,1); */
    background-image: linear-gradient(115deg, rgba(0,153,226,0.7), rgba(0,153,226,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .mutebutton-disabled{
    background-color: rgba(175,175,175,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .mutebutton:hover{
    background-image:unset;
    background-color:rgba(0,120,212,1);
  }
  
  .muteicon{
    color:white;
    margin-top: 20%;
    width: 30px; height: 30px;
  }
  .unmutebutton{
    background-color:rgba(232,231,236,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .unmuteicon{
    /* color:black; */
    margin-top: 20%;
    width: 30px; height: 30px;
  }
  .endcallbutton{
    /* background-color:rgba(244,67,54,1); */
    background-image: linear-gradient(115deg, rgba(244,67,54,0.7), rgba(244,67,54,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .endcallbutton-disabled{
    background-color: rgba(175,175,175,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
  }
  .endcallbutton:hover{
    background-image:unset;
    background-color:rgba(220,28,12,1);
  }
  .endcallicon{
    color:white;
    margin-top: 25%;
    width: 30px;
    height: 30px;
    transform: rotate(135deg);
  }
  .noanswerbutton{
    /* background-color:rgba(244,67,54,1); */
    background-image: linear-gradient(115deg, rgba(244,67,54,0.7), rgba(244,67,54,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
    position: relative;
  }
  .noanswerbutton-disabled{
    background-color: rgba(175,175,175,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
    position: relative;
  }
  .noanswerbutton:hover{
    background-image:unset;
    background-color:rgba(220,28,12,1);
  }
  .noanswericon{
    position: absolute;
    color:white;
    margin-top: 25%;
    margin-left:-28%;
    width: 30px;
    height: 30px;
    transform: rotate(135deg);
  }
  .noanswerx{
      position:absolute;
      width:50px;
      height:50px; 
      font-size:40px;
      color:white;
      font-weight: 100;
      margin-top:-10%;
      transform: rotate(-20deg);
  }
  .noanswerx-text{
     position: absolute;
  }

  .hungupbutton{
    /* background-color:rgba(244,67,54,1); */
    background-image: linear-gradient(115deg, rgba(244,67,54,0.7), rgba(244,67,54,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
    position: relative;
  }
  .hungupbutton-disabled{
    background-color: rgba(175,175,175,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
    position: relative;
  }
  .hungupbutton:hover{
    background-image:unset;
    background-color:rgba(220,28,12,1);
  }
  .hungupicon{
    position: absolute;
    color:white;
    margin-top: 25%;
    margin-left:-28%;
    width: 30px;
    height: 30px;
    transform: rotate(135deg);
  }
  .hungupx{
      position:absolute;
      width:50px;
      height:50px; 
      font-size:40px;
      color:white;
      font-weight: 100;
      margin-top:-10%;
      /* transform: rotate(-20deg); */
  }
  .hungupx-text{
     position: absolute;
  }
  
  .callissuebutton{
    /* background-color:rgba(244,67,54,1); */
    background-image: linear-gradient(115deg, rgba(244,67,54,0.7), rgba(244,67,54,1));
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
    position: relative;
  }
  .callissuebutton-disabled{
    background-color: rgba(175,175,175,1);
    width:50px; height:50px;
    border-radius: 50%;
    margin:auto;
    position: relative;
  }
  .callissuebutton:hover{
    background-image:unset;
    background-color:rgba(220,28,12,1);
  }
  .callissueicon{
    position: absolute;
    color:white;
    margin-top: 18%;
    margin-left:-29%;
    width: 30px;
    height: 30px;
    /* transform: rotate(135deg); */
  }  

.phone-top .phone-section .controls .startcall{
    /* grid-column: 2; */
    height: 98%;
    margin-top: 2%;
     /* background-color: cadetblue;  */
}
.phone-top .phone-section .controls .under-label{
    font-weight: bold;
    text-align: center;
}
.phone-top .phone-section .controls .startcall > img{
    height: 55%;
}
.phone-top .phone-section .controls .mute > img{
    height: 55%;
}
.phone-top .phone-section .controls .hangup > img{
    height: 55%;
}

.phone-top .phone-section .controls .mute{
    /* grid-column: 3; */
    height: 98%;
    margin-top: 2%;
}

.phone-top .phone-section .controls .hangup{
    /* grid-column: 4; */
    height: 98%;
    margin-top: 2%;
}


.phone-top .details{
    width: 100%;
    /* border: 1px solid; */
}
.details-main{
    height: 100%;
}
.details-offline{
    height: 100%;
    
}

/* Add a vertical scroll bar to the table */
.offline-assets tbody {
    display:block;
    height:250px;
    overflow-y:scroll;
}
.offline-assets thead{
    display:table;
    width:100%;
    table-layout:fixed;
}
.offline-assets tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
.offline-assets tbody tr td:nth-child(1) {
    /* background-color: red; */
    width:28%;
}
.offline-assets .status-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
/* .offline-assets tbody :nth-child(2) {
 
    width:100%;
} */
/* END - Add a vertical scroll bar to the table */



.phone-top .detail{
    display: flex;
    flex-direction: row;
    height: 15%;
    /* margin-bottom: 1%; */
}
.phone-top .label-text{
    width: 20%;
    text-align: right;
    padding-right: 2%;
    font-weight: bold;
    /* border: 1px solid; */
}
.phone-top .dropwrapper{
    width: 70%;
    /* border: 1px solid; */
}
.phone-top .dropwrapper-sxs{
    width: 70%;
    display: grid;
    grid-template-columns: 49.5% 49.5%; 
    grid-template-rows: 32px;
    grid-column-gap: 1%;
    -webkit-column-gap: 1%;
            column-gap: 1%;
}

/* .phone-top .dropwrapper .rw-multiselect-taglist{
    max-height: 125px;    
} */


.phone-top .detail-wide{
    display: flex;
    flex-direction: row;
    /* height: 30%; */
    height:35%;
    margin-bottom: 1%;
    /* border: 1px solid; */
}
/* .phone-top .multiselect-wide{
    height:5.5em;
} */



.phone-top .contactgroup{
    display: flex;
    flex-direction: row;
    background-color: azure;
}
.phone-top .details .infraction-dropdown-container{
    width:100%;
    margin-top: 5%;
}
.phone-top .details .form-control {
    width: 15vw; 
}



.phone-top .scrollable-list{
    width: 80%; 
    display: grid;
    /* grid-template-columns: repeat(2,1fr); */
    grid-template-columns: 49.5% 49.5%; 
    grid-template-rows: 32px;
    grid-column-gap: 1%;
    -webkit-column-gap: 1%;
            column-gap: 1%;
    /* border: 1px solid; */
}

.subject-display {

    display:inline-flex;
    flex-direction: row; 

    padding: 5px;
    height: 30px;
    margin-top: 2px; 

    border-radius: 5px;
    border: solid;
    border-width: thin;
    background-color: #fff;
    border-color: #3498DB;
}

.subject-display .infractiontext{
    margin-left:auto;
}

.subject-display div{
    position: relative;
    /* font-size: 80%; */
    color:#3498DB;
    font-weight: bold;
    text-align: center;
}

.subject-display .remove{
    position: relative;
    color: rgb(221, 48, 57);
    background: transparent;
    border:none;
    margin-left:auto; margin-right:0;
    font-weight: bold;
}

.offline-assets{
    height:50%;
}



/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 .dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: black;
    color: black;
    -webkit-animation: dotFlashing 1.5s infinite linear alternate;
            animation: dotFlashing 1.5s infinite linear alternate;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  .dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  
  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: black;
    color: black;
    -webkit-animation: dotFlashing 1.5s infinite alternate;
            animation: dotFlashing 1.5s infinite alternate;
    -webkit-animation-delay: .5s;
            animation-delay: .5s;
  }
  
  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: black;
    color: black;
    -webkit-animation: dotFlashing 1.5s infinite alternate;
            animation: dotFlashing 1.5s infinite alternate;
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
  }
  
  @-webkit-keyframes dotFlashing {
    0% {
      background-color: white;
    }
    50%,
    100% {
      background-color: rgba(0,153,226,1);
    }
  }
  
  @keyframes dotFlashing {
    0% {
      background-color: white;
    }
    50%,
    100% {
      background-color: rgba(0,153,226,1);
    }
  }
/* Add styles to the Selector to remove the hardcoded padding and min-height. These require that the
*  min-height is 38 pixles. These changes allow it to be smaller than 38 pixels in height.
*/
.dropdownlist__control{ min-height: unset !important;  height:100%;}
.dropdownlist__value-container.dropdownlist__value-container{ padding:unset;}
/* Remove the padding around the value inside the dropdown list */
.dropdownlist__input-container{  padding:unset;}
/* Update the padding around the selector icon */
.dropdownlist__indicator.dropdownlist__dropdown-indicator{padding:2px;}
/* Update the padding around the clear icon */
.dropdownlist__indicator.dropdownlist__clear-indicator{padding:2px;}




.notecarddriverid-card-modal {
    /* height: auto; */
    margin-right: 100px;
    margin-top: 120px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

    /* Duplicate in the modal below: */
    width: 52vw;
    height: 60vh;
    max-width: 1000px;      
    
}

.notecarddriverid-card-modal .notecarddriverid-modal {
    /* border-radius: 4px; */
    transform-origin: top right;
    margin: auto;
    text-align: center;
    /* border: none; */

    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;

    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

    /*Repeat the dimesions?*/
    width: 52vw;
    height: 50vh;
    display:grid;
    grid-template-rows: 10% auto 3em;
    grid-template-columns: auto;

}

.notecarddriverid-modal .title{
    grid-row : 1;
    grid-column: unset;
}


.notecarddriverid-modal .topDisplay{
    display: grid;
    grid-template-columns: 50% 49%;
    height: auto;
    position: relative;
}

.notecarddriverid-modal .driverDisplay{
    display: grid;
    grid-template-columns: repeat(3,32%);
    grid-template-rows: 54% 40%;
    grid-column-gap: 0.5%;
    grid-row-gap: 6%;
    height:100%;    
    
    
}

.driver-select:hover{
    transform:scale(1.05,1.05);
    -webkit-transform:scale(1.05,1.05);
    -moz-transform:scale(1.05,1.05);
} 
.driver-select:active{
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}
.driver-select[selected-driver='true'] {
    border: 2px solid rgba(0, 255, 0, .75);
}

.notecarddriverid-modal .driver-select{
    height: 100%;
    /* background-color: blue; */
}
/* .notecarddriverid-modal .driver-details{
 width: 100%;
 height: 100%;
 max-height: 100%;
 position: relative;
 display: grid;
 grid-template-rows: 1.25em auto 1.25em;
 place-items: center;
} */

.notecarddriverid-modal .cell-label{
    width: 100%;
    height: 100%;
}

.notecarddriverid-modal .driverblank{
    height: 100%;
}
/* Driver photo section add [blank="false"] if we need specific styles on the blank avatar sectionts */
/* .notecarddriverid-modal div.driverphoto{
    width: 100%;
    height: 100%;*/
    /* Add background image controls, set to be single contained centered image */
    /* background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
} */ 

.notecarddriverid-modal .notecard-video{
    width: 100%;
    height:auto;    
    object-fit: fill;
}


.notecarddriverid-modal .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.notecarddriverid-modal .footer .btn {
    font-size: clamp(.5em,1vw,.9em);
    padding: clamp(.25em,0.5vw,.5em);
    margin-bottom: 2px;
} 


.empty-bottom{   height:7em;}

input:invalid {  background-color: #ffdddd;  }

/*=====================================================================
  Show the infractionid on small screens that don't have console access:
=====================================================================*/
.notecarddriverid-modal .notecardRef>span{    -webkit-user-select: all;    -ms-user-select: all;    user-select: all; } 
/* By default set it to hiddden */
/* .notecarddriverid-modal .notecardRef{    display: none; }  */
/*only apply if the screen width is less than*/
/* @media  (max-width:1300px)  {  */
    /* Enable the view and allow a single click to select the full text for copy */
    /* .notecarddriverid-modal .notecardRef{    display: initial; }      */
    .notecarddriverid-modal .notecardRef>span{    -webkit-user-select: all;    -ms-user-select: all;    user-select: all; } 
/* } */


/* Driver photo section add [blank="false"] if we need specific styles on the blank avatar sectionts */
.driver-details{
    width: 100%;
    height: 100%;
    max-height: 100%;
    /* background-color: red; */
    position: relative;
    display: grid;
    grid-template-rows: 1.25em auto 1.25em;
    place-items: center;
}

.driver-details div.driverphoto{
    width: 100%;
    height: 100%;
    /* Add background image controls, set to be single contained centered image */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}


/* Set general attributes of modal-content is defined in bootstrap */
.content-modal{
    transform-origin: top right;
    width: 100%;
    height: 100%;
    /* height: 40vh !important;  */
    margin: auto;  text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);  border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

    /* Set padding on the left and right of the card, so content doesn't flow to the edge */
    padding-left:2.5%;
    padding-right:2.5%;
}
/* General positioning of footer elements on the notecard */
.content-modal .footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.content-modal .message{
    /* background-color: red; */
    overflow-wrap: break-word;
    /* hyphens: auto; */
}

.content-modal .title{
    /* Unset the grid-column/row when used in a content-modal context */
    grid-column: unset;
    grid-row: unset;

    /* background-color: red; */
    margin-bottom: 1%;
}

.content-modal .title-warning{
    position: absolute;
    margin-top:0%;
    font-size: 18px;
    font-style: italic;
    left:0;
    right:0;
    color: red;
}

/* Style a popup that Displays a simple message and asks the user to confirm or deny the options */

.userconfirm-modal{
    margin-top: 20vh; 
    width: 30vw;
    height: 30vh;    
    max-width: 40vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
}


/* Set attribute for modal content that is child of the userconfirm-modal */
.userconfirm-modal .content-modal {
    /* Define a grid Title, body, footer */
    display: grid;
    grid-template-rows: 25% 50% 25%;
    grid-template-columns: 100%; 

    font-size: 1.25em;

    padding-left:5%;
    padding-right:5%;
    /* grid-template-rows: fit-content(10%) 25% auto 20px; */
}
.content-modal .footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.userconfirm-modal .message {
/* Vertically center */
    display: flex;
    justify-content: center;
    align-items: center;
}


.label-input-pair {
    display: grid;
    grid-template-columns: 40% 60%;
}

.label-input-pair .text-label{
    text-align: right;
    margin-right: 2%;
    /* Center in the row, this will center it against the input field half of the pair*/
    display: flex ;
    flex-direction: column;
    justify-content: center;
}

.label-input-pair .settime{
    text-align: center; 
}




/* Style a popup that Displays a simple message and asks the user to confirm or deny the options */

.cutvideonotecard-modal {
    /* height: auto ; */
    margin-top: 10vh; 
    width: 50vw;
    height: 45vh;
    max-width: 80vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;

    font-size: 1.1vw;
}
.cutvideonotecard-modal p{
    font-size: inherit;
}

.cutvideonotecard-modal .content-modal {
    display: grid;
    grid-template-rows: fit-content(10%) 14% 30% 20%;
}

/* Configure the base line sizes on the footer */
.cutvideonotecard-modal .btn {min-width: 15%;}
.cutvideonotecard-modal .blank-spacer {  width: 15%;}

.section{
    /* background-color: bisque; */
    display:flex;
    flex-direction: column;
    /* display: grid; */
    /* grid-template-rows: fit-content(10%) auto; */
}

.section-title{
    text-align: left;    
    font-size: 125%;
    margin-bottom: 1%;
}

.section-content{    
    margin-left:7%;
    margin-right: 7%;
}


/* Override previous passed in with the Style from ExpandedCard render() if hwTags were to be shown */
.cutwindow .settime{
    background-color:rgba(0, 174, 239,.05);
}
.cutwindow .settime[clamped="true"]{
    background-color: rgba(237, 28, 36, .4);
    
}

.cutwindow {
    width: 80%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap:1%;
    gap:1%;
}

.cutwindow .label-input-pair {
    grid-template-columns: 60% 40%;
}

.cutfeedback{
    margin-top: 3%;
}

.cutvideonotecard-modal .preloader3 {   
    height:4vh;
    /* Shift it down so that it is center aligned */
    transform: translateY(50%);     
}


.cutvideonotecard-modal .dvr-status{
    /* Push to the far right? */    
    position: absolute;
    right: 0px;
    width: 40%;
    /* Set the children to align side by side */
    display: grid;
    grid-template-columns: 60% 40%;
}
/* Add the label  to right justify against the break*/
.cutvideonotecard-modal .dvr-status .text-label{
    text-align: right;
}

.dvr-status > div{ border-radius: 6px}
/* Set up the animation to stick to the height of the parent container */
.dvr-status .FETCHING .preloader3 {   
    height:100%;
    /* Shift it down so that it is center aligned */
    /* transform: translateY(50%);      */
}
.dvr-status .PENDING {      background-color: #d3e011;}
.dvr-status .ARCHIVED {     background-color: rgb(0, 174, 239);}
.dvr-status .AVAILABLE {    background-color: rgb(141, 198, 63);}
.dvr-status .UNAVAILABLE {  background-color: #eb0b0b;}

.errormsg{
    color:red;
}

.tagconfirm-card-modal {
    /* height: auto; */
    margin-right: auto;
    margin-top: 15vh;
    /* width: 850px;
    height:455px;
    max-width: 850px;      */
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

    width: 28vw;
    height:21vh;
    max-width: 1000px;      
}
.tagconfirm-card-modal-second {
    margin-right: auto;
    margin-top: 15vh;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

    width: 50vw;
    height: 37vh;
    max-width: 1000px;      
}

.tagconfirm-modal {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    /* border: 1px solid #999; */
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

    height:100%
}

.tagconfirm-modal .title{
  text-align: center;
  display: block;
  max-height: 100%;
  max-width: 100%;
  font-size:175%;
  height: 16%;
}

.tagconfirm-modal .topDisplay{
    display: grid;
    grid-template-rows: 70% 30%;
    height:60%;
    font-size: 1.5em;
}

.tagconfirm-modal .topDisplay .iconset{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    min-height: 10px;
    grid-gap:3%;
    gap:3%
}

.tagconfirm-modal .topDisplay .icon > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.tagconfirm-modal .topDisplay .icon{
    position: relative;
    width: 15%;
    height: 90%;
    margin-top: auto;
    margin-bottom: auto;
    
}
/* RE-use the already defined blinkanim (LiveTab), add a tag to set the time loop to 1 second */
.blink2s{
    -webkit-animation: blinkanim normal 2s infinite ease-in-out;
            animation: blinkanim normal 2s infinite ease-in-out;
}

.tagconfirm-modal .topDisplay .tagtype{
    font-size: clamp(.5em,3.5vh,1.5em);
    margin-left:auto;
    margin-right:auto;
    
}
/* Adapt the font to the larger card */
.tagconfirm-card-modal-second .tagconfirm-modal .topDisplay .tagtype{
    font-size: clamp(1em,6vh,2.2em);
}


.tagconfirm-modal .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height:24%;
}

.tagconfirm-modal .footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    width: 18%;
} 
/* Adapt the font to the larger card */
.tagconfirm-card-modal-second .tagconfirm-modal .footer .btn{
    font-size: clamp(0.9em,3.5vh,1.8em);
}



.tagconfirm-modal .footer .blank-spacer {
    width: 30%;
    border: none;
  }

/* .add-comment:hover{
    transform:scale(105%,105%);
} */


.tagconfirm-popup-modal {
    margin-top: 120px;
    height: auto ;
    width: 95vw;
    max-width: 95vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
    
}




/*=============================
  Layout of the help image popup for the groups content
================================*/
.groups-help-popup-modal {
    margin-top: 120px;
    height: auto ;
    width: 50vw;
    max-width: 50vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
}

.groups-help-popup-modal .groups-help-popup-content {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

}
.groups-help-popup-content .titleTop {
    font-size: 26px;
    margin-left:auto;
    margin-right:auto;
}

.groups-help-popup-content .section {
    width: 100%;    
    display: grid;
    grid-template-columns: 5% 80% 5% ;
    margin-left: 2%;
    line-height: 1.45em;
}
.groups-help-popup-content .section .sectionTitle {
    text-align: left;
    grid-column:span 2;
    font-weight: bold;
}
.groups-help-popup-content .section .sectionContent {
    text-align: left;
    grid-column:2;
}


.journey-count {
    padding: 7px 10px 7px 10px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-style: italic;
    width: 9%;
}
.roi-selector-modal .roibuttons{    
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 10%;
  /* height: 1000px; */
  /* justify-content: space-between;    */
  justify-content: space-around;
  align-content: center;
  margin: 1em;
}
.roibuttons .update-button {
  width: 60px;
  height: 55px;
  border: none;
}

.roibuttons .process-button {
  width: 60px;
  height: 55px;
  border: none;
}

.roi-selector-modal {
  height: auto;
  margin-right: 100px;
  margin-top: 120px;
  width: 600px;
  max-width: 1000px;
  
}
.roi-selector-modal .modal-content {
  width: unset;
  margin: auto;
}


  



  
  
  



.video-reviewer .review-card-list {
    flex-grow: unset;
}

.video-reviewer .video-chooser, .video-reviewer .review-card-list {
    margin-top: 1.5em;
}

.notecard-view-toggle{
  margin-top: 25vh; 
  background-color: #AFD47B;
  border-radius: 10px;
  border:none;
  outline: none;
}
.notecard-view-toggle:hover {
  transform:scale(1.05,1.05);
  -webkit-transform:scale(1.05,1.05);
  -moz-transform:scale(1.05,1.05);
}
.notecard-view-toggle:active {
  transform:scale(.9,.9);
  -webkit-transform:scale(.9,.9);
  -moz-transform:scale(.9,.9);
}


/*===== Style the control buttons on the bottom of the page=======*/
  .controlbuttons{   
     
    display: flex; /*dynamically set the location of the elemnts in the group*/
    flex-direction: row; /*position them along a row instead of a column*/
    flex-wrap: wrap; /*Wrap to two rows if there is not enough space*/    
    font-size: 0.78125vw; /*Default font size*/
    width: 100%; /*use the full width of the parent div */
    justify-content: space-between; /*Equally space the elements apart along the row*/ 
    align-items: center;/*Center the items vertically in the control button container*/ 
  }

  /* General styles to apply to all buttons in the group */
  .video-reviewer-bottom .controlbuttons > button{    
    border-radius: 0.78125vw; /* Round the corners of the box */
    width: 7.8125vw; /* Set the default width to 150 pixels */
    height: 5.37vh;  /* Set the default width to height pixels */
    outline:none;     
    transition: ease-in-out all 0.1s; /*Allow interactive animation to transition instead of instant snap*/
  }

  /* Set the unique values for each button */
  .video-reviewer-bottom .controlbuttons .reprocess-button{
    margin: -0.8em;
    width: 4.16vw; /* Set the width to ~80 pixels */
    height: 8.59vh; /* Set the height to ~80 pixels */
    background-color: transparent; /* Remove the background color of the button, leave just the image */
    border: none; /* Remove the border to leave just the image */
  }  
  .video-reviewer-bottom .controlbuttons .complete-button { background-color: #61BD7D;  }
  .video-reviewer-bottom .controlbuttons .publish-button  { background-color: #AFD47B;  }
  .video-reviewer-bottom .controlbuttons .review-button   { background-color: #00AFED;  }
  .video-reviewer-bottom .controlbuttons .unusable-button { background-color: #E6002490;}

  /* Add the interactions with the control buttons, will apply to all buttons in this group */
  /* Only apply these interactions if the button is not disabled */  

  
  

  /* Interaction when hovering over the button */
  .video-reviewer-bottom .controlbuttons > button:hover:enabled { 
    transform:scale(1.05,1.05); /* Change the X,Y scale of the object, relative to the no interative size */
    -webkit-transform:scale(1.05,1.05); /*compatibility with other browsers*/
    -moz-transform:scale(1.05,1.05); /*compatibility with other browsers*/
  }
  /* Interaction when clicking on the button */
  .video-reviewer-bottom .controlbuttons > button:active:enabled {
    transform:scale(.9,.9); /* Change the X,Y scale of the object, relative to the no interative size */
    -webkit-transform:scale(.9,.9); /*compatibility with other browsers*/
    -moz-transform:scale(.9,.9); /*compatibility with other browsers*/
  }

  /* Modify the control buttons if the tagged attribute has been set to processing
     This catches a special case for the processing button, we dont' want the hover and click interation
     to be available when the button is in the processing state.
  */
  .video-reviewer-bottom .controlbuttons > button[tagged="processing"]:hover { 
    transform: unset; /*Disable the hover change when the tag is present */
  }
  .video-reviewer-bottom .controlbuttons > button[tagged="processing"]:active { 
    transform: unset; /*Disable the hover change when the tag is present */
  }

  /* =========END ControlButton styling =========== */

  .video-reviewer-top .video-chooser .reprocess-button{
    position: absolute;
    bottom: 10px;
    width: 80px;
    height: auto;
    border: none;
  }
  

  .nav-link {
    /* color: blue !important; */
    color: black;
    background-color: #00AFED15 !important;
    /* margin: 1em; */
  }
  .nav-link.active {
    color: black;
    background-color: #00AFED77 !important;
}

.roibuttons{    
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10%;
  /* height: 1000px; */
  justify-content: space-between;   
  /* justify-content: space-around; */
  align-content: center;
  /* margin: 1em; */
  
}
  



  
  
  

/*=====================================================================
  Style the VideoClipPlayer
=====================================================================*/

  
  .video-reviewer-player .react-player{
    /* width: auto; */
    width: auto !important;
    height: auto !important ;
  }
  .video-reviewer-player .react-playerLR{
    /* width: auto; */
    width: 765px !important;
    height: 510px !important ;  
    /* height: 640px !important ;   */
    /* max-height: 510px !important; */
    margin-left: auto;
    margin-right: auto;
  }

  .video-reviewer-player .fixed-size{
    object-fit: fill;
    width: 49.6vw;
    height: 28vw;
    margin-left:auto;
    margin-right:auto;
  }

  /*Previously loaded in VideoShaka, removed so that the unused UI elements aren't added */
  .video-reviewer-player .VideoShaka{
    /* object-fit: fill; */
    width:49.6vw;
    height:28vw;
    margin-left:auto;
    margin-right:auto;
  }

/* Handle the attributes for the video player loaded in ShakaPlayer, this affects the Original videos*/
.video-reviewer-player .VideoShaka .shakavideo{ 
  max-width: 49.6vw; /*Set a max width to constrain the initial width of the loading video*/
  max-height: 28vw; /*Set a max height to constrain the initial height of the loading video*/
  /* scale the video using the scales passed from the javascript*/
  /* Scale(x,y) is used to scale the video's width and height*/
  /*The var() loads the custom variable, if the variable isn't found it will fallback to the default scale of 1*/
  transform: scale(1,1);
  transform: scale(var(--vidScaleX,1),var(--vidScaleY,1)); 
}


  .video-reviewer-player .offsetLR .overlay-button{
    position: absolute; 
    top: 10px;
    right: 15%;
    width: 76px;
    height: 76px;
    border-radius: 38px;
    border: none;
    opacity: 0.5;
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none;    
    z-index:5;
  }
  
  .hidden-video {
    display: none;
  }
  
  
  
  .video-reviewer-player {
    position: relative;
  }
  
  .video-reviewer-player .overlay-button {
    /* position: absolute; */
    position: absolute; 
    top: 10px;
    right: 10%;
    width: 76px;
    height: 76px;
    border-radius: 38px;
    border: none;
    opacity: 0.5;
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none;
    z-index:5;
  }
  

  .video-reviewer-player .vcp-reviewtext {
    /* position: absolute;  */
    top: 10px;
    right: 10%;    
    -webkit-user-select:none;    
        -ms-user-select:none;    
            user-select:none;
    color:red;
  }
  .video-reviewer-player .overlaybutton-text {
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none;
    line-height: 1.2;
  }
  .video-reviewer-player .overlay-reviewer {
    color:white;
    font-size: 0.68vw;
  }
  
  
  .video-reviewer-player .overlay-button:focus {
    outline: none;
  }
  
  .video-reviewer-player .overlay-button:hover {
    opacity: 0.6;
  }
  
  .video-reviewer-player .overlay-button:active {
    background: #33cffd;
  }
  .video-reviewer-player .overlay-button[data-card] {
    background: rgb(141, 198, 63);
  }
  .video-reviewer-player .overlay-button[reviewed-clip] {
    opacity: 0.6;
    background: #E60024;
  }
  .video-reviewer-player .overlay-button:disabled {
    opacity: 0.6;
    background: #E60024;
  }
  
  
   .video-reviewer-players .video-filename {
    /* margin-bottom: 0.5em; */
     /* margin-top: -1.0em;  */
  }

  .video-reviewer-players .dvrdeleted{
    margin-top: 5em;
    padding-left: 10%;
    padding-right: 10%;
  }
 
  
  
  .video-filename {
    margin-bottom: 0.5em;
    margin-top: -1.0em; 
     /* margin-top: -5.0em;  */
  }
  

  
  .playbuttonsVR{    
    display: flex;
    flex-direction: row;
    width: 100%; 
    height: 10%;
    /* height: 1000px; */
    /* justify-content: space-between;    */
    /* justify-content: space-evenly; */
    justify-content: space-evenly;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    /* margin: 1em; */
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none;
    
  }


  
  /** Defaut interaction for a child element of the playbuttonsVR container */
  .video-reviewer-players .playbuttonsVR > *:disabled{
    opacity:0.5;
  }
  .video-reviewer-players .playbuttonsVR > *:hover:enabled{
    transform:scale(1.05,1.05);
    -webkit-transform:scale(1.05,1.05);
    -moz-transform:scale(1.05,1.05);
  }
  .video-reviewer-players .playbuttonsVR > *:active:enabled{
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
  }

  /** Default styling of the elements in the playbuttonsVR container*/
  .video-reviewer-players .playbuttonsVR > *{
    border: none;
    height: 75px;
    width: 75px;
  }
  .video-reviewer-players .playbuttonsVR .skipPrev-button {
    width: 125px;   
  }
  .video-reviewer-players .playbuttonsVR .skipNext-button {
    width: 125px;
  }
  .video-reviewer-players .playbuttonsVR .roi-button {
    width: 100px;
    height: 85px;
  }
  .video-reviewer-players .playbuttonsVR .blank-spacer {
    width: 150px;
  }
  

  .playerblink-canvas{
    background-color: white;
    position: absolute;
    z-index:4;
  }
  .onVideoCanvas{
    background-color: white;
    position: absolute;
    z-index:4;
    margin-left: auto;
    margin-right: auto;
  }
  

  
  .archive-dialog-modal {
    height: auto;
    /* margin-right: 100px; */
    margin-top: 120px;
    width: auto;
    /* width: 600px;
    max-width: 1000px; */
  }
  .archive-dialog-modal .modal-content {
    width: unset;
    margin: auto;
    text-align: center;
    border: none;
  }


  .archivetitle {
       margin:1em;
       font-size: 1.5rem;
  }

  

  
  .archive-dialog-modal .archivebuttons{    
    display: flex;
    flex-direction: row;
    width: 50%;
    margin-left: 25%;
    height: 10%;
    /* height: 1000px; */
    /* justify-content: space-between;    */
    justify-content: space-around;
    align-content: center;
    margin-bottom: 1em;
    margin-top: 1em;
  }
  .archivebuttons .restore-button {
    width: 20%;
    height: 55px;
    color: white;
    background: rgb(141,198,63);
  }

  .archivebuttons .restore-spinner{
    height:auto;
    margin-top:45%;
    color: white;
  }
  
  .archivebuttons .decline-button {
    width: 20%;
    height: 55px;
    color: white;
    background: #e74c3c
  }

  .available-countdown{
    /* font-size: 1rem; */
    margin: 0.25em;
  }
  
  
  .preloader-archive {
    width: auto;
    height: 130px;
    text-align: center;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .preloader-archive > div {
      margin: 2px;
      background-color: white !important;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      -webkit-animation: stretchdelay 0.7s infinite ease-in-out;
      animation: stretchdelay 0.7s infinite ease-in-out; }
    .preloader-archive .circ2 {
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s;  }
    .preloader-archive .circ3 {
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s; }
    .preloader-archive .circ4 {
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s; }
    .preloader-archive .circ5 {
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s; }
    .preloader-archive .loader-md {
      height: 13px;
      width: 13px; }
    .preloader-archive .loader-lg {
      height: 18px;
      width: 18px; }
    
    
    
  

.page-title{
  font-size: 30px;
  font-weight: bold; 
  text-align: center;
  margin-top:-4em;
  width: 100%;
}

.asset-chooser .nav{
  flex-wrap: unset;
}
.asset-chooser .nav-tabs{
  width:350px !important;
}
.asset-chooser .nav-link{
  min-width: 33%;
  padding: .5rem;
}
.asset-chooser .nav-link {
  color: black;
  background-color: #00AFED15 !important;
  font-size: 14px;
}
.asset-chooser .nav-link.active {
  color: black;
  background-color: #00AFED77 !important;
  font-size: 14px;  
  font-weight: bold
}



.live-review {
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  /* max-width: 90%; */
  margin-left: auto;
  margin-right: auto;
  transition-duration:250ms; 
}

  .live-reviewer-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -0.5em;
    width: 100%;
  }
  .live-reviewer-bottom .controlbuttons{    
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap; 
    font-size: 15px;
    width: 100%;
    justify-content: space-between;   
     margin-top: 0em; 
    margin-left:auto;
  }
  
  .live-reviewer-bottom .controlbuttons .email-button{
    border-radius: 26px;
    width: 150px;
    /* background-color:#E60024; */
  }
  


  
.live-reviewer {
  display: flex;
  flex-direction: row;
  
  justify-content: space-between;
  align-items: flex-start;
  width:100%;
}

.assetid-block{
  /* display: flex;
  flex-direction: row; */
  width: 42%;
  /* max-width: 30%; */

  display:grid;
  grid-template-columns: 25% 25% 25% 25%; 
  grid-template-rows: 100%; 
  /* height: 20em; */
  /* height:660px; */

  -webkit-user-select:none;

      -ms-user-select:none;

          user-select:none;
  margin-left:-2%;
  /* margin-bottom:auto; */
}
.assetid-block .asset-col{
  display: flex;
  flex-direction: column;
  /* width: 50%; */
  /* max-width: 50%; */
  height:100%
  /* margin-top:auto; */
  /* margin-bottom:auto; */
}

/* .assetid-block .asset-col button { */
.asset-elem button {
  /* width: 97%;  
  height: 7%;
  margin-bottom: 3%; */

  border-radius: 6px;
  border:none;
  outline: none;
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
}


.asset-elem{
  
  /* width:150px; */
  width: 97%;  
  height: 8%;
  /* margin-bottom: 3%; */

  display: grid;
  grid-template-columns: 35% 65%; 
  grid-template-rows: 95%;
  grid-column-gap: 2%;
  -webkit-column-gap: 2%;
          column-gap: 2%; 
  /* flex-wrap:wrap; */
  /* justify-content: space-between;    */
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  font-size:16px;
  /* height:25px; */
  max-width:100%;
}

.warningblock{
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-gap: 15%;
  gap: 15%;
}

.offline-mark{
  margin-top:auto;
  margin-bottom:auto;
  /* height:22px; */
  height:2.3vh;
}

.alert-mark{
  /* height:20px; */
  margin-top:auto;
  margin-bottom:auto;
  height:2.3vh;
}

.tabtitle{    
  display: flex;
  flex-direction: row;
  /* flex-wrap:wrap; */
  justify-content: space-between;   
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  font-size:0.83vw;
  /* font-size:16px; */
  height:auto;
  max-width:100%;
}

.tabtitle .badge{    
  padding: 5px;
  font-size: 12px;
  margin-top: auto;
  margin-bottom: auto;
}
.tabtitle .empty-badge{    
  /* background-color: red; */
  padding: 5px;
  width: 20px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius:10px;
}


@media screen and (max-width:1300px) { /*only apply if the screen width is less than*/
  .tabtitle {  font-size: 14px; } 
  .tabtitle .badge{  font-size: 10px; padding:4px;  }
}

/* .assetid-block button :active {
  
  background-color: blue;
} */

.alert-icon {
  position: absolute; 
  top: 15%;
  right: 5%;
  width: 100px;
  height: 75px;
}

.asset-chooser-group {
  display: flex;
  flex-direction: row;  
  flex-wrap:wrap;
  justify-content: flex-start;   
}

.asset-chooser-tabs {
  width: 10px;
  font-size: 20px;
}

.asset-chooser {
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  /* width: 18% !important; */
  /* width: 18% !important; */
  min-width: 23%;
  max-width: 23%;
  /* min-width: 16em; */
  /* flex-grow: 0; */
  /* height:150px; */
}


  .live-reviewer .live-reviewer-players .live-reviewer-player{
    margin-left: auto;
    margin-right: auto;
  }

  .live-reviewer .live-reviewer-players .notifyButtonsRight{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 80%;
    margin-left:auto;
    width: 20%;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    /* margin-top: 1em; */
    /* margin-top: -1em; */
  }




  .live-reviewer .live-reviewer-players .notifyButtonsLeft{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    /* margin-top: 1em; */
  }

  .live-reviewer .live-reviewer-players .notifyButtonsRight .email-button{
    border-radius: 12px;
    width: 70px;
    height: 50px;
    
    margin-bottom: .25em;
  }
  .live-reviewer .live-reviewer-players .notifyButtonsRight .alert-button{
    border-radius: 20px;
    width: 200px;
    height: 50px;
    outline:none;
    margin-bottom: .25em;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    line-height:1;
  }
  
  .alert-button .alert-button-img{
    width: auto;
    height: 30px;
    margin-right: 10%; 
    transform: translate(50%, 0);
  }
  .alert-button > img{
    max-height: 75%;
  }

  .live-reviewer .live-reviewer-players .notifyButtonsRight .infraction-button{
    border-radius: 20px;
    width: 200px;
    height: 50px;
    outline:none;
    margin-bottom: .25em;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    line-height:1;
  }
  .infraction-button .infraction-button-img{
    width: auto;
    height: 30px;
    margin-right: 10%; 
    transform: translate(50%, 0);
  }
  .infraction-button > img{
    max-height: 75%;
  }



  .hidden-video {
    display: none;
  }
  
  
  
  .live-reviewer-player {
    position: relative;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

.roibuttons{    
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10%;
  /* height: 1000px; */
  justify-content: space-between;   
  /* justify-content: space-around; */
  align-content: center;
  /* margin: 1em; */
  
}

.live-review .asset-button:hover{
  transform:scale(1.05); 
}
.live-review .asset-button:active{
  transform:scale(0.9); 
}


.asset-elem img:hover{
  transform:scale(1.15);   
} 
.asset-elem img:active{
  transform:scale(0.9); 
}

.blink{
  -webkit-animation: blinkanim normal 1s infinite ease-in-out;
          animation: blinkanim normal 1s infinite ease-in-out;
}
@-webkit-keyframes blinkanim {
  0% {
      opacity:1;
  }
  50% {
      opacity:.25;
  }
  100% {
      opacity:1;
  }
}
@keyframes blinkanim {
  0% {
      opacity:1;
  }
  50% {
      opacity:.25;
  }
  100% {
      opacity:1;
  }
}

  



  
  
  


.control-set {
    overflow: hidden;
    width:7.5vw;
    text-align: left;
    .o-pad {
      display: inline-block;
    }
    z-index: 2;
  }
  .control-set.setbg { background: #222; }
  .control-set.setbg2 { background: #5f9837; }
  
  :root {
    --blue: #1e90ff;
    --white: #ffffff;
    --dpad-fg: #545657af;
    --dpad-fg-hover: #1e8fff6e;
    --arrowcolor: #aaa;
    --tri-lrg-a: .68vw;
    --tri-lrg-b: 1vw;
    --dpad-arrow-shift: 5px;
    --dpad-arrow-move: 35%;
  }
  
  
  .o-pad {
    transition: all .25s;

    position: relative;
    background: #545657af;
    background: var(--dpad-fg);
    width: 7.5vw;
    height: 7.5vw;
    /* width: 10.45vw;
    height: 22vh; */
    border-radius:50%;
    overflow:hidden;
    /* Shrink the initial circle down to a center section  */
    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      width: 20%;
      height: 20%;
      top: 50%;
      left: 50%;
      background: #ddd;
      border-radius: 50%;   
      transform: translate(-50%, -50%);
      display: none;
      transition: all .25s;
      cursor: pointer;
    }
    /* Enlarge the o-pad if hovering over */
    &:hover:after {
      width: 30%;
      height: 30%;
    }
    a {
      display:block;
      position: absolute;
      -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
      width: 50%;
      height: 50%;
      text-align: center;  
      transform: rotate(45deg);
      border: 1px solid rgba(0,0,0,.2);
      &:before {
        content: '';
        position: absolute;
        width: 60%;
        height: 60%;
        top: 50%;
        left: 50%;
        background: rgba(255,255,255,0.1);
        border-radius: 50%;   
        transform: translate(-50%, -50%);
        transition: all .25s;
        cursor: pointer;
        display: none;
      }
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-radius: 5px;
        border-style: solid;    
        transform: translate(-50%, -50%) rotate(-45deg);
        transition: all .25s;
        
      }
    }
    a.up {
      bottom: 50%;
      left: 50%;
      transform: translate(-50%, -20%) rotate(45deg);
      border-top-left-radius: 50%;
      z-index:1;
      
      &:hover {
        /* background: linear-gradient(315deg, rgba(255,255,255,0) 15%, rgba(255,255,255,.4) 100%); */
        background: linear-gradient(315deg, #545657af 15%, #1e8fff6e 100%);
        background: linear-gradient(315deg, var(--dpad-fg) 15%, var(--dpad-fg-hover) 100%);
      }
      &:before {
        left: 57%;
        top: 57%;
      }
      &:after {
        left: 53%;
        top: 53%;
        border-width: 0 .68vw  1vw  .68vw;
        border-width: 0 var(--tri-lrg-a)  var(--tri-lrg-b)  var(--tri-lrg-a) ;
        /* border-color: transparent transparent var(--arrowcolor) transparent; */
        border-color: transparent transparent transparent transparent;

      }
      &:active:after {
        border-bottom-color: #333;
      }
    }
    
    a.down {
      top: 50%;
      left: 50%;    
      transform: translate(-50%, 20%) rotate(45deg);
      border-bottom-right-radius: 50%;
      z-index:1;
      &:hover {
        /* background: linear-gradient(135deg, rgba(255,255,255,0) 15%, rgba(255,255,255,.4) 100%); */
        background: linear-gradient(135deg, #545657af 15%, #1e8fff6e 100%);
        background: linear-gradient(135deg, var(--dpad-fg) 15%, var(--dpad-fg-hover) 100%);
      }
      &:before {
        left: 43%;
        top: 43%;
      }
      &:after {
        left: 47%;
        top: 47%;
        border-width: 1vw  .68vw  0px .68vw;
        border-width: var(--tri-lrg-b)  var(--tri-lrg-a)  0px var(--tri-lrg-a) ;
        border-color: #aaa transparent transparent transparent;
        border-color: var(--arrowcolor) transparent transparent transparent;
      }
      &:active:after {
        border-top-color: #333;
      }
    } 
    
    a.left {
      top: 50%;
      right: 50%;    
      transform: translate(-20%, -50%) rotate(45deg);
      border-bottom-left-radius: 50%;
      border: none;
      &:hover {
        /* background: linear-gradient(225deg, rgba(255,255,255,0) 15%, rgba(255,255,255,.4) 100%); */
        background: linear-gradient(225deg, #545657af 15%, #1e8fff6e 100%);
        background: linear-gradient(225deg, var(--dpad-fg) 15%, var(--dpad-fg-hover) 100%);
      }
      &:before {
        left: 57%;
        top: 43%;
      }
      &:after {
        left: 53%;
        top: 47%;
        border-width: .68vw  1vw  .68vw  0;
        border-width: var(--tri-lrg-a)  var(--tri-lrg-b)  var(--tri-lrg-a)  0;
        border-color: transparent #aaa transparent transparent;
        border-color: transparent var(--arrowcolor) transparent transparent;
      }
      &:active:after {
        border-right-color: #333;
      }
    }  
    
    a.right {
      top: 50%;
      left: 50%;    
      transform: translate(20%, -50%) rotate(45deg);  
      border-top-right-radius: 50%;
      border: none;
      &:hover {
        /* background: linear-gradient(45deg, rgba(255,255,255,0) 15%, rgba(255,255,255,.4) 100%); */
        background: linear-gradient(45deg, #545657af 15%, #1e8fff6e 100%);
        background: linear-gradient(45deg, var(--dpad-fg) 15%, var(--dpad-fg-hover) 100%);
      }
      &:before {
        left: 43%;
        top: 57%;
      }
      &:after {
        left: 47%;
        top: 53%;
        border-width: .68vw  0 .68vw  1vw;
        border-width: var(--tri-lrg-a)  0 var(--tri-lrg-a)  var(--tri-lrg-b) ;
        border-color: transparent transparent transparent #aaa;
        border-color: transparent transparent transparent var(--arrowcolor);
      }    
      &:active:after {
        border-left-color: #333;
      }
    } 
    /* a:hover:after { left: 50%; top: 50%; }   */
    a:active:after { 
        left: 50%; top: 50%;
        /* transition: transform 2s ease-in-out; */
     }
     a:active { 
        transition: transform 2s ease-in-out;
     }  
     /* if the button is disabled, then don't pass the hover/click to the link */
     a[disabled]{
      pointer-events: none;
      opacity: 0.3;      
     }
  }
  
  
  .control-set {
    --c: #767777f6;
    --c-h: #143cb9;
    --c-t: #ffffff; /*arrow color*/
    --c-t-a: #1e90ff; /*active arrow color*/
    .o-pad {
      background: var(--c); 
      a {   
            /* border-color: rgba(255,255,255,.5);  */
            border-color: transparent;
        }
      &:before, a { background: var(--c);  }
      &:after { display: block; background: #ccc; }     
      a.up:after { border-bottom-color: var(--c-t); transition: all 0.35s ease-in-out;}
      a.right:after { border-left-color: var(--c-t); transition: all 0.35s ease-in-out;}    
      a.down:after { border-top-color: var(--c-t); transition: all 0.35s ease-in-out;}
      a.left:after { border-right-color: var(--c-t); transition: all 0.35s ease-in-out;}
      a.up:active:after { border-bottom-color: var(--c-t-a); transition: transform 1s ease-in-out;}
      a.right:active:after { border-left-color: var(--c-t-a); transition: transform 1s ease-in-out;}    
      a.down:active:after { border-top-color: var(--c-t-a); transition: transform 1s ease-in-out;}
      a.left:active:after { border-right-color: var(--c-t-a);transition: transform 1s ease-in-out;}
      
      /* Add styling to clicking on the center o-pad button */
      /* Check if any of the other children of the o-pad are active*/
      /* If active then reset the color to prevent false animation */
      &:has(a:active):after {
        background: #ccc;
        transition: all 0.35s ease-in-out;
      }
      /* if there are no active children, and the button has been clicked */
      /* Then cahnge the color and size of the center button */
      &:not(:has(a:active)):active:after{
        background-color: var(--c-t-a); 
        width: 20%; height: 20%;
        transition: transform 1s ease-in-out;
      }
    }
  }
  





  

.camera-control {
    /* margin-top: 100px; */
    height: auto ;
    /* width: 11.5vw;
    height: 22.5vh; */
    width: 14vw;
    height: 7.5vw;
    /* height: 15vh; */

    max-width: 15vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
    z-index: 10004; /*very high z-index is set on the rgb edge3 bars*/

    --mask_radius: 4.25vw
}
/* Set the style on the parent when disabled */
.camera-control[disabled] {
  opacity: .3;
  /* Disable the hover/click events on all the children  */
  > *{
    pointer-events: none;
  }
  
}
/* Style the buttons when the parent is disabled */
.camera-control[disabled] .statefulbutton{
  opacity: .3;
}

.camera-control .body {
    transform-origin: top right;
    width: 100%;
    height:100%;
    margin: auto;
    text-align: center;
    position: relative;

    /* background-color: #dfdede; */
    background-color: transparent;
   
}


/* Position the camera control next to the button */
/* Fine tune the margin-top so that it is vertically centered */
.camera-control{
  position: absolute;
  margin-left:10.5vw;
  margin-top: -4.75vh;
}

.camera-control .statefulbutton{
  background-color: rgb(0, 174, 239);
  width: 100%;
  font-size: 110%;
}

.camera-control .body{
  /* border:none; */
  /* background-color: red; */
  /* background-color: transparent; */
  width:14vw;
  display:flex;
  flex-direction: row;
}

.camera-control .control-buttons{
  /* background-color: blue; */
  position: absolute;
  left:3.75vw;
  /* background-color: blue; */
  height:100%;
  width:8vw;
  z-index: 1; 


  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

.control-buttons {
  /* background-image:  */
  -webkit-mask-image:
    radial-gradient(circle at left, transparent 0, transparent var(--mask_radius), black 0);
          mask-image:
    radial-gradient(circle at left, transparent 0, transparent var(--mask_radius), black 0);
}

.control-buttons .statefulbutton{ 
  --c: #767777f6;
  --dpad-fg: #545657af;
  --dpad-fg-hover: #1e8fff6e;
  --r: .75vw; /* the radius of the button's rounded corners*/

  /* background-color: var(--dpad-fg); */
  background-color: var(--c);
  width:70%;
  margin-left: 30%;
  height: 5vh;
  border-radius: var(--r);
  padding-left: 30%;
  color:white;

  &:hover{
    background: linear-gradient(to right, var(--dpad-fg) 15%, var(--dpad-fg-hover) 100%);
    background-color: var(--dpad-fg); 
  }
  &:active{
    /* transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9); */
    transform: translate(10%,0%);
  }
  &:active:after { transition: transform 1s ease-in-out;}
}
.control-buttons .control{ 
  --c: #767777f6;
  --dpad-fg: #545657af;
  --dpad-fg-hover: #1e8fff6e;
  --arrowcolor: #ffffff;
  --c-t-a: #1e90ff; /*active arrow color*/
  --r: .75vw; /* the radius of the button's rounded corners*/
  --button_offset: 2.5vw;
  --button_width:8vw;

  background-color: var(--c);
  width: calc(var(--button_width) - var(--button_offset));
  margin-left: var(--button_offset); 
  height: 5vh;
  border-radius: var(--r);
  &:hover{
    background: linear-gradient(to right, var(--dpad-fg) 15%, var(--dpad-fg-hover) 100%);
    background-color: var(--dpad-fg); 
  }

  a {
    display:flex;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    width: 100%;
    height: 100%;
    padding-left: calc(var(--mask_radius) - var(--button_offset));
    color: var(--arrowcolor);
    font-weight: 500;
    transition: all 2s ease-in-out;
    line-height: 0px;
    
    /* color: var(--c-t);  */

    &:before {
      background: rgba(255,255,255,0.1);
    }

    &:active{ 
      color: var(--c-t-a);
      transform: translate(20%);  
      transition: transform .25s linear;
    }
  }
  a[disabled]{
    pointer-events: none;
    opacity: 0.3;      
  }
 
}

@media screen and (max-width:1300px) { /*only apply if the screen width is less than*/
  .camera-control-modal .camera-control-modal-content {  height: 70vh;  }
  .camera-control-modal {   width: 75vw;  }
}


/* hide/reveal an entire component */
@-webkit-keyframes flash {
  50%,from,to { opacity: 1 }
  25%,75% { opacity: 0}
}
@keyframes flash {
  50%,from,to {opacity: 1}    
  25%,75% { opacity: 0 }
}



/* Apply an opacity only to the outline */
@-webkit-keyframes flash-outline {
  50%,from,to { outline-color: rgba(0,0,255,1); }  
  25%,75% { outline-color: rgba(0,0,255,0); } 
}
@keyframes flash-outline {
  50%,from,to { outline-color: rgba(0,0,255,1); }  
  25%,75% { outline-color: rgba(0,0,255,0); } 
}
@-webkit-keyframes flash-background {
  50%,from,to { background-color: rgba(0,0,255,1); }  
  25%,75% { background-color: rgba(0,0,255,0); } 
}
@keyframes flash-background {
  50%,from,to { background-color: rgba(0,0,255,1); }  
  25%,75% { background-color: rgba(0,0,255,0); } 
}
.live-float{
  position: fixed;
  height: auto;
  width: 50%;
  right: 10px;
  top: calc( 5.5em + 35px + 20px);
}


.live-float .live-reviewer-players{
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: 75vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
}

/* Add a border around the clip, transparent by default */
.live-float .fixed-size{
  /* border: 0.5vw solid transparent; */
  border-radius: 0.25vw; 
  /* outline-color: blue;
  outline-width: 10px; */
}

.live-float .fixed-size-background {
  /* width:103%;
  height:103%; */
  background-color: none;
}

/* Override the transparent border with a solid color when a remote clip is received */
.live-float .fixed-size{
  /* border: 0.5vw solid transparent;
  border-radius: 0.25vw; */
  /* border: 0.5vw solid blue;
   */
}
.live-float fieldset.fixed-size{
  /* border-width: 0.6vw;
  border-color:green;
  border-style: dashed; */
}

.live-float fieldset.fixed-size.remote-clip{
  outline-width: 0.83vw;
  outline-style: solid;
  /* outline-color: #0000ff77;   */
  -webkit-animation: flash-outline 2.5s infinite ease-in-out;
          animation: flash-outline 2.5s infinite ease-in-out;  
}
.live-float fieldset.fixed-size.remote-clip > div{
  -webkit-animation: flash 2.5s infinite ease-in-out;
          animation: flash 2.5s infinite ease-in-out;  
}

.live-float .cliptitle{
  --fontSize: 0.83vw;
  color:white;
  font-weight: 600;
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  top: calc( var(--fontSize) * -1);
  & div{
    font-size:var(--fontSize);
    line-height: var(--fontSize);
    /* border-radius: 1vw; */
    padding-left: 10px;
    padding-right: 10px;
    /* background-color: blue;   */
    /* animation: flash 2.5s infinite ease-in-out; */
  }
  
}



  

  .live-reviewer-players{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
  }
  .live-reviewer-players .live-reviewer-player{
    margin-left: auto;
    margin-right: auto;
  }
  .live-reviewer-player .fixed-size{
    object-fit: fill;
  }

  .live-reviewer-players .notifyButtonsRight{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    /* margin-top: 1em; */
    /* margin-top: -1em; */
  }
  .live-reviewer-players .notifyButtonsLeft{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    /* margin-top: 1em; */
  }

  .live-reviewer-players .notifyButtonsLeft .email-button{
    border-radius: 20px;
    width: 125px;
    height: 85px;
  }
  .live-reviewer-players .notifyButtonsRight .alert-button{
    border-radius: 20px;
    width: 200px;
    height: 85px;
    outline:none;
    margin-bottom: 1em;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }
  .alert-button .alert-button-img{
    width: 35px;
    height: auto;
    margin-right: 5%;
  }
  .alert-button > img{
    max-height: 75%;
  }


  .hidden-video {
    display: none !important;
  }
  
  .live-reviewer-player {
    position: relative;
  }

  .live-reviewer-player .overlay-button {
    /* position: absolute; */
    position: absolute; 
    top: 10px;
    right: 5%;
    width: 76px;
    height: 76px;
    border-radius: 38px;
    border: none;
    opacity: 0.5;
  }
  .live-reviewer-player  .overlay-button[data-card] {
    background: rgb(141, 198, 63);
  }

  



  .live-reviewer .live-reviewer-players .notifyButtonsRight .top-row{
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin-bottom: .25em;
    
  } 
  
  .live-reviewer .live-reviewer-players .notifyButtonsRight .download-button {
    margin-top: 8px;
    color: white;
    width: 120px;
    transform: translate(0, -10%);
    background: rgb(159, 212, 123);
    border-radius: 20px;
  }
  
  .live-reviewer .live-reviewer-players .notifyButtonsRight .downloaded-button {
    margin-top: 8px;
    color: white;
    width: 120px;
    height: 40px;
    transform: translate(0, -10%);
    background: rgb(159, 159, 159);
    border-radius: 20px;
  }

  
.live-reviewer-players .playbuttons{    
  display: grid;
  grid-template-columns: 14.25% 14.25% 19% 19% 14.25% 14.25%;
  grid-column-gap: 1%;
  -webkit-column-gap: 1%;
          column-gap: 1%;
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
}

  .live-reviewer .live-reviewer-players .playbuttons .skipbutton {
    /* width: 20%; */
    width:100%;
    height: 75px;
    border: none;
  }
  
  .live-reviewer .live-reviewer-players .playbuttons .blank-spacer {
    /* width: 15%; */
    width: 100%;
    height: 75px;
    border: none;
  }

  .live-reviewer .live-reviewer-players .playbuttons .reorderbutton {
    width:100%;
    height: 75px;
    border: none; 
    background-color: rgb(51,207,253);
    border-radius: 15%;
  }
  

  
  .playbuttons > *{
    /*Allow interactive animation to transition instead of instant snap*/
    /* setting all will apply to any transform on the element instead of just scale/position... */
    transition: ease-in-out all 0.1s; 
  }

  .playbuttons > *:disabled{
    opacity:0.5;
  }
  .playbuttons > *:hover:enabled{
    transform:scale(1.05,1.05);
    -webkit-transform:scale(1.05,1.05);
    -moz-transform:scale(1.05,1.05);
  } 
  .playbuttons > *:active:enabled{
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
  }  

#root.reporting .box.mainApp {
    margin-left: 0;
    padding-left: 0;
    margin-right: 0;
    padding-right: 0;
}

#root.reporting .amplify-container {
    padding-left: 6px !important;
}

#root.reporting .pcoded .pcoded-inner-content {
    padding-right: 0;
  }


.hr-review {
    /* height: 40%; */
    display: grid;
    /* grid-template-columns: 10% 20% 20% 20% 20% 10%;  */
    grid-template-columns: 10vw 87.5vw 2.5vw;  
    grid-template-rows: 6vh 80%;
    width: 100%;  
    margin-top: -80px;
    transition-duration:250ms; 
}

.card-lists {
    display: flex;
    flex-direction: row;
    /* justify-content: flex-start; */
    justify-content: space-between; 
    align-items: flex-start;
    position: relative;
    grid-row:2;
    grid-column: 2;
    width:100%;
    
}

.hr-review .review-card:hover{
    /* background-color: red; */
    transform:scale(103%,103%) 
}
.hr-review .review-card-placeholder:hover{
    /* background-color: red; */
    transform:scale(100%,100%) 
}

@media screen and (max-width:1300px) { /*only apply if the screen width is less than*/
    .hr-review .filter-drop-site select.form-control.form-control-default{  max-width: 24ch; } 
}
/* Configure the filter bar and filters that are displayed on the Reporting tab */
/* The Reporting tab is configured as 2x3 grid to layout the different sections of the page*/ 


/* Configure the Total Notecards display located to the left of the workflows */
.hr-review .search-results {
    /* Set background color and and round the edges of the box */
    background-color: #e9e9e9;
    border-radius: 4px;

    /* Define the size of the box to display*/
    height: 3vh;
    width: 9.5vw; 
    
    /* Define a padding left top bottom right to keep the text away from
        the edges of the box */
    padding: 0.75vh 0.4vw 0.75vh 0.4vw; 
    
    
    -webkit-user-select: none; 
    
    
        -ms-user-select: none; 
    
    
            user-select: none; /* Disable the user-select, prevent drag clicking and highlighting */
    /* Set up the element in Flex so that it balances the label and the count*/
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    /* Set the font size for the label, weight of 600 is slighlty bold*/
    font-size:0.75vw;
    font-weight: 600;
    
    /* Configure the location in the Reporting page grid 
        second row aligns below filters event with the workflows
    */
    grid-row:2;
    grid-column: 1;
    
}


/* Set up the filters bar at the top of the page*/
.hr-review .review-filters {
    background-color: #e9e9e9; /* set the background color*/
    border-radius: 4px; /* configure the corner radius to round the edges*/
    padding: 0.752vh 0 0.752vh 0.3125vw; /* Add a default padding to move the content away from the edge*/
    font-size: 0.75vw; /* Set the default font size */
    height: 5vh; /* Configure the height of the filter bar */
    grid-column: 2; /* Set the location to the right portion of the page*/
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; /* Disable user selection to prevent highlighting*/
    /* Set the display to flex type to allow the elementts to balance along the row*/
    display: flex;
    flex-direction: row;
    align-items: center;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content; /* Resize the filter bar based on the number of filters that are avaiable*/
    justify-self: end; /* Right justify the child elements, stack from right to left*/
}

/* Set the background color for all filter elements*/
.hr-review .review-filters > * {
    background-color: #e9e9e9;
}

/* Change the default padding around the dropdown menus*/
.hr-review .review-filters :nth-child(2), .review-filters :nth-child(3) {   padding:0;}

/* Override the default datePicker styles */
.hr-review .review-filters .date-pick {
    margin-right: .41vw; /* Add a space between the pickers*/ 
    border: 1px solid #bdc3c7; /*Make the border softer and thinner */
    border-radius: 2px; /*Round the edges */

    font-size: 0.75vw;   /* Set the font-size to match the page */
    /* Force the height and width*/
    height: 2.9vh !important; 
    width: 7vw !important; 
}
/* Override the padding given to the dropdown menu choices*/
.hr-review .review-filters .label-wrap {
    padding-right: 0.156vw !important;
}

/* Override the style of the dropdown menu buttons*/
.hr-review .review-filters select {
    height: 2.9vh !important;
    font-size: 0.75vw;
    /* Remove the default filtering of the select dropdown */
    -webkit-appearance: none;
    -moz-appearance: none;
     appearance: none; 

    /* Update the display style */
    display: block;
    font-family: sans-serif; /*Change the font type */
    color: #444; /* Set the default color */
    line-height: 1.3; /* Change the space around text to center better*/
    padding: .25em 1.4em .25em .8em;  /* Update teh padding to being the labels into the thinner buttons*/
    
    max-width: 11vw; /* Restrict the width of the dropdown, be default it will update to the longest string in the menu */
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa; /* Add a faint border around the button */ 
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04); /* Add a small shadow beneath and to the right of the button */
    border-radius: .5em;
    background-color: #fff;
    /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
        for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
    */
    /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), */
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right .4em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 1.25em auto, 100%;
    /* font-size:.6vw; */
    
}

.hr-review .review-filters .react-datepicker__close-icon {
    /* right: 12px; */
    right: 0.625vw;
    width: 0.8333vw;
    height: 1.719vh;
    
}
.hr-review .review-filters .react-datepicker__close-icon::after {
    width:100%;
    height:100%;
    line-height: 0.7em;
    right: 0.234vw;
    font-size: 1vw;
    padding: unset;
}
@media (-webkit-device-pixel-ratio: 2) {
    .hr-review .review-filters .react-datepicker__close-icon::after {
        line-height: 0.8em;
    }
    
}


.hr-review .apply-filters{
    /* border-radius: 15px; */
    /* width: 80px; */
    /* margin-right: 25px; */
    border-radius: 0.78vw;
    width: 4.166vw;
    margin-right: 1.30vw;

    background-color: #AFD47B;
}

/* Add the interaction with the apply button, set the transforms to scale the button*/
/* add the :enabled tag to eliminate the need for separate actions on the [disabled] tags*/
.hr-review .apply-filters:hover:enabled{
    transform:scale(1.05);
}
.hr-review .apply-filters:active:enabled{
    transform:scale(0.9); 
}


.hr-review .audioalert-filter{
    margin-right: 1.04vw;
    display: flex;
    width: 5.5vw;
    justify-content: space-around;
    align-items: center;   
}

.hr-review .review-card:hover{
    /* background-color: red; */
    transform:scale(103%,103%) 
}
.hr-review .review-card-placeholder:hover{
    /* background-color: red; */
    transform:scale(100%,100%) 
}


/*=============================
  Layout of the password prompt
================================*/
.password-verify-modal {
    margin-top: 120px;
    height: auto ;
    width: 50vw;
    max-width: 50vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
}

.password-verify-modal .password-verify-content {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

    height: 12em;

}

.password-verify-content .title{
    font-size: 26px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 1em;
    /* grid-column: 2; */
}
.password-verify-content .loginStatus{
    margin-top: 1em;
    font-style: italic;
    margin-left:auto;
    margin-right:auto;
    color:red;
}
.password-verify-content .loginStatus1{
    margin-top: 1em;
    font-style: italic;
    margin-left:auto;
    margin-right:auto;
    color:green;
}



.password-verify-content .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.password-verify-content .footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%
}


/* Top level wrapper on the map - define the region offset */
.mapView{
    /* margin-top: -3.5em;
    height:75vh;
    width:103.5%; */
    /* margin-top: -10vh;
    height: 60vh;
    width: 100vw; */
    margin-left: -1.5%;
}


.olMap {
    width: 100%;
    height: 100%;
    margin-top:5px;
}

.ol-popup .ol-popup-content {
    overflow: hidden;
    cursor: default;
    padding: 0.25em 0.5em;
    text-align: left;
    text-align: initial;
}

.inline-circle{
    height: 65px;
    width: 65px;
    border-radius: 50%;
    border:1px solid red;
    line-height: 65px;
    text-align: center;
}
.ol-popup img {
    margin-left: 10%;
    max-width: 25px;
    max-height: 25px;
    /* float: right;
    margin: -1.25em 0.5em 0 0;
    max-width: 100px;
    max-height: 100px; */
}
.toggle-infractions{
    top:0.5em;
    right:1%;
}


/* Active buttons */
.ol-toggle.ol-active > button {
    background: rgba(62, 136, 0, 0.7)
}
.ol-toggle.ol-active button:hover {
    background: rgba(60, 136, 0, 0.7)
}
.ol-toggle button:disabled {
    background: rgba(0,60,136,.3);
}

.mapView .dateselect{
    /* Set the background color of the datefilter */
    background: #e9e9e9;
}
.toggle-fence-button{
    /* background: rgba(62, 136, 0, 0.7); */
    width: 100px;
    font-size: 14px;
    height: auto;
}

.map-filters2 > * {
    background: #e9e9e9;
}

.map-filters2 :nth-child(2), .map-filters2 :nth-child(3) {
    padding: 0;
}
.map-filters2 .date-pick {
    margin-right: 8px;
    border: 1px solid #bdc3c7;
    border-radius: 2px;
}

.map-filters2 .label-wrap {
    padding-right: 2px !important;
}

.map-filters2 .nav-item {
    max-width: 180px;
}


.map-filters2 .date-pick {
    font-size: 11px;
    height: 27px !important;
    width: 90px !important;
}


.map-filters2 select {
    /* Styling for the specific select box */
    height: 27px !important;
    font-size: 11px;
    /* max-width :8em !important; */
    /* Remove the default filtering of the select dropdown */
    -webkit-appearance: none;
    -moz-appearance: none;
     appearance: none; 
    /* Add some styling */
    display: block;
    font-family: sans-serif;
    color: #444;
    line-height: 1.3;
    padding: .25em 1.4em .25em .8em;
    max-width: 100%; /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: .5em;
    background-color: #fff;
    /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
        for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
    */
    /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), */
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right .4em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 1.25em auto, 100%;
}

.map-filters2 .react-datepicker__close-icon {
    right: 11px;
}

.filter-controls{
    position:relative;
    width: 100%;

    display:grid;
    grid-template-columns: 21.5% fit-content(12vw) auto;
    grid-template-rows: auto;
    margin-top:1vh;
}

.map-controls > * {
    background: #e9e9e9;
}


.filter-dropdown-container ul {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-items: center !important;
}

.filter-controls .site-settings{
    height: 20px;
    border: 1px solid #aaa;
     box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
     border-radius: .5em;
     margin-right: 0.25vw;
}
.filter-controls .dateselect {
    grid-column: 2;
}
.filter-controls .map-controls {
    background: #e9e9e9;
    border-radius: 4px;
    padding: 7px 0 7px 10px;
    font-size: 12px;
    grid-column: 3;
    /* height: 5vh; */
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right:1px;
    margin-left: -0.5vw;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
.filter-controls .map-filters2 {
    background: #e9e9e9;
    border-radius: 4px;
    padding: 7px 0 7px 10px;
    font-size: 11px;
    /* height: 5vh; */
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -0.5vw;
    grid-column: 3;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}

.filter-controls .nav-link.disabled {
    /* color: blue !important; */
    color: #0000006c;
    background-color: #8c8e8f18 !important;
    font-size: 14px;
    /* margin: 1em; */
  }

.filter-controls .map-controls :nth-child(2), .map-controls :nth-child(3) {
    padding: 0;
}


.filter-controls .map-controls .date-pick {
    margin-right: 8px;
    border: 1px solid #bdc3c7;
    border-radius: 2px;
}

.filter-controls .map-controls .label-wrap {
    padding-right: 3px !important;
}

.filter-controls .map-controls .date-pick {
    font-size: 11px;
    height: 27px !important;
}



.filter-controls .map-controls select {
     /* Styling for the specific select box */
     height: 27px !important;
     font-size: 11px;
     /* font-size: 0.9vw; */
     /* max-width :8em !important; */
     /* Remove the default filtering of the select dropdown */
     -webkit-appearance: none;
     -moz-appearance: none;
      appearance: none; 
     /* Add some styling */
     display: block;
     font-family: sans-serif;
     color: #444;
     line-height: 1.3;
     padding: .25em 1.4em .25em .8em;
     max-width: 110px; /* useful when width is set to anything other than 100% */
     /* max-width: 8.5vw; */
     box-sizing: border-box;
     margin: 0;
     border: 1px solid #aaa;
     box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
     border-radius: .5em;
     background-color: #fff;
     /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
         for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
     */
     /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), */
     background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E'),
         linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
     background-repeat: no-repeat, repeat;
     /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
     background-position: right .4em top 50%, 0 0;
     /* icon size, then gradient */
     background-size: 1.25em auto, 100%;
}

.filter-controls .map-controls .react-datepicker__close-icon {
    right: 12px;
}


/* ===============================================================
Add formatting to include a help icon in the title text for the dropdown menu
===============================================================*/
.filter-controls .map-controls .richDropTitle {
    display:flex;
    width:100%;
}
.filter-controls .map-controls .titleText {
    margin-top:auto;
    margin-bottom:auto;
}
.filter-controls .map-controls .richDropTitle .helpIcon {
    width:30%;
    margin-right:5px;
    margin-top:1px;
    
}

.FilterSelection{
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    grid-column-start: 1;
    grid-column-end:2;
}

.FilterSelection .viewSelection{    
    display:grid;
    grid-template-columns: 33% 33% 33%;
    justify-content: space-around;
    width: 21vw;
    margin-right: auto;
    height:100%;    
}

.mapView .apply-filters{
    border-radius: 15px;
    width: 80px;
    margin-right: 25px;
    background-color: #AFD47B;
}

.ol-control.ol-layerswitcher-image{
    right: 3em !important;
    top: 0.5em !important;
}




.geofence-card-modal {
    height: auto;
    margin-right: 100px;
    margin-top: 120px;
    width: 750px;
    max-width: 1000px;
}

.geofence-card-modal .geofence-modal {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    min-height: 500px;
    text-align: center;
    /* border: none; */

    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    /* border: 1px solid #999; */
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
}
.gf-title{
    margin-left:auto;
    margin-right:auto;
}
.gf-title .title2{
    font-size: 26px;
    text-align: center;
}
.gf-add{
    width:5%;
}

.geofence-modal .add-action {
    width: 100%;
}

.geofence-modal .comment {
    background: #f4f5f7;
    border-radius: 4px;
    margin: 8px 0;
    padding-left: 8px;
    padding-top: 8px;
    width: 96%; 
}

.geofence-modal .comments {
    overflow-y: auto;
    max-height: 250px;
    margin-bottom: 5px;    
}
.rw-multiselect-taglist {
    max-height: 75px;
    overflow-y: auto;
}

.geofence-modal .gf-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.geofence-modal .gf-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    /* margin-right: 5px; */
    /* height: 25px; */
} 
.geofence-modal .gf-footer .blank-spacer {
    width: 30%;
    border: none;
  }

.gf-footer .delete-region {
    margin-top: 8px;
    width:15%;
}
.gf-footer .add-comment {
    margin-top: 8px;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
.gf-footer .close-region {
    margin-top: 8px;
    width:15%;
}
.gf-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.geofence-modal .monitored-action{
    width:100%;
    position: relative;
}
.geofence-modal .monitored-action .action-element{
    width:100%;
}
/* .monitored-action .action-element .delete-region{
    position:absolute;
} */
.action-list{
    width:100%;   
    max-height: 400px;
    overflow-y: auto;
}

.monitored-action .delete-action{
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 25px;
    font-size:10px;
    margin: auto;    
    padding:0;
}
.monitored-action .edit-action{
    position: absolute;
    top: 28px;
    right: 0;
    width: 50px;
    height: 25px;
    font-size:10px;
    margin: auto;    
    padding:0;
}




.action-line{
    display: flex;
    flex-direction: row;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.action-line >div{
    padding: 0px 5px;
}
.action-line .contact-list{
    word-wrap: break-word;
    height: auto;
    width: 100%;
    text-align: left;
}

.type-group .radio{
    display:flex;
    flex-direction: row;
    grid-column: 2;
    height: 2em;
}
.type-group .title2{
    display:flex;
    font-size: 14px;
    margin-left:2%;
    grid-column: 1;
}



.type-group {
    display: grid;
    grid-template-columns: 50px 100px 100px 100px 50px 300px;
    grid-template-rows: auto auto auto auto;
    grid-gap: 5px 0px;
    gap: 5px 0px;
  }

.type-group .dwell{
    grid-column: 2 / 3;
 
    display: grid;
    grid-template-columns: 100px 100px 100px 50px 300px;
    align-items: center;
}
.type-group .dwell .radio{
    display:flex;
    flex-direction: row;
    grid-column: 1;
}

.dwell .entry-field{
    height: 2em;
    width:95%;
}
.dwell .title-units{
    font-size: 14px;
    grid-column: 4; 
    grid-row:auto;
}
.dwell .title-comments{
    font-size: 14px;
    grid-column: 5; 
    grid-row:auto;
    color: #D8000C;
}

.type-group .dwelldisable{
    grid-column: 2 / 3;
    color:gray;
    display: grid;
    grid-template-columns: 100px 100px 100px;
    align-items: center;
}
.type-group .dwelldisable .radio{
    display:flex;
    flex-direction: row;
    grid-column: 1;
    color:gray;
}

.dwelldisable .entry-field{
    height: 2em;
    width:95%;
    color:gray;
}


.spec-tall{
    display: grid;
    grid-template-columns: 50px 100px 125px 100px 100px 125px 100px;
    align-items: baseline;
    margin-top:5px;
}
.spec-tall .title-wide{
    font-size: 14px;
    grid-column: span 2;
    grid-row:auto;
}
.spec-tall .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.spec-tall .entry-field{
    height:5.5em;
    width:95%
}
.spec-tall .entry-field-wide{
    height:5.5em;
    grid-column: span 2;
}

.spec-2r{
    display: grid;
    grid-template-columns: 50px 100px 125px 100px 100px 125px 100px;
    grid-template-rows: auto auto auto auto auto auto auto;
    align-items: baseline;
    margin-top:5px;
}
.spec-2r .title-wide{
    font-size: 14px;
    grid-column: span 2;
    grid-row:auto;
}
.spec-2r .title{
    font-size: 14px;
    grid-column: 2;
    grid-row:auto;
}
.spec-2r .entry-field{
    height:2em;
    width:95%
}
.spec-2r .entry-field-wide{
    height:2em;
    grid-column: span 2;
}
.spec-2r .entry-field-wide2{
    height:2em;
    grid-row:2;
    grid-column: 3/6;
}

.spec{
    display: grid;
    grid-template-columns: 50px 100px 125px 100px 100px 125px 100px;
    align-items: center;
    margin-top:5px;
}
.spec .title-wide{
    font-size: 14px;
    grid-column: span 2;
    grid-row:auto;
}
.spec .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.spec .entry-field{
    height:2em;
    width:95%
}
.spec .entry-field-wide{
    height:2em;
    
    grid-column: span 2;
}

.empty-bottom{
    height:7em;
}

input:invalid {
    background-color: #ffdddd;
  }

.asset-spec{
    display: flex;
    flex-direction: row;
    margin-left:2%;
}
.asset-spec .title{
    font-size: 14px;
}
.asset-spec .entry-field{
    height:2em;
    margin-left:10px;
}

.action-spec{
    display: flex;
    flex-direction: row;
    margin-left:2%;
}
.action-spec .radio{
    margin-left:10px;
}
.action-spec .title{
    font-size: 14px;
}

.contact-spec{
    display: flex;
    flex-direction: row;
    margin-left:2%;
}
.contact-spec .title{
    font-size: 14px;
}
.contact-spec .entry-field{
    height:2em;
    margin-left:10px;
}

.commenter .adding-comment textarea {
    width: 100%;
    resize: none;
}


.dwell-config{
    height: 2em;
    width:28%;
    
    display: flex;
    flex-direction: row;    
    justify-content: space-evenly;
}
.dwell-config .title{
    width:110px;
    font-size: 14px;
}
.dwell-config .entry-field{
    width:75px;
}
.siteconfig-card-modal {
    height: auto;
    margin-right: 100px;
    margin-top: 120px;
    width: 750px;
    max-width: 1000px;
}

.siteconfig-card-modal .siteconfig-modal {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    min-height: 250px;
    text-align: center;
    /* border: none; */

    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    /* border: 1px solid #999; */
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
}
.sc-title{
    margin-left:auto;
    margin-right:auto;
    font-size: 26px;
    text-align: center;
}

.siteconfig-modal .add-action {
    width: 100%;
}


.siteconfig-modal .sc-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.siteconfig-modal .sc-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    /* margin-right: 5px; */
    /* height: 25px; */
} 
.siteconfig-modal .sc-footer .blank-spacer {
    width: 30%;
    border: none;
  }

.sc-footer .delete-region {
    margin-top: 8px;
    width:15%;
}
.sc-footer .add-comment {
    margin-top: 8px;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
.sc-footer .close-region {
    margin-top: 8px;
    width:15%;
}
.sc-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.geofence-modal .monitored-action{
    width:100%;
    position: relative;
}
.geofence-modal .monitored-action .action-element{
    width:100%;
}



.settings-grid {
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    grid-template-rows: auto auto auto auto;
    grid-gap: 5px 0px;
    gap: 5px 0px;
    margin-top:5%;
    margin-left:5%;
  }


.settings-grid .entry-field{
    height: 2em;
    width:95%;
}
.settings-grid .title-type{
    font-size: 14px;
    grid-column: 1/3;
    grid-row:auto;
    margin-top: auto;
}
.settings-grid .title-units{
    font-size: 14px;
    grid-row:auto;
    margin-top: auto;
    text-align: left;
}

.settings-grid .title{
    font-size: 14px;
    grid-row:auto;
}


.empty-bottom{
    height:7em;
}

input:invalid {
    background-color: #ffdddd;
  }

.geofencemanagement-modal {
    margin-top: 120px;
    height: auto ;
    width: 95vw;
    max-width: 95vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
}

.geofencemanagement-modal .geofencemanagement-content {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    
}

.geoTitle{
    margin-left:auto;
    margin-right:auto;
    font-size: 26px;
}

.geoTitle2{
    margin-left:auto;
    margin-right:auto;
    font-size: 26px;
}


.geofencemanagement-content .topRow{
    margin-top: 3em;
    margin-bottom:0.5em;
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
}

.AssignGroup {
    /* height:3em; */
    background:#83c63f;
    outline: none;
    border: none;
    margin-left:auto;
    margin-right:auto;    
    font-size: 18px;
    border-radius:5px;
    width:8vw;
    grid-column: 1/2;
}
.AssignGroup:hover {
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
}
.AssignGroup:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}

.search-title{
    grid-column: 8;
    text-align: right;
    margin-right: 1em;
    margin-top:8px;
}
  
.groupSearch{
    grid-column: 9/span 10;
}



/*=====================================================================
  Format video list table
=====================================================================*/
.geofence-lister-row table.video-table {
    table-layout: auto !important; 
 }
 .geofence-lister td.gf-region {
   overflow: hidden;
   text-overflow: ellipsis;
   text-align:left;
 }
 .geofence-lister td.gf-bEnter {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
 .geofence-lister td.gf-bExit {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
.geofence-lister td.gf-bSpeeding {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
.geofence-lister td.gf-bDwell {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
.geofence-lister td.gf-bAssetCount {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
.geofence-lister td.gf-email {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
.geofence-lister td.gf-groupname {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
 
 

 /*=====================================================================
  Style the table used for displaying lists of videos
=====================================================================*/

/* hide the icons that come with the library, they're not working correctly */
.geofence-lister th.sortable span.order, .geofence-lister th.sortable span.react-bootstrap-table-sort-order {
    display: none;
  }
  
  .geofence-lister th.sortable {
    position: relative;
    padding-right: 24px;
    text-align: center;
  }
  
  
  .geofence-lister th.sortable::after {
    content: '\F0DC';
    font-family: 'Font Awesome 5 Free';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    margin-left: 0.6em;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
  }
  
  .geofence-lister th.sorted-asc::after {
    content: '\F0DE';
  }
  
  .geofence-lister th.sorted-desc::after {
    content: '\F0DD';
  }
  
  .geofence-lister {
    font-size: 14px;
    /* margin-top: 3em; */
  }
  
  .geofence-lister th {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
 

.assigngroup-modal {
    margin-top: 15vh;
    min-height: 15vh;
    width: 35vw;
    max-width: 35vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
}

.assigngroup-modal .assigngroup-content {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    min-height: 15vh;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    
}
.assigngroup-content .warningmessage{
    margin-top: 5px;
    color: red;
    font-style: italic;
}
.assigngroup-modal .ag-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.ag-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%
}
.geofence-group-settings-modal {
    margin-top: 120px;
    height: auto ;
    width: 50vw;
    max-width: 50vw; 
    
    -webkit-user-select: none; 
    
        -ms-user-select: none; 
    
            user-select: none;
}

.geofence-group-settings-modal .geofence-group-settings-content {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    min-height: 20vh;
    height: calc(20vh + 1 * 5vh);
    height: calc(20vh + var(--policyCount,1) * 5vh);
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    
}

@media screen and (max-width:1300px) { /*only apply if the screen width is less than*/
  .geofence-group-settings-modal .geofence-group-settings-content {  height: calc(28vh + 1 * 6vh);  height: calc(28vh + var(--policyCount,1) * 6vh);  }
  .geofence-group-settings-modal {   width: 65vw; max-width: 65vw; }
}
.group-header{
  width:100%;
  display: grid;
  grid-template-columns: 10% 80% 10% ;
}

.geoTitle{
    margin-left:auto;
    margin-right:auto;
    font-size: 26px;
}

.group-header .geoTitle2{
    font-size: 2.85vh;
    margin-left:auto;
    margin-right:auto;
    grid-column: 2;
}
.group-header .stackedTitle{
  font-size: 26px;
  margin-left:auto;
  margin-right:auto;
  grid-column: 2;
}
.groupEditTitle{
  font-size: 26px;
  font-weight: normal !important;
}


.group-header .helpIcon{
  grid-column: 1;
}

/*=============================
  Configure the buttons at the base of the card
================================*/
.gfs-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.gfs-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%
}

 /*=============================
  Title text for the speed zones
================================*/
.speedpolicyTitle{
  display: grid;
  grid-template-columns: 10% 15% 20% 20% 20% 10% ;
  margin-top:10px;
}
.speedpolicyTitle .title {
  grid-column: span 1;
  font-size: 16px;
}
/*=============================
  Layout of the rows of the speed zones
================================*/
.speedpolicy-display{
  display: grid;
  grid-template-columns: 10% 15% 20% 20% 20% 10% ;
  height: 4vh;
}
@media screen and (max-width:1300px) { /*only apply if the screen width is less than*/
  .speedpolicy-display{ height: 6vh; }
}
.speedpolicy-display .entry-field{
  border: 2px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  margin-left: 5px;
  /* margin-right: 5px; */
  margin-bottom: 5px;
  text-align: center;
  height: 90%;
}
.speedpolicy-display .dropDown{
  border: 2px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  margin-left: 5px;
  /* margin-right: 5px !important; */
  margin-bottom: 5px;
  text-align: center;
  width: 97%;
}
.speedpolicy-display .deleteRow{
  background:none;
  outline: none !important;
  border: none;
  color:red;
  font-size: 18px;
  text-align: left;
}

/*=============================
  Layout of the footer of the speed zones
================================*/
.speedpolicyFooter{
  display: grid;
  grid-template-columns: 10% 15% 20% 20% 20% 10% ;
}
.speedpolicyFooter .addZone{
  margin-top: 10px;
  grid-column: 2;
}


/*=============================
  Layout of the help image popup
================================*/
.gfs-help-popup-modal {
  margin-top: 120px;
  height: auto ;
  width: 50vw;
  max-width: 50vw; 
  -webkit-user-select: none; 
      -ms-user-select: none; 
          user-select: none;
  
}

.gfs-help-popup-modal .gfs-help-popup-content {
  /* border-radius: 4px; */
  transform-origin: top right;
  width: unset;
  margin: auto;
  text-align: center;
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  
}
.gfs-help-popup-content .helpimg {
  width: 100%;
  object-fit:fill;
}

.categorydrop .rw-placeholder{
  color: red;
}

.speeddelta ::-webkit-input-placeholder{
   color: red;
  font-size:unset; 
}

.speeddelta ::-ms-input-placeholder{
   color: red;
  font-size:unset; 
}

.speeddelta ::placeholder{
   color: red;
  font-size:unset; 
}

.conflict .entry-field{
  color:red;
}

.conflict .rw-input{
  color:red;
}

/* Set the color of the Category placeholder */
.categorydrop .dropdownlist__placeholder.css-1jqq78o-placeholder{color: red;}


.dateselect{
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
}

.dateselect .content{
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  height:5vh;
  grid-column: 4;
  /* background: #e9e9e9; */
  border-radius: 4px;
  padding: 7px 0 7px 10px;
}

.react-datepicker{
  margin-right:1vw;
  /* padding-right: 10px; */
}

.vertically-center{
  display: flex ;

  flex-direction: row;
  /* How to space across the div */
  justify-content: flex-end;
  /* Center in the div */
  align-items: center; 
}

.dateselect .label-text{
  padding-right: 2px !important;
  /* color:black; */

}

/* Style the date selection boxes*/
.dateselect .date-pick {
  margin-right: 8px;
  /* Set the border  and corner rounding */
  border: 1px solid #bdc3c7;
  border-radius: 2px;

  /* Update the size of the date selection regions */
  font-size: 11px;
  height: 27px !important;
  width: 90px !important;
  
}

.dateselect .react-datepicker__close-icon {
  right: 11px;
}


.journeynotecard-modal {
    
    /* height: auto ; */
    margin-top: 10vh; 
    width: 60vw;
    height: 80vh;
    max-width: 80vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
}

.journeynotecard-modal .journeynotecard-modal-content {
    transform-origin: top right;
    width: 100%;
    height:100%;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);


    display: grid;
    grid-template-rows: fit-content(10%) 25% auto 20px;

}

.journeynotecard-modal .sc-title{
    margin-bottom: unset;
}


.journeynotecard-modal .add-action {
    width: 100%;
}


.journeynotecard-modal .sc-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.journeynotecard-modal .sc-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    /* margin-right: 5px; */
    /* height: 25px; */
} 
.journeynotecard-modal .sc-footer .blank-spacer {
    width: 30%;
    border: none;
  }

 .journeynotecard-modal .sc-footer .delete-region {
    margin-top: 8px;
    width:15%;
}
.journeynotecard-modal .sc-footer .add-comment {
    margin-top: 8px;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
.journeynotecard-modal .sc-footer .close-region {
    margin-top: 8px;
    width:15%;
}
.journeynotecard-modal .sc-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.journeynotecard-modal .settings-grid {
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    grid-template-rows: auto auto auto auto;
    grid-gap: 5px 0px;
    gap: 5px 0px;
    margin-top:5%;
    margin-left:5%;
  }


.journeynotecard-modal .settings-grid .entry-field{
    height: 2em;
    width:95%;
}
.journeynotecard-modal .settings-grid .title-type{
    font-size: 14px;
    grid-column: 1/3;
    grid-row:auto;
    margin-top: auto;
}
.journeynotecard-modal .settings-grid .title-units{
    font-size: 14px;
    grid-row:auto;
    margin-top: auto;
    text-align: left;
}

.journeynotecard-modal .settings-grid .title{
    font-size: 14px;
    grid-row:auto;
}


.journeynotecard-modal .empty-bottom{
    height:7em;
}


.journeynotecard-modal .soc-spec-tall{
    display: grid;
    grid-template-columns: 50px 100px 125px 100px 100px 125px 100px;
    align-items: baseline;
    margin-top:5px;
}
.journeynotecard-modal .soc-spec-tall .title-wide{
    font-size: 14px;
    grid-column: span 2;
    grid-row:auto;
}
.journeynotecard-modal .soc-spec-tall .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.journeynotecard-modal-content .soc-spec-tall .entry-field{
    height:5.5em;
    width:95%
}
.journeynotecard-modal-content .soc-spec-tall .entry-field-wide{
    height:5.5em;
    grid-column: span 2;
}



.soc-spec{
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    /* grid-template-rows: auto auto auto auto; */
    grid-gap: 5px 0px;
    gap: 5px 0px;
    margin-left:5%;
    margin-bottom:1%;
    /* grid-template-columns: 40px 10px 100px 125px 100px 98px 125px 100px;
    align-items: center;
    margin-top:5px; */
}
.soc-spec .title-wide{
    font-size: 14px;
    grid-column: 1/3;
    grid-row:auto;
}
.soc-spec .entry-field{
    height:2em;
    width:95%
}
.soc-spec .entry-field-wide{
    height:2em;    
    grid-column: span 3;
}




.journeynotecard-modal-content .Table{
    height: 400px;       /* Just for the demo          */
    overflow-y: auto;    /* Trigger vertical scroll    */
    overflow-x: hidden;  /* Hide the horizontal scroll */
 }




/*=====================================================================
  Format the bottom section (tabs and map/tables)
=====================================================================*/
.journeynotecard-modal-content .journeyBottom{

    display: grid;
    grid-template-rows: fit-content(15%) auto;
    /* row-gap: 2%; */
    position: relative;
}

/* Remove the focus outline from the tabs so it doesn't interrupt the layout */
.journeynotecard-modal-content .viewTabs a:focus {   outline: 0; }

.journeynotecard-modal .journeynotecard-modal-content .viewTabs{
    width:50%;
    margin-left: auto;
    margin-right: auto;
    
}
/* Overwrite the general settings of olMap to make it fit the card */
.journeynotecard-modal-content .site-olMap {
    margin-top:unset;
    margin-left: auto;
    margin-right: auto;
    background-color: #cccccc;
    width: 95%;
    height: auto;
}

/* Style the table to auto add the scroll bar if there are too many elements 
   to fit on the allowed vertical space */
.journeynotecard-modal-content .journeyTable{
    width:90%;
    margin-left:5%;
    height: auto;
    max-height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
}
/* Adjust the table's font to match the scaling of the top section on the card */
.journeynotecard-modal tbody{
    font-size: clamp(10px,.9vw,14px);
}

.journeyTable .react-bootstrap-table{
    height: 35vh; /*Force a height so that the pagination is set in a constant spot*/
    /* background-color: green; */
}

.soc-lister-row table.video-table {
    table-layout: auto !important; 
 }
 .soc-lister tr.soc-name {
   overflow: hidden;
   text-overflow: ellipsis;
   text-align:left;
 }
 .soc-lister tr.soc-group {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
 .soc-lister tr.soc-remove {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}


.journeynotecard-modal .report-grid {
    display: grid;
    /* grid-template-columns: 50px 100px 75px 100px 75px 100px 75px 100px 75px 100px; */
    grid-template-columns: 4.3% 8.6% 6.5% 8.6% 6.5% 8.6% 6.5% 8.6% 6.5% 8.6% 6.5% 8.6% 10%; 
    grid-template-rows: auto auto auto auto;
    grid-gap: 5px 0px;
    gap: 5px 0px;
    /* margin-top:5%; */
    margin-left:5%;
  }

  
  .journeynotecard-modal .report-grid .title-type{
    /* font-size: 14px; */
    font-size: .9vw;
    grid-column: 1/3;
    grid-row:auto;
    margin-top: auto;
}
.journeynotecard-modal .report-grid .value{
    /* font-size: 14px; */
    text-align: left;
    font-size: .9vw;
    margin-top: auto;
    width:95%;
}
.journeynotecard-modal .report-grid .value-wide{
    /* font-size: 14px; */
    grid-column: 3 /span 3;
    text-align: left;
    font-size: .9vw;
    margin-top: auto;
    width:95%;
}


.journeynotecard-modal .report-grid .title-type-r{
    /* font-size: 14px; */
    font-size: .9vw;
    grid-column-start: 11;
    grid-column-end: 12;
    grid-row:auto;
    margin-top: auto;
}
.journeynotecard-modal .report-grid .value-r{
    /* font-size: 14px; */
    font-size: .9vw;
    margin-top: auto;    
    grid-column-start: 12;
    grid-column-end: 14;
}
.journeynotecard-modal .report-grid .units{
    /* font-size: 14px; */
    font-size: .9vw;
    grid-row:auto;
    margin-top: auto;
    /* text-align: center; */
    text-align: left;
    padding-left: .3vw;
}

.driverphoto-oc{
    width: 3.25vw;
    height: auto;
    aspect-ratio: 4.25/5;
    object-fit: fill;
}


.AssetAlert {
    /* font-weight: bold; */
    background:#ed1c2450;
    border-radius: 5px;
    padding: 0px 5% 1px;
}
.AssetOffline {
    /* font-weight: bold; */
    color:rgba(50,50,50,.6);
    background:#88888880;
    border-radius: 5px;
    padding: 0px 5% 1px;
    text-align: center;
}
.AssetOnline {
    /* font-weight: bold; */
    background:rgba(141,198,63,.5);
    border-radius: 5px;
    padding: 0px 5% 1px;
}

.connectivity-Asset{
    /* width: 75%; */
    display:grid;
    grid-template-columns: 5% 90% 5%;
    position: relative;
}

.alertMark{
    margin: auto;
    height:2.2vh;
    width: auto;
    grid-column: 1;
}
.asset-name{
    grid-column: 2;
}

.reset-all{
    position: absolute;
    max-height: 50px;
    float:left;
    background-color: #81d4fa; 
    border-color: #81d4fa;
    border-radius: 10px;
    top: 18%;
    left: 5%;
}

.monitored-sets {   
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    height: 63vh;
}
.monitored-set {    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* align-items: center; */
    /* justify-content: center;        */
    justify-content: space-between;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    /* height: 200px; */
    
    /* margin-top:-4em; */
}
/* .monitored-set>* {
    flex: 0 0 13%;
}     */


.monitored-asset {    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;      
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    /* margin: 1%; */
    /* max-width: 20%; */
    width:125px;
    height: 200px;
    align-items: center;
    
}

.status-circle-green {
    background-color: white; 
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: 5px solid green;
  }
  .status-circle-yellow {
    background-color: white; 
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: 5px solid yellow;
  }
  .status-circle-red {
    background-color: white; 
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: 5px solid red;
  }

  /* monitored-radio */
.monitored-asset .monitored-radio{
    width: 30px;
    height: 30px;
}


.monitored-asset .countdown{
    /* height: 30px; */
    margin-top:1em;
    display: block;
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    display: table-cell;
    align-items: center; 
    text-align: center;
    outline:none;
    background-color: #81d4fa;  
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-color: #81d4fa;
    font-size:14px;
}
.monitored-asset .countdown.flash-background{
    -webkit-animation: flash-background 2s infinite ease-in-out;
            animation: flash-background 2s infinite ease-in-out;
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s;
}

@-webkit-keyframes flash-background {
    40%{background-color: #81d4fa;}
    0%,60%,100%{background-color: #ffffff;}
  }
  @keyframes flash-background {
    40%{background-color: #81d4fa;}
    0%,60%,100%{background-color: #ffffff;}
  }


.monitored-asset .asset-name{
    margin-bottom: 1px;
}



.reset-all:focus {
    outline: none;
}

.monitored-asset .stoplight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;   
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 20%;
    align-items: center;
}

.monitored-asset .stoplight-background{
    width: 35px;
    height: 90px;
    border: 1px solid #000;
    position: absolute;
    background-color: #77777780;
    border-radius: 5%;
    z-index: 0;
}

.monitored-asset .stoplight > button{
    width: 20px;
    height: 20px;
    border-radius: 50%;    
    outline:none;
    margin: 4px 2px;
    background-color:#77777780;     
    z-index:1;
    text-align: center;
    font-size:16px;
}
  
.usermanagement-card-modal {
    /* height: auto; */
    margin-right: 100px;
    margin-top: 120px;
    width: 750px;
    max-width: 750px;
    height: 305px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    height:455px;
}

.usermanagement-card-modal .usermanagement-modal {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    
    text-align: center;
    /* border: none; */

    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    /* border: 1px solid #999; */
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

    height:100%
}
.um-title{
    margin-left:auto;
    margin-right:auto;
    font-size: 26px;
}
.um-title .title2{
    font-size: 26px;
    text-align: center;
}
.um-add{
    width:5%;
}

.usermanagement-modal .add-action {
    width: 100%;
}

.usermanagement-modal .comment {
    background: #f4f5f7;
    border-radius: 4px;
    margin: 8px 0;
    padding-left: 8px;
    padding-top: 8px;
    width: 96%; 
}

.geofence-modal .comments {
    overflow-y: auto;
    max-height: 250px;
    margin-bottom: 5px;    
}
.rw-multiselect-taglist {
    max-height: 75px;
    overflow-y: auto;
}

.usermanagement-modal .gf-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.usermanagement-modal .um-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    /* margin-right: 5px; */
    /* height: 25px; */
} 
.usermanagement-modal .um-footer .blank-spacer {
    width: 30%;
    border: none;
  }

.um-footer .delete-region {
    margin-top: 8px;
    width:15%;
}
.um-footer .add-comment {
    margin-top: 8px;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
.um-footer .close-region {
    margin-top: 8px;
    width:15%;
}
.um-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.geofence-modal .monitored-action{
    width:100%;
    position: relative;
}
.geofence-modal .monitored-action .action-element{
    width:100%;
}


.um-spec-tall{
    display: grid;
    grid-template-columns: 40px 10px 100px 125px 100px 98px 125px 100px;
    align-items: baseline;
    margin-top:10px;
}
.um-spec-tall .title-wide{
    font-size: 14px;
    grid-column: 2/4;
    grid-row:auto;
}
.um-spec-tall .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.um-spec-tall .entry-field{
    height:5.5em;
    width:95%
}
.um-spec-tall .entry-field-wide{
    height:5.5em;
    grid-column: span 3;
}






.rw-popup-container{
    top:unset !important
}



.um-spec{
    display: grid;
    grid-template-columns: 40px 10px 100px 125px 100px 98px 125px 100px;
    align-items: center;
    margin-top:5px;
}
.um-spec .icon{
    display:block;
    margin-left: auto;     
}
.um-spec .title-wide{
    font-size: 14px;
    grid-column: 2/4;
    grid-row:auto;
}
.um-spec .title-wide-error{
    font-size: 14px;
    grid-column: span 2;
    grid-row:auto;
    color: red;
    font-style: italic;
}
.um-spec .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.um-spec .entry-field{
    height:2em;
    width:95%
}
.um-spec .entry-field-wide{
    height:2em;    
    grid-column: span 3;
}
.um-spec .entry-field-wide-conflict{
    height:2em;    
    grid-column: span 3;
    background-color:red;
}

.empty-bottom{
    height:7em;
}

input:invalid {
    background-color: #ffdddd;
  }



.usermanagement-popup-modal {
    margin-top: 120px;
    height: auto ;
    width: 95vw;
    max-width: 95vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
    
}

.usermanagement-popup-modal .help-popup {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    
}
.usermanagement-popup-modal .help-popup .roleimg {
    width: 100%;
    object-fit:fill;
}


/* 
* Set up the notification box section at the bottom fo the User notecard
*/
.notificationSection{
    width:98%;
    height:43%;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 1em;
    margin-top:-1em;
    /* border: 1px solid rgba(0, 0, 0, .2); */
}
.notificationSection .title{
    font-size: 20px;
    text-align: center;
}
.notificationSection .toggles{
    display: grid;
    grid-template-columns: 4% 23% 23% 23% 23% ;
    /* justify-content: space-evenly; */
    margin-top:.5em;
    margin-bottom:.5em;
}
.notificationSection .enableAll{
    border-radius: .5em;
    background-color: rgba(141,198,63,0.6);
    border:none;
    outline: none;
    grid-column: 3;
    width: 75%;
    margin-left: auto;
    margin-right:auto;    
}
.enableAll:hover {
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
}
.enableAll:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}
.notificationSection .disableAll{
    border-radius: .5em;
    background-color: rgba(237,28,36,0.6);
    border:none;
    outline: none;
    width: 75%;
    margin-left: auto;
    margin-right:auto;
}
.disableAll:hover {
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
    }
.disableAll:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}

/* Create the section to hold the list of checkboxes and infraction types */
.notificationSection .notificationlist{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    
}
/* Each element in the list contians two items - checkbox and label 
* adjust the width to determine the number of items per row
*/
.notificationSection .notificationElem{
    width:24%;
    /* border: 1px solid rgba(0, 0, 0, .2); */
    display: grid;
    grid-template-columns: 15% 75% ;
}
/* Format the location of the checkbox  - vertically center */
.notificationSection .notificationElem .notifyCheck{
    margin-top:auto;
    margin-bottom: auto;
    margin-left: 10%;
}
/* Format the label  - vertically center */
.notificationSection .notificationElem .notifyText{
    margin-top:auto;
    margin-bottom: auto;
    text-align: left;
}

/*=====================================================================
  Format notification list
=====================================================================*/
.usermanagement-modal .notifTabs{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:50%;
    margin-left: auto;
    margin-right: auto;   
}

input:disabled+label{color:#ccc;} /* Gray out a label following the disabled checkbox*/




.AdminView{
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position:relative;
}

.AdminView .viewSelection{    
    margin-top: -4em;
    display:flex;
    justify-content: space-around;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}

.AdminView .message1{    
    margin-top: 2em;
    font-style: italic;
}

.AdminView .viewSelection .viewSelection-buttons-selected{
    background-color:#00AFED;
    width: 25%;
    outline: none;
    border: none;

}
.AdminView .viewSelection .viewSelection-buttons-selected:hover{
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
}
.AdminView .viewSelection .viewSelection-buttons{
    width: 25%;
    outline: none;
    border: none;
}
.AdminView .viewSelection .viewSelection-buttons:hover{
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
}


.resetUser {
    background:none;    
}
.resetUser:hover {
    transform:scale(1.5,1.5);
    -webkit-transform:scale(1.5,1.5);
    -moz-transform:scale(1.5,1.5);
}
.resetUser:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}


.removeUser {
  border-radius: 1em;
  background-color: rgba(237,28,36,0.6);
  border:none;
  outline: none;
}
.removeUser:hover {
  transform:scale(1.5,1.5);
  -webkit-transform:scale(1.5,1.5);
  -moz-transform:scale(1.5,1.5);
}
.removeUser:active {
  transform:scale(.9,.9);
  -webkit-transform:scale(.9,.9);
  -moz-transform:scale(.9,.9);
}

.enableUser {
border-radius: 1em;
background-color: rgba(141,198,63,0.6);
border:none;
outline: none;
}
.enableUser:hover {
transform:scale(1.5,1.5);
-webkit-transform:scale(1.5,1.5);
-moz-transform:scale(1.5,1.5);
}
.enableUser:active {
transform:scale(.9,.9);
-webkit-transform:scale(.9,.9);
-moz-transform:scale(.9,.9);
}

.disabledAccount{
  /* color:red; */
}

.AdminView .topRow{
  margin-top: 2em;
  margin-bottom:0.5em;
  display: grid;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  grid-template-rows: 2.5em;
}
.addUser {
    position: absolute;
    height:2.75em;
    width: 10vw;
    /* background:#83c63f; */
    background: rgb(141,198,63);
    outline: none;
    border: none;
    
    /* margin-left:auto;
    margin-right:auto;     */
    font-size: 14px;
    border-radius:5px;
    /* grid-column: 1; */
    margin-top: -4.75em;
}
.addUser:hover {
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
}
.addUser:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}
.addUser:focus{
  outline: none;
}
.totalusers{
  grid-column: 1/span 2;
  font-size: 14px;
  margin-top:8px;
  text-align: left;
}

.show-disabled-users{
  font-size: 14px;
  grid-column: 5/span 2;
}
.disabledCheck{
  vertical-align: middle;  
}
.disabledText{
  vertical-align: middle;  
  margin-left:5px;
  margin-top:8px;
}

.search-title{
  grid-column: 8;
  text-align: right;
  margin-right: 1em;
  margin-top:8px;
}

.usernameSearch{
  /* background-color: grey; */
  /* width:20%; */
  grid-column: 9/span 10;
  /* margin-left:auto; */
}


/*=====================================================================
  Format video list table
=====================================================================*/
.user-lister table.video-table {
    table-layout: auto !important; 
 }
 .user-lister table td.um-Username {
   overflow: hidden;
   text-overflow: ellipsis;
 }
 .user-lister table td.um-UserStatus {
  overflow: hidden;
  text-overflow: ellipsis;
}
 .user-lister table td.um-email {
  overflow: hidden;
  text-overflow: ellipsis;
  
}
.user-lister table td.um-sites {
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-lister table td.um-reset {
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-lister table td.um-remove {
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-lister table td.um-role {
  overflow: hidden;
  text-overflow: ellipsis;
}
 
 

 /*=====================================================================
  Style the table used for displaying lists of videos
=====================================================================*/

/* hide the icons that come with the library, they're not working correctly */
.user-lister th.sortable span.order, .video-lister th.sortable span.react-bootstrap-table-sort-order {
    display: none;
  }
  
  .user-lister th.sortable {
    position: relative;
    padding-right: 24px;
  }
  
  .user-lister th.sortable::after {
    content: '\F0DC';
    font-family: 'Font Awesome 5 Free';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    margin-left: 0.6em;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
  }
  
  .user-lister th.sorted-asc::after {
    content: '\F0DE';
  }
  
  .user-lister th.sorted-desc::after {
    content: '\F0DD';
  }
  
  .user-lister {
    font-size: 14px;
  }
  
  .user-lister th {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
 

/*=============================
  Layout of the help image popup for the groups content
================================*/
.site-utils-modal {
    margin-top: 120px;
    height: auto ;
    width: 80vw;
    height:80vh;
    max-width: 80vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
}

.site-utils-modal .site-utils-content {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    height:100%;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

}
  
.site-olMap {
    width: 95%;
    height: 68%;
    margin-top:5px;
    /* margin-left:5px; */
    margin-left: auto;
    margin-right: auto;
}

.um-title{
    margin-left:auto;
    margin-right:auto;
    font-size: 26px;
}
.um-title .title2{
    font-size: 26px;
    text-align: center;
}
.um-add{
    width:5%;
}

.usermanagement-modal .add-action {
    width: 100%;
}

.usermanagement-modal .comment {
    background: #f4f5f7;
    border-radius: 4px;
    margin: 8px 0;
    padding-left: 8px;
    padding-top: 8px;
    width: 96%; 
}

.geofence-modal .comments {
    overflow-y: auto;
    max-height: 250px;
    margin-bottom: 5px;    
}
.rw-multiselect-taglist {
    max-height: 75px;
    overflow-y: auto;
}

.usermanagement-modal .gf-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.usermanagement-modal .um-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    /* margin-right: 5px; */
    /* height: 25px; */
} 
.usermanagement-modal .um-footer .blank-spacer {
    width: 30%;
    border: none;
  }

.um-footer .delete-region {
    margin-top: 8px;
    width:15%;
}
.um-footer .add-comment {
    margin-top: 8px;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
.um-footer .close-region {
    margin-top: 8px;
    width:15%;
}
.um-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.geofence-modal .monitored-action{
    width:100%;
    position: relative;
}
.geofence-modal .monitored-action .action-element{
    width:100%;
}


.um-spec-tall{
    display: grid;
    grid-template-columns: 40px 10px 100px 125px 100px 98px 125px 100px;
    align-items: baseline;
    margin-top:10px;
}
.um-spec-tall .title-wide{
    font-size: 14px;
    grid-column: 2/4;
    grid-row:auto;
}
.um-spec-tall .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.um-spec-tall .entry-field{
    height:5.5em;
    width:95%
}
.um-spec-tall .entry-field-wide{
    height:5.5em;
    grid-column: span 3;
}


.rw-popup-container{
    top:unset !important
}



.um-spec{
    display: grid;
    grid-template-columns: 40px 10px 100px 125px 100px 98px 125px 100px;
    align-items: center;
    margin-top:5px;
}
.um-spec .icon{
    display:block;
    margin-left: auto;     
}
.um-spec .title-wide{
    font-size: 14px;
    grid-column: 2/4;
    grid-row:auto;
}
.um-spec .title-wide-error{
    font-size: 14px;
    grid-column: span 2;
    grid-row:auto;
    color: red;
    font-style: italic;
}
.um-spec .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.um-spec .entry-field{
    height:2em;
    width:95%
}
.um-spec .entry-field-wide{
    height:2em;    
    grid-column: span 3;
}
.um-spec .entry-field-wide-conflict{
    height:2em;    
    grid-column: span 3;
    background-color:red;
}

.empty-bottom{
    height:7em;
}

input:invalid {
    background-color: #ffdddd;
  }



.usermanagement-popup-modal {
    margin-top: 120px;
    height: auto ;
    width: 95vw;
    max-width: 95vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
    
}

.usermanagement-popup-modal .help-popup {
    /* border-radius: 4px; */
    transform-origin: top right;
    width: unset;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    
}
.usermanagement-popup-modal .help-popup .roleimg {
    width: 100%;
    object-fit:fill;
}

.siteoverview-modal {
    margin-top: 100px;
    height: auto ;
    width: 60vw;
    height: 80vh;
    max-width: 80vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
}

.siteoverview-modal .siteoverview-modal-content {
    transform-origin: top right;
    width: 100%;
    height:100%;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

    
    display: grid;
    grid-template-rows: fit-content(10%) fit-content(15%) auto 3em;

}
.siteoverview-modal .sc-title{
    margin-bottom: 1%;
}

.sc-title-warning{
    position: absolute;
    margin-top:0%;
    font-size: 18px;
    font-style: italic;
    left:0;
    right:0;
    color: red;
}

.siteoverview-modal .add-action {
    width: 100%;
}



.siteoverview-modal .sc-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.siteoverview-modal .sc-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    /* margin-right: 5px; */
    /* height: 25px; */
} 

.siteoverview-modal .sc-footer .submitPlaceholder {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    background-color: gray;
    color:white;
    font-family: 'Open Sans';
    /* margin-right: 5px; */
    /* height: 25px; */
} 



.siteoverview-modal .sc-footer .blank-spacer {
    width: 30%;
    border: none;
  }

 .siteoverview-modal .sc-footer .delete-region {
    margin-top: 8px;
    width:15%;
}
.siteoverview-modal .sc-footer .add-comment {
    margin-top: 8px;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
.siteoverview-modal .sc-footer .close-region {
    margin-top: 8px;
    width:15%;
}
.siteoverview-modal .sc-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.siteoverview-modal .settings-grid {
    display: grid;
    /* grid-template-columns: 50px 100px 100px 50px 100px 50px 50px 150px; assume 1000pixels as reference*/
    grid-template-columns: 5% 12% 10% 5% 10% 5% 5% 15%; 
    grid-gap: 5px 0px; 
    gap: 5px 0px;
    
    margin-top:0%;
    margin-left:5%;
  }


.siteoverview-modal .settings-grid .entry-field{
    height: 2em;
    width:95%;
    margin-top: auto;
    margin-bottom: auto;
}
.entry-field-wide{
    height:2em;    
    grid-column: span 3;
    margin-top: auto;
    margin-bottom: auto;
}



.siteoverview-modal .settings-grid .title-type{
    font-size: clamp(10px,.9vw,14px);
    grid-column: 1/3;
    grid-row:auto;
    margin-top: auto;
    margin-bottom: auto;
    text-align: right;
    margin-right:2%;
}
.siteoverview-modal .settings-grid .title-units{
    font-size: clamp(10px,.9vw,14px);
    grid-row:auto;
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;
}
.siteoverview-modal .settings-grid > input{font-size: clamp(10px,.9vw,14px);}

.siteoverview-modal .settings-grid .title{
    font-size: clamp(10px,.9vw,14px);
    grid-row:auto;
}

.siteoverview-modal .settings-grid .speed-button{
    display:flex;
    font-size: clamp(10px,.9vw,14px);
    grid-column: 8;
    grid-row:auto;
    height: 30px;
    line-height: 30px ;
    border-radius: 1em;
    background-color: rgba(141,198,63,0.6);
    border:none;
    outline: none;
    margin-top: auto;
    margin-bottom: auto;
}
.siteoverview-modal .settings-grid .speed-button span{
    display: block;
    line-height: 100%;    
    margin:auto;
}

.siteoverview-modal .settings-grid .speed-button:hover {
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
}
.siteoverview-modal .settings-grid .speed-button:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}
  
.siteoverview-modal-content .rw-widget-picker{
    /* height: 2em; */
    font-size: clamp(10px,.9vw,14px);
}

.siteoverview-modal-content .soc-spec{
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    grid-template-rows: 30px;
    /* grid-template-rows: auto auto auto auto; */
    grid-gap: 5px 0px;
    gap: 5px 0px;
    margin-left:5%;
    margin-bottom:1%;
    font-size: clamp(10px,.9vw,14px);
}
.siteoverview-modal-content .soc-spec .title-wide{
    font-size: clamp(10px,.9vw,14px);
    grid-column: 1/3;
    grid-row:auto;
    text-align: right;
    margin-right:2%;
    margin-top: auto;
    margin-bottom: auto;
}
.siteoverview-modal-content .soc-spec .entry-field{
    height:2em;
    
    width:95%;
    margin-top: auto;
    margin-bottom: auto;
}
.siteoverview-modal-content .soc-spec .entry-field-wide{
    height:2em;    
    grid-column: span 3;
    margin-top: auto;
    margin-bottom: auto;
}



.siteoverview-modal .empty-bottom{
    height:7em;
}


.siteoverview-modal .soc-spec-tall{
    display: grid;
    grid-template-columns: 50px 100px 125px 100px 100px 125px 100px;
    align-items: baseline;
    margin-top:5px;
}
.siteoverview-modal .soc-spec-tall .title-wide{
    font-size: clamp(10px,.9vw,14px);
    grid-column: span 2;
    grid-row:auto;
}
.siteoverview-modal .soc-spec-tall .title{
    font-size: clamp(10px,.9vw,14px);
    grid-column: span 1;
    grid-row:auto;
}
.siteoverview-modal-content .soc-spec-tall .entry-field{
    height:5.5em;
    width:95%
}
.siteoverview-modal-content .soc-spec-tall .entry-field-wide{
    height:5.5em;
    grid-column: span 2;
}


.siteoverview-modal-content .Table{
    height: 400px;       /* Just for the demo          */
    overflow-y: auto;    /* Trigger vertical scroll    */
    overflow-x: hidden;  /* Hide the horizontal scroll */
 }

/* Adjust the table's font to match the scaling of the top section on the card */
.siteoverview-modal tbody{
    font-size: clamp(10px,.9vw,14px);
}


/*=====================================================================
  Format the bottom section (tabs and map/tables)
=====================================================================*/
.siteoverview-modal-content .siteBottom{

    display: grid;
    grid-template-rows: fit-content(15%) auto;
    /* row-gap: 2%; */
    position: relative;
}
.siteoverview-modal-content .viewTabs a:focus {   outline: 0;}

.siteoverview-modal-content .viewTabs{
    width:50%;
    margin-left: 15%;
}

.siteoverview-modal-content .site-olMap {
    width: 95%;
    height: auto;
    margin-top:unset;
    margin-left: auto;
    margin-right: auto;
    background-color: #cccccc;
}

.siteoverview-modal-content .fenceTable{
    /* background-color: grey; */
    width:90%;
    margin-left:5%
}

.fenceTable .react-bootstrap-table{
    height: 35vh; /*Force a height so that the pagination is set in a constant spot*/
    /* background-color: green; */
}

.soc-lister-row table.video-table {
    table-layout: auto !important; 
 }
 .soc-lister tr.soc-name {
   overflow: hidden;
   text-overflow: ellipsis;
   text-align:left;
 }
 .soc-lister tr.soc-group {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
 .soc-lister tr.soc-remove {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}



.AdminView{
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.AdminView .viewSelection{    
    margin-top: -4em;
    display:flex;
    justify-content: space-around;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}

.AdminView .message1{    
    margin-top: 2em;
    font-style: italic;
}

.AdminView .viewSelection .viewSelection-buttons-selected{
    background-color:#00AFED;
    width: 25%;
    outline: none;
    border: none;

}
.AdminView .viewSelection .viewSelection-buttons-selected:hover{
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
}
.AdminView .viewSelection .viewSelection-buttons{
    width: 25%;
    outline: none;
    border: none;
}
.AdminView .viewSelection .viewSelection-buttons:hover{
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
}


.resetUser {
    background:none;    
}
.resetUser:hover {
    transform:scale(1.5,1.5);
    -webkit-transform:scale(1.5,1.5);
    -moz-transform:scale(1.5,1.5);
}
.resetUser:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}

.removeAsset {
    border-radius: 1em;
    background-color: rgba(237,28,36,0.6);
    border:none;
    outline: none;
}
.removeAsset:hover {
    transform:scale(1.5,1.5);
    -webkit-transform:scale(1.5,1.5);
    -moz-transform:scale(1.5,1.5);
}
.removeAsset:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}

.enableAsset {
  border-radius: 1em;
  background-color: rgba(141,198,63,0.6);
  border:none;
  outline: none;
}
.enableAsset:hover {
  transform:scale(1.5,1.5);
  -webkit-transform:scale(1.5,1.5);
  -moz-transform:scale(1.5,1.5);
}
.enableAsset:active {
  transform:scale(.9,.9);
  -webkit-transform:scale(.9,.9);
  -moz-transform:scale(.9,.9);
}

.removeSite {
  border-radius: 1em;
  background-color: rgba(237,28,36,0.6);
  border:none;
  outline: none;
}
.removeSite:hover {
  transform:scale(1.5,1.5);
  -webkit-transform:scale(1.5,1.5);
  -moz-transform:scale(1.5,1.5);
}
.removeSite:active {
  transform:scale(.9,.9);
  -webkit-transform:scale(.9,.9);
  -moz-transform:scale(.9,.9);
}

.enableSite {
border-radius: 1em;
background-color: rgba(141,198,63,0.6);
border:none;
outline: none;
}
.enableSite:hover {
transform:scale(1.5,1.5);
-webkit-transform:scale(1.5,1.5);
-moz-transform:scale(1.5,1.5);
}
.enableSite:active {
transform:scale(.9,.9);
-webkit-transform:scale(.9,.9);
-moz-transform:scale(.9,.9);
}

.disabledAccount{
  /* color:red; */
}

.AdminView .topRow{
  margin-top: 2em;
  margin-bottom:0.5em;
  display: grid;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  grid-template-rows: 2.5em;
}
.totalassets {
    font-size: 14px;
    border-radius:5px;
    grid-column: 1/span 2;
    margin-right: 1em;
    margin-top:8px;
    text-align: left;    
}

.show-disabled-users{
  font-size: 14px;
  grid-column: 5/span 2;
}
.disabledCheck{
  vertical-align: middle;  
}
.disabledText{
  vertical-align: middle;  
  margin-left:5px;
  margin-top:8px;
}

.search-title{
  grid-column: 8;
  text-align: right;
  margin-right: 1em;
  margin-top:8px;
}

.usernameSearch{
  /* background-color: grey; */
  /* width:20%; */
  grid-column: 9/span 10;
  /* margin-left:auto; */
}

.am-assetFormat{
  width: 95%; 
  height:1.5em;
  display:grid;
  grid-template-columns: 32% 30% 32%;
}
 
.confirmButton{
  background-color: rgb(141,198,63);
  grid-column: 1;
  height: 75%;
  text-align: center;
}
.asset-name{
  grid-column: 2;
  white-space:unset;
}
 


/*=====================================================================
  Format asset management  table
=====================================================================*/
.asset-lister table.video-table {
    table-layout: auto !important; 
 }
 .asset-lister table td.am-assetid {
   overflow: hidden;
   text-overflow: ellipsis;
 }
 .asset-lister table td.am-status {
  overflow: hidden;
  text-overflow: ellipsis;
}
.asset-lister table td.am-sites {
  overflow: hidden;
  text-overflow: ellipsis;
}
 
 

 /*=====================================================================
  Style the table used for displaying lists of videos
=====================================================================*/

/* hide the icons that come with the library, they're not working correctly */
.asset-lister th.sortable span.order, .video-lister th.sortable span.react-bootstrap-table-sort-order {
    display: none;
  }
  
  .asset-lister th.sortable {
    position: relative;
    padding-right: 24px;
  }
  
  .asset-lister th.sortable::after {
    content: '\F0DC';
    font-family: 'Font Awesome 5 Free';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    margin-left: 0.6em;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
  }
  
  .asset-lister th.sorted-asc::after {
    content: '\F0DE';
  }
  
  .asset-lister th.sorted-desc::after {
    content: '\F0DD';
  }
  
  .asset-lister {
    font-size: 14px;
    /* margin-top: 3em; */
  }
  
  .asset-lister th {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
 

.drivercard-modal {
    margin-top: 120px;
    height: auto ;
    width: 60vw;
    height: 60vh;
    max-width: 80vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
}

.drivercard-modal .drivercard-modal-content {
    transform-origin: top right;
    width: 100%;
    height:100%;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

}
.drivercard-modal-content .dc-title{
    margin-left:auto;
    margin-right:auto;
    font-size: 26px;
    text-align: center;
}

.drivercard-modal-content .dc-top{
    margin-top: 1em;
    margin-bottom: 1em;
    height:60%;
    display: flex;
}
.drivercard-modal-content .dc-top .dc-tl{
    width: 65%;
}
.drivercard-modal-content .dc-top .dc-tr{
    width: 35%;
}

.drivercard-modal-content .driverphoto{
    /* width:auto; */
    /* height:80%; */
    height:auto; 
    width:45%;

}

.drivercard-modal-content .hamburger{
    width: 2%;
    height: auto;
    position: absolute;
    top: 6px;
    right: 8px;
}

.drivercard-modal .add-action {
    width: 100%;
}


.drivercard-modal .dc-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
}
.drivercard-modal .dc-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    pointer-events: all;
    /* margin-right: 5px; */
    /* height: 25px; */
} 
.drivercard-modal .dc-footer .blank-spacer {
    width: 30%;
    border: none;
  }

 .drivercard-modal .dc-footer .delete-region {
    margin-top: 8px;
    width:15%;
}
.drivercard-modal  .add-comment {
    /* margin-top: 2em; */
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
.drivercard-modal .dc-footer .close-region {
    margin-top: 8px;
    width:15%;
}
/* .drivercard-modal .dc-footer .adding-buttons{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */


.drivercard-modal .settings-grid {
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    grid-template-rows: auto auto auto auto;
    grid-gap: 5px 0px;
    gap: 5px 0px;
    margin-top:5%;
    margin-left:5%;
  }


.drivercard-modal .settings-grid .entry-field{
    height: 2em;
    width:95%;
}
.drivercard-modal .settings-grid .title-type{
    font-size: 14px;
    grid-column: 1/3;
    grid-row:auto;
    margin-top: auto;
}
.drivercard-modal .settings-grid .title-units{
    font-size: 14px;
    grid-row:auto;
    margin-top: auto;
    text-align: left;
}

.drivercard-modal .settings-grid .title{
    font-size: 14px;
    grid-row:auto;
}


.drivercard-modal .empty-bottom{
    height:7em;
}


.drivercard-modal .soc-spec-tall{
    display: grid;
    grid-template-columns: 50px 100px 125px 100px 100px 125px 100px;
    align-items: baseline;
    margin-top:5px;
}
.drivercard-modal .soc-spec-tall .title-wide{
    font-size: 14px;
    grid-column: span 2;
    grid-row:auto;
}
.drivercard-modal .soc-spec-tall .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.drivercard-modal-content .soc-spec-tall .entry-field{
    height:5.5em;
    width:95%
}
.drivercard-modal-content .soc-spec-tall .entry-field-wide{
    height:5.5em;
    grid-column: span 2;
}

.drivercard-modal-content .soc-spec{
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    grid-gap: 5px 0px;
    gap: 5px 0px;
    margin-left:5%;
    margin-bottom:1%;
}
.drivercard-modal-content .soc-spec .title-wide{
    font-size: 14px;
    grid-column: 1/3;
    grid-row:auto;
    text-align: left;
}
.drivercard-modal-content .soc-spec .entry-field{
    height:2em;
    width:95%
}
.drivercard-modal-content .soc-spec .entry-field-wide{
    height:2em;    
    grid-column: span 3;
}

.drivercard-modal-content .soc-lister-row table.video-table {
    table-layout: auto !important; 
 }
.drivercard-modal-content .soc-lister tr.soc-name {
   overflow: hidden;
   text-overflow: ellipsis;
   text-align:left;
 }
.drivercard-modal-content .soc-lister tr.soc-group {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
.drivercard-modal-content .soc-lister tr.soc-remove {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}

/* Comments */
.drivercard-modal-content .commenter {
    width: 81%;
    margin-left: auto;
    margin-right:auto;
    margin-top:-1em;
}
.drivercard-modal-content .commenter .adding-buttons{
    height:4vh;
}
.drivercard-modal-content .commenter .adding-buttons .btn{
    height: 100%;
    font-size: 0.9em;
}


.drivercard-modal-content .comment {
    background: #f4f5f7;
    border-radius: 4px;
    margin: 8px 0;
    padding-left: 8px;
    padding-top: 8px;
    width: 96%; 
    text-align: left;
}

.drivercard-modal-content .comment pre {
    margin-bottom: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
    font-family: 'Open Sans';
}
.drivercard-modal-content .comment-timestamp pre {
    color: #555;
}



.drivercard-modal-content .comments {
    overflow-y: auto;
    max-height: 50%;
    margin-bottom: 5px;    
    margin-left:5%;
}
.drivercard-modal-content .comments-title {
    margin-top: 8px;
    text-align: left;
    margin-left:5%;
}





.drivercard-modal-content .comment-timestamp {
    font-size: 0.8em;
    margin-top: -0.5em;
    left : 2%;
    display:inline-block;
}

.drivercard-modal-content .comment-username {
    position: relative;
    font-size: 0.8em;
    margin-top: -0.5em;
    display:inline-block;
}



.AdminView{
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.AdminView .viewSelection{    
    margin-top: -4em;
    display:flex;
    justify-content: space-around;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}

.AdminView .message1{    
    margin-top: 2em;
    font-style: italic;
}

.AdminView .viewSelection .viewSelection-buttons-selected{
    background-color:#00AFED;
    width: 25%;
    outline: none;
    border: none;

}
.AdminView .viewSelection .viewSelection-buttons-selected:hover{
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
}
.AdminView .viewSelection .viewSelection-buttons{
    width: 25%;
    outline: none;
    border: none;
}
.AdminView .viewSelection .viewSelection-buttons:hover{
    transform:scale(1.15,1.15);
    -webkit-transform:scale(1.15,1.15);
    -moz-transform:scale(1.15,1.15);
}


.resetUser {
    background:none;    
}
.resetUser:hover {
    transform:scale(1.5,1.5);
    -webkit-transform:scale(1.5,1.5);
    -moz-transform:scale(1.5,1.5);
}
.resetUser:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
}

.disabledAccount{
  /* color:red; */
}

.AdminView .topRow{
  margin-top: 2em;
  margin-bottom:0.5em;
  display: grid;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  grid-template-rows: 2.5em;
}
.totalassets {
    font-size: 14px;
    border-radius:5px;
    grid-column: 1/span 2;
    margin-right: 1em;
    margin-top:8px;
    text-align: left;
}

.show-disabled-users{
  font-size: 14px;
  grid-column: 5/span 2;
}
.disabledCheck{
  vertical-align: middle;  
}
.disabledText{
  vertical-align: middle;  
  margin-left:5px;
  margin-top:8px;
}

.search-title{
  grid-column: 8;
  text-align: right;
  margin-right: 1em;
  margin-top:8px;
}

.usernameSearch{
  /* background-color: grey; */
  /* width:20%; */
  grid-column: 9/span 10;
  /* margin-left:auto; */
}


/*=====================================================================
  Format asset management  table
=====================================================================*/
.driver-lister table.video-table {
    table-layout: auto !important; 
 }
 .driver-lister table td.dm-driverid {
   overflow: hidden;
   text-overflow: ellipsis;
 }
 .driver-lister table td.dm-status {
  overflow: hidden;
  text-overflow: ellipsis;
}
.driver-lister table td.dm-date {
  overflow: hidden;
  text-overflow: ellipsis;
}
.driver-lister table td.dm-sites {
  overflow: hidden;
  text-overflow: ellipsis;
}


.dm-driveridFormat{
  width: 95%; 
  height:1.5em;
  display:grid;
  grid-template-columns: 32% 30% 32%;
}
 
.confirmButton{
  background-color: rgb(141,198,63);
  grid-column: 1;
  height: 75%;
  text-align: center;
}
.driverid-name{
  grid-column: 2;
  white-space:unset;
}
 
 

 /*=====================================================================
  Style the table used for displaying lists of videos
=====================================================================*/

/* hide the icons that come with the library, they're not working correctly */
.driver-lister th.sortable span.order, .video-lister th.sortable span.react-bootstrap-table-sort-order {
    display: none;
  }
  
  .driver-lister th.sortable {
    position: relative;
    padding-right: 24px;
  }
  
  .driver-lister th.sortable::after {
    content: '\F0DC';
    font-family: 'Font Awesome 5 Free';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    margin-left: 0.6em;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
  }
  
  .driver-lister th.sorted-asc::after {
    content: '\F0DE';
  }
  
  .driver-lister th.sorted-desc::after {
    content: '\F0DD';
  }
  
  .driver-lister {
    font-size: 14px;
    /* margin-top: 3em; */
  }
  
  .driver-lister th {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .disableDriver{
    border-radius: 1em;
    background-color: rgba(237,28,36,0.6);
    border:none;
    outline: none;
  }
  .disableDriver:hover {
    transform:scale(1.5,1.5);
    -webkit-transform:scale(1.5,1.5);
    -moz-transform:scale(1.5,1.5);
  }
  .disableDriver:active {
      transform:scale(.9,.9);
      -webkit-transform:scale(.9,.9);
      -moz-transform:scale(.9,.9);
  }

  .enableDriver{
    border-radius: 1em;
    background-color: rgba(141,198,63,0.6);
    border:none;
    outline: none;
  }
  .enableDriver:hover {
    transform:scale(1.5,1.5);
    -webkit-transform:scale(1.5,1.5);
    -moz-transform:scale(1.5,1.5);
  }
  .enableDriver:active {
    transform:scale(.9,.9);
    -webkit-transform:scale(.9,.9);
    -moz-transform:scale(.9,.9);
  }


/*******************************************************************
Style the filter bar for the Call-ins tab:
********************************************************************/

.callin-filters {
    /* background: #e9e9e9; */
    
    position: relative;
    margin-left:auto; /*Add space to the left side: right aligned*/
    margin-top:-6em; /*Shift up*/
  
    /* grid-column: 3; */
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  
    display: grid;
    grid-template-columns: auto fit-content(250px) auto;
    /* The date pick section needs a fixed width (at least 200px)`*/

    /*Auto scale down to the required size*/
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content; 

    border-radius: 4px;
    padding: .7vh 0px .7vh .5vw;
    font-size: 13px;

    /* border: solid 1px black; */
  }

  /* .callin-filters > * {
    border: solid 1px black;
  } */

/*******************************************************************
  Apply section
********************************************************************/
.callin-filters .section-apply {
    /* Define the layout as grid so we can center easily in the grid layout */
    display: grid;
    
    align-items:center; /*Position verically in cell*/
    justify-items:end; /*Position horizontally in cell*/
    padding-right:1em;
    /* place-items: center;
    text-align: right; */
    /* width: fit-content; */
    
}

.callin-filters .section-apply .apply-filters{
    margin-right: unset;
    
}



/* Use the fixed date-picker size to prevent changes to experiences */
.callin-filters .date-pick {
    height: 27px !important;
    width: 100px !important;
    font-size: 13px;
    border: 1px solid #bdc3c7;
    border-radius: 2px;
}
/* .callin-filters .react-datepicker__close-icon {   right: 12px;  } */

/*******************************************************************
  Dropdown filters Section
********************************************************************/
.callin-filters .section-dropdowns {
    position: relative;    
     /* Define the layout as grid so we can center easily in the grid layout */
    display: grid;
    place-items: center;
    max-width: 60vw;
}

/* Remove/update the from the dropdown menus*/
.callin-filters :nth-child(2), .callin-filters :nth-child(3) {  padding: 0px;  }
.callin-filters .filter-drop-container .dropdown-wrap{   padding-right:unset !important; }  
.callin-filters .label-wrap {
    padding-right: 3px !important;
    padding-left:10px;
}
  
  

/* Update the formatting on the select - this is the visible top part of the dropdown menu */
  .callin-filters select {
    height: 27px !important;
    font-size: 13px;
    /* Remove the default filtering of the select dropdown */
    -webkit-appearance: none;
    -moz-appearance: none;
     appearance: none; 
    /* Add some styling */
    display: block;
    font-family: sans-serif;
    color: #444;
    line-height: 1.3;
    padding: .25em 1.4em .25em .8em;
    max-width: 100%; /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: .5em;
    background-color: #fff;
    /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
        for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
    */
    /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), */
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right .4em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 1.25em auto, 100%;
  }
/*Limit the number of characters in the dropdown selection (make it smaller on smaller screens?)*/
/* 4ch is the starting size of thte empty dropdown with an arrow, need +3 ch for the ellipsis */
@media screen and (max-width:1200px) { /*only apply if the screen width is less than*/
    .callin-filters .filter-drop-asset select.form-control.form-control-default{    max-width: 11ch; } /*XXYYYY format allowed without ellipsis*/
    .callin-filters .filter-drop-clip select.form-control.form-control-default{     max-width: 13ch;}
    .callin-filters .filter-drop-hardware select.form-control.form-control-default{ max-width: 14ch;}
    .callin-filters .filter-drop-site select.form-control.form-control-default{     max-width: 14ch;}
    .callin-filters .filter-drop-edge3 select.form-control.form-control-default{    max-width: 10ch; }        
}
/* Set a default length cutoff on a dropdown element:  */
.callin-filters select.form-control.form-control-default {
    max-width: 9vw;
    overflow: hidden;
    text-overflow: clip; /*Doesn't work on android?*/
    text-overflow: ellipsis;
    white-space: nowrap;
}
  
  
.callin-lister {
  font-size: 14px;
  margin-top: -4em;
}


/*=====================================================================
  Format Call-ins list table
  - call-in lister is a surrounding div
  - callin-table is the actual table
=====================================================================*/
.callin-lister table.callin-table {
    table-layout: auto !important; 
 }
.callin-lister .table-row{
  /* background-color: red ; */
  height: 15px !important;
}




 /* hide the icons that come with the library, they're not working correctly */
.callin-lister th.sortable span.order, .callin-lister th.sortable span.react-bootstrap-table-sort-order {
    display: none;
  }
  
  .callin-lister th.sortable {
    position: relative;
    padding-right: 24px;
  }
  
  .callin-lister th.sortable::after {
    content: '\F0DC';
    font-family: 'Font Awesome 5 Free';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    margin-left: 0.6em;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
  }
  
  .callin-lister th.sorted-asc::after {
    content: '\F0DE';
  }
  
  .callin-lister th.sorted-desc::after {
    content: '\F0DD';
  }
  

  .callin-lister .nodata{
    font-size: 150%;
    font-weight: 400;
    margin-top:5vh;
  }
  
  
  .callin-lister th {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }


/* Configure the cell in the Table: */
.callin-table tbody tr td {
    /* max-width: 15vw !important; */
    width: calc(10vw - 0.75rem);
    overflow-x: hidden; 
    text-overflow: ellipsis;    
    vertical-align: middle;
    /* Enable word wrapping in the cells */
    word-wrap: break-word;
    white-space: unset;
    
}
/* Configure the header in the Table: */
table.callin-table thead tr th{
  width: calc(10vw - 0.75rem);
  word-wrap: break-word;
  white-space: unset;
  vertical-align: middle;
}

/* Don't allow the up/down arrows to be split on different lines */
table.callin-table thead tr th > span{
  white-space: nowrap;
}

/* Individually change, start at 1 - with Margins should have around 95vw*/
table.callin-table th:nth-child(1){  width: calc(10vw + 1.5rem);} /*Review card column */
table.callin-table td:nth-child(1){  width: calc(10vw + 1.5rem);} 
table.callin-table th:nth-child(2){  width: 13vw;} /*Infraction type column */
table.callin-table td:nth-child(2){  width: 13vw;}
table.callin-table th:nth-child(5){  width: calc(11vw - 0.75rem);} /*Contact name column */
table.callin-table td:nth-child(5){  width: calc(11vw - 0.75rem);}
/* Set the last row to auto resize to the rest of the table */
table.callin-table th:nth-child(9){  width: auto;} /*Notes column */
table.callin-table td:nth-child(9){  width: auto;}


.callin-table .review-card{
    width: 9vw;
    height: 6.75vh;
    margin-left:auto;
    margin-right:auto;
    transition-duration:50ms;     
}

.callin-table .review-card .date{
  transform: translateY(-.15em);
}

.callin-table .review-card:hover{
  transform:scale(105%,105%) 
}

.notes-short{
    max-width: 15vw !important;
    overflow-x: hidden; 
    text-overflow: ellipsis;    
}
.notes-long{
    /* max-width: 15vw !important;
    overflow-x: hidden; 
    text-overflow: ellipsis;     */
    /* position: relative; */
    background-color: white;
    margin-right:100px;
    text-align: center;
    min-height:2em;
    /* width:35%; */
    /* padding-left: 2%;
    padding-right: 2%; */
     border: 1px solid;
     border-radius: 5px;
    /* margin-top:400px; */
}
.notes-long .comments-section{
    width: 100%;
    border-bottom:1px solid black;
    padding-bottom: 20px;
    /* border-radius: 5px; */
}
.notes-long th, td, tr{
    text-align: center;
}
.notes-long th, td{
    border-bottom:1px solid black;
    padding: 5px;
}
.notes-long th:nth-child(2){
    border-left:1px solid black;
    border-right:1px solid black;
}
.notes-long td:nth-child(2){
    border-left:1px solid black;
    border-right:1px solid black;
}

.notes-short .callcount{
  /* padding: 5px; */
  /* font-size: 12px; */
  border-radius: 50%;
  padding-left:2%;
  padding-right:2%;
  outline:1px solid black;
  margin-left:1em; 
  margin-top: auto;
  margin-bottom: auto;
}
.notes-short .badge{    
  padding: 5px;
  font-size: 12px;
  margin-left:0.5em;
  margin-top: auto;
  margin-bottom: auto;
}

/* table, th, td {
    border:1px solid black;
  } */


/* Add a vertical scroll bar to the table */
.callin-table tbody {
    display:block;
    height:60vh;
    overflow-y:scroll;
}
.callin-table thead{
    display:table;
    width:100%;
    table-layout:fixed;
}
.callin-table tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
.callin-table .status-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.modal-content .progress{
  margin-bottom:1vh;
}
/* Display the total number of elements at the bottom of hte table */
.callin-lister .table_total{
  display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  grid-column-gap: .5vw;
  -webkit-column-gap: .5vw;
          column-gap: .5vw;
  align-items: center;  
  width: 15%;
}



.riskprofile{
  
  display: grid;
  grid-template-rows: 10% 90%;
  grid-template-columns: 100%;
  
  -webkit-user-select: none;
  
      -ms-user-select: none;
  
          user-select: none;
  margin-top:-8vh; 
  height:70vh; /*Higher than this causes the scrollbar? */
  /* position: relative; */
}
.riskprofile .toprow{
  display: grid;
  grid-template-columns: 35% 65%;
  grid-template-rows: auto;
}
.riskprofile .riskcontent{
  height:100%;
}

.riskprofile .callin-filters{
  grid-column: unset;
  margin-top:-1em;
}

.riskprofile .react-datepicker-popper{
  z-index: 5;
}

.riskprofile .form-control{
  min-width: 5.815em;
}


/*=====================================================================
  Format list table
=====================================================================*/
.riskworst {
  height:100%;
  font-size: 14px;
}

.riskworst .risktable{
  width: 100%;
  height: 96%;
  /* max-height: 50%; */
  overflow-y: scroll;
  overflow-x: hidden;
}

.riskworst .risktableheader{
  /* width:calc(100% - 16.8px); remove the scrollbar */
  width:100%;
  display: grid;
  grid-template-columns: 3vw 20% 15% 20% 20% auto;  
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  
  font-weight: bold;
  font-size: 16px;

  background-color: rgba(0, 175, 237, 0.04);
  
}
.riskworst .risktableheader >div{
  border-right: 1px solid black;
  vertical-align: bottom;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.risktableheader div:nth-child(1) {  border-left: 1px solid black;} 





/* Comments */
.radialcard-modal-content .commenter {
  width: 90%;
  margin-left: auto;
  margin-right:auto;
}
.radialcard-modal-content .commenter .adding-buttons{
  height:4vh;
}
.radialcard-modal-content .commenter .adding-buttons .btn{
  height: 100%;
  font-size: 0.9em;
}


.radialcard-modal-content .comment {
  /* background: #f4f5f7; */
  /* border-radius: 4px; */
  /* margin: 8px 0;
  padding-left: 8px;
  padding-top: 8px; */
  width: 96%; 
  text-align: left;
}

.radialcard-modal-content .comment pre {
  margin-bottom: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  font-family: 'Open Sans';
}
.radialcard-modal-content .comment-timestamp pre {
  color: #555;
}



.radialcard-modal-content .comments {
  overflow-y: auto;
  max-height: 64%;
  margin-bottom: 5px;    
  margin-left:5%;
}
.radialcard-modal-content .comments-title {
  margin-top: 8px;
  text-align: left;
  margin-left:5%;
}


.radialcard-modal-content .comment-timestamp {
  font-size: 0.8em;
  margin-top: -0.5em;
  left : 2%;
  display:inline-block;
}

.radialcard-modal-content .comment-username {
  position: relative;
  font-size: 0.8em;
  margin-top: -0.5em;
  display:inline-block;
}

.riskworst .nodata{
  font-size: 24px;
  font-style: italic; 
}

/*=====================================================================
  Format list table
=====================================================================*/
.worsttile-content{
  /* height:19.25vh; */
  width: 100%;
  /* border: 1px solid black;   */
  border-bottom: 1px solid black;  
  /* background-color: white; */
  background-color: rgba(0, 175, 237, 0.02);
  transition-duration:50ms; 
  z-index:2;
}

.worsttile-content .tiletable{
  width:100%;
  display: grid;
  grid-template-columns: 3vw 20% 15% 20.05% 20% auto;
}



.worsttile-content .tiletable> div{
  text-align: center;    
  /* margin-top:auto;
  margin-bottom:auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border-right: 1px solid black; */
}

.worsttile-content .radialdiv{
  height:19.1vh;
}

.worsttile-content .card-half {
  height:100%;
  margin-right:auto;
  margin-left:auto;
}


.worsttile-content .spinner-wrapper{
  flex-direction:row !important;
}


.worsttile-content .tiletable .tile-severity{
  margin-top:unset;
  margin-bottom:unset;
}


.worsttile-content .driverphoto{
  width: 100%;    
  height: 100%;
}
.worsttile-content .driverphoto .photo{
  width: auto;    
  height: 15vh;    
  margin-top:1%;
  object-fit: contain;
}



.worsttile-content:hover {
  transform:scale(1.01,105%) translate(0.05%,2.5%); 
  /* background-color: #00AFED15; */
  background-color: rgb(235,249,254);
  border-top: 1px solid black;
  z-index: 3;
}
.worsttile-content:active {
  transform:scale(.98);  
}




/* Comments */
.radialcard-modal-content .commenter {
  width: 90%;
  margin-left: auto;
  margin-right:auto;
}
.radialcard-modal-content .commenter .adding-buttons{
  height:4vh;
}
.radialcard-modal-content .commenter .adding-buttons .btn{
  height: 100%;
  font-size: 0.9em;
}


.radialcard-modal-content .comment {
  /* background: #f4f5f7;
  border-radius: 4px;
  margin: 8px 0;
  padding-left: 8px;
  padding-top: 8px; */
  width: 96%; 
  text-align: left;
}

.radialcard-modal-content .comment pre {
  margin-bottom: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  font-family: 'Open Sans';
}
.radialcard-modal-content .comment-timestamp pre {
  color: #555;
}



.radialcard-modal-content .comments {
  overflow-y: auto;
  max-height: 64%;
  margin-bottom: 5px;    
  margin-left:5%;
}
.radialcard-modal-content .comments-title {
  margin-top: 8px;
  text-align: left;
  margin-left:5%;
}


.radialcard-modal-content .comment-timestamp {
  font-size: 0.8em;
  margin-top: -0.5em;
  left : 2%;
  display:inline-block;
}

.radialcard-modal-content .comment-username {
  position: relative;
  font-size: 0.8em;
  margin-top: -0.5em;
  display:inline-block;
}

.riskworst .nodata{
  font-size: 24px;
  font-style: italic; 
}


.riskNotes{
  /* max-height: 10vh !important;  */
}
.riskNotes .notet{
  table-layout: fixed;
  white-space: normal !important;
}
.riskNotes tbody{
  height: 100%;
}
.riskNotes .notetd{
  word-wrap: break-word;
  white-space: pre-wrap;
}

.riskstats{
  height:103%; 
  /* height:130vh; */
  display: grid;
  grid-template-columns: auto;
  /* grid-template-rows: 50vh 80vh; */
  /* grid-template-rows: 42vh 45vh 10vh;  */
  grid-template-rows: auto auto auto;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;  
  /* background-color: red; */
  z-index:1;
}
.riskstats .drivertable{
  height: 42vh;
  position: relative;
  display: grid;
  grid-template-columns: repeat(10, 9vw);
  grid-template-rows: 19vh,19vh;
  grid-column-gap: 0.25vw;
  -webkit-column-gap: 0.25vw;
          column-gap: 0.25vw; 
  /* row-gap: .25vh; */
  margin-left:0.125vw;
  margin-top:1.2vh;
  width:100%;  
  z-index: 2;
}

.riskstats .piecharts{
  grid-row:2;
  height: 45vh;
  width:100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-column-gap: 1vw;
  -webkit-column-gap: 1vw;
          column-gap: 1vw;
  margin-top:-3vh;
  z-index: 1;
}
.piecharts .card{
  height:45vh;
  border: none;
}

.piecharts .stats-piechart-title{
  position: relative;
  z-index: 3;
  transform:translate(-5.8vw,3.5vh);  
  
  font-size: 3vh ;
  font-weight: 700 ;
  width:100%;  
  text-align: center;
}

.riskstats .stats-foot{
  height: 10vh;
  margin-top:-2vh;
  width:100%;  
  z-index: 2;
  transform:translateX(-5.5vw);  
}

.riskstats .nodata{
  font-size: 24px;
  font-style: italic; 
}


.google-visualization-tooltip {  
  background-color: red;
  /* margin-left: 150px !important;  */
  /* z-index:+1; */
}


.drivertile{
  position: relative;
  width: 9vw;    
  height: 19vh;  
  display: grid;
  grid-template-rows: 14% 60% 13% 13%;
  border: 1px solid rgb(0,174,239);
  /*Make the hover smoother*/ 
  transition-duration:250ms; 
  background-color: white;

  font-size: clamp(12px,2.25vh,21px); 

}

.drivertile-loading{
  width: 9vw;    
  height: 19vh;  
  display: grid;
  grid-template-rows: 14% 60% 13% 13%;
  border: 1px solid rgb(0,174,239);
  /*Make the hover smoother*/ 
  transition-duration:250ms; 
  background-color: rgba(0,174,239,.1);
}


.drivertile:hover {
  transform:scale(1.15); 
}
.drivertile:active {
  transform:scale(.98);  
}


/* Set the border bottom on each element of the tile to give it a table appearance */
.drivertile .dtrow{ 
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 1;  
}
.drivertile-loading .dtrow{ 
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 1;  
}

.drivertile .dtphoto{ border-bottom: 1px solid white;}
/* Set the background color on each of the tile rows */
.drivertile .dttitle{ background-color: rgb(0,174,239); color:white;}
.drivertile-loading .dttitle{ background-color: rgb(0,174,239); color:white;}
.drivertile .dtphoto{ background-color: rgba(0,174,239,.2);}
.drivertile .dtcount{ background-color: rgba(0,174,239,.1);}
.drivertile .dtpercent{ background-color: rgba(0,174,239,.2);}

/* .drivertile .dtphoto{ border-bottom: 1px solid rgb(0,174,239);} */

/* Auto size based on the size of the tile */
.drivertile .driverphoto{
  width: auto;    
  height:95%;
  margin:auto;
  max-width: 98%;  
}




.combinedcard-modal {
  margin-top: 5vh;
  height: auto ;
  width: 60vw;
  height: 70vh;
  max-width: 80vw; 
  -webkit-user-select: none; 
      -ms-user-select: none; 
          user-select: none;
  transition-duration:250ms; 
}

.combinedcard-modal .combinedcard-modal-content {
  transform-origin: top right;
  width: 100%;
  height:100%;
  margin: auto;
  text-align: center;
  position: relative;
  background-color: #fff;
  
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);


  display: grid;
  grid-template-rows: 6% 94%; 
}

.combinedcard-modal-content .toprow{
  /* background-color: red; */
}
.combinedcard-modal-content .tabcontent{
  /* background-color: blue;   */
}


.radialcard-modal-content {
  transform-origin: top right;
  width: 100%;
  height:100%;
  margin: auto;
  text-align: center;
  position: relative;
  background-color: #fff;
  
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);


  display: grid;
  grid-template-rows: 60% 40%; 
}

.radialTop .card-half {
  width: 40vw;
  height: 32vh;
  margin-top:2vh;  
  margin-right:auto;
  margin-left:auto;
}
.radialTop .radial-left{
  display: grid;
  grid-template-rows: 10.5% 90%;
  margin-left: 5%;
  max-height: 100% !important;
}

.radial-left .radial-name{
  /* margin-top: 5%; */
  margin-top:auto;
  /* margin-bottom:auto; */
  font-size: 16px;
  font-weight: 700;
  
}
.radial-left .radial-photo{
  margin-top:auto;
  margin-bottom:auto;
  margin-left:auto;
  margin-right:auto;
  height: auto;    
  width: 68%;
}
.radialTop{
  display: grid;
  grid-template-columns: 25% 75%; 
}

.radialBottom{
  /* background-color: red; */
  display: flex;
  flex-direction: column;
}
.radialBottom .add-comment{
  width: 20%;
  margin-left: auto;
  margin-right:auto;
  
}


.radialcard-modal-content .btn:hover {
  transform:scale(105%,105%);
} 

/* Comments */
.radialcard-modal-content .commenter {
  width: 90%;
  margin-left: auto;
  margin-right:auto;
}
.radialcard-modal-content .commenter .adding-buttons{
  height:4vh;
}
.radialcard-modal-content .commenter .adding-buttons .btn{
  height: 100%;
  font-size: 0.9em;
}


.radialcard-modal-content .comment {
  background: #f4f5f7;
  border-radius: 4px;
  margin: 8px 0;
  padding-left: 8px;
  padding-top: 8px;
  width: 96%; 
  text-align: left;
}

.radialcard-modal-content .comment pre {
  margin-bottom: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  font-family: 'Open Sans';
}
.radialcard-modal-content .comment-timestamp pre {
  color: #555;
}



.radialcard-modal-content .comments {
  overflow-y: auto;
  max-height: 64%;
  margin-bottom: 5px;    
  margin-left:5%;
}
.radialcard-modal-content .comments-title {
  margin-top: 8px;
  text-align: left;
  margin-left:5%;
}

.radialcard-modal-content .comment-timestamp {
  font-size: 0.8em;
  margin-top: -0.5em;
  left : 2%;
  display:inline-block;
}

.radialcard-modal-content .comment-username {
  position: relative;
  font-size: 0.8em;
  margin-top: -0.5em;
  display:inline-block;
}

.storylinestab{
  width: 98%;
  height:100%;
  margin-left:1%;
}

.storylines-content {
  width: 100%;
  height:100%;
  
  text-align: left;
  position: relative;

  display: grid;
  grid-template-rows: 10% 85%; 
  z-index: 1;
}


.storylines-content .storyTop{
  display: grid;
  grid-template-rows: repeat(4);
}

.storylines-content .storyBottom{
  width:100%;
  
}

.storyBottom .title{
  text-align: left;
}

.storyBottom .header{
  /* width:90%;   */
  width:100%;
  margin-left:auto;
  margin-right:auto;

  display: grid;
  grid-template-columns: 9.8% auto;
  border: 1px solid black;
  text-align: center;
  
  background-color: #00AFED77;
  
  visibility: hidden;
  
}
.storyBottom .header >div{
  border-right: 1px solid black;
}
.storyBottom .bottomcontent{
  width: 58.8vw;
  height: 90%;
  border: 1px solid black;
  z-index: 1;
}

.storyBottom .nodata{
  height: 100%; 
  text-align: center;
  padding-top: 15%;
  font-style: italic;
  font-size: 125%;
  
}
.storyBottom .storytable{
  height: 100%; 
  max-height: 100%; 
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.storytile-content {
  /* width: 90%; */
  width: 100%;
  height:12vh;
  text-align: center;  
  border-bottom: 1px solid black;  
  border-right: 1px solid black;
  margin-left:auto;
  margin-right:auto;
  z-index: 1;

  transition-duration:250ms; 
  /* border: 1px solid black; */
  background-color: white;
}

.storytile-content .tiletable{
  height:100%;
  display: grid;
  grid-template-columns: 12% 89%;
  text-align: center;
}

.storytile-content .date{
  height: 12vh;
  text-align: center;
  /* border-right: 1px solid black;   */
  padding-top:60%;
}

.storytile-content .chartWrap{
  height: 12vh;
  text-align: center;
  position: relative;
}



.storytile-content:hover {
  transform:scale(1.01,105%) translate(0.5%,2.5%); 
  /* background-color: #00AFED15; */
  background-color: rgb(204,239,251);
  border-top: 1px solid black;
  z-index: 2;
}
.storytile-content:active {
  transform:scale(.98);  
}



.storyline-content {
  width: 100%;
  height:100%;
  
  text-align: center;  
  display: grid;
  grid-template-rows: 12% 80% 8%;
  
  /* margin-top: 3%; */
}

/* .storytile-content > * {
  background-color: #ccc;
} */

.storyline-content .titleSection{
  width: 100%;
  /* background-color: red; */
  display: grid;  
  grid-template-columns: 23% 15% 30% 30%; 
  grid-template-rows: auto;
  text-align: left;
  /* margin-left:5%; */
  margin-top:auto;
  margin-bottom:auto;
  font-size: clamp(10px,1vw,16px);
  /* font-size: 1vw;  */
  /* font-size: 110%; */
  margin-left:2vh;  

}

.storyline-content .body{
  width: 100%;
  display: grid;
  grid-template-columns: 77% 22%;
}

.storyline-content .body .left{
  /* width: 100%; */
  display: grid;
  grid-template-rows: 60% 40%;
}

.storyline-content .stemplot{
  /* height: 10vh; */
  /* position: absolute; */
  height: 100%;
  width:100%;
  max-width: 100;
  /* overflow-x: scroll; */
  overflow-x: hidden;
}
/* Prevent the notecards from running out off the edge of the card */
.storyline-content .body .right{
  max-height: 100%;
  overflow: hidden;
}

.storyline-content .review-card-list{
  width: 100%;
  height: 100%;
}
/* .storyline-content .review-card-list .ReactVirtualized__Grid, .storyline-content .ReactVirtualized__List {
  width:100% ;
  height: 100% ; 
} */
/* .storyline-content .ReactVirtualized__Grid__innerScrollContainer {
  height: 100%;
  max-height: 100%;
  overflow-y: auto !important; 
}  */


.storyline-content .spinner-wrapper{
  /* position: absolute; */
  width:100%;
  height: 100%;
}

.storyline-content .summary{
  /* background-color: red; */
  /* margin-left:10%; */
  text-align: left;  
  margin-left:2vh;  
}

.storyline-content .title{
  /* margin-left:-5%; */
  text-align: left;
  font-size: 125%;
  font-weight: bold;
  margin-bottom:2%;
  /* border-top: 1px solid black; */
}

/* .storyline-content .summary >div >span{
  margin-left: 5%;  
} */


.storyline-content .bottom{
  display:grid;
  grid-template-columns: 80% 20%;
}

.storyline-content .downloadicon{
  height: 40%;
  width: auto;
}

/* .storyline-content .report-btn{
  height: 30%;
  width: auto;
  margin: auto;
  border-radius: 20%;
} */

.storyline-content .footer{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.storyline-content .footer .btn{
  font-size: 1em;
  margin-bottom: 2px;  
}
.storyline-content .btn:hover:enabled{
  transform:scale(1.1,1.1);
  font-size: 1em;
}
.storyline-content .btn:disabled{
  opacity: .3;
}
.storyline-content .btn[disabled]:hover{
  transform:scale(1,1);
  background-color:#28a745;
  border-color:#28a745;
}



.storycall-modal {
  min-width: 88vw;
  width: 88vw;
  height: 40vh;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-left:auto;
  margin-right:auto;
  margin-top: 10vh;
}

.storycall-modal .storycall-modal-content {
   width: 100%; 
  height:100%;
  margin: auto;
  text-align: center;
  position: relative;
  background-color: #fff;
  
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

}

.storycall-modal .callin-table{
  font-size: .85vw !important;
  width: 100%;
}

/* Add a vertical scroll bar to the table */
.storycall-modal .callin-table >tbody {
  display:block;
  height:34vh;
  overflow-y:scroll;
}
.storycall-modal .callin-table >thead{
  display:table;
  width:100%;
  table-layout:fixed;
}
.storycall-modal .callin-table >tbody >tr {
  display:table;
  width:100%;
  table-layout:fixed;
}


/* Set a max column width? */
.storycall-modal .callin-table >tbody >tr >td {
 width:unset; 
overflow-x: hidden; 

text-overflow: ellipsis;    
vertical-align: middle
}

/* Set custom column widths? */
.storycall-modal .callin-table >thead >tr >th:nth-child(1) {  width:11.4%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(2) {  width:11.4%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(3) {  width:6.9%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(4) {  width:11.4%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(5) {  width:11.4%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(6) {  width:11.4%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(7) {  width:11.4%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(8) {  width:11.4%!important;} 
.storycall-modal .callin-table >thead >tr >th:nth-child(9) {  width:13.3%!important;}  

.storycall-modal .callin-table >tbody >tr >td:nth-child(1) {  width:11.5%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(2) {  width:11.5%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(3) {  width:7%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(4) {  width:11.5%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(5) {  width:11.5%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(6) {  width:11.5%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(7) {  width:11.5%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(8) {  width:11.5%!important;} 
.storycall-modal .callin-table >tbody >tr >td:nth-child(9) {  width:12.5%!important;}  

.storyreport-modal {
  width: 45vw;  
  height: 85vh;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-left:auto;
  margin-right:auto; 
  margin-top: 5vh;
  box-sizing: border-box;
  max-width: unset;
}
.storyreport-modal .scrolling-report{
  width: 100%;
  max-height: 100%;
  overflow-y: auto;

  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
}



.storyreport-modal .storyreport-modal-content {
  width:100%;
  height: 100%;

  margin-top: -2vh; 
  text-align: center;  
  background-color: #fff;
  
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);


  display: flex;
  flex-direction: column;

  padding-left:1%;
  padding-right:1%;
  text-align: left;
  font-size: 13px;

  
  z-index: 2;
}

.storyreport-modal-content .top{
  min-height: 29vh;
  height: auto;
  width: 100%;
  display: grid;
  grid-template-rows: 18vh 1.5em 1.5em 1.5em 1.5em;
  margin-bottom: 2%; 
}
.storyreport-modal-content .e3-logo{
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  padding-top: 1.5vh;
  padding-right: 1%;
  z-index: 2;
}


.storyreport-modal-content .photo{
  margin-top:auto;
  margin-bottom:auto;
  
  height: 15vh;    
  width: auto;
}


.storyreport-modal-content .events{
  width: 100%;
}

.storyreport-modal-content .title{
  grid-column:unset;
  grid-row:unset;
  font-size: 150%;
  font-weight: 400;
}

.storyreport-modal-content .events .title{
  text-align: left;
  padding-left:unset;
}
.storyreport-modal-content .events >div{
  padding-left:2%;
}

.storyreport-modal-content .notecardlist{
  margin-left: 8vw;
  list-style-type: unset;
}
.storyreport-modal-content .notecardsfoot{
  padding-top:1%;
  padding-bottom:1%;
  margin-left: 8vw;
}
.storyreport-modal-content .listelem{
  margin-left:5%;
}

.storyreport-modal-content .calls{
  max-width: 90%;
  overflow-x: auto;  
  margin-left:auto;
  margin-right:auto;
  margin-top:1%;
}

.reportcalls-table ,.reportcalls-table th,.reportcalls-table td{
  border:1px solid black;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}
 

.storyreport-modal-content .conclusion .title{
  text-align: left;
  padding-left:unset;
}
.storyreport-modal-content .conclusion >div{
  padding-left:2%;
}

.storyreport-modal-content .top .header{
  max-height: 100%;
  height: 100%;
  width: 100%;
  position: relative;

  display: grid;
  grid-template-columns: 80% 20%;
}
.storyreport-modal-content .bottom{
  margin-top:5vh;
  bottom:5px;
  width: 99%;
  padding-left:1%;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.storyreport-modal .scrolling-report .downloadicon{
  height:7vh;
  width:auto;
  position: relative;

  z-index: 5;

  margin-top: -7vh; 
  transform: translateX(37vw) translateY(15.5vh); 
  
}
.storyreport-modal .scrolling-report .downloadicon:hover{
  transform:translateX(37vw) translateY(15.5vh) scale(1.1,1.1);
}


.genreport-modal-dialog {
  margin-top: 15vh;
  height: auto ;
  width: 30vw;
  height: 15vh;
  max-width: 80vw; 
  -webkit-user-select: none; 
      -ms-user-select: none; 
          user-select: none;
  transition-duration:250ms; 
}
.genreport-modal-dialog .genreport-content { 
  width: 100%;
  height:100%;
  margin: auto;
  text-align: center;
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  background-clip: padding-box;
}



.drivercard{
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
    height:100%;
}
.drivercard .drivertab-content {

    transform-origin: top right;
    width: 100%;
    height:100%;
    margin: auto;
    text-align: center;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

}
.drivertab-content .dc-title{
    margin-left:auto;
    margin-right:auto;
    font-size: 26px;
    text-align: center;
}

.drivertab-content .dc-top{
    margin-top: 1em;
    margin-bottom: 1em;
    height:60%;
    display: flex;
}
.drivertab-content .dc-top .dc-tl{
    width: 65%;
}
.drivertab-content .dc-top .dc-tr{
    width: 35%;
}

.drivertab-content .driverphoto{
    width:50%;
    height:auto;
}

.drivertab-content .hamburger{
    width: 2%;
    height: auto;
    position: absolute;
    top: 6px;
    right: 8px;
}

.drivercard .add-action {
    width: 100%;
}


.drivercard .dc-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
}
.drivercard .dc-footer .btn {
    font-size: 0.9em;
    padding: 8px;
    margin-bottom: 2px;
    pointer-events: all;
    /* margin-right: 5px; */
    /* height: 25px; */
} 

.drivercard .dc-footer .btn:hover {
    transform:scale(105%,105%);
}


.drivercard .dc-footer .blank-spacer {
    width: 30%;
    border: none;
  }

 .drivercard .dc-footer .delete-region {
    margin-top: 8px;
    width:15%;
}
.drivercard  .add-comment {
    margin-top: 2em;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
.drivercard .dc-footer .close-region {
    margin-top: 8px;
    width:15%;
}

.drivercard .settings-grid {
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    grid-template-rows: auto auto auto auto;
    grid-gap: 5px 0px;
    gap: 5px 0px;
    margin-top:5%;
    margin-left:5%;
  }


.drivercard .settings-grid .entry-field{
    height: 2em;
    width:95%;
}
.drivercard .settings-grid .title-type{
    font-size: 14px;
    grid-column: 1/3;
    grid-row:auto;
    margin-top: auto;
}
.drivercard .settings-grid .title-units{
    font-size: 14px;
    grid-row:auto;
    margin-top: auto;
    text-align: left;
}

.drivercard .settings-grid .title{
    font-size: 14px;
    grid-row:auto;
}


.drivercard .empty-bottom{
    height:7em;
}


.drivercard .soc-spec-tall{
    display: grid;
    grid-template-columns: 50px 100px 125px 100px 100px 125px 100px;
    align-items: baseline;
    margin-top:5px;
}
.drivercard .soc-spec-tall .title-wide{
    font-size: 14px;
    grid-column: span 2;
    grid-row:auto;
}
.drivercard .soc-spec-tall .title{
    font-size: 14px;
    grid-column: span 1;
    grid-row:auto;
}
.drivercard--content .soc-spec-tall .entry-field{
    height:5.5em;
    width:95%
}
.drivertab-content .soc-spec-tall .entry-field-wide{
    height:5.5em;
    grid-column: span 2;
}

.drivertab-content .soc-spec{
    display: grid;
    grid-template-columns: 50px 100px 100px 50px 100px 50px;
    grid-gap: 5px 0px;
    gap: 5px 0px;
    margin-left:5%;
    margin-bottom:1%;
}
.drivertab-content .soc-spec .title-wide{
    font-size: 14px;
    grid-column: 1/3;
    grid-row:auto;
    text-align: left;
}
.drivertab-content .soc-spec .entry-field{
    height:2em;
    width:95%
}
.drivertab-content .soc-spec .entry-field-wide{
    height:2em;    
    grid-column: span 3;
}

.drivertab-content .soc-lister-row table.video-table {
    table-layout: auto !important; 
 }
.drivertab-content .soc-lister tr.soc-name {
   overflow: hidden;
   text-overflow: ellipsis;
   text-align:left;
 }
.drivertab-content .soc-lister tr.soc-group {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}
.drivertab-content .soc-lister tr.soc-remove {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}


.drivertab-content .btn:hover {
    transform:scale(105%,105%);
}

/* Comments */
.drivertab-content .commenter {
    width: 82.5%;
    margin-left: auto;
    margin-right:auto;
}
.drivertab-content .commenter .adding-buttons{
    height:4vh;
}
.drivertab-content .commenter .adding-buttons .btn{
    height: 100%;
    font-size: 0.9em;
}


.drivertab-content .comment {
    width: 96%; 
    text-align: left;
}

.drivertab-content .comment pre {
    margin-bottom: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
    font-family: 'Open Sans';
}
.drivertab-content .comment-timestamp pre {
    color: #555;
}



.drivertab-content .comments {
    overflow-y: auto;
    max-height: 64%;
    margin-bottom: 5px;    
    margin-left:5%;
}
.drivertab-content .comments-title {
    margin-top: 8px;
    text-align: left;
    margin-left:5%;
}


.drivertab-content .comment-timestamp {
    font-size: 0.8em;
    margin-top: -0.5em;
    left : 2%;
    display:inline-block;
}

.drivertab-content .comment-username {
    position: relative;
    font-size: 0.8em;
    margin-top: -0.5em;
    display:inline-block;
}


.staffperformance{
  display: grid;                  /* Layout of the parent view is a grid */
  grid-template-rows: 10% 90%;    /* 10% of screen to toprow, 90% to remaining content */
  grid-template-columns: 100%;    /* Only one column */
  
  -webkit-user-select: none;
  
      -ms-user-select: none;
  
          user-select: none;              /* Not selectable */
  margin-top:-4vh; 
  height:67vh;                    
}

.staffperformance .toprow{
  display: grid;  
  grid-template-columns: 25% 75%; /* Split top row 25/75 between tabs and filters */
  grid-template-rows: 100%;       /* Only one row */
  /* height: 2.5vh; */
}

.staffperformance .topRight{
  display: flex;  
  align-items: center;
  /* grid-template-columns: 20% 80%; */
  /* margin-top:-2.5vh;  */
  margin-left:auto;
}

.staffperformance .performancecontent{
  /* display: grid;
  grid-template-columns: 100%;     */
  /* Only one column */
  height:100%;
  overflow: auto;
  position:relative;
}

.staffperformance .callin-filters{
  grid-column: unset;
  margin-top:-1em;
}

.staffperformance .react-datepicker-wrapper{
  z-index: 5;
}

.staffperformance .date-checkboxes{
  font-size: 14px;
  display: flex;
  grid-gap: .75vw;
  gap: .75vw;
  padding: .7vh 0px .7vh 0px;
}

.staffperformance .comparison-checkboxes{
  /* padding-right: .5vw; */
  font-size: 14px;
  display: flex;
  grid-gap: .75vw;
  gap: .75vw;
  padding: .7vh .5vw .7vh 0px;
}

.staffperformance .accuracytab{
  height:45vh;
}

.staffperformance .responstab{
  height:149vh;
}

.staffperformance .statisticstab {
  /* height: 100%;  */
  font-family: sans-serif;
  text-align: center;
  font-size: 16px;
  align-items: center;
  width: 50%;
  margin: 0 auto;
}

.staffperformance .cbox {
  margin-bottom: .75rem;
}

.staffperformance .cbox > input {
  margin-right: 5px;
}

.staffperformance .error-table {
  margin: 0 0 0 0;
  font-size: 17px;
  /* height: 9vh !important; */
}

.staffperformance .error-table > thead > tr > th {
  text-align: center!important;
  font-size: 16px;
}

.staffperformance .title {
  font-weight: bold;
  font-size: 18px;
}

.staffperformance .spacer{
  height:7vh;
  width: 40px;
}

.staffperformance .accuracytab .echarts-for-react{
  /* height: 46.39% !important; */
  height: 100% !important;
}

.staffperformance .responstab .echarts-for-react{
  height: 30.20134% !important;
  /*height: 14.754% !important;*/
}

.staffperformance .callin-filters{
  max-width: 45vw;
}
.staffperformance .hidden{
  display:none;
  height:100%;
}


/*only apply if the screen width is less than */
 @media  (max-width:1300px)  { 
  /* Update the font on the checkboxes and dropdown labels */
  .staffperformance .filter-drop-container {    font-size: 12px; } 
  .staffperformance .comparison-checkboxes { font-size: 12px; }
  .staffperformance .date-checkboxes { font-size: 12px; grid-gap: .5vw}
  .staffperformance .date-pick { width:90px !important; font-size: 12px;}

  /* Update the spacing of the top row,  */
  .staffperformance .toprow{
    grid-template-columns: 28% 72%; /* Split top row 25/75 between tabs and filters */
  }

  .staffperformance .filter-drop-staff select.form-control.form-control-default{    max-width: 10ch; } /*XXYYYY format allowed without ellipsis*/
  .staffperformance .filter-drop-bin select.form-control.form-control-default{    max-width: 9ch; } 
  
  /* .staffperformance .date-checkboxes .cbox{max-width: 50px;} */
  .staffperformance .date-checkboxes .cbox:nth-child(1){ font-size: 0px;} 
  .staffperformance .date-checkboxes .cbox:nth-child(1)::after{ content: "Prev 3 Months"; font-size:12px} 
  /*Update the font size of the tab labels */
  .staffperformance .viewSelection > a {font-size: 12px;} 
}



/* Allow the title of the columns to word wrap onto the next line */
.averagereviewtime th.sortable,th{
    white-space: unset;
}


.shiftsummary{
  position:relative;
  height:100%;
  width: 100%;
}

.shiftplots{
  position:relative;
  display: grid;                  /* Layout of the parent view is a grid */
  grid-template-rows: 95% 5%;    /* 10% of screen to toprow, 90% to remaining content */
  grid-template-columns: 100%;    /* Only one column */
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;              /* Not selectable */

  height:90%;                    
}

.shiftplots .plots{
  height:100%;
  overflow: auto;  
}

.shiftplots .plots{
  border: 1px black solid;
}


.shiftplots .controls{
  display:flex;
  flex-direction: rows;
  align-items: center;
  /* justify-content: center; */
  justify-content: space-between;
}
.shiftplots .controls > div{
  min-width: 30vw;
  /* background-color: red; */
}
.shiftplots .controls > div:nth-child(1){
  text-align: left;
}


.shiftplots .controls .btn:hover{
    transform:scale(1.1,1.1); 
}
.shiftplots .controls .btn[disabled]:hover{
  transform:scale(1,1);
}
.shiftplots .controls .btn:active{
  transform:scale(.9,.9);
  -webkit-transform:scale(.9,.9);
  -moz-transform:scale(.9,.9);
}

.shiftplots .pageCount{
  padding-left: 2vw;
  padding-right: 2vw;
}


/* @import '../Util/animations.css'; */

.train-drivers{
  
  margin-top: -4em; /* Offset the main box app  */
  margin-left: 2%;
  margin-right: 2%;
}
.train-drivers-layout{
  display: grid;
  grid-template-columns: 25% 70%;
  grid-gap: 5%;
  gap: 5%; 
  height:50vh;
}

.train-drivers .upload{
  /* background: red; */
  
}
.train-drivers .content{
  /* background: blue; */
  border: solid 1px;
}

.dropzone{
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2;
  border-radius: 2;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  /* height: 100px; */
  
}

.train-drivers .viewSelection{    
    margin-top: 0.5vh;
    /* margin-top: -4em; */
}

/* Format the loaded image, force it to fill the space */
.train-drivers-layout > p > img{
    height: 100%;
}

.train-drivers-layout .selectedcontent{
    /* The tabs at the top fo the section consume 40px? */
    height: calc(100% - 40px);
    
}

.eval-driver{
    height: 100%;
}

/* Layout the grid to show the top 3 matches for the photo */
.eval-driver .driverDisplay {
    display: grid;
    grid-template-columns: repeat(3, 32%);
    grid-template-rows: 100%;
    grid-column-gap: 0.5%;
    height: 70%;
}

.selectedcontent .v-center{  margin-top: 10%;}
.videocutnotecard.expanded-card-modal{
    margin-right:auto;
    margin-left:auto;
    margin-top:12vh;
}

/*=====================================================================
  Style the notifications icon
=====================================================================*/

.notifications .show-notification {
    visibility: hidden !important;
    opacity: 0 !important;
    transform: none !important;
}
  
.notifications .show-notification.shown-menu {
    visibility: visible !important;
    opacity: 1 !important;
}

.notifications .ti-bell{
    font-size: 1.25em;
}
.notifications .ti-bell.showpending{
    color: red;
}
/* Override the defaults so that there is less spacing around the bell icon */
.notifications > a{
    padding: unset !important;
}
.shake-icon {
    -webkit-animation: jump-shaking 1.0s infinite;
            animation: jump-shaking 1.0s infinite;
}

.fade-icon {
    -webkit-animation: flash 1.1s infinite;
            animation: flash 1.1s infinite;
}

@-webkit-keyframes tilt-shaking {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
}

@keyframes tilt-shaking {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
}
@-webkit-keyframes jump-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateY(-5px) }
    35% { transform: translateY(-5px) rotate(17deg) }
    55% { transform: translateY(-5px) rotate(-17deg) }
    65% { transform: translateY(-5px) rotate(17deg) }
    75% { transform: translateY(-5px) rotate(-17deg) }
    100% { transform: translateY(3px) rotate(0) }
  }
@keyframes jump-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateY(-5px) }
    35% { transform: translateY(-5px) rotate(17deg) }
    55% { transform: translateY(-5px) rotate(-17deg) }
    65% { transform: translateY(-5px) rotate(17deg) }
    75% { transform: translateY(-5px) rotate(-17deg) }
    100% { transform: translateY(3px) rotate(0) }
  }



/* .flash {
    -webkit-animation-name: flash;
    animation-name: flash
} */
.alert-icon {
    margin-top: 45%;
    width: 50px;
    height: 50px;
    margin-right: 25px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  






/*=====================================================================
  Handle the logout menu logic
=====================================================================*/
.user-profile .show-notification {
  visibility: hidden !important;
  opacity: 0 !important;
  transform: none !important;
}

.user-profile .show-notification.shown-menu {
  visibility: visible !important;
  opacity: 1 !important;
}
  






/* Style a popup that Displays a simple message and asks the user to confirm or deny the options */

.videocutreview-modal{
    margin-top: 10vh; 
    width: 75vw;
    height: 70vh;    
    max-width: 70vw; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
}


/* Set attribute for modal content that is child of the userconfirm-modal */
.videocutreview-modal .content-modal {
    /* Define a grid Title, body, footer */
    display: grid;
    grid-template-rows: fit-content(25%) 50% 25%;
    grid-template-columns: 100%; 

    font-size: 1.25em;

    padding-left:5%;
    padding-right:5%;
}

.videocutreview-modal .message {
/* Vertically center */
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

/* Override the default behavior to move to the right side since there is only one button */
/* .videocutreview-modal .footer{
    justify-content: flex-end;
} */

/*Force a height so that the pagination is set in a constant spot*/
.videocutreview-modal .react-bootstrap-table{
    height: 51vh; 
}
.videocutreview-modal .cutsTable{
    width: 90%;
    margin-left: 5%;
    height: auto;
}

/* Format the size of the thumbnail in the table */
.videocutreview-modal .tableimg{
    /* Format the height of the thumbnail since we are defining the table in terms of height*/
    height: 6vh; 
    width: auto;
    aspect-ratio: 4.25/5;
    object-fit: fill;
}

/* Adjust the table contents for smaller screens */
@media screen and (max-width:1300px) { /*only apply if the screen width is less than*/
    /* decrease the padding for the smaller screen */
    .videocutreview-modal .cutsTable .table td{ padding: .2rem;}
    /* With decreased padding allow for more thumbnail for visiblity */
    .videocutreview-modal .tableimg{ height: 7vh; }
}


.videocutreview-modal tbody tr td {
    font-size: 90%;
    vertical-align: middle;
    width: calc(10vw - 0.75rem);
    overflow-x: hidden;
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
    white-space: unset;
}

.videocutreview-modal .nodata{
    display: flex;
    font-style: italic;
    align-items: center;
    justify-content: center;

}
/*=============================
  Layout of the password prompt
================================*/

.registeredlogin{
    display: block;
    padding-top: 90px;
    max-width: 500px;
    margin: 30px auto;
}
